import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import {
	CreateIssueActionTypes,
	FetchIssuesActionTypes,
} from "../actions/feedback"

export type FeedbackState = {
	status: AsyncStates
	error: string
	createIssueStatus: AsyncStates
	createIssueError: string
	data: {
		count: number;
		data: any[]
	}
}

const defaultState: FeedbackState = {
	status: AsyncStates.INITIAL,
	error: "",
	createIssueStatus: AsyncStates.INITIAL,
	createIssueError: "",
	data: { count: 0, data: [] },
}

const feedbackReducer = handleActions<FeedbackState, any>(
	{
		[FetchIssuesActionTypes.REQUEST]: (state) => {
			return {
				...state,
				status: AsyncStates.LOADING,
				error: "",
			}
		},
		[FetchIssuesActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				status: AsyncStates.SUCCESS,
				error: "",
				data: action.payload,
			}
		},
		[FetchIssuesActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[CreateIssueActionTypes.REQUEST]: (state) => {
			return {
				...state,
				status: AsyncStates.LOADING,
				error: "",
			}
		},
		[CreateIssueActionTypes.SUCCESS]: (state) => {
			return {
				...state,
				createIssueStatus: AsyncStates.SUCCESS,
				createIssueError: "",
			}
		},
		[CreateIssueActionTypes.FAILURE]: (state, action) => ({
			...state,
			createIssueStatus: AsyncStates.ERROR,
			createIssueError: action.payload.error,
		}),
		[FetchIssuesActionTypes.CLEANUP]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
			createIssueStatus: AsyncStates.INITIAL,
			createIssueError: "",
		}),
	},
	defaultState
)

export default feedbackReducer
