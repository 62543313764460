import { createActions } from "redux-actions";
import { FormulationDatum } from "../../services/formulation/interface";

export enum FormulationDetailsActionTypes {
  REQUEST = "FORMULATION_DETAILS_REQUEST",
  SUCCESS = "FORMULATION_DETAILS_SUCCESS",
  FAILURE = "FORMULATION_DETAILS_FAILURE",
  CLEANUP = "FORMULATION_DETAILS_CLEANUP",
  MARK_FOR_ML_REQUEST = "MARK_FOR_ML_REQUEST",
  MARK_FOR_ML_SUCCESS = "MARK_FOR_ML_SUCCESS",
  MARK_FOR_ML_FAILURE = "MARK_FOR_ML_FAILURE",
}

export enum FetchSimilarExperimentsActionTypes {
  REQUEST = "FETCH_SIMILAR_EXPERIMENTS_REQUEST",
  SUCCESS = "FETCH_SIMILAR_EXPERIMENTS_SUCCESS",
  FAILURE = "FETCH_SIMILAR_EXPERIMENTS_FAILURE",
  CLEANUP = "FETCH_SIMILAR_EXPERIMENTS_CLEANUP",
}

export const {
  formulationDetailsRequest,
  formulationDetailsSuccess,
  formulationDetailsFailure,
  formulationDetailsCleanup,
  markForMlRequest,
  markForMlSuccess,
  markForMlFailure,
  fetchSimilarExperimentsRequest,
  fetchSimilarExperimentsSuccess,
  fetchSimilarExperimentsFailure,
  fetchSimilarExperimentsCleanup,
} = createActions({
  [FormulationDetailsActionTypes.REQUEST]: (formulationId: Array<string>) => ({
    formulationId,
  }),
  [FormulationDetailsActionTypes.SUCCESS]: (
    formulationDetailsData: FormulationDatum[]
  ) => ({
    formulationDetailsData,
  }),
  [FormulationDetailsActionTypes.FAILURE]: (error: string) => ({ error }),
  [FormulationDetailsActionTypes.CLEANUP]: () => { },
  [FormulationDetailsActionTypes.MARK_FOR_ML_REQUEST]: (payload) => payload,
  [FormulationDetailsActionTypes.MARK_FOR_ML_SUCCESS]: (response) => response,
  [FormulationDetailsActionTypes.MARK_FOR_ML_FAILURE]: (error) => ({ error }),
  [FetchSimilarExperimentsActionTypes.REQUEST]: payload => payload,
  [FetchSimilarExperimentsActionTypes.SUCCESS]: payload => payload,
  [FetchSimilarExperimentsActionTypes.FAILURE]: payload => payload,
  [FetchSimilarExperimentsActionTypes.CLEANUP]: () => { },
});
