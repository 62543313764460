import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import { UploadFileActionTypes, FileHistoryActionTypes, DeleteFileActionTypes } from "../actions/files";

export type FilesState = {
  uploadFile: {
    status: AsyncStates;
    error: string;
    data: any
  },
  fileHistory: {
    status: AsyncStates;
    error: string;
    data: any[]
  },
  deleteFile: {
    status: AsyncStates;
    error: string;
  },
}

const defaultState: FilesState = {
  uploadFile: {
    status: AsyncStates.INITIAL,
    error: "",
    data: {}
  },
  fileHistory: {
    status: AsyncStates.INITIAL,
    error: "",
    data: []
  },
  deleteFile: {
    status: AsyncStates.INITIAL,
    error: "",
  },
}

const filesReducer = handleActions(
  {
    [UploadFileActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        uploadFile: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        }
      })
    },
    [UploadFileActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload
        }
      };
    },
    [UploadFileActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      uploadFile: {
        ...state.uploadFile,
        status: AsyncStates.ERROR,
        error: action.payload,
      }
    }),
    [UploadFileActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      uploadFile: {
        status: AsyncStates.INITIAL,
        error: "",
        data: {}
      }
    }),
    [FileHistoryActionTypes.REQUEST]: (state) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.LOADING,
        error: "",
        data: []
      }
    }),
    [FileHistoryActionTypes.FAILURE]: (state) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.ERROR,
        error: "",
        data: []
      }
    }),
    [FileHistoryActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.SUCCESS,
        error: "",
        data: action.payload
      }
    }),
    [DeleteFileActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.INITIAL,
        error: ""
      }
    }),
    [DeleteFileActionTypes.FAILURE]: (state) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.ERROR,
        error: ""
      }
    }),
    [DeleteFileActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.SUCCESS,
        error: ""
      },
      fileHistory: {
        ...state.fileHistory,
        data: state.fileHistory.data.filter((res: any) => res.file_id !== action.payload)
      }
    })
  },
  defaultState
);

export default filesReducer;
