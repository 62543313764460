import { createActions } from "redux-actions";

export enum FetchMethodListActionTypes {
  REQUEST = "FETCH_METHOD_LIST_REQUEST",
  SUCCESS = "FETCH_METHOD_LIST_SUCCESS",
  FAILURE = "FETCH_METHOD_LIST_FAILURE",
}

export enum CreateMethodActionTypes {
  REQUEST = "CREATE_METHOD_REQUEST",
  SUCCESS = "CREATE_METHOD_SUCCESS",
  FAILURE = "CREATE_METHOD_FAILURE",
  RESET = "CREATE_METHOD_RESET",
}

export enum SaveCharacterizationSetsActionTypes {
  REQUEST = "SAVE_CHARACTERIZATION_SETS_REQUEST",
  SUCCESS = "SAVE_CHARACTERIZATION_SETS_SUCCESS",
  FAILURE = "SAVE_CHARACTERIZATION_SETS_FAILURE",
}


export enum DeleteCharacterizationSetsActionTypes {
  REQUEST = "DELETE_CHARACTERIZATION_SETS_REQUEST",
  SUCCESS = "DELETE_CHARACTERIZATION_SETS_SUCCESS",
  FAILURE = "DELETE_CHARACTERIZATION_SETS_FAILURE",
}

export enum DeleteMethodActionTypes {
  REQUEST = "DELETE_METHOD_REQUEST",
  SUCCESS = "DELETE_METHOD_SUCCESS",
  FAILURE = "DELETE_METHOD_FAILURE"
}

export enum CheckVariationUsedActionTypes {
  REQUEST = "CHECK_VARIATION_USED_REQUEST",
  SUCCESS = "CHECK_VARIATION_USED_SUCCESS",
  FAILURE = "CHECK_VARIATION_USED_FAILURE",
  CLEAR = "CHECK_VARIATION_USED_CLEAR"
}

export enum UpdateCharacterizationMethodActionTypes {
  REQUEST = "UPDATE_CHARACTERIZATION_METHOD_REQUEST",
  SUCCESS = "UPDATE_CHARACTERIZATION_METHOD_SUCCESS",
  FAILURE = "UPDATE_CHARACTERIZATION_METHOD_FAILURE",
  CLEAR = "UPDATE_CHARACTERIZATION_METHOD_CLEAR"
}

export const {
  fetchMethodListRequest,
  fetchMethodListSuccess,
  fetchMethodListFailure,
  createMethodRequest,
  createMethodSuccess,
  createMethodFailure,
  createMethodReset,
  saveCharacterizationSetsRequest,
  saveCharacterizationSetsSuccess,
  saveCharacterizationSetsFailure,
  deleteCharacterizationSetsRequest,
  deleteCharacterizationSetsSuccess,
  deleteCharacterizationSetsFailure,
  deleteMethodRequest,
  deleteMethodSuccess,
  deleteMethodFailure,
  checkVariationUsedRequest,
  checkVariationUsedSuccess,
  checkVariationUsedFailure,
  checkVariationUsedClear,
  updateCharacterizationMethodRequest,
  updateCharacterizationMethodSuccess,
  updateCharacterizationMethodFailure,
  updateCharacterizationMethodClear
} = createActions({
  [FetchMethodListActionTypes.REQUEST]: (payload) => payload,
  [FetchMethodListActionTypes.SUCCESS]: (payload) => payload,
  [FetchMethodListActionTypes.FAILURE]: (payload) => payload,
  [CreateMethodActionTypes.REQUEST]: (payload) => payload,
  [CreateMethodActionTypes.SUCCESS]: (payload) => payload,
  [CreateMethodActionTypes.FAILURE]: (payload) => payload,
  [CreateMethodActionTypes.RESET]: (payload) => payload,
  [SaveCharacterizationSetsActionTypes.REQUEST]: (payload) => payload,
  [SaveCharacterizationSetsActionTypes.SUCCESS]: (payload) => payload,
  [SaveCharacterizationSetsActionTypes.FAILURE]: (payload) => payload,
  [DeleteCharacterizationSetsActionTypes.REQUEST]: (payload) => payload,
  [DeleteCharacterizationSetsActionTypes.SUCCESS]: (payload) => payload,
  [DeleteCharacterizationSetsActionTypes.FAILURE]: (payload) => payload,
  [DeleteMethodActionTypes.REQUEST]: (payload) => payload,
  [DeleteMethodActionTypes.SUCCESS]: (payload) => payload,
  [DeleteMethodActionTypes.FAILURE]: (payload) => payload,
  [CheckVariationUsedActionTypes.REQUEST]: (payload) => payload,
  [CheckVariationUsedActionTypes.SUCCESS]: (payload) => payload,
  [CheckVariationUsedActionTypes.FAILURE]: (payload) => payload,
  [CheckVariationUsedActionTypes.CLEAR]: () => { },
  [UpdateCharacterizationMethodActionTypes.REQUEST]: (payload) => payload,
  [UpdateCharacterizationMethodActionTypes.SUCCESS]: (payload) => payload,
  [UpdateCharacterizationMethodActionTypes.FAILURE]: (payload) => payload,
  [UpdateCharacterizationMethodActionTypes.CLEAR]: () => { },
});
