import { handleActions } from "redux-actions"
import { LanguageUnion } from "src/utils/useTranslate"
import { setDefaultHeadersActionTypes } from "../actions/defaultHeaders"

export type DefaultHeadersState = {
	"Content-Type": "application/json" | "multipart/form-data"
	"Access-Control-Allow-Origin": "*"
	token: string
	lang: LanguageUnion
	platform: string
}

const defaultState: DefaultHeadersState = {
	"Content-Type": "application/json",
	"Access-Control-Allow-Origin": "*",
	lang: "en",
	token: "",
	platform: process.env.REACT_APP_CURRENT_APP ?? ""
}

const defaultHeadersReducer = handleActions<
	DefaultHeadersState,
	DefaultHeadersState
>(
	{
		[setDefaultHeadersActionTypes.SET_DEFAULT_HEADERS]: (state, action) => {
			if (action.payload.lang === "ja") {
				return {
					...action.payload,
					lang: "ja",
				} as any
			}

			return action.payload
		},
	},
	defaultState
)
export default defaultHeadersReducer
