import { createActions } from "redux-actions";
import { ForgotPasswordPayload, ForgotPasswordResponse } from "../../services/passwordReset/interface"

export enum ForgotPasswordActionTypes {
  REQUEST = "FORGOT_PASSWORD_REQUEST",
  SUCCESS = "FORGOT_PASSWORD_SUCCESS",
  FAILURE = "FORGOT_PASSWORD_FAILURE",
  CLEAR = "FORGOT_PASSWORD_CLEAR",
}

export const {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordClear
} = createActions({
  [ForgotPasswordActionTypes.REQUEST]: (payload: ForgotPasswordPayload) => payload,
  [ForgotPasswordActionTypes.SUCCESS]: (response: ForgotPasswordResponse) => response,
  [ForgotPasswordActionTypes.FAILURE]: (error: string) => ({ error }),
  [ForgotPasswordActionTypes.CLEAR]: () => { }
})


