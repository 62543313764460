import { put, takeLatest, call, select } from "redux-saga/effects";
import { teams, updateUserRoles, updateUserAccessApi, revokeUserAccessApi, reactivateAccountApi, invitationApprovalApi } from "src/services/teams";
import { teamsFailure, teamsRequest, teamsSuccess, updateUserRolesFailure, updateUserRolesRequest, updateUserRolesSuccess, updateUserAccessRequest, updateUserAccessFailure, updateUserAccessSuccess, revokeUserAccessRequest, reactivateAccountRequest, approveInvitationRequest, revokeUserAccessSuccess, revokeUserAccessFailure, reactivateAccountSuccess, reactivateAccountFailure, approveInvitationSuccess, approveInvitationFailure } from "../actions/teams";
import { fetchProjectListRequest } from 'src/store/actions/projects'
import jwtManager from "src/utils/jwtManager";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import { notification } from "antd";
import { currentPlatform } from "src/App";
import { updateLoginResponse } from "../actions/login";


type teamsAction = {
    type: string;
    payload: any
};

function* teamsSaga(action: teamsAction): Generator<any, any, any> {
    try {
        const { defaultHeaders, login: { loginResponse } } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    data,
                    status,
                    message,
                    subscription_status,
                    license_period_end,
                    license_period_start,
                    trial_end_date
                },
            },
        } = yield call(teams, null, headers);
        if (status === "Success") {
            yield put(teamsSuccess(data));
            if (subscription_status && currentPlatform === "connect") {
                yield put(updateLoginResponse({ ...loginResponse, subscription_status, license_period_end, license_period_start, trial_end_date }))
            }
        } else {
            yield put(teamsFailure(message));
        }
    } catch (error) {
        yield put(teamsFailure(error));
    }
}

function* updateUserRolesSaga(action: teamsAction): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const params = {
            ...action.payload,
        }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(updateUserRoles, params, headers);
        if (status === "Success") {
            yield put(updateUserRolesSuccess());
            yield put(teamsRequest())
        } else {
            yield put(updateUserRolesFailure(message));
        }
    } catch (error) {
        yield put(updateUserRolesFailure(error));
    }
}


function* updateUserAccessSaga(action: teamsAction): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(updateUserAccessApi, action.payload, headers);
        if (status === "Success") {
            yield put(updateUserAccessSuccess());
            yield put(fetchProjectListRequest())
        } else {
            yield put(updateUserAccessFailure(message));
        }
    } catch (error) {
        yield put(updateUserAccessFailure(error));
    }
}

function* revokeUserAccessSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(revokeUserAccessApi, payload, headers);
        if (status === "Success") {
            yield put(revokeUserAccessSuccess());
            yield put(teamsRequest())
            notification.success({
                message
            })
        } else {
            notification.error({
                message,
                placement: "bottomRight",
            })
            yield put(revokeUserAccessFailure(message));
        }
    } catch (error) {
        yield put(revokeUserAccessFailure(error ?? messages[ln]?.internal_server_error));
    }
}

function* reActivateAccountSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(reactivateAccountApi, payload, headers);
        if (status === "Success") {
            yield put(reactivateAccountSuccess());
            yield put(teamsRequest())
            notification.success({
                message,
                placement: "bottomRight",
            })
        } else {
            yield put(reactivateAccountFailure(message));
        }
    } catch (error) {
        yield put(reactivateAccountFailure(error ?? messages[ln]?.internal_server_error));
    }
}

function* invitationApprovalSaga(action: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    )

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const payload = action.payload

        const {
            data: {
                result: { status, message },
            },
        } = yield call(invitationApprovalApi, payload, headers)

        if (status === "Success") {
            yield put(approveInvitationSuccess())
            yield put(teamsRequest())
            notification["success"]({
                message: message,
                placement: "bottomRight",
            })
        } else {
            notification["success"]({
                message: message,
                placement: "bottomRight",
            })
            yield put(approveInvitationFailure(message))
        }
    } catch (error) {
        yield put(approveInvitationFailure(error))
        notification["error"]({
            message: messages[ln].internal_server_error,
            placement: "bottomRight",
        })
    }
}




export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(teamsRequest, teamsSaga)
    yield takeLatest(updateUserRolesRequest, updateUserRolesSaga)
    yield takeLatest(updateUserAccessRequest, updateUserAccessSaga)
    yield takeLatest(revokeUserAccessRequest, revokeUserAccessSaga)
    yield takeLatest(reactivateAccountRequest, reActivateAccountSaga)
    yield takeLatest(approveInvitationRequest, invitationApprovalSaga)
}
