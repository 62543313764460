import { Action, handleActions } from "redux-actions"
import { CatDataSummaryActionTypes } from "src/store/actions/catDataSummary"
import { AsyncStates } from "src/constants"
import { CategoricalSummary } from "src/components/AIEngine/DataSummary/types"

export type CatDataSummaryReducerState =
	| {
		status: AsyncStates.SUCCESS
		data: CategoricalSummary
		error: null
		message?: string
	}
	| {
		status: AsyncStates.INITIAL
		data: null
		error: null
		message?: string
	}
	| {
		status: AsyncStates.ERROR
		data: null
		error: string
		message?: string
	}
	| {
		status: AsyncStates.LOADING
		data: CategoricalSummary | null
		error: null
		message?: string
	}

const defaultState: CatDataSummaryReducerState = {
	status: AsyncStates.INITIAL,
	data: null,
	error: null,
}

const DataSummaryReducer = handleActions<
	CatDataSummaryReducerState,
	{ data: CategoricalSummary; error: string }
>(
	{
		[CatDataSummaryActionTypes.REQUEST]: (state) => ({
			status: AsyncStates.LOADING,
			data: state.data,
			error: null,
		}),
		[CatDataSummaryActionTypes.SUCCESS]: (
			state,
			action: Action<{ data: CategoricalSummary }>
		) => ({
			status: AsyncStates.SUCCESS,
			data: {
				...state.data,
				...action.payload.data,
			},
			error: null,
		}),
		[CatDataSummaryActionTypes.FAILURE]: (
			state,
			action: Action<{ error: string }>
		) => ({
			status: AsyncStates.ERROR,
			data: null,
			error: action.payload.error,
		}),
		[CatDataSummaryActionTypes.CLEAR]: () => ({
			status: AsyncStates.INITIAL,
			data: null,
			error: null,
		}),
	},
	defaultState
)

export default DataSummaryReducer
