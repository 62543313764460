import { createActions } from "redux-actions"

export enum PivotTableActionTypes {
	REQUEST = "PIVOT_TABLE_REQUEST",
	SUCCESS = "PIVOT_TABLE_SUCCESS",
	FAILURE = "PIVOT_TABLE_FAILURE",
	CLEAR = "PIVOT_TABLE_CLEAR",
}

export const {
	pivotTableRequest,
	pivotTableSuccess,
	pivotTableFailure,
	pivotTableClear,
} = createActions({
	[PivotTableActionTypes.REQUEST]: (payload) => payload,
	[PivotTableActionTypes.SUCCESS]: (response) => response,
	[PivotTableActionTypes.FAILURE]: (error) => ({ error }),
	[PivotTableActionTypes.CLEAR]: () => {},
})
