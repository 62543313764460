import { createActions } from "redux-actions";


export enum CelsureDataUploadActionTypes {
  REQUEST = "SAVE_CELSURE_DATA_REQUEST",
  SUCCESS = "SAVE_CELSURE_DATA_SUCCESS",
  FAILURE = "SAVE_CELSURE_DATA_FAILURE",
  RESET = "SAVE_CELSURE_DATA_RESET",
}

export enum GetCelsureTrialsActionTypes {
  REQUEST = "GET_CELSURE_TRIALS_REQUEST",
  SUCCESS = "GET_CELSURE_TRIALS_SUCCESS",
  FAILURE = "GET_CELSURE_TRIALS_FAILURE",
}

export enum CelsureDisplayNamesActionTypes {
  REQUEST = "CELSURE_DISPLAY_NAMES_REQUEST",
  SUCCESS = "CELSURE_DISPLAY_NAMES_SUCCESS",
  FAILURE = "CELSURE_DISPLAY_NAMES_FAILURE",
}

export enum CelsureDeleteTrialsActionTypes {
  REQUEST = "CELSURE_DELETE_TRIALS_REQUEST",
  SUCCESS = "CELSURE_DELETE_TRIALS_SUCCESS",
  FAILURE = "CELSURE_DELETE_TRIALS_FAILURE",
}

export enum CelsureEditTrialActionTypes {
  REQUEST = "CELSURE_EDIT_TRIAL_REQUEST",
  SUCCESS = "CELSURE_EDIT_TRIAL_SUCCESS",
  FAILURE = "CELSURE_EDIT_TRIAL_FAILURE",
  RESET = "CELSURE_EDIT_TRIAL_RESET",
}

export enum SaveIstaProfilesActionTypes {
  REQUEST = "SAVE_ISTA_PROFILES_REQUEST",
  SUCCESS = "SAVE_ISTA_PROFILES_SUCCESS",
  FAILURE = "SAVE_ISTA_PROFILES_FAILURE",
}

export enum DeleteIstaProfilesActionTypes {
  REQUEST = "DELETE_ISTA_PROFILES_REQUEST",
  SUCCESS = "DELETE_ISTA_PROFILES_SUCCESS",
  FAILURE = "DELETE_ISTA_PROFILES_FAILURE",
}

export enum CelsureInventoryActionTypes {
  REQUEST = "CELSURE_INVENTORY_REQUEST",
  SUCCESS = "CELSURE_INVENTORY_SUCCESS",
  FAILURE = "CELSURE_INVENTORY_FAILURE",
}

export enum CelsureEditingStateActionTypes {
  SET_EDITING_WO = "SET_CELSURE_EDITING_WO_STATE",
  SET_EDITING_TRIALS = "SET_CELSURE_EDITING_TRIALS_STATE"
}

export enum ResetCelsureActionTypes {
  RESET = "RESET_CELSURE_DATA"
}


export const {
  saveCelsureDataRequest,
  saveCelsureDataSuccess,
  saveCelsureDataFailure,
  saveCelsureDataReset,
  getCelsureTrialsRequest,
  getCelsureTrialsSuccess,
  getCelsureTrialsFailure,
  celsureDisplayNamesRequest,
  celsureDisplayNamesSuccess,
  celsureDisplayNamesFailure,
  celsureDeleteTrialsRequest,
  celsureDeleteTrialsSuccess,
  celsureDeleteTrialsFailure,
  celsureEditTrialRequest,
  celsureEditTrialSuccess,
  celsureEditTrialFailure,
  celsureEditTrialReset,
  saveIstaProfilesRequest,
  saveIstaProfilesSuccess,
  saveIstaProfilesFailure,
  deleteIstaProfilesRequest,
  deleteIstaProfilesSuccess,
  deleteIstaProfilesFailure,
  celsureInventoryRequest,
  celsureInventorySuccess,
  celsureInventoryFailure,
  setCelsureEditingWoState,
  setCelsureEditingTrialsState,
  resetCelsureData
} = createActions({
  [CelsureDataUploadActionTypes.REQUEST]: payload => payload,
  [CelsureDataUploadActionTypes.SUCCESS]: response => response,
  [CelsureDataUploadActionTypes.FAILURE]: () => { },
  [CelsureDataUploadActionTypes.RESET]: payload => payload,
  [GetCelsureTrialsActionTypes.REQUEST]: payload => payload,
  [GetCelsureTrialsActionTypes.SUCCESS]: response => response,
  [GetCelsureTrialsActionTypes.FAILURE]: () => { },
  [CelsureDisplayNamesActionTypes.REQUEST]: payload => payload,
  [CelsureDisplayNamesActionTypes.SUCCESS]: response => response,
  [CelsureDisplayNamesActionTypes.FAILURE]: () => { },
  [CelsureDeleteTrialsActionTypes.REQUEST]: payload => payload,
  [CelsureDeleteTrialsActionTypes.SUCCESS]: response => response,
  [CelsureDeleteTrialsActionTypes.FAILURE]: () => { },
  [CelsureEditTrialActionTypes.REQUEST]: payload => payload,
  [CelsureEditTrialActionTypes.SUCCESS]: response => response,
  [CelsureEditTrialActionTypes.FAILURE]: () => { },
  [CelsureEditTrialActionTypes.RESET]: (payload) => payload,
  [SaveIstaProfilesActionTypes.REQUEST]: payload => payload,
  [SaveIstaProfilesActionTypes.SUCCESS]: response => response,
  [SaveIstaProfilesActionTypes.FAILURE]: () => { },
  [DeleteIstaProfilesActionTypes.REQUEST]: payload => payload,
  [DeleteIstaProfilesActionTypes.SUCCESS]: response => response,
  [DeleteIstaProfilesActionTypes.FAILURE]: () => { },
  [CelsureInventoryActionTypes.REQUEST]: payload => payload,
  [CelsureInventoryActionTypes.SUCCESS]: response => response,
  [CelsureInventoryActionTypes.FAILURE]: () => { },
  [CelsureEditingStateActionTypes.SET_EDITING_WO]: payload => payload,
  [CelsureEditingStateActionTypes.SET_EDITING_TRIALS]: payload => payload,
  [ResetCelsureActionTypes.RESET]: () => { },
})
