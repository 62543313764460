import axios from "axios";
import { Headers } from "../interface";

export const getPerformanceApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + "get_performance",
    params,
    {
      headers,
    },
  );
};
