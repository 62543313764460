import { createActions } from "redux-actions";


export enum InsightsActionTypes {
    INSIGHTS_SOCKET_CONNECT = 'INSIGHTS_SOCKET_CONNECT',
    INSIGHTS_SUCCESS = 'INSIGHTS_SUCCESS',
    INSIGHTS_FAILURE = 'INSIGHTS_FAILURE',
    CLEAR_INSIGHTS = 'CLEAR_INSIGHTS',
    SUGGESTED_EXP_INSIGHTS = 'SUGGESTED_EXP_INSIGHTS',
    CLEAR_SUGGESTED_EXP_INSIGHTS = 'CLEAR_SUGGESTED_EXP_INSIGHTS'
}

export enum GetCustomInsightsDataActionTypes {
    REQUEST = "GET_CUSTOM_INSIGHTS_DATA_REQUEST",
    SUCCESS = "GET_CUSTOM_INSIGHTS_DATA_SUCCESS",
    FAILURE = "GET_CUSTOM_INSIGHTS_DATA_FAILURE",

}

export const {
    insightsSocketConnect,
    insightsSuccess,
    insightsFailure,
    clearInsights,
    suggestedExpInsights,
    clearSuggestedExpInsights,
    getCustomInsightsDataRequest,
    getCustomInsightsDataSuccess,
    getCustomInsightsDataFailure
} = createActions({
    [InsightsActionTypes.INSIGHTS_SOCKET_CONNECT]: (payload) => payload,
    [InsightsActionTypes.INSIGHTS_SUCCESS]: (response) => response,
    [InsightsActionTypes.INSIGHTS_FAILURE]: (error: string) => ({ error }),
    [InsightsActionTypes.CLEAR_INSIGHTS]: () => { },
    [InsightsActionTypes.SUGGESTED_EXP_INSIGHTS]: (response) => response,
    [InsightsActionTypes.CLEAR_SUGGESTED_EXP_INSIGHTS]: () => { },
    [GetCustomInsightsDataActionTypes.REQUEST]: (payload) => payload,
    [GetCustomInsightsDataActionTypes.SUCCESS]: (response) => response,
    [GetCustomInsightsDataActionTypes.FAILURE]: error => ({ error }),
})
