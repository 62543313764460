import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import { ConversionActionTypes, UnitListActionTypes } from "../actions/conversion"

export type ConversionState = {
  formulas: any,
  status: AsyncStates,
  error: string,
  unitList: any[],
  unitListStatus: AsyncStates,
  unitListError: string
}

const defaultState: ConversionState = {
  formulas: {},
  status: AsyncStates.INITIAL,
  error: "",
  unitList: [],
  unitListStatus: AsyncStates.INITIAL,
  unitListError: ""
}

const conversionReducer = handleActions(
  {
    [UnitListActionTypes.REQUEST]: (state) => ({
      ...state,
      unitListStatus: AsyncStates.LOADING,
    }),
    [UnitListActionTypes.SUCCESS]: (state, action: any) => {
      return ({
        ...state,
        unitListStatus: AsyncStates.SUCCESS,
        unitList: action.payload
      })
    },
    [UnitListActionTypes.FAILURE]: (state) => ({
      ...state,
      unitListError: AsyncStates.ERROR,
    }),
    [ConversionActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
    }),
    [ConversionActionTypes.SUCCESS]: (state, action: any) => {
      return ({
        ...state,
        status: AsyncStates.SUCCESS,
        formulas: {
          ...state.formulas,
          ...action.payload.reduce((acc: any, curr: any) => ({ ...acc, [Object.keys(curr)[0]]: Object.values(curr)[0] }))
        }
      })
    },
    [ConversionActionTypes.FAILURE]: (state) => ({
      ...state,
      error: AsyncStates.ERROR,
    }),
  },
  defaultState
)

export default conversionReducer
