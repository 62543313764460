import { createActions } from "redux-actions"

export enum NewInverseModelActionTypes {
	REQUEST = "NEW_INVERSE_MODEL_REQUEST",
	SUCCESS = "NEW_INVERSE_MODEL_SUCCESS",
	FAILURE = "NEW_INVERSE_MODEL_FAILURE",
	NEW_INVERSE_ACK = "NEW_INVERSE_ACK",
}

export enum FetchVersionListActionTypes {
	REQUEST = "FETCH_VERSION_LIST_REQUEST",
	SUCCESS = "FETCH_VERSION_LIST_SUCCESS",
	FAILURE = "FETCH_VERSION_LIST_FAILURE",
}

export const {
	newInverseModelRequest,
	newInverseModelSuccess,
	newInverseModelFailure,
	newInverseAck,
	fetchVersionListRequest,
	fetchVersionListSuccess,
	fetchVersionListFailure,
} = createActions({
	[NewInverseModelActionTypes.REQUEST]: (payload) => payload,
	[NewInverseModelActionTypes.SUCCESS]: (response) => response,
	[NewInverseModelActionTypes.FAILURE]: (error) => ({ error }),
	[NewInverseModelActionTypes.NEW_INVERSE_ACK]: (response) => response,
	[FetchVersionListActionTypes.REQUEST]: (payload) => payload,
	[FetchVersionListActionTypes.SUCCESS]: (response) => response,
	[FetchVersionListActionTypes.FAILURE]: (error) => ({ error }),
})
