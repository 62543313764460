import { useEffect, useState } from "react"
import { Divider, Typography, Row, Badge } from "antd"
import { blue, green, red, yellow } from "@ant-design/colors"
import useTranslate from "src/utils/useTranslate"

const { Link, Text } = Typography

const Footer = () => {
	const [t] = useTranslate()

	const [state, setstate] = useState(yellow.primary)

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "v1/data/_server_health_check")
			.then(() => setstate(green.primary))
			.catch(() => setstate(red.primary))
	}, [])

	return (
		<div>
			<Divider />
			<Row className="footer-row">
				<Link
					style={{ color: blue.primary }}
					type="secondary"
					href="https://polymerize.io/privacy-policy"
					target="_blank"
					rel="noreferrer"
				>
					{t("common.privacyPolicy")}
				</Link>

				<Link
					style={{ color: blue.primary }}
					type="secondary"
					href="https://polymerize.io/terms-of-use"
					target="_blank"
					rel="noreferrer"
				>
					{t("footer.tc")}
				</Link>

				<Badge
					text={
						<Link
							style={{ color: blue.primary }}
							href="https://polymerize.freshstatus.io/"
							target="_blank"
							rel="noreferrer"
						>
							Service Status
						</Link>
					}
					status="processing"
					color={state}
				/>

				<Text type="secondary" className="all-right-reserved">
					{t("navbar.footer.rightsReserved")} © {new Date().getFullYear()}
				</Text>
			</Row>
		</div>
	)
}
export default Footer
