import { handleActions } from "redux-actions"
import {
  CelsureDataUploadActionTypes, GetCelsureTrialsActionTypes, CelsureDisplayNamesActionTypes,
  ResetCelsureActionTypes, CelsureDeleteTrialsActionTypes, CelsureEditTrialActionTypes, CelsureEditingStateActionTypes,
  SaveIstaProfilesActionTypes, DeleteIstaProfilesActionTypes, CelsureInventoryActionTypes
} from "../actions/celsure"
import { AsyncStates } from "../../constants"


export type CelsureReducerState = {
  saveDataStatus: AsyncStates,
  getCelsureTrialsStatus: AsyncStates,
  celsureTrialsList: any,
  celsureDisplayNamesData: any,
  celsureDisplayNamesStatus: AsyncStates
  celsureDeleteTrialsStatus: AsyncStates
  celsureEditTrialStatus: AsyncStates
  celsureEditingWoState: boolean
  celsureEditingTrialsState: boolean
  saveIstaProfilesStatus: AsyncStates,
  deleteIstaProfilesStatus: AsyncStates,
  celsureInventoryStatus: AsyncStates
}

const defaultState: CelsureReducerState = {
  saveDataStatus: AsyncStates.INITIAL,
  getCelsureTrialsStatus: AsyncStates.INITIAL,
  celsureTrialsList: { total_records: 0, data: [] },
  celsureDisplayNamesData: {},
  celsureDisplayNamesStatus: AsyncStates.INITIAL,
  celsureDeleteTrialsStatus: AsyncStates.INITIAL,
  celsureEditTrialStatus: AsyncStates.INITIAL,
  celsureEditingWoState: false,
  celsureEditingTrialsState: false,
  saveIstaProfilesStatus: AsyncStates.INITIAL,
  deleteIstaProfilesStatus: AsyncStates.INITIAL,
  celsureInventoryStatus: AsyncStates.INITIAL
}

const celsureReducer = handleActions(
  {
    [CelsureDataUploadActionTypes.REQUEST]: (state) => ({
      ...state,
      saveDataStatus: AsyncStates.LOADING,
    }),
    [CelsureDataUploadActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      saveDataStatus: AsyncStates.SUCCESS,
    }),
    [CelsureDataUploadActionTypes.FAILURE]: (state, action) => ({
      ...state,
      saveDataStatus: AsyncStates.ERROR,
    }),
    [CelsureDataUploadActionTypes.RESET]: (state, action) => ({
      ...state,
      saveDataStatus: AsyncStates.INITIAL,
    }),
    [GetCelsureTrialsActionTypes.REQUEST]: (state) => ({
      ...state,
      getCelsureTrialsStatus: AsyncStates.LOADING,
    }),
    [GetCelsureTrialsActionTypes.SUCCESS]: (state, action: any) => {
      return ({
        ...state,
        getCelsureTrialsStatus: AsyncStates.SUCCESS,
        celsureTrialsList: {
          previous_id: action.payload.previous_id,
          total_records: action.payload.total_records,
          data: action.payload.reset ?
            [...(action.payload.celsure_trials_data.map((data: any) => ({ ...data, overall_enthalpy_value: data.pcm_type.reduce((acc: any, current: any) => { return acc += current.enthalpy }, 0) })) || [])] :
            [
              ...(state.celsureTrialsList.data.map((data: any) => ({ ...data, overall_enthalpy_value: data.pcm_type.reduce((acc: any, current: any) => { return acc += current.enthalpy }, 0) })) || []),
              ...(action.payload.celsure_trials_data.map((data: any) => ({ ...data, overall_enthalpy_value: data.pcm_type.reduce((acc: any, current: any) => { return acc += current.enthalpy }, 0) })) || [])
            ]
        },
      })
    },
    [GetCelsureTrialsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      getCelsureTrialsStatus: AsyncStates.ERROR,
    }),
    [CelsureDisplayNamesActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureDisplayNamesStatus: AsyncStates.LOADING,
      celsureDisplayNamesData: {}
    }),
    [CelsureDisplayNamesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureDisplayNamesStatus: AsyncStates.SUCCESS,
      celsureDisplayNamesData: action.payload
    }),
    [CelsureDisplayNamesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureDisplayNamesStatus: AsyncStates.ERROR,
      celsureDisplayNamesData: {}
    }),
    [CelsureDeleteTrialsActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureDeleteTrialsStatus: AsyncStates.LOADING,
    }),
    [CelsureDeleteTrialsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureDeleteTrialsStatus: AsyncStates.SUCCESS,
    }),
    [CelsureDeleteTrialsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureDeleteTrialsStatus: AsyncStates.ERROR,
    }),
    [CelsureEditTrialActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureEditTrialStatus: AsyncStates.LOADING,
    }),
    [CelsureEditTrialActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureEditTrialStatus: AsyncStates.SUCCESS,
    }),
    [CelsureEditTrialActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureEditTrialStatus: AsyncStates.ERROR,
    }),
    [CelsureEditTrialActionTypes.RESET]: (state, action) => ({
      ...state,
      celsureEditTrialStatus: AsyncStates.INITIAL,
    }),
    [SaveIstaProfilesActionTypes.REQUEST]: (state) => ({
      ...state,
      saveIstaProfilesStatus: AsyncStates.LOADING,
    }),
    [SaveIstaProfilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      saveIstaProfilesStatus: AsyncStates.SUCCESS,
    }),
    [SaveIstaProfilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      saveIstaProfilesStatus: AsyncStates.ERROR,
    }),
    [DeleteIstaProfilesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteIstaProfilesStatus: AsyncStates.ERROR,
    }),
    [DeleteIstaProfilesActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteIstaProfilesStatus: AsyncStates.LOADING,
    }),
    [DeleteIstaProfilesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteIstaProfilesStatus: AsyncStates.SUCCESS,
    }),
    [CelsureInventoryActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureInventoryStatus: AsyncStates.ERROR,
    }),
    [CelsureInventoryActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureInventoryStatus: AsyncStates.LOADING,
    }),
    [CelsureInventoryActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureInventoryStatus: AsyncStates.SUCCESS,
    }),
    [CelsureEditingStateActionTypes.SET_EDITING_WO]: (state, action: any) => ({
      ...state,
      celsureEditingWoState: action.payload,
    }),
    [CelsureEditingStateActionTypes.SET_EDITING_TRIALS]: (state, action: any) => ({
      ...state,
      celsureEditingTrialsState: action.payload,
    }),
    [ResetCelsureActionTypes.RESET]: () => defaultState
  },
  defaultState
)

export default celsureReducer
