import { handleActions } from "redux-actions";
import { BillingDetailsActionTypes, SettingsActionTypes, UpdateBillingDetailsActionTypes, UpdatePaymentMethodActionTypes, UpdateSubscriptionActionTypes } from "../actions/settings";
import { AsyncStates } from "../../constants";
import { SettingsResponse } from "../../services/settings/interface"

export type SettingsReducerState = {
    status: AsyncStates;
    updatedStatus: AsyncStates;
    error: string;
    data: SettingsResponse,
    message: {},
    billingDetails: any,
    billingDetailsStatus: AsyncStates,
    billingDetailsErrorMessage: string,
    updatePaymentMethodStatus: AsyncStates,
    updatePaymentMethodErrorMessage: string,
    updateBillingDetailsStatus: AsyncStates,
    updateBillingDetailsErrorMessage: string,
    updateSubscriptionStatus: AsyncStates,
    updateSubscriptionErrorMessage: string
}

const defaultState: SettingsReducerState = {
    status: AsyncStates.INITIAL,
    updatedStatus: AsyncStates.INITIAL,
    error: "",
    data: { company: { company_name: '', location: '', stages: [{ identifier: '', name: '' }] }, stages: [{ identifier: '', name: '' }] },
    message: "",
    billingDetails: {},
    billingDetailsStatus: AsyncStates.INITIAL,
    billingDetailsErrorMessage: "",
    updatePaymentMethodStatus: AsyncStates.INITIAL,
    updatePaymentMethodErrorMessage: "",
    updateBillingDetailsStatus: AsyncStates.INITIAL,
    updateBillingDetailsErrorMessage: "",
    updateSubscriptionStatus: AsyncStates.INITIAL,
    updateSubscriptionErrorMessage: ""
}

const settingsReducer = handleActions({
    [SettingsActionTypes.REQUEST]: (state) => ({
        ...state,
        status: AsyncStates.LOADING,
        updatedStatus: AsyncStates.INITIAL,
        error: "",
        message: ''
    }),
    [SettingsActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            status: AsyncStates.SUCCESS,
            error: "",
            data: action.payload.data,
        };
    },
    [SettingsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        status: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [SettingsActionTypes.UPDATE_REQUEST]: (state) => ({
        ...state,
        updatedStatus: AsyncStates.LOADING,
        error: '',
    }),
    [SettingsActionTypes.UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.SUCCESS,
        error: '',
        message: action.payload
    }),
    [SettingsActionTypes.UPDATE_FAILURE]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [SettingsActionTypes.UPDATE_CLEAR]: (state) => ({
        ...state,
        status: AsyncStates.INITIAL,
        data: defaultState.data,
        updatedStatus: AsyncStates.INITIAL,
        error: '',
        message: ''
    }),
    [BillingDetailsActionTypes.REQUEST]: (state) => ({
        ...state,
        billingDetailsStatus: AsyncStates.LOADING,
        billingDetailsErrorMessage: '',
    }),
    [BillingDetailsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        billingDetails: action.payload,
        billingDetailsStatus: AsyncStates.SUCCESS,
    }),
    [BillingDetailsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        billingDetailsStatus: AsyncStates.ERROR,
        billingDetailsErrorMessage: action.payload.error,
    }),
    [BillingDetailsActionTypes.CLEAR]: (state) => ({
        ...state,
        billingDetailsStatus: AsyncStates.INITIAL,
        billingDetailsErrorMessage: '',
    }),
    [UpdatePaymentMethodActionTypes.REQUEST]: (state) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.LOADING,
        updatePaymentMethodErrorMessage: '',
    }),
    [UpdatePaymentMethodActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.SUCCESS,
    }),
    [UpdatePaymentMethodActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.ERROR,
        updatePaymentMethodErrorMessage: action.payload.error,
    }),
    [UpdatePaymentMethodActionTypes.CLEAR]: (state) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.INITIAL,
        updatePaymentMethodErrorMessage: '',
    }),
    [UpdateBillingDetailsActionTypes.REQUEST]: (state) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.LOADING,
        updateBillingDetailsErrorMessage: '',
    }),
    [UpdateBillingDetailsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.SUCCESS,
    }),
    [UpdateBillingDetailsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.ERROR,
        updateBillingDetailsErrorMessage: action.payload.error,
    }),
    [UpdateBillingDetailsActionTypes.CLEAR]: (state) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.INITIAL,
        updateBillingDetailsErrorMessage: '',
    }),
    [UpdateSubscriptionActionTypes.REQUEST]: (state) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.LOADING,
        updateSubscriptionErrorMessage: '',
    }),
    [UpdateSubscriptionActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.SUCCESS,
    }),
    [UpdateSubscriptionActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.ERROR,
        updateSubscriptionErrorMessage: action.payload.error,
    }),
    [UpdateSubscriptionActionTypes.CLEAR]: (state) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.INITIAL,
        updateSubscriptionErrorMessage: '',
    })
}, defaultState)


export default settingsReducer;