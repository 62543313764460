import { message, notification } from "antd"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { getExplainabilityApi, getExplainableAIReportApi } from "src/services/explainableAI"
import { messages } from "src/utils/hooks"
import jwtManager from "src/utils/jwtManager"
import { LanguageUnion } from "src/utils/useTranslate"
import { getExplainabilityFailure, getExplainabilityRequest, getExplainabilitySuccess, getExplainableAiReportFailure, getExplainableAiReportRequest, getExplainableAiReportSuccess } from "../actions/explainableAI"
import { StoreState } from "../configureStore"

function* getExplainabilitySaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: { status, message: errorMessage, data },
            },
        } = yield call(getExplainabilityApi, payload, headers)
        if (status === "Success") {
            yield put(getExplainabilitySuccess(data))
            notification.success({
                message: errorMessage
            })
        } else {
            yield put(getExplainabilityFailure(errorMessage))
            notification.error({
                message: errorMessage
            })
        }
    } catch (error) {
        yield put(getExplainabilityFailure(error))
        message.error(messages[ln].internal_server_error)
    }
}

function* getExplainableAIReportSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const newPayload = {
            chart_type: payload.chart_type,
            filter_names: payload.filter_names,
            model_types: payload.model_types,
            property_name: payload.property_name,
            report_name: payload.report_name,
            version: payload.version,
            bar_colors: payload?.bar_colors,
            formulation_urls: payload?.formulation_urls
        }

        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }

        const {
            data: {
                result: { data, status, message },
            },
        } = yield call(
            getExplainableAIReportApi,
            newPayload,
            headers
        )
        if (status === "Success") {
            yield put(getExplainableAiReportSuccess())
            window.open(data?.report_url, "_blank")
        } else {
            notification.error({
                message
            })
            yield put(getExplainableAiReportFailure())
        }
    } catch (error: any) {
        yield put(getExplainableAiReportFailure())
        message.error(messages[ln].internal_server_error)
    }
}


export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(getExplainabilityRequest, getExplainabilitySaga)
    yield takeLatest(getExplainableAiReportRequest, getExplainableAIReportSaga)
}
