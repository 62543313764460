import { createActions } from "redux-actions"

export enum CatDataSummaryActionTypes {
	REQUEST = "CAT_DATA_SUMMARY_REQUEST",
	SUCCESS = "CAT_DATA_SUMMARY_SUCCESS",
	FAILURE = "CAT_DATA_SUMMARY_FAILURE",
	CLEAR = "CAT_DATA_SUMMARY_CLEAR",
}

export const {
	catDataSummaryRequest,
	catDataSummarySuccess,
	catDataSummaryFailure,
	catDataSummaryClear,
} = createActions({
	[CatDataSummaryActionTypes.REQUEST]: (payload) => payload,
	[CatDataSummaryActionTypes.SUCCESS]: (data) => ({ data }),
	[CatDataSummaryActionTypes.FAILURE]: (error: string) => ({ error }),
	[CatDataSummaryActionTypes.CLEAR]: () => null,
})
