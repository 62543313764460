import { createActions } from "redux-actions";
import { FormulationDatum } from "../../services/formulation/interface";

export enum FileUploadDetailsActionTypes {
    REQUEST = "FILE_UPLOAD_REQUEST",
    SUCCESS = "FILE_UPLOAD_SUCCESS",
    FAILURE = "FILE_UPLOAD_FAILURE",
    CLEANUP = "FILE_UPLOAD_CLEANUP",
}

export const {
    fileuploadDetailsRequest,
    fileuploadDetailsSuccess,
    fileuploadDetailsFailure,
    fileuploadDetailsCleanup,
} = createActions({
    [FileUploadDetailsActionTypes.REQUEST]: (formulationId: Array<string>) => ({
        formulationId,
    }),
    [FileUploadDetailsActionTypes.SUCCESS]: (
        formulationDetailsData: FormulationDatum[]
    ) => ({
        formulationDetailsData,
    }),
    [FileUploadDetailsActionTypes.FAILURE]: (error: string) => ({ error }),
    [FileUploadDetailsActionTypes.CLEANUP]: () => {},
});
