import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import { ConfigsActionTypes } from "../actions/configs"

export type ConfigsReducerState = {
    modules: any,
    features: any,
    configsStatus: AsyncStates
}

const defaultState: ConfigsReducerState = {
    modules: {},
    features: {},
    configsStatus: AsyncStates.INITIAL
}

const chemDrawReducer = handleActions(
    {
        [ConfigsActionTypes.REQUEST]: (state) => ({
            ...state,
            configsStatus: AsyncStates.LOADING,
        }),
        [ConfigsActionTypes.SUCCESS]: (state, action: any) => ({
            ...state,
            configsStatus: AsyncStates.SUCCESS,
            modules: action.payload?.navbar_configs,
            features: action.payload?.feature_configs,
        }),
        [ConfigsActionTypes.FAILURE]: (state) => ({
            ...state,
            configsStatus: AsyncStates.ERROR,
        }),
        [ConfigsActionTypes.CLEAR]: (state) => ({
            ...state,
            configsStatus: AsyncStates.INITIAL,
        }),
    },
    defaultState
)

export default chemDrawReducer
