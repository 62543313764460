import { handleActions } from "redux-actions";

export type PreviousActionReducerState = any[]
const defaultState: PreviousActionReducerState = []

const previousActionReducer = handleActions(
    {
        'CLEAR_PREVIOUS_ACTION': (state, action) => {
            return defaultState
        },
    },
    defaultState
);

export default previousActionReducer
