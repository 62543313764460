import { handleActions } from "redux-actions";
import { FetchSimilarExperimentsActionTypes, FormulationDetailsActionTypes } from "../actions/formulationDetails";
import { AsyncStates } from "../../constants";
import { FormulationDatum } from "../../services/formulation/interface";
export type FormulationDetailsState = {
  status: AsyncStates;
  error: string;
  formulationDetailsData: FormulationDatum[] | [];
  markForMlStatus: AsyncStates,
  similarExperimentsData: {
    similarExperiments: any[];
    similarExperimentsTotal: number,
    similarExperimentsDisplayIdList: String[],
    similarExperimentsStatus: AsyncStates;
  }
};
const defaultState: FormulationDetailsState = {
  status: AsyncStates.INITIAL,
  error: "",
  formulationDetailsData: [],
  markForMlStatus: AsyncStates.INITIAL,
  similarExperimentsData: {
    similarExperiments: [],
    similarExperimentsTotal: 0,
    similarExperimentsDisplayIdList: [],
    similarExperimentsStatus: AsyncStates.INITIAL,
  }
};

const formulationDetailsReducer = handleActions(
  {
    [FormulationDetailsActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [FormulationDetailsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        formulationDetailsData: action.payload.formulationDetailsData,
      };
    },
    [FormulationDetailsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [FormulationDetailsActionTypes.CLEANUP]: (state) => ({
      ...state,
      status: AsyncStates.INITIAL,
      error: "",
      formulationDetailsData: [],
    }),
    [FormulationDetailsActionTypes.MARK_FOR_ML_REQUEST]: (state) => ({
      ...state,
      markForMlStatus: AsyncStates.LOADING
    }),
    [FormulationDetailsActionTypes.MARK_FOR_ML_SUCCESS]: (state) => ({
      ...state,
      markForMlStatus: AsyncStates.SUCCESS
    }),
    [FormulationDetailsActionTypes.MARK_FOR_ML_FAILURE]: (state) => ({
      ...state,
      markForMlStatus: AsyncStates.ERROR
    }),
    [FetchSimilarExperimentsActionTypes.REQUEST]: state => ({
      ...state,
      similarExperimentsData: {
        ...state.similarExperimentsData,
        similarExperimentsStatus: AsyncStates.LOADING
      }
    }),
    [FetchSimilarExperimentsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        similarExperimentsData: {
          similarExperimentsStatus: AsyncStates.SUCCESS,
          similarExperimentsDisplayIdList: action.payload?.display_id_list,
          similarExperiments: action.payload?.data,
          similarExperimentsTotal: action.payload?.total
        }
      }
    },
    [FetchSimilarExperimentsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      similarExperimentsData: {
        ...state.similarExperimentsData,
        similarExperimentsStatus: AsyncStates.ERROR
      }
    }),
    [FetchSimilarExperimentsActionTypes.CLEANUP]: (state, action: any) => {
      return {
        ...state,
        similarExperimentsData: {
          similarExperiments: [],
          similarExperimentsTotal: 0,
          similarExperimentsDisplayIdList: [],
          similarExperimentsStatus: AsyncStates.INITIAL,
        }
      }
    }
  },
  defaultState
);

export default formulationDetailsReducer;
