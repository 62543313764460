import { createActions } from "redux-actions";

export enum FetchIssuesActionTypes {
  REQUEST = "FETCH_ISSUES_REQUEST",
  SUCCESS = "FETCH_ISSUES_SUCCESS",
  FAILURE = "FETCH_ISSUES_FAILURE",
  CLEANUP = "ISSUES_CLEANUP",
}
export enum CreateIssueActionTypes {
  REQUEST = "CREATE_ISSUE_REQUEST",
  SUCCESS = "CREATE_ISSUE_SUCCESS",
  FAILURE = "CREATE_ISSUE_FAILURE",
}

export const {
  fetchIssuesRequest,
  fetchIssuesSuccess,
  fetchIssuesFailure,
  issuesCleanup,
  createIssueRequest,
  createIssueSuccess,
  createIssueFailure,
} = createActions({
  [FetchIssuesActionTypes.REQUEST]: payload => payload,
  [FetchIssuesActionTypes.SUCCESS]: payload => payload,
  [FetchIssuesActionTypes.FAILURE]: payload => payload,
  [FetchIssuesActionTypes.CLEANUP]: () => { },
  [CreateIssueActionTypes.REQUEST]: payload => payload,
  [CreateIssueActionTypes.SUCCESS]: payload => payload,
  [CreateIssueActionTypes.FAILURE]: payload => payload,
});
