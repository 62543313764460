import React, { ComponentPropsWithoutRef } from "react"

type P = ComponentPropsWithoutRef<"svg"> & {
	size?: number
}

export const PortugalFlag = ({ size = 24, ...props }: P) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={size}
			viewBox="0 0 600 400"
			{...props}
		>
			<path fill="red" d="M0 0H600V400H0z"></path>
			<path fill="#060" d="M0 0H240V400H0z"></path>
			<g
				fill="#ff0"
				fillRule="evenodd"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="0.573"
			>
				<path d="M318.24 262.04c-30.21-.91-168.74-87.38-169.69-101.15l7.65-12.757c13.74 19.966 155.36 104.06 169.27 101.08l-7.23 12.823"></path>
				<path d="M154.59 146.4c-2.71 7.294 36.149 31.318 82.903 59.754 46.752 28.434 87.065 46.006 90.053 43.486.183-.325 1.47-2.54 1.352-2.523-.56.844-1.923 1.11-4.05.497-12.632-3.643-45.576-18.765-86.375-43.506-40.798-24.743-76.294-47.544-81.81-57.232-.384-.671-.658-1.896-.602-2.848l-.134-.002-1.175 2.053-.161.322h-.001zm164.36 116.04c-.512.93-1.467.96-3.282.761-11.3-1.25-45.589-17.925-86.162-42.213-47.21-28.26-86.2-54.01-81.97-60.74l1.151-2.034.227.07c-3.804 11.405 76.948 57.578 81.702 60.522 46.724 28.947 86.115 45.851 89.601 41.458l-1.268 2.181v-.002z"></path>
				<path d="M240.17 169.23c30.237-.239 67.55-4.132 89.023-12.69l-4.627-7.517c-12.692 7.025-50.21 11.644-84.652 12.335-40.736-.375-69.49-4.168-83.897-13.835l-4.367 8.005c26.484 11.207 53.623 13.587 88.52 13.703"></path>
				<path d="M330.44 156.71c-.739 1.182-14.743 6.011-35.373 9.575-13.988 2.133-32.234 3.956-55.004 3.978-21.633.02-39.305-1.52-52.684-3.333-21.656-3.396-32.833-8.12-36.965-9.79.395-.785.65-1.337 1.03-2.07 11.895 4.736 23.124 7.591 36.28 9.615 13.29 1.8 30.75 3.362 52.275 3.342 22.664-.023 40.71-1.984 54.616-4.053 21.155-3.412 32.711-7.804 34.334-9.843l1.494 2.579h-.002zm-4.06-7.623c-2.293 1.842-13.718 5.894-33.82 9.104-13.414 1.922-30.471 3.643-52.264 3.664-20.704.02-37.62-1.375-50.485-3.249-20.414-2.666-31.28-7.475-35.196-8.877.39-.674.786-1.343 1.194-2.014 3.048 1.535 13.533 5.791 34.226 8.723 12.72 1.803 29.66 3.147 50.262 3.126 21.69-.022 38.553-1.776 51.883-3.688 20.205-2.78 31.077-7.948 32.728-9.241l1.473 2.45v.003zM140.88 205.66c18.598 10.003 59.905 15.044 98.994 15.391 35.591.056 81.958-5.502 99.297-14.69l-.477-10.012c-5.425 8.477-55.113 16.609-99.206 16.276-44.093-.333-85.038-7.143-98.687-15.959l.08 8.991"></path>
				<path d="M340.12 204.22v2.387c-2.605 3.116-18.945 7.826-39.436 11.142-15.595 2.391-35.927 4.195-61.262 4.195-24.07 0-43.263-1.716-58.148-4.001-23.53-3.427-38.58-9.427-41.6-11.217l.013-2.786c9.075 6.034 33.661 10.447 41.917 11.798 14.788 2.27 33.868 3.974 57.817 3.974 25.216 0 45.434-1.792 60.931-4.167 14.701-2.123 35.644-7.646 39.767-11.324h.001zm.01-8.492v2.387c-2.605 3.114-18.945 7.824-39.436 11.14-15.595 2.391-35.927 4.195-61.262 4.195-24.07 0-43.263-1.715-58.148-4.002-23.53-3.424-38.58-9.424-41.6-11.216l.013-2.785c9.075 6.033 33.661 10.447 41.917 11.796 14.788 2.272 33.868 3.976 57.817 3.976 25.216 0 45.434-1.792 60.931-4.17 14.701-2.123 35.644-7.646 39.767-11.323l.001.002zM239.79 260.32c-42.772-.255-79.421-11.659-87.16-13.544l5.643 8.834c13.67 5.75 49.424 14.32 81.927 13.371 32.504-.948 60.91-3.466 80.928-13.21l5.787-9.156c-13.642 6.425-60.068 13.639-87.125 13.705"></path>
				<path
					strokeWidth="0.55"
					d="M323.3 253.72a134.08 134.08 0 01-2.596 3.83c-9.442 3.329-24.32 6.824-30.597 7.843-12.824 2.643-32.665 4.594-50.274 4.603-37.89-.554-68.905-7.972-83.496-14.299l-1.178-2.024.193-.304 1.996.774c25.948 9.284 55.091 12.987 82.698 13.652 17.538.062 35.095-2.01 49.292-4.549 21.771-4.362 30.574-7.65 33.275-9.14l.687-.386h0zm5.017-8.275l.066.077a269.275 269.275 0 01-1.963 3.263c-5.035 1.8-18.702 5.798-38.659 8.589-13.149 1.791-21.322 3.526-47.479 4.034-49.015-1.247-80.75-10.831-88.289-13.195l-1.117-2.143c28.406 7.415 57.422 12.592 89.408 13.12 23.93-.509 34.112-2.271 47.152-4.05 23.27-3.618 34.996-7.449 38.515-8.555a2.667 2.667 0 00-.154-.2l2.523-.942-.002.002z"
				></path>
				<path d="M328.83 197.76c.139 28.137-14.26 53.386-25.858 64.525-16.408 15.759-38.163 25.896-63.569 26.363-28.37.521-55.12-17.974-62.295-26.099-14.028-15.885-25.449-36.057-25.815-63.243 1.738-30.709 13.793-52.1 31.268-66.769s40.743-21.813 60.121-21.302c22.358.59 48.475 11.558 66.521 33.332 11.823 14.266 16.943 29.748 19.627 53.193zm-89.186-96.342c54.485 0 99.296 44.338 99.296 98.703 0 54.364-44.811 98.704-99.296 98.704s-98.924-44.339-98.924-98.704 44.439-98.703 98.924-98.703"></path>
				<path d="M239.91 101.08c54.534 0 99.011 44.483 99.011 99.022 0 54.538-44.478 99.02-99.011 99.02-54.534 0-99.011-44.481-99.011-99.02s44.478-99.022 99.011-99.022zm-96.832 99.022c0 53.26 43.736 96.842 96.832 96.842 53.097 0 96.833-43.582 96.833-96.842 0-53.262-43.737-96.844-96.833-96.844s-96.832 43.584-96.832 96.844z"></path>
				<path d="M239.99 109.31c49.731 0 90.693 40.821 90.693 90.704 0 49.884-40.963 90.703-90.693 90.703s-90.693-40.819-90.693-90.703c0-49.883 40.964-90.704 90.693-90.704zm-88.515 90.703c0 48.685 39.979 88.524 88.515 88.524s88.515-39.839 88.515-88.524c0-48.686-39.978-88.525-88.515-88.525-48.536 0-88.515 39.84-88.515 88.525z"></path>
				<path d="M243.98 100.68h-8.485l.01 198.96h8.514z"></path>
				<path d="M243.13 99.546h2.16l.018 201.25h-2.161l-.016-201.25zm-8.421.002h2.176l.003 201.25h-2.178V99.548z"></path>
				<path d="M338.99 203.935v-7.355L333 191l-34-9-49-5-59 3-42 10-8.48 6.28v7.357L162 194l51-8h49l36 4 25 6z"></path>
				<path d="M239.95 184.77c23.383-.043 46.07 2.215 64.065 5.72 18.569 3.712 31.637 8.355 36.105 13.57l-.005 2.583c-5.388-6.49-22.973-11.248-36.518-13.968-17.858-3.474-40.393-5.717-63.647-5.674-24.538.046-47.387 2.37-64.984 5.803-14.12 2.802-32.951 8.368-35.302 13.858v-2.689c1.291-3.8 15.313-9.479 34.984-13.417 17.729-3.457 40.62-5.741 65.302-5.786zm.01-8.492c23.383-.042 46.07 2.217 64.065 5.72 18.569 3.713 31.637 8.355 36.105 13.57l-.005 2.583c-5.388-6.489-22.973-11.247-36.518-13.966-17.858-3.476-40.393-5.719-63.647-5.674-24.538.044-47.276 2.37-64.875 5.801-13.626 2.584-33.226 8.37-35.412 13.86v-2.69c1.291-3.76 15.597-9.642 34.985-13.417 17.729-3.458 40.62-5.74 65.302-5.787zM239.48 132.96c36.849-.184 68.99 5.152 83.695 12.685l5.364 9.279c-12.781-6.888-47.456-14.05-89.005-12.979-33.854.208-70.027 3.727-88.176 13.41l6.403-10.709c14.895-7.724 50.022-11.643 81.72-11.684"></path>
				<path d="M239.97 140.62c21.017-.056 41.325 1.13 57.476 4.044 15.041 2.799 29.385 7 31.436 9.26l1.59 2.81c-4.988-3.257-17.4-6.884-33.339-9.906-16.006-3.008-36.3-4.005-57.2-3.95-23.722-.081-42.152 1.171-57.969 3.929-16.728 3.13-28.334 7.601-31.197 9.726l1.558-2.97c5.564-2.838 14.39-6.26 29.223-8.93 16.357-2.988 34.983-3.884 58.423-4.013h0zm-.009-8.484c20.113-.053 39.972 1.068 55.452 3.85 12.209 2.377 24.283 6.088 28.704 9.39l2.326 3.695c-3.954-4.395-18.836-8.56-31.974-10.892-15.361-2.65-34.395-3.698-54.508-3.866-21.108.06-40.615 1.352-55.752 4.108-14.441 2.749-23.76 6.002-27.703 8.543l2.045-3.087c5.441-2.864 14.232-5.495 25.303-7.646 15.249-2.777 34.876-4.036 56.108-4.095zM289.15 241.26c-18.218-3.4-36.469-3.895-49.217-3.745-61.407.72-81.244 12.61-83.665 16.21l-4.59-7.482c15.635-11.332 49.074-17.687 88.588-17.037 20.518.336 38.224 1.698 53.119 4.583l-4.236 7.473"></path>
				<path
					strokeWidth="0.55"
					d="M239.58 236.46c17.082.255 33.849.96 50.033 3.978l-1.172 2.07c-15.031-2.775-31.055-3.837-48.803-3.75-22.663-.178-45.585 1.939-65.541 7.666-6.297 1.753-16.721 5.8-17.784 9.146l-1.166-1.923c.336-1.977 6.636-6.08 18.414-9.39 22.858-6.545 44.24-7.649 66.02-7.799v.002zm.775-8.596c17.698.33 35.975 1.149 53.74 4.668l-1.22 2.153c-16.042-3.184-31.37-4.246-52.415-4.57-22.735.042-46.851 1.663-68.778 8.037-7.08 2.063-19.297 6.52-19.704 10.05l-1.166-2.065c.265-3.206 10.842-7.388 20.358-10.156 22.096-6.424 46.275-8.076 69.186-8.117z"
				></path>
				<path d="M327.58 247.38l-7.379 11.449L299 240l-55-37-62-34-32.19-11.01 6.86-12.72L159 144l20 5 66 34 38 24 32 23 13 15z"></path>
				<path d="M148.65 158.29c5.646-3.83 47.139 14.655 90.555 40.834 43.301 26.254 84.677 55.921 80.942 61.473l-1.228 1.932-.564.445c.12-.087.743-.848-.06-2.906-1.846-6.07-31.196-29.491-79.895-58.895-47.475-28.31-87.04-45.371-90.997-40.494l1.247-2.39h0zm180.44 88.927c3.57-7.052-34.916-36.044-82.632-64.272-48.813-27.666-83.994-43.951-90.42-39.095l-1.428 2.6c-.012.142.052-.178.354-.411 1.168-1.02 3.105-.95 3.979-.967 11.065.166 42.667 14.71 87.006 40.128 19.428 11.315 82.071 51.491 81.832 62.79.017.97.08 1.17-.285 1.651l1.594-2.423v-.002z"></path>
			</g>
			<path
				fill="#fff"
				stroke="#000"
				strokeWidth="0.67"
				d="M180.6 211.01c0 16.271 6.663 30.987 17.457 41.742 10.815 10.778 25.512 17.579 41.809 17.579 16.381 0 31.247-6.653 42.016-17.389 10.769-10.735 17.443-25.552 17.446-41.88h-.002v-79.189l-118.74-.141.012 79.278h.002z"
			></path>
			<path
				fill="red"
				stroke="#000"
				strokeWidth="0.507"
				d="M182.82 211.12v.045c0 15.557 6.441 29.724 16.775 40.009 10.354 10.305 24.614 16.712 40.214 16.712 15.681 0 29.912-6.36 40.222-16.626 10.308-10.265 16.697-24.433 16.699-40.044h-.002V134.39l-113.84-.019-.07 76.748m91.022-53.747l.004 48.891-.041 5.172h0c0 1.361-.081 2.912-.24 4.233-.925 7.73-4.48 14.467-9.745 19.708-6.164 6.136-14.671 9.942-24.047 9.942-9.327 0-17.64-3.938-23.83-10.1-6.349-6.32-10.03-14.986-10.03-23.947l-.012-54.023 67.94.122.002.002z"
			></path>
			<g id="castle3">
				<g id="castle" fill="#ff0" stroke="#000" strokeWidth="0.5">
					<path
						stroke="none"
						d="M190.19 154.43c.135-5.521 4.052-6.828 4.08-6.847.029-.019 4.232 1.407 4.218 6.898l-8.298-.051"
					></path>
					<path d="M186.81 147.69l-.682 6.345 4.14.009c.04-5.25 3.975-6.123 4.07-6.103.09-.005 3.989 1.16 4.093 6.103h4.151l-.75-6.394-15.022.038v.002zM185.85 154.06h16.946c.357 0 .65.353.65.784 0 .43-.293.781-.65.781H185.85c-.357 0-.65-.35-.65-.781 0-.431.293-.784.65-.784z"></path>
					<path d="M192.01 154.03c.018-3.313 2.262-4.25 2.274-4.248 0 0 2.342.966 2.36 4.248h-4.634M186.21 145.05h16.245c.342 0 .623.318.623.705 0 .387-.28.704-.623.704H186.21c-.342 0-.623-.315-.623-.704 0-.387.28-.705.623-.705zM186.55 146.47h15.538c.327 0 .595.317.595.704 0 .388-.268.704-.595.704H186.55c-.327 0-.595-.316-.595-.704 0-.387.268-.704.595-.704zM191.57 135.88l1.227.002v.871h.895v-.89l1.257.004v.887h.897v-.89h1.258l-.002 2.01c0 .317-.254.521-.549.521h-4.41c-.297 0-.57-.237-.572-.526l-.003-1.988h.001zM196.19 138.57l.277 6.451-4.303-.015.285-6.453 3.741.017"></path>
					<path
						id="cp1"
						d="M190.94 141.56l.131 3.478-4.125.001.116-3.479h3.879-.001z"
					></path>
					<use x="10.609" xlinkHref="#cp1"></use>
					<path
						id="cp2"
						d="M186.3 139.04l1.2.003v.872h.877v-.892l1.23.004v.889h.879v-.893l1.23.002-.002 2.012c0 .314-.249.518-.536.518h-4.317c-.29 0-.559-.235-.56-.525l-.002-1.989h0z"
					></path>
					<use x="10.609" xlinkHref="#cp2"></use>
					<path
						fill="#000"
						stroke="none"
						d="M193.9 140.61c-.026-.627.877-.634.866 0v1.536h-.866v-1.536"
					></path>
					<path
						id="cp3"
						fill="#000"
						stroke="none"
						d="M188.57 142.84c-.003-.606.837-.618.826 0v1.187h-.826v-1.187"
					></path>
					<use x="10.641" xlinkHref="#cp3"></use>
				</g>
				<use y="46.32" xlinkHref="#castle"></use>
				<use
					transform="rotate(-45.202 312.766 180.004)"
					xlinkHref="#castle"
				></use>
			</g>
			<use x="45.714" xlinkHref="#castle"></use>
			<use transform="matrix(-1 0 0 1 479.792 0)" xlinkHref="#castle3"></use>
			<g id="quina" fill="#fff">
				<path
					fill="#039"
					d="M232.636 202.406v.005c0 2.212.85 4.227 2.212 5.69 1.365 1.466 3.245 2.377 5.302 2.377 2.067 0 3.944-.905 5.303-2.365 1.358-1.459 2.202-3.472 2.202-5.693v-10.768l-14.992-.013-.028 10.766"
				></path>
				<circle cx="236.074" cy="195.735" r="1.486"></circle>
				<circle cx="244.392" cy="195.742" r="1.486"></circle>
				<circle cx="240.225" cy="199.735" r="1.486"></circle>
				<circle cx="236.074" cy="203.916" r="1.486"></circle>
				<circle cx="244.383" cy="203.905" r="1.486"></circle>
			</g>
			<use y="-26.016" xlinkHref="#quina"></use>
			<use x="-20.799" xlinkHref="#quina"></use>
			<use x="20.745" xlinkHref="#quina"></use>
			<use y="25.784" xlinkHref="#quina"></use>
		</svg>
	)
}
