import { handleActions } from "redux-actions";
import { DisplayNamesActionTypes, FilteredDisplayNamesActionTypes } from "../actions/displayNames";
import { AsyncStates, sortingConditonKeys } from "../../constants";

export interface DisplayNamesState {
  status: AsyncStates,
  statusWithoutSpinner: AsyncStates,
  error: string,
  data: {
    [key: string]: any
  },
  inventoryData: any
};

export interface FilteredDisplayNamesState {
  filterStatus: AsyncStates,
  error: string,
  data: {
    [key: string]: any
  }
};

const defaultState: DisplayNamesState = {
  status: AsyncStates.INITIAL,
  statusWithoutSpinner: AsyncStates.INITIAL,
  error: '',
  inventoryData: {},
  data: {
    ingredients: {},
    characterizations: {},
    material: {},
    application: [],
    processing: {},
    properties: {},
    metadata_properties: {},
    stages: {},
    descriptor: { ingredients: "Ingredients", characterizations: "Characterizations", processing: "Processing", properties: "Properties" },
  }
}

const defaultFilteredState: FilteredDisplayNamesState = {
  filterStatus: AsyncStates.INITIAL,
  error: '',
  data: {},
}

const sortObjectKeys = (input: any) => {
  if (Array.isArray(input)) {
    return input.sort((a: string, b: string) => a?.localeCompare(b));
  }
  const sortedArray = Object.entries(input || {}).sort((a, b) => a[0].localeCompare(b[0]));
  return Object.fromEntries(sortedArray);
};



const displayNamesReducer = handleActions<DisplayNamesState, any>(
  {
    [DisplayNamesActionTypes.REQUEST]: (state, action: any) => ({
      ...state,
      ...(!action.payload?.backgroundFetch && { status: AsyncStates.LOADING }),
      statusWithoutSpinner: AsyncStates.LOADING,
      data: {
        ingredients: {},
        characterizations: {},
        material: {},
        processing: {},
        properties: {},
        metadata_properties: {},
        stages: {},
        descriptor: { ingredients: "Ingredients", characterizations: "Characterizations", processing: "Processing", properties: "Properties" },
      },
      error: "",
    }),
    [DisplayNamesActionTypes.SUCCESS]: (state, action) => {
      const newDataObj: any = { ...state.data }
      const displayNames = action.payload
      const constantIterator = ["ingredients", "processing", "characterizations", "properties", "material", "metadata_properties"]
      for (let i of constantIterator) {
        if (Object.keys(displayNames || {}).includes(i)) {
          displayNames[i].forEach((record: any) => {
            newDataObj[i][record.identifier] = { ...record }
          })
        }
      }
      if (Object.keys(displayNames || {}).includes("stages")) {
        newDataObj.stages = displayNames.stages.reduce((obj: any, element: any) => ({ ...obj, [element.identifier]: element.name_translated?.[action?.payload?.language] || element?.name }), {})
      }

      const resultObj = { ...displayNames, ...newDataObj }

      const arrangeByKeys = [...sortingConditonKeys, ...Object.keys(resultObj || {})]
      const rearrangedDisplayName: any = {};
      arrangeByKeys.forEach((key: string) => {
        if (resultObj.hasOwnProperty(key)) {
          if ((key !== "stages")) {
            rearrangedDisplayName[key] = sortObjectKeys(resultObj[key])
          } else {
            rearrangedDisplayName[key] = resultObj[key]
          }
        }
      });

      return ({
        ...state,
        status: AsyncStates.SUCCESS,
        statusWithoutSpinner: AsyncStates.SUCCESS,
        data: rearrangedDisplayName,
        inventoryData: action.payload,
      })
    },
    [DisplayNamesActionTypes.FAILURE]: (state) => ({
      ...state,
      status: AsyncStates.ERROR,
      statusWithoutSpinner: AsyncStates.ERROR,
    }),
    [DisplayNamesActionTypes.UPDATE]: (state, action) => {
      const data: { name: string, unit: string, category: string, property_type: string, identifier: string }[] = action.payload.displayNames.data
      const newDataState = { ...state.data }
      data.forEach((res: any) => {
        const { property_type, name, identifier, unit, category } = res
        newDataState[property_type][identifier] = { category, unit, name }
      })
      return ({
        ...state,
        data: newDataState
      })
    },
    [DisplayNamesActionTypes.CLEANUP]: (state) => ({
      ...state,
      status: AsyncStates.INITIAL,
      statusWithoutSpinner: AsyncStates.INITIAL,
      error: "",
      data: {}
    }),
  },
  defaultState
);


export const FilteredDisplayNamesReducer = handleActions<FilteredDisplayNamesState>(
  {
    [FilteredDisplayNamesActionTypes.REQUEST]: (state) => ({
      ...state,
      filterStatus: AsyncStates.LOADING,
      error: "",
    }),
    [FilteredDisplayNamesActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      filterStatus: AsyncStates.SUCCESS,
      data: action.payload.data
    }),
    [FilteredDisplayNamesActionTypes.FAILURE]: (state, action) => ({
      ...state,
      filterStatus: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [FilteredDisplayNamesActionTypes.CLEANUP]: (state) => ({
      ...state,
      filterStatus: AsyncStates.INITIAL,
      error: "",
      data: {}
    }),
  },
  defaultFilteredState
);

export default displayNamesReducer;
