import styles from "./Spinner.module.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const Spinner = () => {
  return (
    <div className={styles.container}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} />} />
    </div>
  );
};

export default Spinner;
