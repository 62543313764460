import { createActions } from "redux-actions";
import { LoginResponse } from "../../services/login/interface";

export enum LoginActionTypes {
  REQUEST = "LOGIN_REQUEST",
  SUCCESS = "LOGIN_SUCCESS",
  FAILURE = "LOGIN_FAILURE",
  NOT_VERIFIED = "LOGIN_NOT_VERIFIED",
  UPDATE_LOGIN_RESPONSE = "UPDATE_LOGIN_RESPONSE",
  SET_TOKEN_STATUS = "SET_TOKEN_STATUS"
}

export enum LoginAuthActionTypes {
  STATUS = "LOGIN_STATUS",
}

export enum RefreshTokenActionTypes {
  REQUEST = "REFRESH_TOKEN_REQUEST",
  SUCCESS = "REFRESH_TOKEN_SUCCESS",
}

export enum OtpActionTypes {
  REQUEST_OTP_REQUEST = "REQUEST_OTP_REQUEST",
  REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS",
  REQUEST_OTP_FAILURE = "REQUEST_OTP_FAILURE",
  VERIFY_OTP_TRUE_REQUEST = "VERIFY_OTP_TRUE_REQUEST",
  VERIFY_OTP_TRUE_SUCCESS = "VERIFY_OTP_TRUE_SUCCESS",
  VERIFY_OTP_TRUE_FAILURE = "VERIFY_OTP_TRUE_FAILURE",
  VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE",
  DISABLE_OTP_REQUEST = "DISABLE_OTP_REQUEST",
  DISABLE_OTP_SUCCESS = "DISABLE_OTP_SUCCESS",
  DISABLE_OTP_FAILURE = "DISABLE_OTP_FAILURE",
  SET_OTP = "SET_OTP",
  SET_VERIFICATION = "SET_VERIFICATION",
  SET_TWO_FA_STATUS = "SET_TWO_FA_STATUS",
  CLEAR_OTP_STATE = "CLEAR_OTP_STATE"
}

export enum SetProjectTypeActionTypes {
  REQUEST = "SET_PROJECT_TYPE_REQUEST",
  SUCCESS = "SET_PROJECT_TYPE_SUCCESS",
  FAILURE = "SET_PROJECT_TYPE_FAILURE",
}

export enum LogoutActionTypes {
  REQUEST = "LOGOUT_REQUEST",
  SUCCESS = "LOGOUT_SUCCESS",
  FAILURE = "LOGOUT_FAILURE",
}

export enum UpdateProfileActionTypes {
  REQUEST = "UPDATE_PROFILE_REQUEST",
  SUCCESS = "UPDATE_PROFILE_SUCCESS",
  FAILURE = "UPDATE_PROFILE_FAILURE",
}

export enum UpdateCurrencyActionTypes {
  REQUEST = "UPDATE_CURRENCY_REQUEST",
  SUCCESS = "UPDATE_CURRENCY_SUCCESS",
  FAILURE = "UPDATE_CURRENCY_FAILURE",
}

export const {
  loginRequest,
  loginNotVerified,
  loginSuccess,
  loginFailure,
  loginStatus,
  setTokenStatus,
  refreshTokenRequest,
  refreshTokenSuccess,
  requestOtpRequest,
  requestOtpSuccess,
  requestOtpFailure,
  verifyOtpTrueRequest,
  verifyOtpTrueSuccess,
  verifyOtpTrueFailure,
  verifyOtpRequest,
  verifyOtpSuccess,
  verifyOtpFailure,
  updateLoginResponse,
  disableOtpRequest,
  disableOtpSuccess,
  disableOtpFailure,
  setOtp,
  setVerification,
  setTwoFaStatus,
  clearOtpState,
  setProjectTypeRequest,
  setProjectTypeSuccess,
  setProjectTypeFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  updateCurrencyRequest,
  updateCurrencySuccess,
  updateCurrencyFailure,
} = createActions({
  [LoginActionTypes.REQUEST]: (payload) => payload,
  [LoginActionTypes.NOT_VERIFIED]: () => { },
  [LoginActionTypes.SUCCESS]: (loginResponse: LoginResponse) => ({
    loginResponse,
  }),
  [LoginActionTypes.FAILURE]: (error: string) => ({ error }),
  [LoginActionTypes.SET_TOKEN_STATUS]: (payload: string) => payload,
  [RefreshTokenActionTypes.REQUEST]: (payload) => payload,
  [RefreshTokenActionTypes.SUCCESS]: (response) => response,
  [OtpActionTypes.REQUEST_OTP_REQUEST]: (payload) => payload,
  [OtpActionTypes.REQUEST_OTP_SUCCESS]: (response) => response,
  [OtpActionTypes.REQUEST_OTP_FAILURE]: () => { },
  [OtpActionTypes.VERIFY_OTP_TRUE_REQUEST]: (payload) => payload,
  [OtpActionTypes.VERIFY_OTP_TRUE_SUCCESS]: (response) => response,
  [OtpActionTypes.VERIFY_OTP_TRUE_FAILURE]: () => { },
  [OtpActionTypes.VERIFY_OTP_REQUEST]: (payload) => payload,
  [OtpActionTypes.VERIFY_OTP_SUCCESS]: (response) => response,
  [OtpActionTypes.VERIFY_OTP_FAILURE]: () => { },
  [LoginActionTypes.UPDATE_LOGIN_RESPONSE]: (response) => response,
  [OtpActionTypes.DISABLE_OTP_REQUEST]: (payload) => payload,
  [OtpActionTypes.DISABLE_OTP_SUCCESS]: (response) => response,
  [OtpActionTypes.DISABLE_OTP_FAILURE]: () => { },
  [OtpActionTypes.SET_OTP]: (payload) => payload,
  [OtpActionTypes.SET_VERIFICATION]: (payload) => payload,
  [OtpActionTypes.SET_TWO_FA_STATUS]: (payload) => payload,
  [OtpActionTypes.CLEAR_OTP_STATE]: () => { },
  [SetProjectTypeActionTypes.REQUEST]: (payload) => payload,
  [SetProjectTypeActionTypes.SUCCESS]: (payload) => payload,
  [SetProjectTypeActionTypes.FAILURE]: (payload) => payload,
  [LogoutActionTypes.REQUEST]: (payload) => payload,
  [LogoutActionTypes.SUCCESS]: (response) => response,
  [LogoutActionTypes.FAILURE]: () => { },
  [UpdateProfileActionTypes.REQUEST]: (payload) => payload,
  [UpdateProfileActionTypes.SUCCESS]: (payload) => payload,
  [UpdateProfileActionTypes.FAILURE]: (payload) => payload,
  [UpdateCurrencyActionTypes.REQUEST]: (payload) => payload,
  [UpdateCurrencyActionTypes.SUCCESS]: (payload) => payload,
  [UpdateCurrencyActionTypes.FAILURE]: (payload) => payload,
})
