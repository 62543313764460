import { put, takeLatest, call, select } from "redux-saga/effects"
import { ResetPasswordApi } from "../../services/passwordReset"
import { messages } from "src/utils/hooks"
import {
	resetPasswordFailure,
	resetPasswordRequest,
	resetPasswordSuccess,
} from "../actions/resetPassword"
import { history } from "src"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import CryptoJS from "crypto-js"

type resetPasswordAction = {
	type: string
	payload: {
		token: string
		password: string
	}
}


function* resetPasswordSaga({
	payload,
}: resetPasswordAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { password } = payload
		const key = CryptoJS.enc.Utf8.parse(String(process.env.REACT_APP_LOGIN_ENCRYPTION_KEY))
		const randomBytes = CryptoJS.lib.WordArray.random(128 / 8).toString()
		const iv = CryptoJS.enc.Hex.parse(randomBytes)
		const encryptedPassword = CryptoJS.AES.encrypt(password, key, { mode: CryptoJS.mode.CBC, iv }).toString()
		const credentials = `${encryptedPassword}.${iv.toString()}`

		const { defaultHeaders } = yield select((state) => state)
		const {
			data: { result },
		} = yield call(ResetPasswordApi, { ...payload, password: credentials }, defaultHeaders)
		if (result.status === "Success") {
			yield put(resetPasswordSuccess(result))
			yield call(history.push, "/after-reset")
		} else {
			yield put(resetPasswordFailure(result.message))
		}
	} catch (err) {
		console.log(err)
		yield put(resetPasswordFailure(messages[ln].internal_server_error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(resetPasswordRequest, resetPasswordSaga)
}
