import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";
import "firebase/auth";

//connect
const firebaseConfig = {
  apiKey: "AIzaSyBHjyhQAubrFbEXfntGha-Z6cDEgA3FE_k",
  authDomain: "polymerize-lab.firebaseapp.com",
  databaseURL: "https://polymerize-lab.firebaseio.com",
  projectId: "polymerize-lab",
  storageBucket: "polymerize-lab.appspot.com",
  messagingSenderId: "273992283450",
  appId: "1:273992283450:web:34e1e550c30daf21b28c03",
  measurementId: "G-BFH4G4HMN1",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);

export const handleFirebaseLogin = async (authToken: string) => {
  await signInWithCustomToken(auth, authToken);
};

// remoteConfig.settings.minimumFetchIntervalMillis = 1000;

remoteConfig.defaultConfig = {
  welcome_message: "Welcome",
};

fetchAndActivate(remoteConfig);
// .then(() => {
//   const val = getAll(remoteConfig);
//   console.log("🚀 ~ file: firebase.ts:42 ~ fetchAndActivate ~ val:", val)
// }).catch((err) => {
//   console.log(err)
// })
export const COLLECTION_ENVIRONTMENT_SUFFIX =
  process.env.REACT_APP_ENV === "development"
    ? "DEV"
    : process.env.REACT_APP_ENV === "sit"
    ? "SIT"
    : process.env.REACT_APP_ENV === "uat"
    ? "UAT"
    : "PROD";

export const FB_COLLECTION_NAME_PRESENCE = `presence_${COLLECTION_ENVIRONTMENT_SUFFIX}`;
export const FB_COLLECTION_NAME_COMMENTS = `comments_${COLLECTION_ENVIRONTMENT_SUFFIX}`;
export const FB_COLLECTION_NAME_RELEASES = "releases";
