import { message, notification } from "antd"
import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	getModelInfoApi,
	createModelCommentApi,
	deleteModelCommentApi,
	modelCommentsListApi,
	editModelCommentApi,
	requestInverseApi,
	modelInfoChangeApi,
	getCustomModelDataApi,
} from "src/services/modelAnalysis"
import {
	getModelInfoRequest,
	getModelInfoFailure,
	getModelInfoSuccess,
	createModelCommentFailure,
	createModelCommentRequest,
	createModelCommentSuccess,
	deleteModelCommentFailure,
	deleteModelCommentRequest,
	deleteModelCommentSuccess,
	modelCommentListFailure,
	modelCommentListRequest,
	modelCommentListSuccess,
	editModelCommentFailure,
	editModelCommentRequest,
	editModelCommentSuccess,
	requestInverseFailure,
	requestInverseRequest,
	requestInverseSuccess,
	modelInfoChangeFailure,
	modelInfoChangeRequest,
	modelInfoChangeSuccess,
	getCustomModelDataSuccess,
	getCustomModelDataFailure,
	getCustomModelDataRequest,
} from "../actions/modelAnalysis"
import { modelsConfigListRequest } from "src/store/actions/formulate"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"

function* getModelInfoSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data } = yield call(getModelInfoApi, payload, headers)
		if (data.status === "Success") {
			yield put(getModelInfoSuccess(data))
		} else {
			notification.info({ message: data.message })
			yield put(getModelInfoFailure())
		}
	} catch (error) {
		yield put(getModelInfoFailure(error))
	}
}

function* createModelCommentSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status },
			},
		} = yield call(createModelCommentApi, payload, headers)

		if (status === "Success") {
			yield put(createModelCommentSuccess())
			yield put(
				modelCommentListRequest({
					model_version: payload.model_version,
					reset: true,
				})
			)
		} else {
			yield put(createModelCommentFailure())
		}
	} catch (error) {
		yield put(createModelCommentFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* deleteModelCommentSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(deleteModelCommentApi, payload, headers)

		if (status === "Success") {
			yield put(deleteModelCommentSuccess())
			notification.success({ message })
			yield put(
				modelCommentListRequest({
					model_version: payload.model_version,
					reset: true,
				})
			)
		} else {
			yield put(deleteModelCommentFailure())
		}
	} catch (error) {
		yield put(deleteModelCommentFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* editModelCommentSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(editModelCommentApi, payload, headers)
		if (status === "Success") {
			yield put(editModelCommentSuccess())
			notification.success({ message })
		} else {
			yield put(editModelCommentFailure())
		}
	} catch (error) {
		yield put(editModelCommentFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* modelCommentListSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { reset } = payload
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(modelCommentsListApi, payload, headers)
		if (status === "Success") {
			yield put(modelCommentListSuccess({ modelCommentList: data, reset }))
		} else {
			yield put(modelCommentListFailure())
		}
	} catch (error) {
		yield put(modelCommentListFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* requestInverseSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { status, message, description },
		} = yield call(requestInverseApi, payload, headers)
		if (status === "Success") {
			yield put(requestInverseSuccess())
			notification.success({
				message,
				description,
			})
			yield put(modelsConfigListRequest())
		} else {
			yield put(requestInverseFailure())
		}
	} catch (error) {
		yield put(requestInverseFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* modelInfoChangeSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { status, message, data },
		} = yield call(modelInfoChangeApi, payload, headers)
		if (status === "Success") {
			yield put(modelInfoChangeSuccess(data))
			notification.success({ message })
		} else {
			yield put(modelInfoChangeFailure())
		}
	} catch (error) {
		yield put(modelInfoChangeFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* getCustomModelDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message, data },
			},
		} = yield call(getCustomModelDataApi, payload, headers)
		if (status === "Success") {
			yield put(getCustomModelDataSuccess(data))
		} else {
			yield put(getCustomModelDataFailure(message))
		}
	} catch (error) {
		yield put(getCustomModelDataFailure(messages[ln].internal_server_error))
	}
}

export default function* rootSaga() {
	yield takeLatest(getModelInfoRequest, getModelInfoSaga)
	yield takeLatest(createModelCommentRequest, createModelCommentSaga)
	yield takeLatest(deleteModelCommentRequest, deleteModelCommentSaga)
	yield takeLatest(editModelCommentRequest, editModelCommentSaga)
	yield takeLatest(modelCommentListRequest, modelCommentListSaga)
	yield takeLatest(requestInverseRequest, requestInverseSaga)
	yield takeLatest(modelInfoChangeRequest, modelInfoChangeSaga)
	yield takeLatest(getCustomModelDataRequest, getCustomModelDataSaga)
}
