import axios from "axios";

export const fetchdashboardDataApi = (params: any, headers: any) => {
  return axios.post<any>(
    process.env.REACT_APP_API_URL + "v1/data/_dashboard",
    params,
    { headers }
  );
};

export const fetchNewsApi = (params: any, headers: any) => {
  return axios.post<any>(
    process.env.REACT_APP_API_URL + "v1/data/_news",
    params,
    { headers }
  );
};