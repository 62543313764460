import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import {
  CelsureCompareFormulationsActionTypes,
  CelsureReportDeleteActionTypes,
  CelsureReportShareActionTypes,
  CelsureReportsRetrieveActionTypes,
} from "../actions/celsureCompareFormulation";

export type CelsureCompareFormulationState = {
  status: AsyncStates;
  retrieveStatus: AsyncStates;
  uploadStatus: AsyncStates;
  shareStatus: AsyncStates;
  deleteStatus: AsyncStates;
  error: string;
  retrieveError: string;
  uploadError: string;
  shareError: string;
  deleteError: string;
  formulationDetailsData: any[];
  reports: any[];
};
const defaultState: CelsureCompareFormulationState = {
  status: AsyncStates.INITIAL,
  retrieveStatus: AsyncStates.INITIAL,
  uploadStatus: AsyncStates.INITIAL,
  shareStatus: AsyncStates.INITIAL,
  deleteStatus: AsyncStates.INITIAL,
  error: "",
  retrieveError: "",
  uploadError: "",
  shareError: "",
  deleteError: "",
  formulationDetailsData: [],
  reports: [],
};

const celsureCompareFormulationsReducer = handleActions<any, any>(
  {
    [CelsureCompareFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [CelsureCompareFormulationsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        formulationDetailsData: action.payload.map((value: any) => ({ ...value, overall_enthalpy_value: value.pcm_type.reduce((acc: any, current: any) => { return acc += current.enthalpy }, 0) + '  KJ' })),
      };
    },
    [CelsureCompareFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [CelsureCompareFormulationsActionTypes.CLEANUP]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.INITIAL,
      retrieveError: "",
      formulationDetailsData: [],
    }),
    [CelsureReportShareActionTypes.REQUEST]: (state) => ({
      ...state,
      shareStatus: AsyncStates.LOADING,
      shareError: "",
    }),
    [CelsureReportShareActionTypes.FAILURE]: (state, action) => ({
      ...state,
      shareStatus: AsyncStates.ERROR,
      shareError: action.payload,
    }),
    [CelsureReportDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteStatus: AsyncStates.LOADING,
      deleteError: "",
    }),
    [CelsureReportDeleteActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        deleteStatus: AsyncStates.SUCCESS,
        deleteError: "",
      };
    },
    [CelsureReportDeleteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteStatus: AsyncStates.ERROR,
      deleteError: action.payload,
    }),
    [CelsureReportsRetrieveActionTypes.REQUEST]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.LOADING,
      retrieveError: "",
    }),
    [CelsureReportsRetrieveActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        retrieveStatus: AsyncStates.SUCCESS,
        retrieveError: "",
        reports: action.payload.reports.reverse(),
      };
    },
    [CelsureReportsRetrieveActionTypes.FAILURE]: (state, action) => ({
      ...state,
      retrieveStatus: AsyncStates.ERROR,
      retrieveError: action.payload.error,
    }),
  },
  defaultState
);

export default celsureCompareFormulationsReducer;
