export function parseSearchQuery<T extends object>(query: string): Partial<T> {
	const search = new URLSearchParams(query)
	const result: { [key: string]: string } = {}

	for (const [key, value] of search) {
		result[key] = value
	}

	return result as unknown as Partial<T>
}
