const jwtManager = () => {

    let inMemoryJWT: string | null = null

    const getToken = () => {
        return inMemoryJWT
    }

    const setToken = (token: string) => {
        inMemoryJWT = token
        return true
    };

    const deleteToken = () => {
        inMemoryJWT = null
        return true
    }

    return {
        getToken,
        setToken,
        deleteToken
    }
}

export default jwtManager()