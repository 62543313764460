import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	formulationDetailsRequest,
	formulationDetailsSuccess,
	formulationDetailsFailure,
	markForMlFailure,
	markForMlRequest,
	markForMlSuccess,
	fetchSimilarExperimentsRequest,
	fetchSimilarExperimentsSuccess,
	fetchSimilarExperimentsFailure,
} from "../actions/formulationDetails"
import {
	getFormulationDetails,
	markForMlAPi,
} from "src/services/formulation/index"
import { notification } from "antd"
import { fetchExperimentRequest } from "../actions/experiment"
import jwtManager from "src/utils/jwtManager"
import { EXP_PAGE_SIZE } from "src/constants"
import { fetchSimilarExperiment } from "src/services/experiment"

type formulationDetailsAction = {
	type: string
	payload: {
		formulationId: Array<string>
	}
}

function* formulationDetailsSaga({
	payload: { formulationId },
}: formulationDetailsAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			formulation_id: formulationId,
		}
		const {
			data: {
				result: { formulation_data, status },
			},
		} = yield call(getFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(formulationDetailsSuccess(formulation_data))
		} else {
			yield put(formulationDetailsFailure())
		}
	} catch (error) {
		yield put(formulationDetailsFailure())
	}
}

function* markForMlSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(markForMlAPi, payload, headers)

		if (status === "Success") {
			yield put(markForMlSuccess())
			yield put(fetchExperimentRequest({
				experiment_id: [payload.experiment_id],
				page_num: payload?.page_num,
				page_size: EXP_PAGE_SIZE
			}))
			notification.success({ message })
		} else {
			yield put(markForMlFailure())
		}
	} catch (error) {
		yield put(markForMlFailure())
	}
}

function* fetchSimilarExperimentsSaga({ payload }: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data: responseData, status, message },
			},
		} = yield call(fetchSimilarExperiment, { ...payload }, headers)
		if (status === "Success") {
			if (!!(responseData?.data ?? []).length) {
				yield put(fetchSimilarExperimentsSuccess(responseData))
			} else {
				yield put(fetchSimilarExperimentsFailure(message))
				notification.error({
					message
				})
			}
		} else {
			yield put(fetchSimilarExperimentsFailure(message))
		}
	} catch (error) {
		yield put(fetchSimilarExperimentsFailure(error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(formulationDetailsRequest, formulationDetailsSaga)
	yield takeLatest(markForMlRequest, markForMlSaga)
	yield takeLatest(fetchSimilarExperimentsRequest, fetchSimilarExperimentsSaga)
}
