import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	pivotTableSuccess,
	pivotTableFailure,
	pivotTableRequest,
} from "src/store/actions/pivotTable"
import {
	PivotTableAPIResponse,
	postPivotTableData,
} from "src/services/dataSummary"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from 'src/utils/useTranslate'
import { StoreState } from '../configureStore'
import jwtManager from "src/utils/jwtManager"

function* pivotTableSaga({ payload }: any): Generator<any, any, any> {

	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const data: PivotTableAPIResponse = yield call(
			postPivotTableData,
			payload,
			headers
		)

		if (data.status === 200) {
			yield put(pivotTableSuccess(data))
		} else {
			yield put(pivotTableFailure(messages[ln].internal_server_error))
		}
	} catch (error: any) {
		if (error && error.response?.status === 500) {
			yield put(pivotTableFailure(messages[ln].internal_server_error))
		} else {
			yield put(
				pivotTableFailure(
					error?.response?.data?.detail ?? messages[ln].internal_server_error
				)
			)
		}
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(pivotTableRequest, pivotTableSaga)
}
