import { createActions } from "redux-actions";

export enum PreferencesActionTypes {
    REQUEST = "PREFERENCES_REQUEST",
    SUCCESS = "PREFERENCES_SUCCESS",
    FAILURE = "PREFERENCES_FAILURE",
    UPDATE_REQUEST = "PREFERENCES_UPDATE_REQUEST",
    UPDATE_SUCCESS = "PREFERENCES_UPDATE_SUCCESS",
    UPDATE_FAILURE = "PREFERENCES_UPDATE_FAILURE",
}

export const {
    preferencesRequest,
    preferencesSuccess,
    preferencesFailure,
    preferencesUpdateRequest,
    preferencesUpdateSuccess,
    preferencesUpdateFailure,
} = createActions({
    [PreferencesActionTypes.REQUEST]: (payload) => payload,
    [PreferencesActionTypes.SUCCESS]: (response) => response,
    [PreferencesActionTypes.FAILURE]: (error: string) => ({ error }),
    [PreferencesActionTypes.UPDATE_REQUEST]: (payload) => payload,
    [PreferencesActionTypes.UPDATE_SUCCESS]: (response: string) => response,
    [PreferencesActionTypes.UPDATE_FAILURE]: (error: string) => ({ error }),
})