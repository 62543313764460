import { createActions } from "redux-actions";
import { Services } from "src/services/firebase/firebase-auth";
import { FirebaseResponse } from "../reducers/login";

export enum LoginFirebaseActionTypes {
  REQUEST = "LOGIN_FIREBASE_REQUEST",
  SUCCESS = "LOGIN_FIREBASE_SUCCESS",
  FAILURE = "LOGIN_FIREBASE_FAILURE",
}

export const {
  loginFirebaseRequest,
  loginFirebaseSuccess,
  loginFirebaseFailure,
  loginFirebaseCompleteRequest,
  loginFirebaseCompleteNotVerified,
  loginFirebaseCompleteSuccess,
  loginFirebaseCompleteFailure,
} = createActions({
  [LoginFirebaseActionTypes.REQUEST]: (payload: { providerId: Services, user?: any }) => payload,
  [LoginFirebaseActionTypes.SUCCESS]: (firebaseResponse: FirebaseResponse) => ({
    firebaseResponse,
  }),
  [LoginFirebaseActionTypes.FAILURE]: (error: string) => ({ error })
});
