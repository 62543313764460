import { createActions } from "redux-actions";


export enum CelsureWoCreateActionTypes {
  REQUEST = "CELSURE_WO_CREATE_REQUEST",
  SUCCESS = "CELSURE_WO_CREATE_SUCCESS",
  FAILURE = "CELSURE_WO_CREATE_FAILURE",
}

export enum CelsureWoUpdateActionTypes {
  REQUEST = "CELSURE_WO_UPDATE_REQUEST",
  SUCCESS = "CELSURE_WO_UPDATE_SUCCESS",
  FAILURE = "CELSURE_WO_UPDATE_FAILURE",
}

export enum CelsureWoDeleteActionTypes {
  REQUEST = "CELSURE_WO_DELETE_REQUEST",
  SUCCESS = "CELSURE_WO_DELETE_SUCCESS",
  FAILURE = "CELSURE_WO_DELETE_FAILURE",
}

export enum CelsureWoListActionTypes {
  REQUEST = "CELSURE_WO_LIST_REQUEST",
  SUCCESS = "CELSURE_WO_LIST_SUCCESS",
  FAILURE = "CELSURE_WO_LIST_FAILURE",
}

export enum CelsureWoTrialsActionTypes {
  REQUEST = "CELSURE_WO_TRIALS_REQUEST",
  SUCCESS = "CELSURE_WO_TRIALS_SUCCESS",
  FAILURE = "CELSURE_WO_TRIALS_FAILURE",
}

export enum CelsureFileUploadActionTypes {
  REQUEST = "CELSURE_FILE_UPLOAD_REQUEST",
  SUCCESS = "CELSURE_FILE_UPLOAD_SUCCESS",
  FAILURE = "CELSURE_FILE_UPLOAD_FAILURE",
}

export enum CelsureGetPlotsActionTypes {
  REQUEST = "CELSURE_GET_PLOTS_REQUEST",
  SUCCESS = "CELSURE_GET_PLOTS_SUCCESS",
  FAILURE = "CELSURE_GET_PLOTS_FAILURE",
}

export enum CelsureFileHistoryActionTypes {
  REQUEST = "CELSURE_FILE_HISTORY_REQUEST",
  SUCCESS = "CELSURE_FILE_HISTORY_SUCCESS",
  FAILURE = "CELSURE_FILE_HISTORY_FAILURE",
}

export enum CelsureApproveTrialsActionTypes {
  REQUEST = "CELSURE_APPROVE_TRIALS_REQUEST",
  SUCCESS = "CELSURE_APPROVE_TRIALS_SUCCESS",
  FAILURE = "CELSURE_APPROVE_TRIALS_FAILURE",
}

export enum SetCelsureWoActionTypes {
  SET = "SET_CELSURE_WO"
}

export enum ResetCelsureWoActionTypes {
  RESET = "RESET_CELSURE_WO"
}


export const {
  celsureWoCreateRequest,
  celsureWoCreateSuccess,
  celsureWoCreateFailure,
  celsureWoUpdateRequest,
  celsureWoUpdateSuccess,
  celsureWoUpdateFailure,
  celsureWoDeleteRequest,
  celsureWoDeleteSuccess,
  celsureWoDeleteFailure,
  celsureWoListRequest,
  celsureWoListSuccess,
  celsureWoListFailure,
  celsureWoTrialsRequest,
  celsureWoTrialsSuccess,
  celsureWoTrialsFailure,
  celsureFileUploadRequest,
  celsureFileUploadSuccess,
  celsureFileUploadFailure,
  celsureGetPlotsRequest,
  celsureGetPlotsSuccess,
  celsureGetPlotsFailure,
  celsureFileHistoryRequest,
  celsureFileHistorySuccess,
  celsureFileHistoryFailure,
  celsureApproveTrialsRequest,
  celsureApproveTrialsSuccess,
  celsureApproveTrialsFailure,
  setCelsureWo,
  resetCelsureWo
} = createActions({
  [CelsureWoCreateActionTypes.REQUEST]: payload => payload,
  [CelsureWoCreateActionTypes.SUCCESS]: response => response,
  [CelsureWoCreateActionTypes.FAILURE]: () => { },
  [CelsureWoUpdateActionTypes.REQUEST]: payload => payload,
  [CelsureWoUpdateActionTypes.SUCCESS]: response => response,
  [CelsureWoUpdateActionTypes.FAILURE]: () => { },
  [CelsureWoDeleteActionTypes.REQUEST]: payload => payload,
  [CelsureWoDeleteActionTypes.SUCCESS]: response => response,
  [CelsureWoDeleteActionTypes.FAILURE]: () => { },
  [CelsureWoListActionTypes.REQUEST]: payload => payload,
  [CelsureWoListActionTypes.SUCCESS]: response => response,
  [CelsureWoListActionTypes.FAILURE]: () => { },
  [CelsureWoTrialsActionTypes.REQUEST]: payload => payload,
  [CelsureWoTrialsActionTypes.SUCCESS]: response => response,
  [CelsureWoTrialsActionTypes.FAILURE]: () => { },
  [CelsureFileUploadActionTypes.REQUEST]: payload => payload,
  [CelsureFileUploadActionTypes.SUCCESS]: response => response,
  [CelsureFileUploadActionTypes.FAILURE]: () => { },
  [CelsureGetPlotsActionTypes.REQUEST]: payload => payload,
  [CelsureGetPlotsActionTypes.SUCCESS]: response => response,
  [CelsureGetPlotsActionTypes.FAILURE]: () => { },
  [CelsureFileHistoryActionTypes.REQUEST]: payload => payload,
  [CelsureFileHistoryActionTypes.SUCCESS]: response => response,
  [CelsureFileHistoryActionTypes.FAILURE]: () => { },
  [CelsureApproveTrialsActionTypes.REQUEST]: payload => payload,
  [CelsureApproveTrialsActionTypes.SUCCESS]: response => response,
  [CelsureApproveTrialsActionTypes.FAILURE]: () => { },
  [SetCelsureWoActionTypes.SET]: payload => payload,
  [ResetCelsureWoActionTypes.RESET]: () => { },
})
