import { handleActions } from "redux-actions"
import {
  CreateParameterActionType, UpdateParameterActionType, CheckPropertyExistsType, DeletePropertyActionType, InventoryDataActionTypes,
  MetaDataTemplatesCreateActionTypes, MetaDataTemplatesDeleteActionTypes, MetaDataTemplatesListsActionTypes, MetaDataTemplatesUpdateActionTypes,
  InventoryAttachmentsAddActionTypes, InventoryAttachmentsDeleteActionTypes, InventoryAttachmentsListActionTypes
} from "../actions/inventory"
import { AsyncStates } from "../../constants"


export interface inventoryReducerState {
  updateParameterStatus: AsyncStates
  createParameterStatus: AsyncStates
  deletePropertyStatus: AsyncStates
  checkPropertyExistsStatus: AsyncStates
  checkPropertyExists: any[]
  templateCreateStatus: AsyncStates
  templateDeletetatus: AsyncStates
  templateUpdateStatus: AsyncStates
  templateListStatus: AsyncStates
  templateData: any[]
  metadataPropertiesStatus: AsyncStates
  inventoryData: any
  attachmentsList: any[]
  attachementsListStatus: AsyncStates
  attachementsAddStatus: AsyncStates
  attachementsDeleteStatus: AsyncStates
  error: string
}

const defaultState: inventoryReducerState = {
  updateParameterStatus: AsyncStates.INITIAL,
  createParameterStatus: AsyncStates.INITIAL,
  deletePropertyStatus: AsyncStates.INITIAL,
  checkPropertyExistsStatus: AsyncStates.INITIAL,
  checkPropertyExists: [],
  templateCreateStatus: AsyncStates.INITIAL,
  templateDeletetatus: AsyncStates.INITIAL,
  templateUpdateStatus: AsyncStates.INITIAL,
  templateListStatus: AsyncStates.INITIAL,
  templateData: [],
  metadataPropertiesStatus: AsyncStates.INITIAL,
  inventoryData: {},
  attachmentsList: [],
  attachementsListStatus: AsyncStates.INITIAL,
  attachementsAddStatus: AsyncStates.INITIAL,
  attachementsDeleteStatus: AsyncStates.INITIAL,
  error: ""
}


const inventoryReducer = handleActions({
  [CreateParameterActionType.REQUEST]: (state, action: any) => ({
    ...state,
    ...(action?.payload?.get("type") === "metadata_properties" ? { metadataPropertiesStatus: AsyncStates.LOADING }
      : { createParameterStatus: AsyncStates.LOADING })
  }),
  [CreateParameterActionType.SUCCESS]: (state, action: any) => ({
    ...state,
    ...(action?.payload?.get("type") === "metadata_properties" ? { metadataPropertiesStatus: AsyncStates.SUCCESS }
      : { createParameterStatus: AsyncStates.SUCCESS })
  }),
  [CreateParameterActionType.FAILURE]: (state, action: any) => ({
    ...state,
    metadataPropertiesStatus: AsyncStates.ERROR,
    createParameterStatus: AsyncStates.ERROR
  }),
  [CreateParameterActionType.CLEAR]: (state, action) => ({
    ...state,
    createParameterStatus: AsyncStates.INITIAL,
    metadataPropertiesStatus: AsyncStates.INITIAL
  }),
  [UpdateParameterActionType.REQUEST]: (state, action) => ({
    ...state,
    updateParameterStatus: AsyncStates.LOADING,
  }),
  [UpdateParameterActionType.SUCCESS]: (state, action) => ({
    ...state,
    updateParameterStatus: AsyncStates.SUCCESS,
  }),
  [UpdateParameterActionType.FAILURE]: (state, action) => ({
    ...state,
    updateParameterStatus: AsyncStates.ERROR,
  }),
  [UpdateParameterActionType.CLEAR]: (state, action) => ({
    ...state,
    updateParameterStatus: AsyncStates.INITIAL,
  }),
  [CheckPropertyExistsType.REQUEST]: (state, action) => ({
    ...state,
    checkPropertyExistsStatus: AsyncStates.LOADING,
    checkPropertyExists: [],
    error: ''
  }),
  [CheckPropertyExistsType.SUCCESS]: (state, action: any) => ({
    ...state,
    checkPropertyExistsStatus: AsyncStates.SUCCESS,
    checkPropertyExists: action.payload,
    error: ''
  }),
  [CheckPropertyExistsType.FAILURE]: (state, action: any) => ({
    ...state,
    checkPropertyExistsStatus: AsyncStates.ERROR,
  }),
  [CheckPropertyExistsType.CLEAR]: (state, action) => ({
    ...state,
    checkPropertyExistsStatus: AsyncStates.INITIAL,
    checkPropertyExists: [],
    error: ""
  }),
  [DeletePropertyActionType.REQUEST]: (state, action) => ({
    ...state,
    deletePropertyStatus: AsyncStates.LOADING,
    error: ''
  }),
  [DeletePropertyActionType.SUCCESS]: (state, action) => ({
    ...state,
    deletePropertyStatus: AsyncStates.SUCCESS,
    error: ''
  }),
  [DeletePropertyActionType.FAILURE]: (state, action) => ({
    ...state,
    deletePropertyStatus: AsyncStates.ERROR,
    error: action.payload.error
  }),
  [DeletePropertyActionType.CLEAR]: (state, action) => ({
    ...state,
    deletePropertyStatus: AsyncStates.INITIAL,
    error: ""
  }),
  [MetaDataTemplatesCreateActionTypes.REQUEST]: (state, action) => ({
    ...state,
    templateCreateStatus: AsyncStates.LOADING
  }),
  [MetaDataTemplatesCreateActionTypes.SUCCESS]: (state, action) => ({
    ...state,
    templateCreateStatus: AsyncStates.SUCCESS
  }),
  [MetaDataTemplatesCreateActionTypes.FAILURE]: (state, action) => ({
    ...state,
    templateCreateStatus: AsyncStates.ERROR
  }),
  [MetaDataTemplatesDeleteActionTypes.REQUEST]: (state, action) => ({
    ...state,
    templateDeletetatus: AsyncStates.LOADING
  }),
  [MetaDataTemplatesDeleteActionTypes.SUCCESS]: (state, action) => ({
    ...state,
    templateDeletetatus: AsyncStates.SUCCESS
  }),
  [MetaDataTemplatesDeleteActionTypes.FAILURE]: (state, action) => ({
    ...state,
    templateDeletetatus: AsyncStates.ERROR
  }),
  [MetaDataTemplatesUpdateActionTypes.REQUEST]: (state, action) => ({
    ...state,
    templateUpdateStatus: AsyncStates.LOADING
  }),
  [MetaDataTemplatesUpdateActionTypes.SUCCESS]: (state, action) => ({
    ...state,
    templateUpdateStatus: AsyncStates.SUCCESS
  }),
  [MetaDataTemplatesUpdateActionTypes.FAILURE]: (state, action) => ({
    ...state,
    templateUpdateStatus: AsyncStates.ERROR
  }),
  [MetaDataTemplatesListsActionTypes.REQUEST]: (state, action) => ({
    ...state,
    templateListStatus: AsyncStates.LOADING
  }),
  [MetaDataTemplatesListsActionTypes.SUCCESS]: (state, action: any) => ({
    ...state,
    templateListStatus: AsyncStates.SUCCESS,
    templateData: action.payload
  }),
  [MetaDataTemplatesListsActionTypes.FAILURE]: (state, action) => ({
    ...state,
    templateListStatus: AsyncStates.ERROR
  }),
  [InventoryDataActionTypes.SET]: (state, action: any) => ({
    ...state,
    inventoryData: action.payload
  }),
  [InventoryDataActionTypes.CLEAR]: (state, action) => ({
    ...state,
    inventoryData: {}
  }),
  [InventoryAttachmentsAddActionTypes.REQUEST]: (state, action: any) => ({
    ...state,
    attachementsAddStatus: AsyncStates.LOADING
  }),
  [InventoryAttachmentsAddActionTypes.SUCCESS]: (state, action) => ({
    ...state,
    attachementsAddStatus: AsyncStates.SUCCESS
  }),
  [InventoryAttachmentsAddActionTypes.FAILURE]: (state, action) => ({
    ...state,
    attachementsAddStatus: AsyncStates.ERROR
  }),
  [InventoryAttachmentsListActionTypes.REQUEST]: (state, action: any) => ({
    ...state,
    attachementsListStatus: AsyncStates.LOADING
  }),
  [InventoryAttachmentsListActionTypes.SUCCESS]: (state, action: any) => ({
    ...state,
    attachementsListStatus: AsyncStates.SUCCESS,
    attachmentsList: action?.payload
  }),
  [InventoryAttachmentsListActionTypes.FAILURE]: (state, action) => ({
    ...state,
    attachementsListStatus: AsyncStates.ERROR
  }),
  [InventoryAttachmentsListActionTypes.CLEAR]: (state, action) => ({
    ...state,
    attachementsListStatus: AsyncStates.INITIAL,
    attachmentsList: []
  }),
  [InventoryAttachmentsDeleteActionTypes.REQUEST]: (state, action: any) => ({
    ...state,
    attachementsDeleteStatus: AsyncStates.LOADING
  }),
  [InventoryAttachmentsDeleteActionTypes.SUCCESS]: (state, action) => ({
    ...state,
    attachementsDeleteStatus: AsyncStates.SUCCESS
  }),
  [InventoryAttachmentsDeleteActionTypes.FAILURE]: (state, action) => ({
    ...state,
    attachementsDeleteStatus: AsyncStates.ERROR
  }),
}, defaultState)


export default inventoryReducer