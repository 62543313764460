import { notification } from "antd";
import { END, eventChannel } from "redux-saga";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import {
  fetchVersionList,
  inverseModelStream,
  newInverseModelStream,
  zeonFetchVersionList,
  zeonInverseModelStream,
} from "src/services/inverseModel";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { getPredictionRequest } from "src/store/actions/inverseModel";
import {
  fetchVersionListFailure,
  fetchVersionListRequest,
  fetchVersionListSuccess,
  newInverseAck,
  newInverseModelFailure,
  newInverseModelRequest,
  newInverseModelSuccess,
} from "../actions/newInverseModel";
import { reconnect } from "../actions/connection";
import { suggestedExperimentsRequest } from "../actions/suggestedExp";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";
import { insightsSocketConnect } from "../actions/insights";

function createInverseModelChannel(socket: WebSocket) {
  return eventChannel((emit) => {
    socket.onmessage = (event: any) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

function isValid(data: any) {
  try {
    JSON.parse(JSON.parse(data));
  } catch {
    return false;
  }
  return true;
}

export let socket: WebSocket;
export let socketChannel: any;
function* newInverseModelSaga({
  payload: inputPayload,
}: any): Generator<any, any, any> {
  const token = jwtManager.getToken();
  const { user_id: key } = yield select((state) => state.login.loginResponse);
  const configs = yield select((state: StoreState) => state.configs.features);
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  // const currentLanguage: LanguageUnion = yield select(
  // 	(state: StoreState) => state.language.current
  // )
  const { defaultHeaders } = yield select((state) => state);
  const headers = { ...defaultHeaders, token: jwtManager.getToken() };
  if (socket?.readyState !== WebSocket.OPEN) {
    socket = yield call(
      Boolean(configs?.ai_engine_with_methods)
        ? zeonInverseModelStream
        : Boolean(configs?.nestle_configs) || Boolean(configs?.kuraray_configs)
        ? newInverseModelStream
        : inverseModelStream,
      key
    );
    socketChannel = yield call(createInverseModelChannel, socket);
  }
  socket.send(JSON.stringify(inputPayload));
  while (true) {
    try {
      const payload = yield take(socketChannel);
      if (isValid(payload)) {
        const result = JSON.parse(JSON.parse(payload))[0];
        if (result) {
          try {
            if (!!result?.prediction_id.length && result.type === "result") {
              const payload = {
                prediction_id: result.prediction_id,
              };
              const {
                data: {
                  result: { status, message, versions, variations_versions },
                },
              } = yield call(
                Boolean(configs?.ai_engine_with_methods)
                  ? zeonFetchVersionList
                  : fetchVersionList,
                payload,
                headers
              );

              const versionsData = Boolean(configs?.ai_engine_with_methods)
                ? variations_versions
                : versions;

              if (status === "Success") {
                yield put(fetchVersionListSuccess(versionsData));
                yield put(
                  suggestedExperimentsRequest({
                    prediction_id: result.prediction_id,
                    version:
                      versions?.[0] ?? variations_versions?.[0]?.versions?.[0],
                    variation: variations_versions?.[0]?.variation,
                    pageNum: 1,
                  })
                );

                if (!Boolean(configs?.characterization_methods)) {
                  yield put(
                    insightsSocketConnect({
                      event: "custom_insights",
                      action: "inverse_results",
                      data: { prediction_id: result?.prediction_id },
                      key,
                      token: jwtManager.getToken(),
                    })
                  );
                }

                yield put(newInverseModelSuccess(result));
              } else {
                yield put(fetchVersionListFailure());
                notification.error({ message });
              }
            }
          } catch (error) {
            yield put(fetchVersionListFailure());
            notification.error({
              message: messages[ln].internal_server_error,
            });
          }
          if (!!token) {
            // notification.success({
            // 	message: messages[ln].ai_engine_inverse_results_ready,
            // 	description: messages[ln].ai_engine_inverse_results_desc,
            // 	style: { cursor: "pointer" },
            // 	duration: 0,
            // })
            yield put(getPredictionRequest({ pageNum: 1 }));
          }
        } else {
          notification.error({
            duration: 0,
            message: messages[ln].could_not_fetch_results,
          });
          yield put(newInverseModelFailure());
        }
      } else {
        const ack = JSON.parse(payload);
        yield put(newInverseAck(ack));
      }
    } catch (error) {
      notification.error({
        message: messages[ln].internal_server_error,
      });
      yield put(reconnect(error));
    }
  }
}

function* fetchVersionListSaga({ payload }: any): Generator<any, any, any> {
  const currentLanguage: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  const configs = yield select((state: StoreState) => state.configs.features);

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, versions, variations_versions },
      },
    } = yield call(
      Boolean(configs?.ai_engine_with_methods)
        ? zeonFetchVersionList
        : fetchVersionList,
      payload,
      headers
    );

    const versionsData = Boolean(configs?.ai_engine_with_methods)
      ? variations_versions
      : versions;
    const additionalPayload = Boolean(configs?.ai_engine_with_methods)
      ? {
          variation: variations_versions?.[0]?.variation,
          version: variations_versions?.[0]?.versions?.[0],
        }
      : {
          version: versionsData?.[0],
        };

    if (status === "Success") {
      yield put(fetchVersionListSuccess(versionsData));
      yield put(
        suggestedExperimentsRequest({
          prediction_id: payload?.prediction_id,
          ...additionalPayload,
          pageNum: 1,
        })
      );
    } else {
      yield put(fetchVersionListFailure(message));
      notification.error({
        message,
      });
    }
  } catch (error) {
    notification.error({
      message: messages[currentLanguage].internal_server_error,
    });
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(newInverseModelRequest, newInverseModelSaga);
  yield takeLatest(fetchVersionListRequest, fetchVersionListSaga);
}
