import { Headers } from "src/services/interface"

export enum baseTypes {
	INGREDIENTS = "Ingredients",
	PROPERTIES = "Properties",
}

export enum AsyncStates {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

export const commonDefaultPageSize = 10

export const permissions = {
	projectAdmin: "project_admin",
	editor: "editor",
	viewer: "viewer"
}

export const userPermissions: any = {
	project_admin: "Project Admin",
	editor: "Editor",
	viewer: "Viewer"
}

export const userRoles = {
	admin: "admin",
	member: "member"
}

export const projectStatus = {
	in_progress: "in_progress",
	archived: "archived",
	completed: "completed"
}

export const defaultStages = [
	{
		name: "",
		description: "",
		data: [
			{
				"type": "formulation_stage",
				"name": "Formulation"
			},
			{
				"type": "processing_stage",
				"name": "Processing"
			},
			{
				"type": "characterization_stage",
				"name": "Characterization"
			},
			{
				"type": "properties_stage",
				"name": "Properties"
			},
		]
	}
]

export const platformInfo: any = {
	"connect": {
		name: "Polymerize Connect",
		link: "https://polymerize.io/connect/"
	},
	"labs": {
		name: "Polymerize Labs",
		link: "https://polymerize.io/polymerize-labs/"
	}
}

export const defaultHeaders: Headers = {
	"Content-Type": "application/json" || "multipart/form-data",
	"Access-Control-Allow-Origin": "*",
	"platform": process.env.REACT_APP_CURRENT_APP ?? ""
}

export const projectType = {
	celsure: "celsure",
}


export const maxellForwardVersion = [34, 37, 38, 39]

export const EXP_PAGE_SIZE = 20

export const sendCredsHostnames = [
	"labs.polymerize.io",
	"uat.labs.polymerize.io",
	"sit.labs.polymerize.io",
]

export const zeonFixedCategoryList = [
	"POLYMER",
	"FILLER",
	"COUPLING AGENT",
	"STABILIZER",
	"RETARDER",
	"PROCESS AID",
	"PLASTICIZER",
	"ACTIVATOR",
	"METAL OXIDE",
	"COLOR",
	"FLAME RETARDANT",
	"METAL SALTS",
	"ORGANIC ACID",
	"ACID SCAVENGER",
	"COAGENT",
	"CURATIVE"
]
export const sortingConditonKeys = ["ingredients", "processing", "characterizations", "properties", "stages", "material", "application", "order_type", "grade", "descriptor"]

export const fileUploadtemplateUrl =
	"https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/Data+Templates/Polymerize_data_upload_sample.xlsx"

export const nestleFileUploadTemplateUrl = "https://s3.ap-southeast-1.amazonaws.com/polymerize-uploads/COMPJ7X6Q4GMC42023/uploads/Lab Worksheet (Single Experiment).xlsx"

export const PLUSS_HS05_MODEL_CONFIG = {
	"objective": "HS05",
	"is_inverse": false,
	"access": true,
	"is_custom": true,
	"comments": "Custom-trained",
	"input_type": ["ingredients"],
	"inputs_ingredients": [
		"attapulgite_clay_-_350",
		"bacl2_2h2o",
		"borax",
		"caso4",
		"fumed_silica",
		"kcl",
		"na2so4",
		"nacl",
		"nh4cl",
		"sodium_polyacrylate_ck-766",
		"water",
	],
	"inputs": [
		"attapulgite_clay_-_350",
		"bacl2_2h2o",
		"borax",
		"caso4",
		"fumed_silica",
		"kcl",
		"na2so4",
		"nacl",
		"nh4cl",
		"sodium_polyacrylate_ck-766",
		"water",
	],
	"inputs_range": {
		"sodium_polyacrylate_ck-766": {
			"min": 1.18,
			"max": 1.18,
		},
		"nacl": {
			"min": 3.78,
			"max": 25,
		},
		"water": {
			"min": 27.82,
			"max": 86.54,
		},
		"attapulgite_clay_-_350": {
			"min": 5,
			"max": 10.1,
		},
		"fumed_silica": {
			"min": 0.05,
			"max": 1.84,
		},
		"caso4": {
			"min": 4.81,
			"max": 14.77,
		},
		"kcl": {
			"min": 2.4,
			"max": 25,
		},
		"bacl2_2h2o": {
			"min": 3.85,
			"max": 15.72,
		},
		"na2so4": {
			"min": 1.92,
			"max": 48.05,
		},
		"nh4cl": {
			"min": 1.84,
			"max": 16.82,
		},
		"borax": {
			"min": 0,
			"max": 2.84,
		},
	},
	"output_type": "properties",
	"version": -1,
	"created_on": "2022-04-22T10:22:02.519000",
	"model_display_type": "custom_client",
}
