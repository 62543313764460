import { handleActions } from "redux-actions"
import { CreateNewFilterActionTypes } from "src/store/actions/saveFormulationsFilters"
import { AsyncStates } from "src/constants"

export type SaveFormulationsFilterState = {
	status: AsyncStates
	error: string
	data: {
		name: string
		description: string
		access: boolean
		filter_id: string
		query: object
		user_id: string
	} | null
}

const defaultState: SaveFormulationsFilterState = {
	status: AsyncStates.INITIAL,
	error: "",
	data: null,
}

const saveFormulationsFilterReducer = handleActions(
	{
		[CreateNewFilterActionTypes.REQUEST]: (state) => ({
			...state,
			status: AsyncStates.LOADING,
			error: "",
		}),
		[CreateNewFilterActionTypes.SUCCESS]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			data: action.payload.data,
		}),
		[CreateNewFilterActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
			data: null,
		}),
		[CreateNewFilterActionTypes.CLEANUP]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
			data: null,
		}),
	},
	defaultState
)

export default saveFormulationsFilterReducer
