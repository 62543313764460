import { handleActions } from "redux-actions"
import { DeleteFiltersActionTypes } from "src/store/actions/saveFormulationsFilters"
import { AsyncStates } from "src/constants"

export type DeleteFormulationsFilterState = {
	status: AsyncStates
	error: string
	data: null
}

const defaultState: DeleteFormulationsFilterState = {
	status: AsyncStates.INITIAL,
	error: "",
	data: null,
}

const deleteFormulationsFilterReducer = handleActions(
	{
		[DeleteFiltersActionTypes.REQUEST]: (state) => ({
			...state,
			status: AsyncStates.LOADING,
			error: "",
		}),
		[DeleteFiltersActionTypes.SUCCESS]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			data: action.payload.data,
		}),
		[DeleteFiltersActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
			data: null,
		}),
		[DeleteFiltersActionTypes.CLEANUP]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
			data: null,
		}),
	},
	defaultState
)

export default deleteFormulationsFilterReducer
