import { createActions } from "redux-actions";

export enum TemplatesCreateActionTypes {
    REQUEST = "CREATE_TEMPLATE_REQUEST",
    SUCCESS = "CREATE_TEMPLATE_SUCCESS",
    FAILURE = "CREATE_TEMPLATE_FAILURE",
}
export enum TemplatesListsActionTypes {
    REQUEST = "LIST_TEMPLATE_REQUEST",
    SUCCESS = "LIST_TEMPLATE_SUCCESS",
    FAILURE = "LIST_TEMPLATE_FAILURE",
}

export enum TemplatesDeleteActionTypes {
    REQUEST = "DELETE_TEMPLATE_REQUEST",
    SUCCESS = "DELETE_TEMPLATE_SUCCESS",
    FAILURE = "DELETE_TEMPLATE_FAILURE",
}

export enum TemplatesUpdateActionTypes {
    REQUEST = "EDIT_TEMPLATE_REQUEST",
    SUCCESS = "EDIT_TEMPLATE_SUCCESS",
    FAILURE = "EDIT_TEMPLATE_FAILURE",
}


export const {
    createTemplateRequest,
    createTemplateSuccess,
    createTemplateFailure,
    listTemplateRequest,
    listTemplateSuccess,
    listTemplateFailure,
    deleteTemplateRequest,
    deleteTemplateSuccess,
    deleteTemplateFailure,
    editTemplateRequest,
    editTemplateSuccess,
    editTemplateFailure,
} = createActions({
    [TemplatesCreateActionTypes.REQUEST]: (payload) => payload,
    [TemplatesCreateActionTypes.SUCCESS]: (data) => data,
    [TemplatesCreateActionTypes.FAILURE]: (error: any) => (error),
    [TemplatesListsActionTypes.REQUEST]: (payload) => payload,
    [TemplatesListsActionTypes.SUCCESS]: (data) => data,
    [TemplatesListsActionTypes.FAILURE]: (error: any) => (error),
    [TemplatesDeleteActionTypes.REQUEST]: (payload) => payload,
    [TemplatesDeleteActionTypes.SUCCESS]: (data) => data,
    [TemplatesDeleteActionTypes.FAILURE]: (error: any) => (error),
    [TemplatesUpdateActionTypes.REQUEST]: (payload) => payload,
    [TemplatesUpdateActionTypes.SUCCESS]: (data) => data,
    [TemplatesUpdateActionTypes.FAILURE]: (error: any) => (error),
});
