import { handleActions } from "redux-actions";
import { CheckWorkOrderExistsActionTypes, SelectTrialsActionTypes, WorkOrdersActionTypes } from "../actions/workOrders";
import { AsyncStates } from "../../constants";

export type WorkOrdersState = {
  status: AsyncStates;
  error: string;
  data: any[];
  selectedTrials: any,
  isWorkOrderExists: boolean,
  checkWorkOrderExistStatus: AsyncStates,
  checkWorkOrderExistError: string,
};

const defaultState: WorkOrdersState = {
  status: AsyncStates.INITIAL,
  error: "",
  data: [],
  selectedTrials: [],
  isWorkOrderExists: false,
  checkWorkOrderExistStatus: AsyncStates.INITIAL,
  checkWorkOrderExistError: "",

};

const workOrdersReducer = handleActions(
  {
    [WorkOrdersActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [WorkOrdersActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        data: action?.payload?.data ?? [],
      };
    },
    [WorkOrdersActionTypes.CLEAR]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
      };
    },
    [WorkOrdersActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [WorkOrdersActionTypes.CLEAR]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
      };
    },
    [SelectTrialsActionTypes.SET_SELECTED_TRIALS]: (state, action) => ({
      ...state,
      selectedTrials: action.payload,
    }),
    [CheckWorkOrderExistsActionTypes.REQUEST]: (state, action) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.LOADING,
      isWorkOrderExists: false,
      checkWorkOrderExistError: ''
    }),
    [CheckWorkOrderExistsActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.SUCCESS,
      isWorkOrderExists: action.payload,
      checkWorkOrderExistError: ''
    }),
    [CheckWorkOrderExistsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.ERROR,
    }),
    [CheckWorkOrderExistsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.INITIAL,
      isWorkOrderExists: false,
      checkWorkOrderExistError: ""
    }),
  },
  defaultState
);

export default workOrdersReducer;
