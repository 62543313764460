import { handleActions } from "redux-actions";
import { FileUploadDetailsActionTypes } from "../actions/development";
import { AsyncStates } from "../../constants";
export type FileUploadDetailsState = {
    status: AsyncStates;
    error: string;
};
const defaultState: FileUploadDetailsState = {
    status: AsyncStates.INITIAL,
    error: ""
};

const fileUploadDetailsReducer = handleActions(
    {
        [FileUploadDetailsActionTypes.REQUEST]: (state) => ({
            ...state,
            status: AsyncStates.LOADING,
            error: "",
        }),
        [FileUploadDetailsActionTypes.SUCCESS]: (state, action) => {
            return {
                ...state,
                status: AsyncStates.SUCCESS,
                error: ""
            };
        },
        [FileUploadDetailsActionTypes.FAILURE]: (state, action) => ({
            ...state,
            status: AsyncStates.ERROR,
            error: action.payload.error,
        }),
        [FileUploadDetailsActionTypes.CLEANUP]: (state) => ({
            ...state,
            status: AsyncStates.INITIAL,
            error: "",
            fileUploadDetailsData: [],
        }),
    },
    defaultState
);

export default fileUploadDetailsReducer;
