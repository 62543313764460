import { createActions } from "redux-actions";


export enum SaveDataActionTypes {
    REQUEST = "SAVE_CHEM_DRAW_REQUEST",
    SUCCESS = "SAVE_CHEM_DRAW_SUCCESS",
    FAILURE = "SAVE_CHEM_DRAW_FAILURE",
}

export enum GetDataActionTypes {
    REQUEST = "GET_CHEM_DRAW_REQUEST",
    SUCCESS = "GET_CHEM_DRAW_SUCCESS",
    FAILURE = "GET_CHEM_DRAW_FAILURE",
}

export const {
    saveChemDrawRequest,
    saveChemDrawSuccess,
    saveChemDrawFailure,
    getChemDrawRequest,
    getChemDrawSuccess,
    getChemDrawFailure,
} = createActions({
    [SaveDataActionTypes.REQUEST]: payload => payload,
    [SaveDataActionTypes.SUCCESS]: response => response,
    [SaveDataActionTypes.FAILURE]: () => { },
    [GetDataActionTypes.REQUEST]: payload => payload,
    [GetDataActionTypes.SUCCESS]: response => response,
    [GetDataActionTypes.FAILURE]: () => { },
})
