import { put, takeLatest, call, select } from "redux-saga/effects";
import { notification } from "antd"
import { getChemDrawFailure, getChemDrawRequest, getChemDrawSuccess, saveChemDrawFailure, saveChemDrawRequest, saveChemDrawSuccess } from "../actions/chemDraw";
import { getChemDrawApi, saveChemDrawApi } from "src/services/chemDraw";
import { messages } from "src/utils/hooks";
import { StoreState } from '../configureStore';
import { LanguageUnion } from 'src/utils/useTranslate';
import jwtManager from "src/utils/jwtManager";


function* getChemDrawSaga({ payload }: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const { data: { result: { data, status } } } = yield call(getChemDrawApi, payload, headers)
        if (status === "Success") {
            yield put(getChemDrawSuccess(data))
        } else {
            yield put(getChemDrawFailure())
        }
    } catch (error) {
        yield put(getChemDrawFailure())
    }
}

function* saveChemDrawSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const { data: { result: { status, message } } } = yield call(saveChemDrawApi, payload, headers)

        if (status === "Success") {
            yield put(saveChemDrawSuccess())
            notification.success({ message })
        } else {
            notification.error({ message })
            yield put(saveChemDrawFailure())
        }
    } catch (error) {
        notification.error({ message: messages[ln].internal_server_error })
        yield put(saveChemDrawFailure())
    }
}


export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(getChemDrawRequest, getChemDrawSaga)
    yield takeLatest(saveChemDrawRequest, saveChemDrawSaga)
}