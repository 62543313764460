import { message, message as toast, notification } from "antd"
import { put, takeLatest, call, select, delay } from "redux-saga/effects"
import { editDisplayId, fetchExperiment } from "src/services/experiment"
import {
	ArchiveFormulationBody,
	EditFormulationDetailsBody,
} from "src/services/formulation/interface"
import {
	createWorkOrder,
	dataSanityApi,
	dataSanityDataApi,
	deleteFileListApi,
	deleteWorkOrder,
	editWorkOrder,
	fetchFileListApi,
	getWorkOrder,
	stageExistsApi,
	uploadFileAttachmentApi,
	ZeonDataExportApi,
} from "src/services/workOrders"
import {
	archiveFormulation,
	autoSaveExperimentDetails,
	editFormulationDetails,
	getFormulationDetails,
} from "../../services/formulation"
import {
	fetchExperimentFailure,
	fetchExperimentRequest,
	fetchExperimentSuccess,
	fetchLinkedExperimentDetailsFailure,
	fetchLinkedExperimentDetailsRequest,
	fetchLinkedExperimentDetailsSuccess,
	fetchLinkedExperimentFailure,
	fetchLinkedExperimentRequest,
	fetchLinkedExperimentSuccess,
} from "../actions/experiment"
import {
	editDisplayIdRequest,
	editDisplayIdSuccess,
	editDisplayIdFailure,
	createWorkOrderFailure,
	createWorkOrderRequest,
	createWorkOrderSuccess,
	deleteWorkOrderFailure,
	deleteWorkOrderRequest,
	deleteWorkOrderSuccess,
	editExperimentFailure,
	editExperimentRequest,
	editWorkOrderFailure,
	editWorkOrderRequest,
	editWorkOrderSuccess,
	formulationFailure,
	formulationRequest,
	formulationSuccess,
	referenceFormulationsFailure,
	referenceFormulationsRequest,
	referenceFormulationsSuccess,
	workOrderFailure,
	workOrderRequest,
	workOrderSuccess,
	archiveFormulationFailure,
	archiveFormulationSuccess,
	archiveFormulationRequest,
	dataExportSuccess,
	dataExportRequest,
	dataExportFailure,
	uploadGraphsDataFailure,
	uploadGraphsDataRequest,
	uploadGraphsDataSuccess,
	plotsDataFailure,
	plotsDataRequest,
	plotsDataSuccess,
	graphsDataHistoryFailure,
	graphsDataHistoryRequest,
	graphsDataHistorySuccess,
	woStatusChangeFailure,
	woStatusChangeRequest,
	woStatusChangeSuccess,
	setDuplicateWo,
	fetchFileListSuccess,
	fetchFileListFailure,
	fetchFileListRequest,
	uploadFileAttachmentSuccess,
	uploadFileAttachmentFailure,
	uploadFileAttachmentRequest,
	deleteFileAttachmentFailure,
	deleteFileAttachmentSuccess,
	deleteFileAttachmentRequest,
	stageExistsRequest,
	stageExistsSuccess,
	stageExistsFailure,
	saveProcessingProfilesRequest,
	saveProcessingProfilesFailure,
	saveProcessingProfilesSuccess,
	deleteProcessingProfilesFailure,
	deleteProcessingProfilesRequest,
	deleteProcessingProfilesSuccess,
	listProcessingProfilesFailure,
	listProcessingProfilesRequest,
	listProcessingProfilesSuccess,
	autoSaveExperimentRequest,
	autoSaveExperimentSuccess,
	dataSanityRequest,
	dataSanitySuccess,
	dataSanityFailure,
	dataSanityDataRequest,
	dataSanityDataFailure,
	dataSanityDataSuccess,
	editExperimentSuccess
} from "../actions/workOrderDetails"
import { workOrdersRequest } from "../actions/workOrders"
import { StoreState, store } from "../configureStore"
import {
	dataExportApi,
	uploadGraphsDataApi,
	plotsDataApi,
	graphsDataHistoryApi,
	changeWoStatusApi,
} from "../../services/workOrders"
import { history } from "src"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { displayNamesRequest } from "../actions/displayNames"
import jwtManager from "src/utils/jwtManager"
import { createEditProfileApi, DeleteProfileApi, listProfileApi } from "src/services/nestle"
import { firestoreDb } from "src/utils/firebase"
import { doc, onSnapshot } from "firebase/firestore"

type workOrderDetailsAction = {
	type: string
	payload: any
}

type EditFormulationAction = {
	type: string
	payload: EditFormulationDetailsBody
}

type ArchiveFormulationAction = {
	type: string
	payload: ArchiveFormulationBody
}

function* createWorkOrderSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const {
			suggested_experiment_id = [],
			prediction_experiment_ids = [],
			doe_id = [],
		} = yield select((state: StoreState) => state.workOrderDetails.workOrder)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const params = {
			...action.payload,
			suggested_experiment_id,
			prediction_experiment_ids,
			doe_ids: doe_id,
		}
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(createWorkOrder, params, headers)

		if (status === "Success") {
			yield put(displayNamesRequest({ backgroundFetch: true }))
			if (!!action.payload?.duplicate_work_order) {
				yield put(setDuplicateWo(data))
			} else {
				yield put(createWorkOrderSuccess(data))
				notification.success({ message: message })
			}
			if (!!data?.suggested_experiment_id?.length || !!data?.prediction_experiment_ids?.length) {
				delay(3000)
				yield put(workOrderRequest(data?.work_order_id))
			}
		} else {
			yield put(createWorkOrderFailure(message))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		yield put(createWorkOrderFailure(error))
		notification.error({
			message:
				(error as any)?.response?.data?.detail ??
				messages[ln].internal_server_error,
		})
	}
}

function* editWorkOrderSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			...action.payload,
		}
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(editWorkOrder, params, headers)

		if (status === "Success") {
			yield put(displayNamesRequest({ backgroundFetch: true }))
			notification.success({ message })
			if (!!data?.length) {
				yield put(editWorkOrderSuccess(data[0]))
			} else {
				yield call(history.push, "/signup")
			}
		} else {
			yield put(editWorkOrderFailure(message))
			notification.error({ message })
		}
	} catch (error) {
		yield put(editWorkOrderFailure(error))
		notification.error({
			message:
				(error as any)?.response?.data?.detail ??
				messages[ln].internal_server_error,
		})
	}
}

function* deleteWorkOrderSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const project_id = yield select(
			(state: StoreState) => state.projects.current
		)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(deleteWorkOrder, action.payload, headers)
		if (status === "Success") {
			yield put(deleteWorkOrderSuccess(data))
			toast.success(message)
			yield put(workOrdersRequest({ project_id }))
		} else {
			yield put(deleteWorkOrderFailure(message))
			toast.error(message)
		}
	} catch (error) {
		yield put(deleteWorkOrderFailure(error))
		notification.error({
			message:
				(error as any)?.response?.data?.detail ??
				messages[ln].internal_server_error,
		})
	}
}

function* workOrderSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			work_order_id: action.payload,
		}
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(getWorkOrder, params, headers)
		if (status === "Success") {
			yield put(workOrderSuccess(data))
		} else {
			yield put(workOrderFailure(message))
		}
	} catch (error) {
		yield put(workOrderFailure(error))
	}
}

function* referenceFormulationsSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			formulation_id: action.payload,
		}
		const {
			data: {
				result: { data, status, message = "Error" },
			},
		} = yield call(getFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(referenceFormulationsSuccess(data))
		} else {
			yield put(referenceFormulationsFailure(message))
		}
	} catch (error) {
		yield put(referenceFormulationsFailure(error))
	}
}

function* formulationSaga(
	action: workOrderDetailsAction
): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			formulation_id: action.payload,
		}
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(getFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(formulationSuccess(data))
		} else {
			yield put(formulationFailure(message))
		}
	} catch (error) {
		yield put(formulationFailure(error))
	}
}

function* editFormulationSaga(
	action: EditFormulationAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const {
			data: {
				result: { status, message, data },
			},
		} = yield call(editFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(editExperimentSuccess(data))
			toast.success(message)
		} else {
			yield put(editExperimentFailure(message))
			toast.error(message)
		}
	} catch (error) {
		yield put(editExperimentFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* autoSaveExperimentSaga(
	action: EditFormulationAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const {
			data: {
				result: { status, message, data },
			},
		} = yield call(autoSaveExperimentDetails, params, headers)
		if (status === "Success") {
			yield put(autoSaveExperimentSuccess({ data }))
		} else {
			yield put(editExperimentFailure(message))
			toast.error(message)
		}
	} catch (error) {
		yield put(editExperimentFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* archiveFormulationSaga(
	action: ArchiveFormulationAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const {
			data: {
				result: { status, message },
			},
		} = yield call(archiveFormulation, params, headers)
		if (status === "Success") {
			yield put(archiveFormulationSuccess(action.payload.formulation_id))
			notification.success({ message })
		} else {
			yield put(archiveFormulationFailure(message))
		}
	} catch (error) {
		yield put(archiveFormulationFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

type ExperimentRequestAction = {
	type: string
	payload: {
		experiment_id: string
	}
}

function* fetchExperimentSaga({ payload }: ExperimentRequestAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data: responseData, status, message },
			},
		} = yield call(fetchExperiment, { ...payload }, headers)
		if (status === "Success") {
			yield put(fetchExperimentSuccess(responseData))
		} else {
			yield put(fetchExperimentFailure(message))
		}
	} catch (error) {
		yield put(fetchExperimentFailure(error))
	}
}

function* fetchLinkedExperimentSaga({ payload }: ExperimentRequestAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data: responseData, status, message },
			},
		} = yield call(fetchExperiment, payload, headers)
		if (status === "Success") {
			const { data } = responseData
			yield put(fetchLinkedExperimentSuccess(data))
		} else {
			yield put(fetchLinkedExperimentFailure(message))
		}
	} catch (error) {
		yield put(fetchLinkedExperimentFailure(error))
	}
}

function* fetchLinkedExperimentDetailsSaga({ payload }: ExperimentRequestAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data: responseData, status, message },
			},
		} = yield call(fetchExperiment, { ...payload }, headers)
		if (status === "Success") {
			const { data } = responseData
			yield put(fetchLinkedExperimentDetailsSuccess(data))
		} else {
			yield put(fetchLinkedExperimentDetailsFailure(message))
		}
	} catch (error) {
		yield put(fetchLinkedExperimentDetailsFailure(error))
	}
}

function* dataExportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const configs = yield select((state: StoreState) => state.configs.features)
		const newPayload = Boolean(configs?.characterization_methods) ? payload?.from === "compare" ? { graphs: payload.graphs } : payload : payload?.from === "compare" ? { graphs: payload.graphs } : payload

		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			Boolean(configs?.characterization_methods) ? ZeonDataExportApi : dataExportApi,
			newPayload,
			headers
		)
		if (status === "Success") {
			yield put(dataExportSuccess(data))
			window.open(data?.download_link, "_blank")
		} else {
			yield put(dataExportFailure())
			message.error(message)
		}
	} catch (error: any) {
		yield put(dataExportFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* editDisplayIdSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message: apiResponseMessage },
			},
		} = yield call(editDisplayId, payload, headers)
		if (status === "Success") {
			message.success({ content: apiResponseMessage, duration: 1 })
			yield put(editDisplayIdSuccess(payload))
		} else {
			yield put(editDisplayIdFailure(apiResponseMessage))
		}
	} catch (error) {
		yield put(editDisplayIdFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* uploadGraphsDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(uploadGraphsDataApi, payload, headers)

		if (status === "Success") {
			yield put(uploadGraphsDataSuccess())
			notification.success({ message })
		} else {
			yield put(uploadGraphsDataFailure())
			notification.error({ message })
		}
	} catch (error: any) {
		yield put(uploadGraphsDataFailure())
		notification.error({
			message:
				(error as any)?.response?.data?.detail ??
				messages[ln].internal_server_error,
		})
	}
}

function* plotsDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(plotsDataApi, payload, headers)
		if (status === "Success") {
			yield put(plotsDataSuccess(data))
		} else {
			yield put(plotsDataFailure())
		}
	} catch (error) {
		yield put(plotsDataFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* graphsDataHistorySaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(graphsDataHistoryApi, payload, headers)
		if (status === "Success") {
			yield put(graphsDataHistorySuccess(data))
		} else {
			yield put(graphsDataHistoryFailure())
		}
	} catch (error) {
		yield put(graphsDataHistoryFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* woStatusChangeSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(changeWoStatusApi, payload, headers)
		if (status === "Success") {
			yield put(woStatusChangeSuccess())
			notification.success({ message })
			if (payload.status === "closed") {
				yield call(history.push, "/work-orders?closed=true")
			} else {
				yield call(history.push, "/work-orders")
			}
		} else {
			yield put(woStatusChangeFailure())
			notification.error({ message })
		}
	} catch (error) {
		yield put(woStatusChangeFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}


function* uploadFileAttachmentSaga(action: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, message, status },
			},
		} = yield call(uploadFileAttachmentApi, action.payload.formData, headers)

		if (status === "Success") {
			notification.success({ message })
			yield put(uploadFileAttachmentSuccess(data))
			yield put(fetchFileListRequest(action.payload.details))
		} else {
			notification.error({ message })
			yield put(uploadFileAttachmentFailure(message))
		}
	} catch (error) {
		console.log(error)
		yield put(uploadFileAttachmentFailure(error))
	}
}

function* fetchFileListSaga(action: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, message, status },
			},
		} = yield call(fetchFileListApi, action.payload, headers)

		if (status === "Success") {
			yield put(fetchFileListSuccess(data))
		} else {
			yield put(fetchFileListFailure(message))
		}
	} catch (error) {
		console.log(error)
		yield put(fetchFileListFailure(error))
	}
}

function* deleteFileAttachmentSaga(action: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { file_id, work_order_stage, ...rest } = action.payload
		const {
			data: {
				result: { data, message, status },
			},
		} = yield call(deleteFileListApi, { file_id }, headers)

		if (status === "Success") {
			notification.success({ message })
			yield put(deleteFileAttachmentSuccess(data))
			yield put(fetchFileListRequest(rest))
		} else {
			notification.error({ message })
			yield put(deleteFileAttachmentFailure(message))
		}
	} catch (error) {
		console.log(error)
		yield put(deleteFileAttachmentFailure(error))
	}
}

function* stageExistsSaga(action: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { is_existing, message, status },
			},
		} = yield call(stageExistsApi, action.payload, headers)
		if (status === "Success") {
			yield put(stageExistsSuccess(is_existing))
		} else {
			yield put(stageExistsFailure(message))
		}
	} catch (error) {
		yield put(stageExistsFailure())
	}
}


function* saveProcessingProfilesSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message } } } = yield call(createEditProfileApi, payload, headers)
		if (status === "Success") {
			yield put((saveProcessingProfilesSuccess()))
			notification.success({ message })
			yield put(listProcessingProfilesRequest())
		} else {
			yield put(saveProcessingProfilesFailure())
			notification.error({ message })
		}
	} catch (error) {
		yield put(saveProcessingProfilesFailure())
	}
}


function* deleteProcessingProfilesSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message } } } = yield call(DeleteProfileApi, payload, headers);
		if (status === "Success") {
			yield put(deleteProcessingProfilesSuccess())
			notification.success({ message })
			yield put(listProcessingProfilesRequest())
		} else {
			yield put(deleteProcessingProfilesFailure())
			notification.error({ message })
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
		yield put(deleteProcessingProfilesFailure())
	}
}


function* listProcessingProfilesSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message, data } } } = yield call(listProfileApi, payload, headers);
		if (status === "Success") {
			yield put(listProcessingProfilesSuccess(data))
		} else {
			yield put(listProcessingProfilesFailure())
			notification.error({ message })
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
		yield put(listProcessingProfilesFailure())
	}
}

function* dataSanitySaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message, data } } } = yield call(dataSanityApi, payload, headers);
		if (status === "Success") {
			yield put(dataSanitySuccess(data))
			const unsubscribe = onSnapshot(doc(firestoreDb, process.env.REACT_APP_DS_COLLECTION ?? '', data?.redis_key), (doc) => {
				const checkStatus = doc.data()
				if (checkStatus?.column_group_check?.status === 'failed') {
					store.dispatch(dataSanitySuccess({ column_group_check: 'failed' }))
					unsubscribe()
					store.dispatch(dataSanityDataRequest({ key: data?.redis_key }))
				}
				if (checkStatus?.column_group_check?.status === 'in_progress') {
					store.dispatch(dataSanitySuccess({ checkStatus }))
				}
				if (checkStatus?.column_group_check?.status === 'completed') {
					unsubscribe()
					store.dispatch(dataSanityDataRequest({ key: data?.redis_key }))
				}
			});
		} else {
			yield put(dataSanityFailure())
			notification.error({ message })
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
		yield put(dataSanityFailure())
	}
}

function* dataSanityDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message, data } } } = yield call(dataSanityDataApi, payload, headers);
		if (status === "Success") {
			yield put(dataSanityDataSuccess(data))
		} else {
			yield put(dataSanityDataFailure())
			notification.error({ message })
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
		yield put(dataSanityDataFailure())
	}
}


export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(fetchExperimentRequest, fetchExperimentSaga)
	yield takeLatest(fetchLinkedExperimentRequest, fetchLinkedExperimentSaga)
	yield takeLatest(fetchLinkedExperimentDetailsRequest, fetchLinkedExperimentDetailsSaga)
	yield takeLatest(archiveFormulationRequest, archiveFormulationSaga)
	yield takeLatest(editExperimentRequest, editFormulationSaga)
	yield takeLatest(autoSaveExperimentRequest, autoSaveExperimentSaga)
	yield takeLatest(formulationRequest, formulationSaga)
	yield takeLatest(referenceFormulationsRequest, referenceFormulationsSaga)
	yield takeLatest(createWorkOrderRequest, createWorkOrderSaga)
	yield takeLatest(editWorkOrderRequest, editWorkOrderSaga)
	yield takeLatest(deleteWorkOrderRequest, deleteWorkOrderSaga)
	yield takeLatest(workOrderRequest, workOrderSaga)
	yield takeLatest(dataExportRequest, dataExportSaga)
	yield takeLatest(editDisplayIdRequest, editDisplayIdSaga)
	yield takeLatest(uploadGraphsDataRequest, uploadGraphsDataSaga)
	yield takeLatest(plotsDataRequest, plotsDataSaga)
	yield takeLatest(graphsDataHistoryRequest, graphsDataHistorySaga)
	yield takeLatest(woStatusChangeRequest, woStatusChangeSaga)
	yield takeLatest(fetchFileListRequest, fetchFileListSaga)
	yield takeLatest(uploadFileAttachmentRequest, uploadFileAttachmentSaga)
	yield takeLatest(deleteFileAttachmentRequest, deleteFileAttachmentSaga)
	yield takeLatest(stageExistsRequest, stageExistsSaga)
	yield takeLatest(saveProcessingProfilesRequest, saveProcessingProfilesSaga)
	yield takeLatest(deleteProcessingProfilesRequest, deleteProcessingProfilesSaga)
	yield takeLatest(listProcessingProfilesRequest, listProcessingProfilesSaga)
	yield takeLatest(dataSanityRequest, dataSanitySaga)
	yield takeLatest(dataSanityDataRequest, dataSanityDataSaga)
}
