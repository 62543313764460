

import { handleActions } from "redux-actions"
import { ConnectActionTypes } from "../actions/connection"

export type connectionReducerState = {
    connectionStatus: boolean
}

const defaultState: connectionReducerState = {
    connectionStatus: false
}


const connectionReducer = handleActions(
    {
        [ConnectActionTypes.CONNECTED]: (state) => {
            return {
                ...state,
                connectionStatus: true
            }
        },
    },
    defaultState
)

export default connectionReducer
