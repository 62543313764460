import React, { ComponentPropsWithoutRef } from "react"

type P = ComponentPropsWithoutRef<"svg"> & {
	size?: number
}

export const FranceFlag = ({ size = 24, ...props }: P) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			fill="none"
			viewBox="0 0 900 600"
			{...props}
		>
			<path fill="#CE1126" d="M900 0H0v600h900V0z"></path>
			<path fill="#fff" d="M600 0H0v600h600V0z"></path>
			<path fill="#002654" d="M300 0H0v600h300V0z"></path>
		</svg>
	)
}
