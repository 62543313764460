//import { GraphQLError } from 'graphql';
import {
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from 'relay-runtime';
import { createClient } from 'graphql-ws';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';
import { store } from './configureStore';
import { Modal } from 'antd';


export const subscriptionsClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL ?? '',
  connectionParams: () => {
    return {
      token: store.getState().login.loginResponse.token
    }
  }
})

const dispatchActions = (error_msg: string) => {
  subscriptionsClient.dispose()
  if (error_msg === "session_expired") {
    const storeState = store.getState()
    !!storeState.login?.loginResponse?.user_id && Modal.info({
      title: "Session expired",
      content: "Please Login again",
    });
    store.dispatch({ type: 'LOGOUT_SUCCESS' })
  }
  else if (error_msg === "new_token_required") {
    const storeState = store.getState()
    const requestPayload = {
      type: "REFRESH_TOKEN_REQUEST",
      payload: {
        lang: storeState.language.current,
      }
    }
    store.dispatch(requestPayload)
    store.dispatch({ type: 'CLEAR_PREVIOUS_ACTION' })
  }
}

subscriptionsClient.on("connected", (msg: any) => {
  store.dispatch({ type: "CONNECTED" })
})

subscriptionsClient.on("message", (msg: any) => {
  if (msg.type === "next") {
    const errors = msg?.payload?.errors
    if (errors?.length) {
      dispatchActions(errors?.[0]?.message)
    }
  }
})

subscriptionsClient.on("closed", (msg: any) => {
  subscriptionsClient.dispose()
})

// yes, both fetch AND subscribe handled in one implementation
function fetchOrSubscribe(operation: RequestParameters, variables: Variables): RelayObservable<GraphQLResponse> {
  return Observable.create((sink) => {
    if (!operation.text) {
      return
      // return sink.error(new Error('Operation text cannot be empty'));
    }
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      {
        ...sink,
        error: (err: any) => {
          if (err instanceof Error) {
            return
            // return sink.error(err);
          }

          if (err instanceof CloseEvent) {
            return
            // return sink.error(
            // reason will be available on clean closes
            //   new Error(
            //     `Socket closed with event ${err.code} ${err.reason || ''}`,
            //   ),
            // );
          }

          return
          // return sink.error(
          //   new Error(
          //     (err as GraphQLError[]).map(({ message }) => message).join(', ')
          //   )
          // );
        },

      } as any
    );
  });
}

const environment = new Environment({
  network: Network.create(fetchOrSubscribe, fetchOrSubscribe),
  store: new Store(new RecordSource()),
});

export default environment;
