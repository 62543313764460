import { sendPost } from "src/utils/sendApi"

export const ForgetPasswordApi = sendPost("v1/data/_forget_password")

export const ResetPasswordApi = sendPost("v1/data/_reset_password")

export type UpdatePasswordParams = {
	credentials: string
}
export interface UpdatePasswordResponse {
	message: string
	status: "Success" | "Failed"
}
export const UpdatePasswordApi = sendPost<
	UpdatePasswordParams,
	UpdatePasswordResponse
>("v1/data/_update_password")
