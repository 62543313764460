import { sendPost } from "src/utils/sendApi";

export const teams = sendPost("v1/data/_team");

export const updateUserRoles = sendPost(
  "v1/data/_update_user_role"
);

export const updateUserAccessApi = sendPost("v1/data/_update_user_access")

export const revokeUserAccessApi = sendPost("v1/data/_revoke_user")

export const reactivateAccountApi = sendPost("v1/data/_update_revoked_access")

export const invitationApprovalApi = sendPost("v1/data/_approve_invite")