import { put, takeLatest, call, select, delay } from "redux-saga/effects"
import { UpdatePasswordApi } from "src/services/passwordReset"
import { messages } from "src/utils/hooks"
import {
	updatePasswordFailure,
	updatePasswordRequest,
	updatePasswordSuccess,
} from "src/store/actions/updatePassword"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { logoutSuccess } from "../actions/login"
import { notification } from "antd"
import CryptoJS from "crypto-js"
import jwtManager from "src/utils/jwtManager"


type UpdatePasswordAction = {
	type: string
	payload: {
		old_password: string
		new_password: string
	}
}


function* updatePasswordSaga({
	payload,
}: UpdatePasswordAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const { old_password, new_password } = payload
		const key = CryptoJS.enc.Utf8.parse(String(process.env.REACT_APP_LOGIN_ENCRYPTION_KEY))
		const oldRandomBytes = CryptoJS.lib.WordArray.random(128 / 8).toString()
		const oldIv = CryptoJS.enc.Hex.parse(oldRandomBytes)
		const newRandomBytes = CryptoJS.lib.WordArray.random(128 / 8).toString()
		const newIv = CryptoJS.enc.Hex.parse(newRandomBytes)
		const encryptedOld = CryptoJS.AES.encrypt(old_password, key, { mode: CryptoJS.mode.CBC, iv: oldIv }).toString()
		const encryptedNew = CryptoJS.AES.encrypt(new_password, key, { mode: CryptoJS.mode.CBC, iv: newIv }).toString()
		const credentials = `${encryptedOld}.${oldIv.toString()}.${encryptedNew}.${newIv.toString()}`

		const {
			data: { result: { status, message } } } = yield call(UpdatePasswordApi, { credentials }, headers)
		if (status === "Success") {
			yield put(updatePasswordSuccess())
			delay(1000)
			yield put(logoutSuccess())
			notification.success({ message: "Password reset succesfully", description: "Please Login again to continue" })
		} else {
			yield put(updatePasswordFailure())
			notification.error({ message })
		}
	} catch (err) {
		yield put(updatePasswordFailure(messages[ln].internal_server_error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(updatePasswordRequest, updatePasswordSaga)
}
