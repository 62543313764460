import { createActions } from "redux-actions";

export enum CelsureCompareFormulationsActionTypes {
  REQUEST = "CELSURE_COMPARE_FORMULATIONS_REQUEST",
  SUCCESS = "CELSURE_COMPARE_FORMULATIONS_SUCCESS",
  FAILURE = "CELSURE_COMPARE_FORMULATIONS_FAILURE",
  CLEANUP = "CELSURE_COMPARE_FORMULATIONS_CLEANUP",
}

export enum CelsureReportUploadActionTypes {
  REQUEST = "CELSURE_REPORT_UPLOAD_REQUEST",
  SUCCESS = "CELSURE_REPORT_UPLOAD_SUCCESS",
  FAILURE = "CELSURE_REPORT_UPLOAD_FAILURE",
}

export enum CelsureReportShareActionTypes {
    REQUEST = "CELSURE_REPORT_SHARE_REQUEST",
    SUCCESS = "CELSURE_REPORT_SHARE_SUCCESS",
    FAILURE = "CELSURE_REPORT_SHARE_FAILURE",
  }
  
  export enum CelsureReportDeleteActionTypes {
    REQUEST = "CELSURE_REPORT_DELETE_REQUEST",
    SUCCESS = "CELSURE_REPORT_DELETE_SUCCESS",
    FAILURE = "CELSURE_REPORT_DELETE_FAILURE",
  }
  
  
  export enum CelsureReportsRetrieveActionTypes {
    REQUEST = "CELSURE_REPORTS_RETRIEVE_REQUEST",
    SUCCESS = "CELSURE_REPORTS_RETRIEVE_SUCCESS",
    FAILURE = "CELSURE_REPORTS_RETRIEVE_FAILURE",
  }

export const {
  celsureCompareFormulationsRequest,
  celsureCompareFormulationsSuccess,
  celsureCompareFormulationsFailure,
  celsureCompareFormulationsCleanup,
  celsureReportUploadRequest,
  celsureReportUploadSuccess,
  celsureReportUploadFailure,
  celsureReportShareRequest,
  celsureReportShareSuccess,
  celsureReportShareFailure,
  celsureReportDeleteRequest,
  celsureReportDeleteSuccess,
  celsureReportDeleteFailure,
  celsureReportsRetrieveRequest,
  celsureReportsRetrieveSuccess,
  celsureReportsRetrieveFailure,
} = createActions({
  [CelsureCompareFormulationsActionTypes.REQUEST]: (payload) => payload,
  [CelsureCompareFormulationsActionTypes.SUCCESS]: (payload) => payload,
  [CelsureCompareFormulationsActionTypes.FAILURE]: (payload) => payload,
  [CelsureCompareFormulationsActionTypes.CLEANUP]: (payload) => payload,
  [CelsureReportUploadActionTypes.REQUEST]: payload => payload,
  [CelsureReportUploadActionTypes.SUCCESS]: payload => payload,
  [CelsureReportUploadActionTypes.FAILURE]: (error: string) => ({ error }),
  [CelsureReportShareActionTypes.REQUEST]: payload => payload,
  [CelsureReportShareActionTypes.SUCCESS]: payload => payload,
  [CelsureReportShareActionTypes.FAILURE]: (error: string) => ({ error }),
  [CelsureReportDeleteActionTypes.REQUEST]: payload => payload,
  [CelsureReportDeleteActionTypes.SUCCESS]: payload => payload,
  [CelsureReportDeleteActionTypes.FAILURE]: (error: string) => ({ error }),
  [CelsureReportsRetrieveActionTypes.REQUEST]: payload => payload,
  [CelsureReportsRetrieveActionTypes.SUCCESS]: payload => payload,
  [CelsureReportsRetrieveActionTypes.FAILURE]: (error: string) => ({ error }),
});
