import axios from "axios"
import { sendCredsHostnames } from "src/constants"
import { Headers } from "../services/interface"

export const sendPost = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axios.post<R>(process.env.REACT_APP_API_URL + url, params, {
			headers,
			...(sendCredsHostnames.includes(window.location.hostname) && { withCredentials: true })
		})
}
export const sendPostML = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axios.post<R>(process.env.REACT_APP_ML_URL + url, params, {
			headers
		})
}

export const sendGetML = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axios.get<R>(process.env.REACT_APP_ML_URL + url, {
			headers
		})
}

export const sendGet = <Result = any>(url: string) => {
	return (headers: Headers) =>
		axios.get<Result>(process.env.REACT_APP_API_URL + url, {
			headers
		})
}

export const sendPostCelsure = (url: string) => {
	return (params: any, headers: Headers) =>
		axios.post(process.env.REACT_APP_CELSURE_URL + url, params, {
			headers
		})
}
