import React from "react"
import { Select, Tooltip } from "antd"
import { useDispatch, useSelector } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore"
import { Language, languages } from "src/store/reducers/language"
import {
	ChinaFlag,
	FranceFlag,
	JapanFlag,
	PortugalFlag,
	SpainFlag,
	UsFlag,
} from "src/components/icons"
import { Icon } from "src/typings"
import { blue } from "@ant-design/colors"
import { trackEvent } from "src/analytics"

export const languageLabels: {
	[key in Language]: {
		label: string
		flag: Icon
	}
} = {
	en: {
		label: "English",
		flag: UsFlag,
	},
	fr: {
		label: "Français",
		flag: FranceFlag,
	},
	ja: {
		label: "日本語",
		flag: JapanFlag,
	},
	es: {
		label: "Español",
		flag: SpainFlag,
	},
	pt: {
		label: "Português",
		flag: PortugalFlag,
	},
	zh: {
		label: "中文",
		flag: ChinaFlag,
	},
}

export const LanguageSelector = () => {
	const [t, changeLanguage] = useTranslate()
	const dispatch = useDispatch()
	const { current } = useSelector((state: StoreState) => state.language)
	const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
	// const isSidebarcollapsed = useSelector(
	// 	(state: StoreState) => state.sidebar.collapsed
	// )

	return (
		<Tooltip
			placement="right"
			title={t("navBar.switchLanguage")}
			getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
		>
			<Select
				popupMatchSelectWidth={false}
				onChange={(value: Language) => {
					trackEvent(userId, 'User changed the language to ' + value)
					dispatch(changeLanguage(value))
				}}
				defaultValue="en"
				getPopupContainer={(trigger) => trigger.parentNode}
				value={current}
				style={{
					width: "100%",
					fontWeight: "bold",
					display: "flex",
					flexDirection: "column",
					textAlign: "center",
				}}
			>
				{languages.map((lang) => {
					// const Flag = languageLabels[lang].flag
					return (
						<Select.Option
							style={{
								backgroundColor: lang === current ? blue["5"] : "",
								color: lang === current ? "#fff" : "",
							}}
							key={lang}
							value={lang}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<span>{languageLabels[lang].label}</span>
								<span>{lang}</span>
								{/* {!isSidebarcollapsed && <Flag size={16} />} */}
								{/* {!isSidebarcollapsed && (
									<span>{languageLabels[lang].label}</span>
								)} */}
								{/* {isSidebarcollapsed && <span>{lang}</span>} */}
							</div>
						</Select.Option>
					)
				})}
			</Select>
		</Tooltip>
	)
}
