import axios from "axios"
import { Headers } from "../interface"

export const getExplainabilityApi = (params: any, headers: Headers) => {
    return axios.post<any>(
        process.env.REACT_APP_ML_URL + 'get_explainability',
        params,
        {
            headers
        }
    )
}

export const getCustomInsightsDataApi = (params: any, headers: Headers) => {
    return axios.post<any>(
        process.env.REACT_APP_ML_URL + 'get_custom_insights_info',
        params,
        {
            headers
        }
    )
}

export const getExplainableAIReportApi = (params: any, headers: Headers) => {
    return axios.post<any>(
        process.env.REACT_APP_ML_URL + 'get_ex_ai_report',
        params,
        {
            headers
        }
    )
}