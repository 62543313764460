import { createActions } from "redux-actions";

export enum celsureReportPreviewActionTypes {
  CELSURE_WO_UPLOAD_REPORT_REQUEST = "CELSURE_WO_UPLOAD_REPORT_REQUEST",
  CELSURE_WO_UPLOAD_REPORT_SUCCESS = "CELSURE_WO_UPLOAD_REPORT_SUCCESS",
  CELSURE_WO_UPLOAD_REPORT_FAILURE = "CELSURE_WO_UPLOAD_REPORT_FAILURE",
  CELSURE_WO_RETRIEVE_REPORT_REQUEST = "CELSURE_WO_RETRIEVE_REPORT_REQUEST",
  CELSURE_WO_RETRIEVE_REPORT_SUCCESS = "CELSURE_WO_RETRIEVE_REPORT_SUCCESS",
  CELSURE_WO_RETRIEVE_REPORT_FAILURE = "CELSURE_WO_RETRIEVE_REPORT_FAILURE",
  CELSURE_WO_REVIEW_REPORT_REQUEST = "CELSURE_WO_REVIEW_REPORT_REQUEST",
  CELSURE_WO_REVIEW_REPORT_SUCCESS = "CELSURE_WO_REVIEW_REPORT_SUCCESS",
  CELSURE_WO_REVIEW_REPORT_FAILURE = "CELSURE_WO_REVIEW_REPORT_FAILURE",
  CELSURE_WO_DELETE_REPORT_REQUEST = "CELSURE_WO_DELETE_REPORT_REQUEST",
  CELSURE_WO_DELETE_REPORT_SUCCESS = "CELSURE_WO_DELETE_REPORT_SUCCESS",
  CELSURE_WO_DELETE_REPORT_FAILURE = "CELSURE_WO_DELETE_REPORT_FAILURE",
}
export const{
    celsureWoUploadReportRequest,
    celsureWoUploadReportSuccess,
    celsureWoUploadReportFailure,
    celsureWoRetrieveReportRequest,
    celsureWoRetrieveReportSuccess,
    celsureWoRetrieveReportFailure,
    celsureWoReviewReportRequest,
    celsureWoReviewReportSuccess,
    celsureWoReviewReportFailure,
    celsureWoDeleteReportRequest,
    celsureWoDeleteReportSuccess,
    celsureWoDeleteReportFailure
}= createActions({
    [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_REQUEST]:(payload)=>payload,
    [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_SUCCESS]:(response)=>response,
    [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_FAILURE]:(error:string)=>({error}),
    [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_REQUEST]:(payload)=>payload,
    [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_SUCCESS]:(response)=>response,
    [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_FAILURE]:(error:string)=>({error}),
    [celsureReportPreviewActionTypes.CELSURE_WO_DELETE_REPORT_REQUEST]:(payload)=>payload,
    [celsureReportPreviewActionTypes.CELSURE_WO_DELETE_REPORT_SUCCESS]:(response)=>response,
    [celsureReportPreviewActionTypes.CELSURE_WO_DELETE_REPORT_FAILURE]:(error:string)=>({error}),
})