import { createActions } from "redux-actions";

export enum SidebarActionTypes {
    TOGGLE = "SIDEBAR_TOGGLE",
}

export const {
    sidebarToggle,
} = createActions({
    [SidebarActionTypes.TOGGLE]: (collapsed: boolean) => ({ collapsed })
});
