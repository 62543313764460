import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import { SaveDataActionTypes, GetDataActionTypes } from "../actions/chemDraw"

export type chemDrawState = {
    saveStatus: AsyncStates,
    getStatus: AsyncStates,
    data: any
}

const defaultState: chemDrawState = {
    saveStatus: AsyncStates.INITIAL,
    getStatus: AsyncStates.INITIAL,
    data: {}
}

const chemDrawReducer = handleActions(
    {
        [SaveDataActionTypes.REQUEST]: (state) => ({
            ...state,
            saveStatus: AsyncStates.LOADING,
        }),
        [SaveDataActionTypes.SUCCESS]: (state, action: any) => ({
            ...state,
            saveStatus: AsyncStates.SUCCESS,
        }),
        [SaveDataActionTypes.FAILURE]: (state) => ({
            ...state,
            saveStatus: AsyncStates.ERROR,
        }),
        [GetDataActionTypes.REQUEST]: (state) => ({
            ...state,
            getStatus: AsyncStates.LOADING,
            data: {}
        }),
        [GetDataActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            getStatus: AsyncStates.SUCCESS,
            data: action.payload
        }),
        [GetDataActionTypes.FAILURE]: (state) => ({
            ...state,
            getStatus: AsyncStates.ERROR,
        }),
    },
    defaultState
)

export default chemDrawReducer
