import {
  Dropdown as AndDropdown,
  Badge,
  Menu,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Team } from "src/typings";
import { Dayjs } from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useEffect, useState } from "react";
import { FB_COLLECTION_NAME_PRESENCE, firestoreDb } from "src/utils/firebase";
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";

export type OnlineUser = Team & { lastSeen: Dayjs };

type P = {
  users: Team[];
  onlineUsers: OnlineUser[];
  user_id: string;
};

type UserMenuItemProps = {
  user: Team;
};

export const UserMenuItem = ({ user }: UserMenuItemProps) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    let unsub: Unsubscribe;
    const listenToUserPresence = async () => {
      const userDocRef = doc(
        firestoreDb,
        `${FB_COLLECTION_NAME_PRESENCE}/${user.user_id}`
      );
      unsub = onSnapshot(userDocRef, (doc) => {
        const userDoc = doc.data();
        if (userDoc) {
          setStatus(userDoc["online"]);
        }
      });
    };
    if (user.user_id) {
      listenToUserPresence();
    }

    return () => {
      unsub && unsub();
    };
  }, [user.user_id]);

  return (
    <Menu.Item
      key={user.user_id}
      disabled
      style={{ backgroundColor: "transparent", cursor: "default" }}
    >
      <Space>
        <Tooltip title={status ? "online" : "offline"}>
          <Badge status={status ? "success" : "default"} />
        </Tooltip>
        <Typography.Text
          ellipsis={{ tooltip: user.user_name }}
          style={{ width: 90 }}
        >
          {user.user_name}
        </Typography.Text>
      </Space>
    </Menu.Item>
  );
};

export const Dropdown = ({ users, onlineUsers, user_id }: P) => {
  const [t] = useTranslate();

  return (
    <AndDropdown
      trigger={["hover"]}
      getPopupContainer={(triggerNode: HTMLElement) =>
        (triggerNode?.parentNode as HTMLElement) || document.body
      }
      overlay={
        <Menu onClick={(e) => e.domEvent.stopPropagation()}>
          {users
            .filter((user) => user.user_id !== user_id)
            .map((user) => (
              <UserMenuItem user={user} />
            ))}
        </Menu>
      }
    >
      <div
        style={{
          cursor: "pointer",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Tooltip title="online">
            <Badge status="success" />
          </Tooltip>
          <span>{t("common.online")}</span>
        </div>
        <DownOutlined />
      </div>
    </AndDropdown>
  );
};
