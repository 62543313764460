import { handleActions } from "redux-actions"
import {
  CelsureWoCreateActionTypes, CelsureWoDeleteActionTypes, CelsureWoUpdateActionTypes, CelsureWoListActionTypes,
  CelsureWoTrialsActionTypes, SetCelsureWoActionTypes, ResetCelsureWoActionTypes, CelsureFileHistoryActionTypes,
  CelsureFileUploadActionTypes, CelsureGetPlotsActionTypes, CelsureApproveTrialsActionTypes
} from "../actions/celsureWo"
import { AsyncStates } from "../../constants"


export type CelsureWoReducerState = {
  celsureCreateWoStatus: AsyncStates,
  celsureUpdateWoStatus: AsyncStates,
  celsureDeleteWoStatus: AsyncStates,
  celsureWoListStatus: AsyncStates,
  celsureWoList: any[],
  celsureWoTrialsStatus: AsyncStates,
  celsureWoTrials: any[],
  celsureWo: any
  celsureFileUploadStatus: AsyncStates
  ceslurePlotsStatus: AsyncStates
  ceslurePlotsData: any,
  ceslureFileHistoryStatus: AsyncStates
  celsureFileHistoryData: any[],
  ceslureApproveTrialsStatus: AsyncStates
}

const defaultState: CelsureWoReducerState = {
  celsureCreateWoStatus: AsyncStates.INITIAL,
  celsureUpdateWoStatus: AsyncStates.INITIAL,
  celsureDeleteWoStatus: AsyncStates.INITIAL,
  celsureWoListStatus: AsyncStates.INITIAL,
  celsureWoList: [],
  celsureWoTrialsStatus: AsyncStates.INITIAL,
  celsureWoTrials: [],
  celsureWo: {},
  celsureFileUploadStatus: AsyncStates.INITIAL,
  ceslurePlotsStatus: AsyncStates.INITIAL,
  ceslurePlotsData: [],
  ceslureFileHistoryStatus: AsyncStates.INITIAL,
  celsureFileHistoryData: [],
  ceslureApproveTrialsStatus: AsyncStates.INITIAL
}

const celsureWoReducer = handleActions(
  {
    [CelsureWoCreateActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureCreateWoStatus: AsyncStates.LOADING,
    }),
    [CelsureWoCreateActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureCreateWoStatus: AsyncStates.SUCCESS,
    }),
    [CelsureWoCreateActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureCreateWoStatus: AsyncStates.ERROR,
    }),
    [CelsureWoDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureDeleteWoStatus: AsyncStates.LOADING,
    }),
    [CelsureWoDeleteActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureDeleteWoStatus: AsyncStates.SUCCESS,
    }),
    [CelsureWoDeleteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureDeleteWoStatus: AsyncStates.ERROR,
    }),
    [CelsureWoUpdateActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureUpdateWoStatus: AsyncStates.LOADING,
    }),
    [CelsureWoUpdateActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      celsureUpdateWoStatus: AsyncStates.SUCCESS,
    }),
    [CelsureWoUpdateActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureUpdateWoStatus: AsyncStates.ERROR,
    }),
    [CelsureWoListActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureWoListStatus: AsyncStates.LOADING,
      celsureWoList: []
    }),
    [CelsureWoListActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      celsureWoListStatus: AsyncStates.SUCCESS,
      celsureWoList: action.payload
    }),
    [CelsureWoListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureWoListStatus: AsyncStates.ERROR,
      celsureWoList: []
    }),
    [CelsureWoTrialsActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureWoTrialsStatus: AsyncStates.LOADING,
      celsureWoTrials: []
    }),
    [CelsureWoTrialsActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      celsureWoTrialsStatus: AsyncStates.SUCCESS,
      celsureWoTrials: action.payload
    }),
    [CelsureWoTrialsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureWoTrialsStatus: AsyncStates.ERROR,
      celsureWoTrials: []
    }),
    [CelsureFileUploadActionTypes.REQUEST]: (state) => ({
      ...state,
      celsureFileUploadStatus: AsyncStates.LOADING,
    }),
    [CelsureFileUploadActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      celsureFileUploadStatus: AsyncStates.SUCCESS,
      celsureWo: { ...state.celsureWo, plots_data_uploaded: true }
    }),
    [CelsureFileUploadActionTypes.FAILURE]: (state, action) => ({
      ...state,
      celsureFileUploadStatus: AsyncStates.ERROR,
    }),
    [CelsureGetPlotsActionTypes.REQUEST]: (state) => ({
      ...state,
      ceslurePlotsStatus: AsyncStates.LOADING,
    }),
    [CelsureGetPlotsActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      ceslurePlotsStatus: AsyncStates.SUCCESS,
      ceslurePlotsData: action.payload
    }),
    [CelsureGetPlotsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      ceslurePlotsStatus: AsyncStates.ERROR,
    }),
    [CelsureFileHistoryActionTypes.REQUEST]: (state) => ({
      ...state,
      ceslureFileHistoryStatus: AsyncStates.LOADING,
    }),
    [CelsureFileHistoryActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      ceslureFileHistoryStatus: AsyncStates.SUCCESS,
      celsureFileHistoryData: action.payload
    }),
    [CelsureFileHistoryActionTypes.FAILURE]: (state, action) => ({
      ...state,
      ceslureFileHistoryStatus: AsyncStates.ERROR,
    }),
    [CelsureApproveTrialsActionTypes.REQUEST]: (state) => ({
      ...state,
      ceslureApproveTrialsStatus: AsyncStates.LOADING,
    }),
    [CelsureApproveTrialsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      ceslureApproveTrialsStatus: AsyncStates.SUCCESS,
    }),
    [CelsureApproveTrialsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      ceslureApproveTrialsStatus: AsyncStates.ERROR,
    }),
    [SetCelsureWoActionTypes.SET]: (state, action) => ({
      ...state,
      celsureWo: action.payload
    }),
    [ResetCelsureWoActionTypes.RESET]: () => defaultState
  },
  defaultState
)

export default celsureWoReducer
