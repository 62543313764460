import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import { GetPendingInvitationsListActionTypes, InviteActionTypes, ResendEmailActionTypes, WithDrawInvitationActionTypes } from "../actions/invite";

export type InviteState = {
  status: AsyncStates;
  error: string;
  invitationList: any[];
  invitationListError: string;
  invitationListStatus: AsyncStates,
  resendEmailError: string;
  resendEmailStatus: AsyncStates,
  withdrawInvitationError: string;
  withdrawInvitationStatus: AsyncStates,
};

const defaultState: InviteState = {
  status: AsyncStates.INITIAL,
  error: "",
  invitationList: [],
  invitationListError: "",
  invitationListStatus: AsyncStates.INITIAL,
  resendEmailError: "",
  resendEmailStatus: AsyncStates.INITIAL,
  withdrawInvitationError: "",
  withdrawInvitationStatus: AsyncStates.INITIAL,
};

const inviteReducer = handleActions(
  {
    [InviteActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [InviteActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
      };
    },
    [InviteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [GetPendingInvitationsListActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        invitationListStatus: AsyncStates.LOADING,
        invitationListError: "",
      })
    },
    [GetPendingInvitationsListActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        invitationListStatus: AsyncStates.SUCCESS,
        invitationListError: "",
        invitationList: action.payload,
      };
    },
    [GetPendingInvitationsListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      invitationListStatus: AsyncStates.ERROR,
      invitationListError: action.payload.error,
    }),
    [ResendEmailActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        resendEmailStatus: AsyncStates.LOADING,
        resendEmailError: "",
      })
    },
    [ResendEmailActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        resendEmailStatus: AsyncStates.SUCCESS,
        resendEmailError: "",
      };
    },
    [ResendEmailActionTypes.FAILURE]: (state, action) => ({
      ...state,
      resendEmailStatus: AsyncStates.ERROR,
      resendEmailError: action.payload.error,
    }),

    [WithDrawInvitationActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        withdrawInvitationStatus: AsyncStates.LOADING,
        withdrawInvitationError: "",
      })
    },
    [WithDrawInvitationActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        withdrawInvitationStatus: AsyncStates.SUCCESS,
        withdrawInvitationError: "",
      };
    },
    [WithDrawInvitationActionTypes.FAILURE]: (state, action) => ({
      ...state,
      withdrawInvitationStatus: AsyncStates.ERROR,
      withdrawInvitationError: action.payload.error,
    }),
  },
  defaultState
);

export default inviteReducer;
