import { sendPost } from "../../utils/sendApi"

export const createParameterApi = sendPost("v1/data/_inventory/_create_parameter")

export const updateParameterApi = sendPost("v1/data/_inventory/_update_parameter")

export const checkPropertyApi = sendPost("v1/data/_check_property_exists")

export const deletePropertyApi = sendPost("v1/data/_delete_property")

export const createMetadataTemplateApi = sendPost("v1/data/metadata/_create_template")

export const deleteMetadataTemplateApi = sendPost("v1/data/metadata/_delete_template")

export const listMetadataTemplateApi = sendPost("v1/data/metadata/_get_template")

export const updateMetadataTemplateApi = sendPost("v1/data/metadata/_update_template")

export const inventoryAttachmentsAddApi = sendPost("v1/data/_inventory/_add_upload")

export const inventoryAttachmentsListApi = sendPost("v1/data/_inventory/_list_upload")

export const inventoryAttachmentsDeleteApi = sendPost("v1/data/_inventory/_delete_upload")