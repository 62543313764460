export const toTitleCase = (input: string) => {
	if (typeof input !== "string") return input
	if (input === "") return input

	try {
		const output = input
			.trim()
			.replaceAll("_", " ")
			.replaceAll(/\s\s+/g, " ")
			.trim()
			.split(" ")
			.map((word) => {
				return word[0].toUpperCase() + word.slice(1)
			})
			.join(" ")
		return output
	} catch (err) {
		console.error("Error in toTitleCase function: ", { input, err })
		return input
	}
}
