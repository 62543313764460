import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	settingsRequest,
	settingsSuccess,
	settingsFailure,
	settingsUpdateRequest,
	settingsUpdateSuccess,
	settingsUpdateFailure,
	billingDetailsRequest,
	billingDetailsSuccess,
	billingDetailsFailure,
	updatePaymentMethodSuccess,
	updatePaymentMethodFailure,
	updatePaymentMethodRequest,
	updateBillingDetailsRequest,
	updateBillingDetailsSuccess,
	updateBillingDetailsFailure,
	updateSubscriptionRequest,
	updateSubscriptionSuccess,
	updateSubscriptionFailure,
} from "../actions/settings"
import { SettingsApi, SettingsUpdateApi, getBillingDetailsApi, updateBillingDetailsApi, updatePaymentMethodApi, updateSubscriptionApi } from "../../services/settings"
import { messages } from "src/utils/hooks"
import { message } from "antd"
import { displayNamesRequest } from "../actions/displayNames"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { updateLoginResponse } from "../actions/login"

type updateSettingsAction = {
	type: string
	payload: {
		stages: [
			{
				identifier: string
				name: string
			}
		]
	}
}

const alertMessage = (message: string) => {
	return {
		content: message,
		style: {
			marginTop: "5vh",
		},
		className: "ant-message",
		duration: 2,
	}
}

function* settingsSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(SettingsApi, {}, headers)
		if (result.status === "Success") {
			yield put(settingsSuccess(result))
		} else {
			yield put(settingsFailure(result.message))
			message.error(alertMessage(result.message))
		}
	} catch (error) {
		yield put(settingsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* settingsUpdateSaga({
	payload,
}: updateSettingsAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(SettingsUpdateApi, payload, headers)
		if (result.status === "Success") {
			yield put(settingsUpdateSuccess(result.message))
			message.success(alertMessage(result.status))
			yield put(displayNamesRequest())
		} else {
			yield put(settingsUpdateFailure(result.message))
			message.error(alertMessage(result.message))
		}
	} catch (error) {
		yield put(settingsUpdateFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* getBillingDetailsSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(getBillingDetailsApi, {}, headers)
		if (status === "Success") {
			yield put(billingDetailsSuccess(data))
		} else {
			yield put(billingDetailsFailure(message))
		}
	} catch (error) {
		yield put(billingDetailsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* updatePaymentMethodSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updatePaymentMethodApi, {}, headers)
		if (status === "Success") {
			yield put(updatePaymentMethodSuccess(data))
			window.location.href = data?.checkout_url
		} else {
			yield put(updatePaymentMethodFailure(message))
		}
	} catch (error) {
		yield put(updatePaymentMethodFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* updateBillingDetailsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updateBillingDetailsApi, payload, headers)
		if (status === "Success") {
			yield put(updateBillingDetailsSuccess(data))
			yield put(billingDetailsRequest())
		} else {
			yield put(updateBillingDetailsFailure(message))
		}
	} catch (error) {
		yield put(updateBillingDetailsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}


function* updateSubscriptionSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders, login: { loginResponse } } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updateSubscriptionApi, payload, headers)
		if (status === "Success") {
			yield put(updateSubscriptionSuccess(data))
			if (Object.keys(data || {}).length) {
				yield put(updateLoginResponse({ ...loginResponse, ...data }))
			}
			yield put(billingDetailsRequest())
		} else {
			yield put(updateSubscriptionFailure(message))
		}
	} catch (error) {
		yield put(updateSubscriptionFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(settingsRequest, settingsSaga)
	yield takeLatest(settingsUpdateRequest, settingsUpdateSaga)
	yield takeLatest(billingDetailsRequest, getBillingDetailsSaga)
	yield takeLatest(updatePaymentMethodRequest, updatePaymentMethodSaga)
	yield takeLatest(updateBillingDetailsRequest, updateBillingDetailsSaga)
	yield takeLatest(updateSubscriptionRequest, updateSubscriptionSaga)
}
