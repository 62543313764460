import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { StoreState } from "src/store/configureStore"
import { parseSearchQuery } from "src/utils/general"

export const useWorkOrdersTab = (workOrderType: "closed" | "open") => {
	const { push } = useHistory()
	const project_id = useSelector((state: StoreState) => state.projects.current)

	const previousPage = useMemo(() => {
		const query = window.location.search.split("?")[1]
		const searchParams =
			parseSearchQuery<{
				root: string
			}>(query)
		return searchParams.root
	}, [])

	function onBack() {
		push(
			previousPage === "projects"
				? `/projects/details/${project_id}?closed=${workOrderType === "closed"}`
				: `/work-orders?closed=${workOrderType === "closed"}`
		)
	}

	return { onBack }
}
