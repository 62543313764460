import { message } from "antd"
import { put, takeLatest, call, select } from "redux-saga/effects"
import { createFeedbackApi, getFeedbackListApi } from "src/services/feedback"
import { messages } from "src/utils/hooks"
import jwtManager from "src/utils/jwtManager"
import { LanguageUnion } from "src/utils/useTranslate"

import {
	fetchIssuesFailure,
	fetchIssuesRequest,
	fetchIssuesSuccess,
	createIssueRequest,
} from "../actions/feedback"
import { StoreState } from "../configureStore"

type issuesAction = {
	type: string
	payload: any
}


function* fetchIssuesSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(getFeedbackListApi, payload, headers)
		if (status === "Success") {
			yield put(fetchIssuesSuccess(data))
		} else {
			yield put(fetchIssuesFailure())
		}
	} catch (error) {
		yield put(fetchIssuesFailure(error))
	}
}

function* createIssueSaga(action: issuesAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: {
			result: { message: responseMessage, status },
		} } = yield call(createFeedbackApi, { title: action.payload.title, description: action.payload.body }, headers)
		if (status === "Success") {
			message.success(responseMessage)
			const {
				data: {
					result: { data, status },
				},
			} = yield call(getFeedbackListApi, { status: 'open', page_size: action.payload.page_size, page_num: 1 }, headers)
			if (status === "Success") {
				yield put(fetchIssuesSuccess(data))
			} else {
				yield put(fetchIssuesFailure())
			}
		} else {
			message.error(responseMessage)
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(fetchIssuesRequest, fetchIssuesSaga)
	yield takeLatest(createIssueRequest, createIssueSaga)
}
