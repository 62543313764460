import { createActions } from "redux-actions"

export enum GetSavedFiltersActionTypes {
	REQUEST = "GET_SAVED_FILTERS_REQUEST",
	SUCCESS = "GET_SAVED_FILTERS_SUCCESS",
	FAILURE = "GET_SAVED_FILTERS_FAILURE",
	CLEANUP = "GET_SAVED_FILTERS_CLEANUP",
	ADD = "GET_SAVED_FILTERS_ADD",
	REMOVE = "GET_SAVED_FILTERS_REMOVE",
}

export enum CreateNewFilterActionTypes {
	REQUEST = "CREATE_NEW_FILTER_REQUEST",
	SUCCESS = "CREATE_NEW_FILTER_SUCCESS",
	FAILURE = "CREATE_NEW_FILTER_FAILURE",
	CLEANUP = "CREATE_NEW_FILTER_CLEANUP",
}

export enum DeleteFiltersActionTypes {
	REQUEST = "DELETE_FILTERS_REQUEST",
	SUCCESS = "DELETE_FILTERS_SUCCESS",
	FAILURE = "DELETE_FILTERS_FAILURE",
	CLEANUP = "DELETE_FILTERS_CLEANUP",
}

export const {
	getSavedFiltersRequest,
	getSavedFiltersSuccess,
	getSavedFiltersFailure,
	getSavedFiltersCleanup,
	getSavedFiltersAdd,
	getSavedFiltersRemove,
	createNewFilterRequest,
	createNewFilterSuccess,
	createNewFilterFailure,
	createNewFilterCleanup,
	deleteFiltersRequest,
	deleteFiltersSuccess,
	deleteFiltersFailure,
	deleteFiltersCleanup,
} = createActions({
	[GetSavedFiltersActionTypes.REQUEST]: (payload) => payload,
	[GetSavedFiltersActionTypes.SUCCESS]: (data) => ({ data }),
	[GetSavedFiltersActionTypes.FAILURE]: (error) => ({ error }),
	[GetSavedFiltersActionTypes.CLEANUP]: () => {},
	[GetSavedFiltersActionTypes.ADD]: (data) => ({ data: [data] }),
	[GetSavedFiltersActionTypes.REMOVE]: (ids) => ({ data: ids }),

	[CreateNewFilterActionTypes.REQUEST]: (payload) => payload,
	[CreateNewFilterActionTypes.SUCCESS]: (data) => ({ data }),
	[CreateNewFilterActionTypes.FAILURE]: (error) => ({ error }),
	[CreateNewFilterActionTypes.CLEANUP]: () => {},

	[DeleteFiltersActionTypes.REQUEST]: (payload) => payload,
	[DeleteFiltersActionTypes.SUCCESS]: (data) => ({ data }),
	[DeleteFiltersActionTypes.FAILURE]: (error) => ({ error }),
	[DeleteFiltersActionTypes.CLEANUP]: () => {},
})
