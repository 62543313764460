import { Action, handleActions } from "redux-actions"
import { BoxPlotSummaryActionTypes, DataSummaryActionTypes, GetZeonCharacterizationDsmActionTypes, RecipeDistributionActionTypes } from "src/store/actions/dataSummary"
import { AsyncStates } from "src/constants"
import { NumericalSummary } from "src/components/AIEngine/DataSummary/types"
import { CharacterizationVariation } from "src/typings"

type CharacterizationVMethodZeonDsm =
	| {
		type: "methods"
		data: {
			characterization_id: string
			characterization_name: string
		}[]
	}
	| {
		type: "variations"
		data: {
			characterization_name: string
			characterization_id: string
			variations: CharacterizationVariation[]
		}
	}


export type DataSummaryReducerState =
	| {
		status: AsyncStates
		data: NumericalSummary
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionData: any[],
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
	}
	| {
		status: AsyncStates
		data: null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionData: any[],
		recipeDistributionDisplayNames: any,
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
	}
	| {
		status: AsyncStates
		data: null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionData: any[],
		recipeDistributionError: null,
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
	}
	| {
		status: AsyncStates
		data: NumericalSummary | null
		error: string | null
		message?: string
		numericalSummaryStatus: AsyncStates,
		recipeDistributionDisplayNames: any,
		recipeDistributionStatus: AsyncStates,
		recipeDistributionData: any[],
		recipeDistributionError: null
		characterizationMethodsZeonDsm?: CharacterizationVMethodZeonDsm | null
		characterizationMethodsZeonDsmStatus?: AsyncStates
		characterizationMethodsZeonDsmError?: string
		boxPlotSummaryData?: any[]
		boxPlotSummaryStatus?: AsyncStates
		boxPlotSummaryError?: string
	}


const defaultState: any = {
	status: AsyncStates.INITIAL,
	data: null,
	error: null,
	numericalSummaryStatus: AsyncStates.INITIAL,
	recipeDistributionStatus: AsyncStates.INITIAL,
	recipeDistributionData: [],
	recipeDistributionError: null,
	characterizationMethodsZeonDsm: null,
	characterizationMethodsZeonDsmStatus: AsyncStates.INITIAL,
	characterizationMethodsZeonDsmError: "",
	boxPlotSummaryData: [],
	boxPlotSummaryStatus: AsyncStates.INITIAL,
	boxPlotSummaryError: "",
}

const DataSummaryReducer = handleActions<
	any,
	{ data: NumericalSummary; error: string }
>(
	{
		[DataSummaryActionTypes.REQUEST]: (state) => ({
			...state,
			numericalSummaryStatus: AsyncStates.LOADING,
			data: state.data,
			error: null,
		}),
		[DataSummaryActionTypes.SUCCESS]: (
			state,
			action: Action<{ data: NumericalSummary }>
		) => ({
			...state,
			numericalSummaryStatus: AsyncStates.SUCCESS,
			data: { ...state.data, ...action.payload.data },
			error: null,
		}),
		[DataSummaryActionTypes.FAILURE]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			numericalSummaryStatus: AsyncStates.ERROR,
			data: null,
			error: action.payload.error,
		}),
		[DataSummaryActionTypes.CLEAR]: (state, action) => ({
			...state,
			numericalSummaryStatus: AsyncStates.INITIAL,
			data: null,
			error: null,
		}
		),
		[RecipeDistributionActionTypes.REQUEST]: (state) => ({
			...state,
			recipeDistributionStatus: AsyncStates.LOADING,
			recipeDistributionData: state.recipeDistributionData,
			recipeDistributionError: null,
		}),
		[RecipeDistributionActionTypes.SUCCESS]: (
			state,
			action: Action<{ data: any }>
		) => {
			return {
				...state,
				recipeDistributionStatus: AsyncStates.SUCCESS,
				recipeDistributionData: action.payload.data.recipeData,
				recipeDistributionDisplayNames: action.payload.data.displayNames,
				recipeDistributionError: null,
			}
		},
		[RecipeDistributionActionTypes.FAILURE]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			recipeDistributionStatus: AsyncStates.ERROR,
			recipeDistributionData: null,
			recipeDistributionError: action.payload.error,
		}),
		[RecipeDistributionActionTypes.CLEAR]: (
			state,
			action: Action<{ error: string }>
		) => ({
			...state,
			recipeDistributionStatus: AsyncStates.INITIAL,
			recipeDistributionData: [],
			recipeDistributionDisplayNames: {},
			recipeDistributionError: null,
		}),
		[GetZeonCharacterizationDsmActionTypes.REQUEST]: (state) => {
			return {
				...state,
				characterizationMethodsZeonDsmStatus: AsyncStates.LOADING,
				characterizationMethodsZeonDsmError: "",
			}
		},
		[GetZeonCharacterizationDsmActionTypes.SUCCESS]: (
			state,
			action: any
		) => {
			const firstKey = Object.keys(action.payload)[0]

			if (!firstKey)
				return {
					...state,
					characterizationMethodsZeonDsmStatus: AsyncStates.SUCCESS,
					characterizationMethodsZeonDsmError: "",
					characterizationMethodsZeonDsm: {
						type: "variations",
						data: {
							characterization_id: "",
							characterization_name: "",
							variations: [],
						},
					},
				}

			const data =
				firstKey && action.payload[firstKey].variations
					? {
						type: "variations",
						data: {
							characterization_id: firstKey,
							characterization_name: action.payload[firstKey].name,
							variations: action.payload[firstKey].variations,
						},
					}
					: {
						type: "methods",
						data: Object.keys(action.payload).map((chracId) => {
							return {
								characterization_id: chracId,
								characterization_name: action.payload[chracId].name as string,
							}
						}),
					}

			return {
				...state,
				characterizationMethodsZeonDsmStatus: AsyncStates.SUCCESS,
				characterizationMethodsZeonDsmError: "",
				characterizationMethodsZeonDsm: data,
			}
		},
		[GetZeonCharacterizationDsmActionTypes.FAILURE]: (state, action) => ({
			...state,
			characterizationMethodsZeonDsmStatus: AsyncStates.ERROR,
			characterizationMethodsZeonDsmError: action.payload.error,
		}),
		[GetZeonCharacterizationDsmActionTypes.CLEAR]: (state, action) => ({
			...state,
			characterizationMethodsZeonDsmStatus: AsyncStates.INITIAL,
			characterizationMethodsZeonDsmError: "",
			characterizationMethodsZeonDsm: null,
		}),
		[BoxPlotSummaryActionTypes.REQUEST]: (state) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.LOADING,
			boxPlotSummaryError: null,
		}),
		[BoxPlotSummaryActionTypes.SUCCESS]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.SUCCESS,
			boxPlotSummaryData: action.payload.data,
			boxPlotSummaryError: null,
		}),
		[BoxPlotSummaryActionTypes.FAILURE]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.ERROR,
			boxPlotSummaryData: [],
			boxPlotSummaryError: action.payload.error,
		}),
		[BoxPlotSummaryActionTypes.CLEAR]: (state, action) => ({
			...state,
			boxPlotSummaryStatus: AsyncStates.INITIAL,
			boxPlotSummaryData: [],
			boxPlotSummaryError: null,
		}),
	},
	defaultState
)

export default DataSummaryReducer
