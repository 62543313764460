import { handleActions } from "redux-actions"
import { UpdatePasswordActionTypes } from "../actions/updatePassword"
import { AsyncStates } from "../../constants"

export type UpdatePasswordReducerState = {
	status: AsyncStates
	error: string
}

const defaultState: UpdatePasswordReducerState = {
	status: AsyncStates.INITIAL,
	error: "",
}

const updatePasswordReducer = handleActions(
	{
		[UpdatePasswordActionTypes.REQUEST]: (state) => ({
			...state,
			status: AsyncStates.LOADING,
			error: "",
		}),
		[UpdatePasswordActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				status: AsyncStates.SUCCESS,
				error: "",
			}
		},
		[UpdatePasswordActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[UpdatePasswordActionTypes.CLEAR]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
		}),
	},
	defaultState
)

export default updatePasswordReducer
