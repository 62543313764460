import { put, takeLatest, call, select, takeEvery } from "redux-saga/effects"
import { messages } from "src/utils/hooks"
import {
	notifications,
	updateMultipleNotification,
	updateNotification,
	UpdateMultipleNotificationParams,
} from "src/services/notifications"
import {
	notificationsFailure,
	notificationsRequest,
	notificationsSuccess,
	notificationReadRequest,
	notificationReadSuccess,
	notificationReadFailure,
	notificationMultipleReadRequest,
	notificationMultipleReadSuccess,
	notificationMultipleReadFailure,
} from "../actions/notifications"
import { LanguageUnion } from 'src/utils/useTranslate'
import { StoreState } from '../configureStore'
import jwtManager from "src/utils/jwtManager"


type TeamsAction = {
	type: string
	payload: any
}

function* notificationsSaga(action: TeamsAction): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(notifications, headers)
		if (status === "Success") {
			yield put(notificationsSuccess(data))
		} else {
			yield put(notificationsFailure())
		}
	} catch (error) {
		yield put(notificationsFailure())
	}
}

function* notificationReadSaga(action: TeamsAction): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, message, status },
			},
		} = yield call(updateNotification, action.payload, headers)
		if (status === "Success") {
			yield put(
				notificationReadSuccess({ notification_id: action.payload, ...data })
			)
		} else {
			yield put(notificationReadFailure(message))
		}
	} catch (error) {
		yield put(notificationReadFailure(error))
	}
}

type NotificationReadMultipleSaga = {
	type: string
	payload: UpdateMultipleNotificationParams
}
function* notificationReadMultipleSaga(
	action: NotificationReadMultipleSaga
): Generator<any, any, any> {

	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(updateMultipleNotification, action.payload, headers)

		if (status === "Success") {
			yield put(notificationMultipleReadSuccess({ data }))
		} else {
			yield put(notificationMultipleReadFailure(messages[ln].internal_server_error))
		}
	} catch (error) {
		yield put(notificationMultipleReadFailure(error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(
		notificationMultipleReadRequest,
		notificationReadMultipleSaga
	)
	yield takeLatest(notificationsRequest, notificationsSaga)
	yield takeEvery(notificationReadRequest, notificationReadSaga)
}
