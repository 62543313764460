import { handleActions } from "redux-actions"
import { LanguageActionTypes } from "src/store/actions/language"
import { switchMomentLocale } from "src/utils/switchLocale"

export const languages = ["en", "ja", "fr", "es", "pt", "zh"] as const
export type Language = typeof languages[number]

export type LanguageState = {
	current: Language
}

const defaultState: LanguageState = {
	current: "en",
}

type Payload = {
	current: Language
}

const languageReducer = handleActions<LanguageState, Payload>(
	{
		[LanguageActionTypes.TOGGLE]: (state, action) => {
			switchMomentLocale(action.payload.current)

			return {
				...state,
				current: action.payload.current,
			}
		},
	},
	defaultState
)
export default languageReducer
