import { handleActions } from "redux-actions"
import { ProductGuideSettingsActionTypes } from "src/store/actions/product-guide-settings"
import {
	defaultSettings,
	ModuleSetting,
} from "src/components/product-guide/product-guide-tooltip/utils"

export type ProductGuideSettingsState = ModuleSetting

const defaultState: ProductGuideSettingsState = defaultSettings

const proceduresReducer = handleActions(
	{
		[ProductGuideSettingsActionTypes.PRODUCT_GUIDE_SETTINGS_UPDATE]: (
			state,
			action
		) => action.payload,
	},
	defaultState
)

export default proceduresReducer
