/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppForwardPredSubscriptionVariables = {
    user_id: string;
};
export type AppForwardPredSubscriptionResponse = {
    readonly forwardPred: {
        readonly prediction_id: string | null;
        readonly message: string | null;
        readonly status: string | null;
    } | null;
};
export type AppForwardPredSubscription = {
    readonly response: AppForwardPredSubscriptionResponse;
    readonly variables: AppForwardPredSubscriptionVariables;
};



/*
subscription AppForwardPredSubscription(
  $user_id: String!
) {
  forwardPred(user_id: $user_id) {
    prediction_id
    message
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user_id",
        "variableName": "user_id"
      }
    ],
    "concreteType": "forwardPredResult",
    "kind": "LinkedField",
    "name": "forwardPred",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "prediction_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppForwardPredSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppForwardPredSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7577c9da971c225af04bcae150e1bc07",
    "id": null,
    "metadata": {},
    "name": "AppForwardPredSubscription",
    "operationKind": "subscription",
    "text": "subscription AppForwardPredSubscription(\n  $user_id: String!\n) {\n  forwardPred(user_id: $user_id) {\n    prediction_id\n    message\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '05abfbaed7ac9aaf68e900e1842efa3b';
export default node;
