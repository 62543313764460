// import { Octokit } from "@octokit/rest"
// import { createAppAuth } from "@octokit/auth-app"

// const privateKey = `-----BEGIN PRIVATE KEY-----
// MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCznS+tAprpOFNu
// 28NPfE2SK0ESiNOi/fBpbCrkKVabIhk4e9NycjwUYM91SF7huYequ7EaFZmwzW/M
// ISW1dvpQDtwahFpNjqus09CWxUAd2nwWF3RNyLVGVtHuRGyaMn9D7D+GvGiVAgue
// Xu+MsYMYe3phDm6QCm0UNlfeOgWlBN3Gjmp2XgLHo+OkxTVZaNjwzE8zDb/zXbQP
// AL56pp1DoGgsS9Om8puTmD/nTykMOjnsjAgh9ayT/o6TlpGORTppZnBg6QdzE/zX
// v7o+SgwDQMSiyh2QDHU2MbDhaRZgAdH1iN9MLBYYdIHRjqF2mK8ZIYhpfedYsuZt
// OYT86Ed/AgMBAAECggEBAKGtK+0gGzEzcmanivdRfMHGDZ0RbO+ahX1f0HvwefTK
// jMk7LOonyczOF/AKZN3qkyQsLw67wsxTH/8vFzJxFoBZ3+Y+nMqTUhUP1QLiKwS0
// Vl3MXvRlZkcaf31qOhfSvJv3XHd7wOfgH3U/H4IVh1vSSiZzRzKBMA6xnAZ9U/dl
// FemXpJdZkwW/fa1fe2m1M9CDW0JKqYsjtaMbt2WFN741PTIV0QGYCIu7YVDz/tLb
// GYLHPK+QQwlmhwdusUQu3tBHJpDBrtmuNqJNn0jcQldEfkbTLRerASRvR9MtGh+V
// P4n0aQlOEls6ahfeUKKFQUEryV/weZdLCjXuj0QOt4ECgYEA7Zfk+CuqeeZd33YG
// 7YAH1ndp/LOveuZQObdnsvAlBtPKYLlheatSC6cTOaVuFeuaTG7XkuK1dFyRZ4pl
// fH0hZ2JJFgZCyZo0JqKwfhaNB7/rD9IcNxrVLiFuvls2Az+A9dkPzShwOgVzQXIU
// VFCokCdtstgc90H2PW6Duzc6OIUCgYEAwYdodwSDKgmIqERHppXjI1xUdDewAreX
// pYAAhjlagjeJ80gP5sLccGKJ942tWRYY5WBmoDJZe2LH1I7lJ3RKNTW/cxt/tTkN
// UGhIXx8UG0ytuTWyvlkPdEjVpB+gGLItRCAiwKKOrXG5yBrXX9J0KpS+jPWY7dVP
// kvr9+eDEgTMCgYAkv/7pqA9pZYmVm/L8X3ZW5Qeez8hP0ToNg2Hl2qFPigjepy39
// nNNG/CPMIduCRnwKYIa1sJ1yjzLqegyykcRN8nTtlDBWcKSqaTW1exV7YmMgglZc
// 66iXxxITHZagL/1piZaZHC0nvFoUgGnGlOdNzNt581WfpTNKU0LK/YLi5QKBgFaU
// xd+5XRUJfuII6iVVGXFQdVgpMc2FZYrdYV6rehqXzlfQ/Yw30XdWHnNqJwucHzc/
// kapJ3E6fOteVtl+YXzq++Q/+VXw2MgavIPGtbclo3K/nnjgakBduHmZ236Es1aOM
// 3P9huoNORdeIaqKr/Nyv/zABEJIWxKr1L3sDW+aRAoGAIFiCiUW/U5wX0BAxNWEH
// AkrmJWNvsGb2D3RnR+TIe+ekEcoETuNdSzqiHqXApyyiBd01Fj2Wt9BZ8ULY5pDY
// rDVq9AS2Z1Vj7Kl9TkdpzUoXS7HIJgUPXZL4AF6VTCSdwbzUnthLioR/T2Umhx5D
// oPvbBRgZNx/toy42hODc5mo=
// -----END PRIVATE KEY-----
// `

// export const issuesTracker = new Octokit({
//   authStrategy: createAppAuth,
//   auth: {
//     appId: 97264,
//     privateKey,
//     installationId: 14230091,
//   },
// })

import { sendPost } from "src/utils/sendApi";

export const createFeedbackApi = sendPost("v1/data/_send_feedback");
export const getFeedbackListApi = sendPost("v1/data/_list_feedback");
