import { sendPost } from "src/utils/sendApi";

export const SettingsApi = sendPost("v1/data/_settings");

export const SettingsUpdateApi = sendPost(
  "v1/data/_update_settings"
);

export const getBillingDetailsApi = sendPost("v1/data/payment_details");

export const updatePaymentMethodApi = sendPost("v1/data/add_payment_method");

export const updateBillingDetailsApi = sendPost("v1/data/update_billing_details");

export const updateSubscriptionApi = sendPost("v1/data/update_subscription");