import { Suspense, useMemo } from "react";
import { Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import SignUp from "../components/OldSignUp/SignUp";
import { useSelector } from "react-redux";
import { StoreState } from "../store/configureStore";
import { LoginProgress } from "../store/reducers/login";
import { Result } from "antd";
import { AsyncStates } from "src/constants";
import Spinner from "src/components/Spinner/Spinner";
import { RouteObj, getRoutes } from './routes'
import { history } from "src";
import { AnimatePresence } from "framer-motion";
import { LoadingOutlined } from "@ant-design/icons";

const NoMatch = () => (
  <Result
    style={{ height: "80vh", display: "flex", flexDirection: "column" }}
    status="404"
    title="Work in Progress!"
    subTitle=""
    extra={
      <Link type="primary" to="/dashboard">
        Back Home
      </Link>
    }
  />
)

const UnprotectedRoute = (props: RouteObj) => {
  const token = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email
  })

  if ((props.path.includes("login") || props.path.includes("signup")) && !!token) {
    history.push("/formulations");
    return null;
  } else {
    return (
      <Route {...props} />
    )
  }
};

const ProtectedRoute = (props: RouteObj) => {
  const token = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email
  })
  const status = useSelector((state: StoreState) => state.displayNames.status);
  const configsStatus = useSelector((state: StoreState) => state.configs.configsStatus);

  if (!token) {
    history.push("/login");
    return null
  }

  if (!props.enable && configsStatus === AsyncStates.SUCCESS) {
    return <NoMatch />

  }

  return token ? (
    status !== AsyncStates.SUCCESS ? (
      <Spinner />
    ) :
      (
        <Navbar>
          <Route {...props} />
        </Navbar>
      )
  ) : null;
};

export const SectionLoader = () => {
  return (
    <Result icon={<LoadingOutlined />} style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />
  )
}

const AppRouter = () => {
  const location = useLocation()
  const signInProgress = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email
  })

  const { modules: navbarConfigs } = useSelector((state: StoreState) => state.configs)

  const activeRoutes = useMemo(() => {
    return getRoutes(navbarConfigs)
  }, [navbarConfigs])

  return (

    <AnimatePresence exitBeforeEnter>
      <Switch key={location.pathname} location={location}>
        {Object.entries(activeRoutes).map(([key, route]: [any, any]) => {
          return !route.protectedRoute ? (
            <UnprotectedRoute {...route} key={key} />
          ) : (
            <ProtectedRoute {...route} key={key} />
          );
        })}

        {signInProgress === LoginProgress.IN_PROGRESS && (
          <Suspense fallback={<SectionLoader />}>
            <Route exact path="/signup" render={() => <SignUp />} />
          </Suspense>
        )}
        <Suspense fallback={<SectionLoader />}>
          <Route
            exact
            path="/"
            render={() => {
              return (
                <Redirect to="/formulations" />
                // <Redirect to="/dashboard" />
              );
            }}
          />
          <Route component={NoMatch} />
        </Suspense>
      </Switch>
    </AnimatePresence>
  )
}

export default AppRouter;
