import { createActions } from "redux-actions";

export enum FilterDataActionTypes {
  REQUEST = "FILTER_DATA_REQUEST",
  SUCCESS = "FILTER_DATA_SUCCESS",
  FAILURE = "FILTER_DATA_FAILURE",
  CLEAR = "FILTER_DATA_CLEAR"
}

export enum CustomTraingsActionTypes {
  REQUEST = "GET_CUSTOM_TRAINGS_REQUEST",
  SUCCESS = "GET_CUSTOM_TRAINGS_SUCCESS",
  FAILURE = "GET_CUSTOM_TRAINGS_FAILURE",
  CLEAR = "GET_CUSTOM_TRAINGS_CLEAR"
}

export enum CustomTrainActionTypes {
  REQUEST = "CUSTOM_TRAIN_REQUEST",
  SUCCESS = "CUSTOM_TRAIN_SUCCESS",
  FAILURE = "CUSTOM_TRAIN_FAILURE",
  CLEAR = "CUSTOM_TRAIN_CLEAR",
}

export enum DeleteModelsActionTypes {
  REQUEST = "DELETE_MODEL_REQUEST",
  SUCCESS = "DELETE_MODEL_SUCCESS",
  FAILURE = "DELETE_MODEL_FAILURE",
}

export const {
  filterDataRequest,
  filterDataSuccess,
  filterDataFailure,
  filterDataClear,
  getCustomTraingsRequest,
  getCustomTraingsSuccess,
  getCustomTraingsFailure,
  getCustomTraingsClear,
  customTrainRequest,
  customTrainSuccess,
  customTrainFailure,
  customTrainClear,
  deleteModelRequest,
  deleteModelSuccess,
  deleteModelFailure
} = createActions({
  [FilterDataActionTypes.REQUEST]: payload => payload,
  [FilterDataActionTypes.SUCCESS]: response => response,
  [FilterDataActionTypes.FAILURE]: () => { },
  [FilterDataActionTypes.CLEAR]: () => { },
  [CustomTraingsActionTypes.REQUEST]: payload => payload,
  [CustomTraingsActionTypes.SUCCESS]: response => response,
  [CustomTraingsActionTypes.FAILURE]: () => { },
  [CustomTraingsActionTypes.CLEAR]: () => { },
  [CustomTrainActionTypes.REQUEST]: payload => payload,
  [CustomTrainActionTypes.SUCCESS]: response => response,
  [CustomTrainActionTypes.FAILURE]: () => { },
  [CustomTrainActionTypes.CLEAR]: () => { },
  [DeleteModelsActionTypes.REQUEST]: payload => payload,
  [DeleteModelsActionTypes.SUCCESS]: response => response,
  [DeleteModelsActionTypes.FAILURE]: () => { },
});
