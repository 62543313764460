import { createActions } from "redux-actions";
import { ResetPasswordResponse, ResetPasswordPayload } from "../../services/passwordReset/interface"

export enum ResetPasswordActionTypes {
    REQUEST = "RESET_PASSWORD_REQUEST",
    SUCCESS = "RESET_PASSWORD_SUCCESS",
    FAILURE = "RESET_PASSWORD_FAILURE",
    CLEAR = "RESET_PASSWORD_CLEAR",
}

export const {
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordClear
} = createActions({
    [ResetPasswordActionTypes.REQUEST]: (payload: ResetPasswordPayload) => payload,
    [ResetPasswordActionTypes.SUCCESS]: (response: ResetPasswordResponse) => response,
    [ResetPasswordActionTypes.FAILURE]: (error: string) => ({ error }),
    [ResetPasswordActionTypes.CLEAR]: () => { },
})
