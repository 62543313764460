import { handleActions } from "redux-actions"
import { ExportPredictionActionTypes, SuggestedExpActionTypes, SuggestedExperimentsFilterActionTypes } from "src/store/actions/suggestedExp"
import { AsyncStates } from "src/constants"

export interface suggestedExpReducerState {
	expIdStatus: AsyncStates
	error: string
	data: any,
	suggestedExpDataForFilterData: any
	predictionDataExport: string | null,
	predictionDataExportStatus: AsyncStates
	predictionDataExportError: string
}

const defaultState: suggestedExpReducerState = {
	expIdStatus: AsyncStates.INITIAL,
	error: "",
	data: { experiments: [], total: 0, versions: [] },
	suggestedExpDataForFilterData: [],
	predictionDataExport: null,
	predictionDataExportStatus: AsyncStates.INITIAL,
	predictionDataExportError: ""
}

const suggestedExpReducer = handleActions(
	{
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_REQUEST]: (
			state,
			action
		) => ({
			...state,
			expIdStatus: AsyncStates.LOADING,
			error: "",
			data: { experiments: [], total: 0, versions: [] },
		}),
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				expIdStatus: AsyncStates.SUCCESS,
				error: "",
				data: action.payload,
			}
		},
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_FAILURE]: (
			state,
			action
		) => ({
			...state,
			expIdStatus: AsyncStates.ERROR,
			error: action.payload.error,
			data: { experiments: [], total: 0, versions: [] },
		}),
		[SuggestedExperimentsFilterActionTypes.SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				suggestedExpDataForFilterData: action.payload,
			}
		},
		[SuggestedExperimentsFilterActionTypes.FAILURE]: (
			state,
			action
		) => ({
			...state,
			suggestedExpDataForFilterData: [],
		}),
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_CLEAR]: (state) => ({
			...state,
			expIdStatus: AsyncStates.INITIAL,
			error: "",
			data: { experiments: [], total: 0, versions: [] },
		}),
		[ExportPredictionActionTypes.REQUEST]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.LOADING,
			predictionDataExport: null
		}),
		[ExportPredictionActionTypes.SUCCESS]: (state, action: any) => ({
			...state,
			predictionDataExportStatus: AsyncStates.SUCCESS,
			predictionDataExport: action.payload
		}),
		[ExportPredictionActionTypes.FAILURE]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.ERROR,
			predictionDataExportError: action.payload.error
		}),
		[ExportPredictionActionTypes.CLEAR]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.INITIAL,
			predictionDataExportError: ""
		}),
	},
	defaultState
)

export default suggestedExpReducer
