import { createActions } from "redux-actions"

export enum ProductGuideSettingsActionTypes {
	PRODUCT_GUIDE_SETTINGS_UPDATE = "PRODUCT_GUIDE_SETTINGS_UPDATE",
}

export const { productGuideSettingsUpdate } = createActions({
	[ProductGuideSettingsActionTypes.PRODUCT_GUIDE_SETTINGS_UPDATE]: (payload) =>
		payload,
})
