import { sendPost } from "src/utils/sendApi";

export const fetchProjectList = sendPost("v1/data/_projects");

export const newProject = sendPost("v1/data/_create_project");

export const updateProject = sendPost("v1/data/_update_project");

export const deleteProject = sendPost("v1/data/_delete_project");

export const createNoteApi = sendPost("v1/data/_projects/notes/create_notes");

export const updateNoteApi = sendPost("v1/data/_projects/notes/update_notes");

export const deleteNoteApi = sendPost("v1/data/_projects/notes/delete_notes");

export const getAllNotesApi = sendPost("v1/data/_projects/notes");

export const projectActivityAPi = sendPost("v1/data/_projects/activity");