import { createActions } from "redux-actions";

export enum GetExplainabilityActionTypes {
    REQUEST = "GET_EXPLAINABILITY_REQUEST",
    SUCCESS = "GET_EXPLAINABILITY_SUCCESS",
    FAILURE = "GET_EXPLAINABILITY_FAILURE",
    CLEAR = "GET_EXPLAINABILITY_CLEAR"
}

export enum GetExplainableAiReportActionTypes {
    REQUEST = "GET_EXPLAINABLE_AI_REPORT_REQUEST",
    SUCCESS = "GET_EXPLAINABLE_AI_REPORT_SUCCESS",
    FAILURE = "GET_EXPLAINABLE_AI_REPORT_FAILURE",
    CLEAR = "GET_EXPLAINABLE_AI_REPORT_CLEAR",
}

export const {
    getExplainabilityRequest,
    getExplainabilitySuccess,
    getExplainabilityFailure,
    getExplainabilityClear,
    getExplainableAiReportRequest,
    getExplainableAiReportSuccess,
    getExplainableAiReportFailure,
    getExplainableAiReportClear
} = createActions({
    [GetExplainabilityActionTypes.REQUEST]: payload => payload,
    [GetExplainabilityActionTypes.SUCCESS]: response => response,
    [GetExplainabilityActionTypes.FAILURE]: (payload) => payload,
    [GetExplainabilityActionTypes.CLEAR]: () => { },
    [GetExplainableAiReportActionTypes.REQUEST]: (payload) => payload,
    [GetExplainableAiReportActionTypes.SUCCESS]: (response) => response,
    [GetExplainableAiReportActionTypes.FAILURE]: error => ({ error }),
    [GetExplainableAiReportActionTypes.CLEAR]: () => ({}),
})