import { useEffect, useState } from "react";
import { AsyncStates } from "../../constants";
import { Button, Divider, Form, Input, Space, Typography, Layout, Row, Col, Card, Select, Alert } from 'antd';
import "../OldLogin/Login.scss";
import Sha1 from "js-sha1";
import { loginFirebaseRequest } from "../../store/actions/firebase";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/configureStore";
import { signupClearError, signupFailure, signupRequest } from '../../store/actions/signup';
import './SignUp.scss'
import Header from "./Header";
import Footer from "./Footer";
import Icon, { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Spinner from "../Spinner/Spinner";

import useTranslate from "src/utils/useTranslate";
import { Services, signInViaFirebase } from "src/services/firebase/firebase-auth";
import { MicrosoftIcon, GsuiteIcon } from "../OldLogin/Login";
import { getIpDetails } from "src/services/login";
import Title from "antd/lib/typography/Title";
import { countryNames } from "../../constants/countries"
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const { Content: LayoutContent, Footer: LayoutFooter } = Layout
const { Text } = Typography;
const { Option } = Select;

const SignUp = () => {
    const [t] = useTranslate()
    const dispatch = useDispatch();
    const [ip, setIp] = useState<any>(undefined);
    const [validatedPassword, setValidatedPassword] = useState<any>({
        password: "",
        error: false,
        passwordLength: false,
        passWordwithNoSpaces: false,
        containsUppercaseCharacters: false,
        containsLowercaseCharacters: false,
        containsOneNumber: false,
        containsOneSymbol: false,
        containsNoWhiteSpaces: false
    })
    const [recaptcha, setRecaptcha] = useState("")

    const submitForm = (values: any) => {
        let { first_name, last_name, pwd, user_email, user_location, user_loggedin_IP, company, user_name } = values
        pwd = Sha1(pwd.trim())
        user_loggedin_IP = ip;
        user_name = `${first_name} ${last_name}`
        dispatch(signupRequest({ user_name, company, user_location, user_email, pwd, user_loggedin_IP }));
    }

    const signInViaGsuite = () => {
        signInViaFirebase(Services.gsuite)
    }
    const signInViaMicrosoft = async () => {
        signInViaFirebase(Services.microsoft)
    }
    const apiStatus = useSelector<StoreState, AsyncStates>
        (
            (state) => state.signup.status
        );
    const firebaseStatus = useSelector<StoreState, AsyncStates>(
        (state) => state.loginFirebase.status
    );
    const errorMsg = useSelector<StoreState, string>(
        (state) => state.signup.error
    );

    useEffect(() => {
        dispatch(signupClearError())
        const parseIp = async () => {
            const {
                data: { ip },
            }: any = await getIpDetails();
            setIp(ip);
        };
        parseIp();
    }, [dispatch]);

    useEffect(() => {
        dispatch(loginFirebaseRequest())
    }, [dispatch])

    useEffect(() => {
        if (AsyncStates.LOADING === "LOADING") {
            dispatch(signupFailure(""))
        }
    }, [dispatch])

    const checkPasswordStrength = (password: any) => {
        const passwordStrength = {
            password: password,
            error: false,
            passwordLength: false,
            containsUppercaseCharacters: false,
            containsLowercaseCharacters: false,
            containsOneNumber: false,
            containsOneSymbol: false,
            containsNoWhiteSpaces: false
        }
        if (password.match(/[a-z]/)) {
            passwordStrength.containsLowercaseCharacters = true
        }
        if (password.match(/[A-Z]/)) {
            passwordStrength.containsUppercaseCharacters = true
        }
        if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            passwordStrength.containsOneSymbol = true
        }
        if (password.length >= 8) {
            passwordStrength.passwordLength = true
        }
        if (password.match(/([0-9])/)) {
            passwordStrength.containsOneNumber = true
        }
        passwordStrength.containsNoWhiteSpaces = !(/^(?=.*\s)/).test(password)

        passwordStrength.error = passwordStrength.containsOneNumber && passwordStrength.passwordLength && passwordStrength.containsOneSymbol && passwordStrength.containsUppercaseCharacters && passwordStrength.containsLowercaseCharacters && passwordStrength.containsNoWhiteSpaces

        setValidatedPassword(passwordStrength)
    }
    const [form] = Form.useForm()

    return (
        <Layout style={{ textAlign: 'center', minHeight: '100%' }}>
            <LayoutContent style={{ overflow: 'auto', marginTop: 80 }}>
                <Space style={{ width: "100%" }} direction="vertical" size="large">
                    <Space direction="vertical">
                        <Header />
                    </Space>
                    <Row align="middle" justify="center" style={{ flex: 1 }} className="login-card-container">
                        <Card style={{ marginTop: 45, padding: 0 }}>
                            <Space direction="vertical" style={{ margin: 48 }}>
                                <Title level={3} style={{ marginBottom: "40px" }}>Sign up for an account</Title>

                                <Form name="normal_login" className="login-form" layout='vertical' onFinish={submitForm} form={form}>
                                    <Form.Item>
                                        <Button size="large" className="round-text social-btn" onClick={signInViaGsuite} style={{ whiteSpace: 'normal', display: 'block', height: 'max-content', textAlign: 'center', width: '100%', padding: "8px, 16px, 8px, 16px" }}>
                                            <Space><Icon component={GsuiteIcon} style={{ fontSize: 24 }} />&nbsp;  Log in with Google</Space>
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button size="large" className="round-text social-btn" onClick={signInViaMicrosoft} style={{ whiteSpace: 'normal', height: 'max-content', textAlign: 'center', width: '100%', padding: "8px, 16px, 8px, 16px", overflow: 'hidden' }}>
                                            <Space><Icon component={MicrosoftIcon} style={{ fontSize: 24 }} />&nbsp; Log in with Microsoft</Space>
                                        </Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Divider plain><Text type="secondary">or sign up with email</Text></Divider>
                                    </Form.Item>

                                    {!!errorMsg && <Alert message={errorMsg} type="error" style={{ backgroundColor: '#CF1322', color: "white", textAlign: "left", display: "block", marginBottom: "1rem" }} className="error-alert-msg" />}

                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Form.Item label={"First Name"} name="first_name" validateTrigger="onChange"
                                                rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>Please input your First Name</Text> }, { pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/), message: <>First Name cannot start and end with spaces.</> }]}>
                                                <Input size='large' placeholder={"John"} className="round-text" minLength={2} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={"Last Name"} name="last_name" validateTrigger="onChange"
                                                rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>Please input your Last Name</Text> }, { pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/), message: <Text style={{ float: "left", color: "red" }}>Last Name cannot start and end with spaces.</Text> }]}>
                                                <Input size='large' placeholder={"Doe"} className="round-text" minLength={2} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Form.Item label={t("common.companyName")} name='company' validateTrigger="onChange"
                                        rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>{t("signup.warn.companyName")}</Text> }, { pattern: new RegExp(/^(?!.*[-_]{2})(?=.*[a-zA-Z0-9.]$)[a-zA-Z0-9.][a-zA-Z0-9. _-]*$/), message: <Text style={{ float: "left", color: "red" }}>Sorry, only letters(a-z),numbers(0-9) and (._) is allowed</Text> }]}>
                                        <Input size='large' placeholder={"Your company"} className="round-text" minLength={2} />
                                    </Form.Item>

                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label={t("signup.location")} name='user_location' rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>{t("signup.warn.location")}</Text> }]} validateTrigger="onChange">
                                                <Select showSearch placeholder="Select location" style={{ width: "100%", textAlign: "left" }} className="round-text">
                                                    {countryNames.map((countryName) => <Option key={countryName} value={countryName}>{countryName}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item label={t("common.email")} name='user_email' validateTrigger="onChange"
                                        rules={[{ required: true, pattern: new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/), message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>{"Email address is required. Please enter a valid email address."}</Text> }]}>
                                        <Input size='large' placeholder={"name@company.com"} autoComplete="on" className="round-text" minLength={4} />
                                    </Form.Item>

                                    <Form.Item label={t("login.password")} name='pwd'>
                                        <Input.Password size='large' placeholder={t("login.password")} className="round-text" type="password" minLength={8} onChange={(e: any) => checkPasswordStrength(e.target.value)} />
                                    </Form.Item>

                                    <div className="password-validator-msg-container">
                                        <ul className={!!validatedPassword.password ? "listStyleDisabled" : ""}>
                                            <li>
                                                {validatedPassword.passwordLength ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> Mininum 8 characters</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }} ><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> Mininum 8 characters</Text>}
                                            </li>
                                            <li>
                                                {validatedPassword.containsNoWhiteSpaces ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> Cannot contain spaces</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }}><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> Cannot contain spaces</Text>}
                                            </li>

                                            <li>
                                                {validatedPassword.containsUppercaseCharacters ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one uppercase letter</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }}><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one uppercase letter</Text>}
                                            </li>
                                            <li>
                                                {validatedPassword.containsLowercaseCharacters ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one lowercase letter</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }}><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one lowercase letter</Text>}
                                            </li>
                                            <li>
                                                {validatedPassword.containsOneNumber ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one number</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }}><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one number</Text>}
                                            </li>
                                            <li>
                                                {validatedPassword.containsOneSymbol ? <Text type="secondary" style={{ color: "green" }}><CheckOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one symbol</Text> : <Text type="secondary" style={{ color: !!validatedPassword.password ? "red" : "" }}><CloseOutlined className={!validatedPassword.password ? "hide-icons" : ""} /> At least one symbol</Text>}
                                            </li>
                                        </ul>
                                    </div>

                                    <Form.Item rules={[{ required: true }]} style={{ marginTop: "20px" }}>
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_CAPTCHA ?? ""}
                                            onChange={(value: any) => setRecaptcha(value)}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{ height: 15, marginTop: "10px" }} shouldUpdate>
                                        {() => (
                                            <Button className="primary-btn" style={{ background: '#FF9300', width: "100%" }} size="large" type="primary" htmlType="submit" disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }: any) => errors.length).length || !validatedPassword.error || !recaptcha}>
                                                {t("login.signUp")}</Button>
                                        )}

                                    </Form.Item>
                                </Form>

                                <Row style={{ marginTop: "8px" }}>
                                    <Text type="secondary" style={{ textAlign: "left" }}>
                                        By signing up, you agree to Polymerize's{" "}
                                        <span>
                                            <a href="https://polymerize.io/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                                            {" "}
                                        </span>
                                        <span>
                                            and
                                        </span>
                                        <span>
                                            {" "}<a href="https://polymerize.io/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Service.</a>
                                        </span>
                                    </Text>
                                </Row>

                                <Row>
                                    <Text strong type="secondary" className="linktoLogin">Have an account? <Link to="/login">Log in</Link></Text>
                                </Row>

                            </Space>
                        </Card>
                    </Row>
                </Space >
            </LayoutContent >
            <LayoutFooter>
                <Footer />
            </LayoutFooter>
            {(apiStatus === AsyncStates.LOADING || firebaseStatus === AsyncStates.LOADING) && <Spinner />}
        </Layout >
    );
};

export default SignUp;
