import { createActions } from "redux-actions";

export enum GetModelInfoActionTypes {
  REQUEST = "GET_MODEL_INFO_REQUEST",
  SUCCESS = "GET_MODEL_INFO_SUCCESS",
  FAILURE = "GET_MODEL_INFO_FAILURE",
  CLEAR = "GET_MODEL_INFO_CLEAR"
}

export enum SetModelDataActionTypes {
  SET_MODEL_DATA = "SET_MODEL_DATA",
  SET_ANALYSIS_TAB = "SET_ANALYSIS_TAB",
  SET_MODEL_VERSION = "SET_MODEL_VERSION",
  SET_MODEL_COMMENTS_NOTIFICATION_CLICK = "SET_MODEL_COMMENTS_NOTIFICATION_CLICK"
}

export enum CreateModelCommentActionTypes {
  REQUEST = "CREATE_MODEL_COMMENT_REQUEST",
  SUCCESS = "CREATE_MODEL_COMMENT_SUCCESS",
  FAILURE = "CREATE_MODEL_COMMENT_FAILURE",
}

export enum DeleteModelCommentActionTypes {
  REQUEST = "DELETE_MODEL_COMMENT_REQUEST",
  SUCCESS = "DELETE_MODEL_COMMENT_SUCCESS",
  FAILURE = "DELETE_MODEL_COMMENT_FAILURE",
}

export enum EditModelCommentActionTypes {
  REQUEST = "EDIT_MODEL_COMMENT_REQUEST",
  SUCCESS = "EDIT_MODEL_COMMENT_SUCCESS",
  FAILURE = "EDIT_MODEL_COMMENT_FAILURE",
}

export enum ModelCommentListActionTypes {
  REQUEST = "MODEL_COMMENT_LIST_REQUEST",
  SUCCESS = "MODEL_COMMENT_LIST_SUCCESS",
  FAILURE = "MODEL_COMMENT_LIST_FAILURE",
  CLEAR = "MODEL_COMMENT_LIST_CLEAR"
}

export enum RequestInverseActionTypes {
  REQUEST = "REQUEST_INVERSE_REQUEST",
  SUCCESS = "REQUEST_INVERSE_SUCCESS",
  FAILURE = "REQUEST_INVERSE_FAILURE",
}

export enum ModelInfoChangeActionTypes {
  REQUEST = "MODEL_INFO_CHANGE_REQUEST",
  SUCCESS = "MODEL_INFO_CHANGE_SUCCESS",
  FAILURE = "MODEL_INFO_CHANGE_FAILURE",
}


export enum GetCustomModelDataActionTypes {
  REQUEST = "GET_CUSTOM_MODEL_DATA_REQUEST",
  SUCCESS = "GET_CUSTOM_MODEL_DATA_SUCCESS",
  FAILURE = "GET_CUSTOM_MODEL_DATA_FAILURE",
  CLEAR = "GET_CUSTOM_MODEL_DATA_CLEAR"
}

export enum SetCustomModal {
  SetModal = "SET_CUSTOM_MODEL_REQUEST",
}

export const {
  getModelInfoRequest,
  getModelInfoSuccess,
  getModelInfoFailure,
  getModelInfoClear,
  setModelData,
  setAnalysisTab,
  setModelVersion,
  setModelCommentsNotificationClick,
  createModelCommentRequest,
  createModelCommentSuccess,
  createModelCommentFailure,
  deleteModelCommentRequest,
  deleteModelCommentSuccess,
  deleteModelCommentFailure,
  editModelCommentRequest,
  editModelCommentSuccess,
  editModelCommentFailure,
  modelCommentListRequest,
  modelCommentListSuccess,
  modelCommentListFailure,
  modelCommentListClear,
  requestInverseRequest,
  requestInverseSuccess,
  requestInverseFailure,
  modelInfoChangeRequest,
  modelInfoChangeSuccess,
  modelInfoChangeFailure,
  getCustomModelDataRequest,
  getCustomModelDataSuccess,
  getCustomModelDataFailure,
  getCustomModelDataClear,
  setCustomModelRequest,

} = createActions({
  [GetModelInfoActionTypes.REQUEST]: payload => payload,
  [GetModelInfoActionTypes.SUCCESS]: response => response,
  [GetModelInfoActionTypes.FAILURE]: () => { },
  [GetModelInfoActionTypes.CLEAR]: () => { },
  [SetModelDataActionTypes.SET_MODEL_DATA]: payload => payload,
  [SetModelDataActionTypes.SET_ANALYSIS_TAB]: payload => payload,
  [SetModelDataActionTypes.SET_MODEL_VERSION]: payload => payload,
  [SetModelDataActionTypes.SET_MODEL_COMMENTS_NOTIFICATION_CLICK]: payload => payload,
  [CreateModelCommentActionTypes.REQUEST]: payload => payload,
  [CreateModelCommentActionTypes.SUCCESS]: response => response,
  [CreateModelCommentActionTypes.FAILURE]: () => { },
  [DeleteModelCommentActionTypes.REQUEST]: payload => payload,
  [DeleteModelCommentActionTypes.SUCCESS]: response => response,
  [DeleteModelCommentActionTypes.FAILURE]: () => { },
  [EditModelCommentActionTypes.REQUEST]: payload => payload,
  [EditModelCommentActionTypes.SUCCESS]: response => response,
  [EditModelCommentActionTypes.FAILURE]: () => { },
  [ModelCommentListActionTypes.REQUEST]: payload => payload,
  [ModelCommentListActionTypes.SUCCESS]: response => response,
  [ModelCommentListActionTypes.FAILURE]: () => { },
  [ModelCommentListActionTypes.CLEAR]: () => { },
  [RequestInverseActionTypes.REQUEST]: payload => payload,
  [RequestInverseActionTypes.SUCCESS]: response => response,
  [RequestInverseActionTypes.FAILURE]: () => { },
  [ModelInfoChangeActionTypes.REQUEST]: payload => payload,
  [ModelInfoChangeActionTypes.SUCCESS]: response => response,
  [ModelInfoChangeActionTypes.FAILURE]: () => { },
  [GetCustomModelDataActionTypes.REQUEST]: payload => payload,
  [GetCustomModelDataActionTypes.SUCCESS]: response => response,
  [GetCustomModelDataActionTypes.FAILURE]: (payload) => payload,
  [GetCustomModelDataActionTypes.CLEAR]: () => { },
  [SetCustomModal.SetModal]: (payload) => payload
}
);
