import { Button } from "antd"
import { ButtonProps } from "antd/lib/button"
import { useState } from "react"

type ExtraProps = {
	hoverColor?: string
}

export const StyledButton = (props: ButtonProps & ExtraProps) => {
	const { style, hoverColor, ...rest } = props
	const [hover, setHover] = useState(false)

	const hoverStyle =
		hoverColor && hover ? { borderColor: hoverColor, color: hoverColor } : {}

	return (
		<Button
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			style={{
				borderRadius: 8,
				...hoverStyle,
				...style,
			}}
			{...rest}
		/>
	)
}
