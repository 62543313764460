import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import {
  FetchMethodListActionTypes,
  CreateMethodActionTypes,
  SaveCharacterizationSetsActionTypes, DeleteCharacterizationSetsActionTypes, DeleteMethodActionTypes, CheckVariationUsedActionTypes, UpdateCharacterizationMethodActionTypes
} from "../actions/characterizationMethods";

export type CharacterizationMethodsReducerState = {
  methodListStatus: AsyncStates;
  methodList: any[];
  processingMethodList: any[]
  methodListError: string;
  createMethodStatus: AsyncStates,
  saveCharacterizationsStatus: AsyncStates,
  deleteCharacterizationsStatus: AsyncStates,
  deleteCharacterizationMethodsStatus: AsyncStates,
  checkVariationUsedStatus: AsyncStates,
  isVariationUsed: boolean | null,
  checkVariationUsedError: string,
  updateMethodStatus: AsyncStates,
  updateMethodError: string
};

const defaultState: CharacterizationMethodsReducerState = {
  methodListStatus: AsyncStates.INITIAL,
  methodList: [],
  processingMethodList: [],
  methodListError: "",
  createMethodStatus: AsyncStates.INITIAL,
  saveCharacterizationsStatus: AsyncStates.INITIAL,
  deleteCharacterizationsStatus: AsyncStates.INITIAL,
  deleteCharacterizationMethodsStatus: AsyncStates.INITIAL,
  checkVariationUsedStatus: AsyncStates.INITIAL,
  isVariationUsed: null,
  checkVariationUsedError: "",
  updateMethodStatus: AsyncStates.INITIAL,
  updateMethodError: ""
};

const characterizationMethodsReducer = handleActions<CharacterizationMethodsReducerState, any>(
  {
    [FetchMethodListActionTypes.REQUEST]: (state) => ({
      ...state,
      methodListStatus: AsyncStates.LOADING,
      methodListError: "",
    }),
    [FetchMethodListActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      methodListStatus: AsyncStates.SUCCESS,
      methodListError: "",
      ...(action?.payload?.methodType === "processing" && { processingMethodList: action.payload.data }),
      ...(action?.payload?.methodType === "characterizations" && { methodList: action.payload.data }),
    }),
    [FetchMethodListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      methodListStatus: AsyncStates.ERROR,
      methodListError: action.payload,
    }),
    [CreateMethodActionTypes.REQUEST]: (state) => ({
      ...state,
      createMethodStatus: AsyncStates.LOADING
    }),
    [CreateMethodActionTypes.SUCCESS]: (state) => ({
      ...state,
      createMethodStatus: AsyncStates.SUCCESS
    }),
    [CreateMethodActionTypes.FAILURE]: (state) => ({
      ...state,
      createMethodStatus: AsyncStates.ERROR
    }),
    [CreateMethodActionTypes.RESET]: (state) => ({
      ...state,
      createMethodStatus: AsyncStates.INITIAL
    }),
    [SaveCharacterizationSetsActionTypes.SUCCESS]: (state) => ({
      ...state,
      saveCharacterizationsStatus: AsyncStates.SUCCESS
    }),
    [SaveCharacterizationSetsActionTypes.FAILURE]: (state) => ({
      ...state,
      saveCharacterizationsStatus: AsyncStates.ERROR
    }),
    [SaveCharacterizationSetsActionTypes.REQUEST]: (state) => ({
      ...state,
      saveCharacterizationsStatus: AsyncStates.LOADING
    }),
    [DeleteCharacterizationSetsActionTypes.SUCCESS]: (state) => ({
      ...state,
      deleteCharacterizationsStatus: AsyncStates.SUCCESS
    }),
    [DeleteCharacterizationSetsActionTypes.FAILURE]: (state) => ({
      ...state,
      deleteCharacterizationsStatus: AsyncStates.ERROR
    }),
    [DeleteCharacterizationSetsActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteCharacterizationsStatus: AsyncStates.LOADING
    }),
    [DeleteMethodActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteCharacterizationMethodsStatus: AsyncStates.INITIAL
    }),
    [DeleteMethodActionTypes.SUCCESS]: (state) => ({
      ...state,
      deleteCharacterizationMethodsStatus: AsyncStates.SUCCESS
    }),
    [DeleteMethodActionTypes.FAILURE]: (state) => ({
      ...state,
      deleteCharacterizationMethodsStatus: AsyncStates.ERROR
    }),
    [CheckVariationUsedActionTypes.REQUEST]: (state) => ({
      ...state,
      checkVariationUsedStatus: AsyncStates.LOADING,
      checkVariationUsedError: "",
    }),
    [CheckVariationUsedActionTypes.SUCCESS]: (state, action) => {

      return {
        ...state,
        checkVariationUsedStatus: AsyncStates.SUCCESS,
        checkVariationUsedError: "",
        isVariationUsed: action.payload,
      }
    },
    [CheckVariationUsedActionTypes.FAILURE]: (state, action) => ({
      ...state,
      checkVariationUsedStatus: AsyncStates.ERROR,
      checkVariationUsedError: action.payload,
    }),
    [CheckVariationUsedActionTypes.CLEAR]: (state, action) => ({
      ...state,
      checkVariationUsedStatus: AsyncStates.INITIAL,
      checkVariationUsedError: "",
      isVariationUsed: null
    }),
    [UpdateCharacterizationMethodActionTypes.REQUEST]: (state) => ({
      ...state,
      updateMethodStatus: AsyncStates.LOADING
    }),
    [UpdateCharacterizationMethodActionTypes.SUCCESS]: (state) => ({
      ...state,
      updateMethodStatus: AsyncStates.SUCCESS
    }),
    [UpdateCharacterizationMethodActionTypes.FAILURE]: (state, action) => ({
      ...state,
      updateMethodStatus: AsyncStates.ERROR,
      updateMethodError: action.payload.error
    }),
    [UpdateCharacterizationMethodActionTypes.CLEAR]: (state) => ({
      ...state,
      updateMethodStatus: AsyncStates.INITIAL,
      updateMethodError: ""
    }),
  },
  defaultState
);

export default characterizationMethodsReducer;
