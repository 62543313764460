import { handleActions } from "redux-actions"

import {
	FormulateActionTypes,
	ModelsConfigActionTypes,
	ForwardPredictionHistoryActionTypes,
	GetForwardConfigsActionTypes,
	GetForwardConfigsZeonActionTypes,
	GetCharacterizationMethodsZeonActionTypes,
	AddFavoritesActionTypes,
	GetFavoritesActionTypes,
	DeleteFavoritesActionTypes,
	FavouriteModelActionTypes,
	ModelsConfigListActionTypes
} from "src/store/actions/formulate"
import { AsyncStates } from "src/constants"
import { FormulationDatum } from "src/services/formulation/interface"

export type FormulateState = {
	configStatus: AsyncStates
	configError: string
	configData: FormulationDatum
	formulateStatus: AsyncStates
	metricData: any
	error: string
	forwardPredListStatus: AsyncStates
	forwardPredResultStatus: AsyncStates
	forwardPredDeleteStatus: AsyncStates
	forwardPredList: any
	forwardPredResult: {
		hidden_ings: string[],
		hidden_procs: string[],
		predictions: any[]
	}
	mlProjectSpecificData: Partial<{
		"ingredients": any
		"processing": any
		"properties": any
		"items_per_property": any
		"ingredient_names": any
	}>
	mlProjectSpecificDataZeon: Partial<{
		"ingredients": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"processing": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"properties": {
			[key: string]: {
				min: number
				max: number
			}
		}
		"items_per_property": {
			[key: string]: {
				ingredients: string[]
				processing: string[]
			}
		}
		"ingredient_names": {
			[key: string]: string
		}
	}>
	mlProjectSpecificDataStatus: AsyncStates
	mlProjectSpecificDataZeonStatus: AsyncStates
	mlProjectSpecificDataError: string
	mlProjectSpecificDataZeonError: string
	characterizationMethodsZeon: any
	characterizationMethodsZeonStatus: AsyncStates
	characterizationMethodsZeonError: string,
	addFavoriteStatus: AsyncStates
	addFavoriteError: string
	favoritesData: {
		favoritesList: any[],
		favoritesDisplayNames: any
		total_count: number,
	}
	getFavoritesStatus: AsyncStates,
	getFavoritesError: string,
	deleteFavoritesStatus: AsyncStates,
	deleteFavoritesError: string,
	favouriteModelStatus: AsyncStates
	modelConfigData: any[]
}

const defaultState: FormulateState = {
	configStatus: AsyncStates.INITIAL,
	configError: "",
	configData: [],
	formulateStatus: AsyncStates.INITIAL,
	metricData: {},
	error: "",
	forwardPredListStatus: AsyncStates.INITIAL,
	forwardPredResultStatus: AsyncStates.INITIAL,
	forwardPredDeleteStatus: AsyncStates.INITIAL,
	forwardPredList: { forwardData: [], forwardTotal: "" },
	forwardPredResult: {
		hidden_ings: [],
		hidden_procs: [],
		predictions: []
	},
	mlProjectSpecificData: {},
	mlProjectSpecificDataStatus: AsyncStates.INITIAL,
	mlProjectSpecificDataError: "",
	mlProjectSpecificDataZeon: {},
	mlProjectSpecificDataZeonStatus: AsyncStates.INITIAL,
	mlProjectSpecificDataZeonError: "",
	characterizationMethodsZeon: null,
	characterizationMethodsZeonStatus: AsyncStates.INITIAL,
	characterizationMethodsZeonError: "",
	addFavoriteStatus: AsyncStates.INITIAL,
	addFavoriteError: "",
	favoritesData: {
		favoritesList: [],
		favoritesDisplayNames: {},
		total_count: 0,
	},
	getFavoritesStatus: AsyncStates.INITIAL,
	getFavoritesError: "",
	deleteFavoritesStatus: AsyncStates.INITIAL,
	deleteFavoritesError: "",
	favouriteModelStatus: AsyncStates.INITIAL,
	modelConfigData: []
}

const formulateReducer = handleActions(
	{
		[ModelsConfigListActionTypes.REQUEST]: (state) => ({
			...state,
			configStatus: AsyncStates.LOADING,
			configError: "",
		}),
		[ModelsConfigListActionTypes.SUCCESS]: (state, action) => {
			return {
				...state,
				configStatus: AsyncStates.SUCCESS,
				configError: "",
				configData: action.payload.configData,
			}
		},
		[ModelsConfigListActionTypes.FAILURE]: (state, action) => ({
			...state,
			configStatus: AsyncStates.ERROR,
			configError: action.payload.error,
		}),
		[ModelsConfigActionTypes.REQUEST]: (state) => ({
			...state,
			configStatus: AsyncStates.LOADING,
			configError: "",
		}),
		[ModelsConfigActionTypes.SUCCESS]: (state, action: any) => {
			const newConfigData = state.configData.map((modelConfig: any) => {
				if (modelConfig.version === action.payload.configData[0].version) {
					return action.payload.configData[0]
				}
				return modelConfig
			})
			return {
				...state,
				configStatus: AsyncStates.SUCCESS,
				configError: "",
				configData: newConfigData,
				modelConfigData: action.payload.configData
			}
		},
		[ModelsConfigActionTypes.FAILURE]: (state, action) => ({
			...state,
			configStatus: AsyncStates.ERROR,
			configError: action.payload.error,
		}),
		[FormulateActionTypes.REQUEST]: (state, action: any) => {
			return {
				...state,
				formulateStatus: AsyncStates.LOADING,
				error: "",
			}
		},
		[FormulateActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				formulateStatus: AsyncStates.SUCCESS,
				error: "",
			}
		},
		[FormulateActionTypes.FAILURE]: (state, action) => ({
			...state,
			formulateStatus: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[FormulateActionTypes.CLEANUP]: (state) => ({
			...state,
			configStatus: AsyncStates.INITIAL,
			configError: "",
			configData: [],
			formulateStatus: AsyncStates.INITIAL,
			error: "",
			forwardPredListStatus: AsyncStates.INITIAL,
			forwardPredResultStatus: AsyncStates.INITIAL,
			forwardPredDeleteStatus: AsyncStates.INITIAL,
			forwardPredList: { forwardData: [], forwardTotal: "" },
			forwardPredResult: {
				hidden_ings: [],
				hidden_procs: [],
				predictions: []
			},
		}),
		[FormulateActionTypes.SET_METRIC]: (state, action) => ({
			...state,
			metricData: action.payload,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredListStatus: AsyncStates.LOADING,
			forwardPredList: { forwardData: [], forwardTotal: "" },
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				forwardPredListStatus: AsyncStates.SUCCESS,
				forwardPredList: action.payload,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_FAILURE]: (
			state,
			action
		) => ({
			...state,
			forwardPredListStatus: AsyncStates.ERROR,
			forwardPredList: { forwardData: [], forwardTotal: "" },
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredResultStatus: AsyncStates.LOADING,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				forwardPredResultStatus: AsyncStates.SUCCESS,
				forwardPredResult: action.payload,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_FAILURE]: (
			state
		) => ({
			...state,
			forwardPredResultStatus: AsyncStates.ERROR,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_REQUEST]: (
			state
		) => ({
			...state,
			forwardPredDeleteStatus: AsyncStates.LOADING,
		}),
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_SUCCESS]: (
			state
		) => {
			return {
				...state,
				forwardPredDeleteStatus: AsyncStates.SUCCESS,
			}
		},
		[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_FAILURE]: (
			state
		) => ({
			...state,
			forwardPredDeleteStatus: AsyncStates.ERROR,
		}),
		[GetForwardConfigsActionTypes.REQUEST]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataStatus: AsyncStates.LOADING,
				mlProjectSpecificDataError: "",
			}
		},
		[GetForwardConfigsActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataStatus: AsyncStates.SUCCESS,
				mlProjectSpecificDataError: "",
				mlProjectSpecificData: action.payload,
			}
		},
		[GetForwardConfigsActionTypes.FAILURE]: (state, action) => ({
			...state,
			mlProjectSpecificDataStatus: AsyncStates.ERROR,
			mlProjectSpecificDataError: action.payload.error,
		}),
		[GetForwardConfigsZeonActionTypes.REQUEST]: (state) => {
			return {
				...state,
				mlProjectSpecificDataZeonStatus: AsyncStates.LOADING,
				mlProjectSpecificDataZeonError: "",
			}
		},
		[GetForwardConfigsZeonActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				mlProjectSpecificDataZeonStatus: AsyncStates.SUCCESS,
				mlProjectSpecificDataZeonError: "",
				mlProjectSpecificDataZeon: action.payload,
			}
		},
		[GetForwardConfigsZeonActionTypes.FAILURE]: (state, action) => ({
			...state,
			mlProjectSpecificDataZeonStatus: AsyncStates.ERROR,
			mlProjectSpecificDataZeonError: action.payload.error,
		}),
		[GetCharacterizationMethodsZeonActionTypes.REQUEST]: (state) => {
			return {
				...state,
				characterizationMethodsZeonStatus: AsyncStates.LOADING,
				characterizationMethodsZeonError: "",
			}
		},
		[GetCharacterizationMethodsZeonActionTypes.SUCCESS]: (
			state: any,
			action: any
		) => {
			const apiResponse = action.payload.response
			const firstKey = Object.keys(apiResponse || {})[0]

			if (!firstKey)
				return {
					...state,
					characterizationMethodsZeonStatus: AsyncStates.SUCCESS,
					characterizationMethodsZeonError: "",
					characterizationMethodsZeon: {
						...state.characterizationMethodsZeon,
						[action.payload.from]: {
							type: "variations",
							data: {
								characterization_id: "",
								characterization_name: "",
								variations: [],
							},
						},
					}
				}

			const data: FormulateState["characterizationMethodsZeon"] =
				firstKey && apiResponse?.[firstKey].variations
					? {
						type: "variations",
						data: {
							characterization_id: firstKey,
							characterization_name: apiResponse?.[firstKey].name,
							variations: apiResponse?.[firstKey].variations,
						},
					}
					: {
						type: "methods",
						data: Object.keys(apiResponse || {}).map((chracId) => {
							return {
								characterization_id: chracId,
								characterization_name: apiResponse?.[chracId].name as string,
							}
						}),
					}

			return {
				...state,
				characterizationMethodsZeonStatus: AsyncStates.SUCCESS,
				characterizationMethodsZeonError: "",
				characterizationMethodsZeon: {
					...state.characterizationMethodsZeon,
					[action.payload.from]: data
				},
			}
		},
		[GetCharacterizationMethodsZeonActionTypes.FAILURE]: (state, action) => ({
			...state,
			characterizationMethodsZeonStatus: AsyncStates.ERROR,
			characterizationMethodsZeonError: action.payload.error,
		}),
		[GetCharacterizationMethodsZeonActionTypes.CLEAN]: (state, action) => ({
			...state,
			characterizationMethodsZeonStatus: AsyncStates.INITIAL,
			characterizationMethodsZeonError: "",
			characterizationMethodsZeon: null,
		}),
		[AddFavoritesActionTypes.REQUEST]: (state) => {
			return {
				...state,
				addFavoriteStatus: AsyncStates.LOADING,
				addFavoriteError: "",
			}
		},
		[AddFavoritesActionTypes.SUCCESS]: (state, action) => ({
			...state,
			addFavoriteStatus: AsyncStates.SUCCESS,
			addFavoriteError: "",
		}),
		[AddFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			addFavoriteStatus: AsyncStates.ERROR,
			addFavoriteError: action.payload.error,
		}),
		[GetFavoritesActionTypes.REQUEST]: (state) => ({
			...state,
			getFavoritesStatus: AsyncStates.LOADING,
			getFavoritesError: "",
		}),
		[GetFavoritesActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				getFavoritesStatus: AsyncStates.SUCCESS,
				getFavoritesError: "",
				favoritesData: action.payload,
			}
		},
		[GetFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			getFavoritesStatus: AsyncStates.ERROR,
			getFavoritesError: action.payload.error,
		}),
		[DeleteFavoritesActionTypes.REQUEST]: (state) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.LOADING,
			deleteFavoritesError: "",
		}),
		[DeleteFavoritesActionTypes.SUCCESS]: (state, action) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.SUCCESS,
			deleteFavoritesError: "",
		}),
		[DeleteFavoritesActionTypes.FAILURE]: (state, action) => ({
			...state,
			deleteFavoritesStatus: AsyncStates.ERROR,
			deleteFavoritesError: action.payload.error,
		}),
		[FavouriteModelActionTypes.REQUEST]: (state) => ({
			...state,
			favouriteModelStatus: AsyncStates.LOADING,
		}),
		[FavouriteModelActionTypes.SUCCESS]: (state, action) => {
			const property = action?.payload?.property
			const fav = action.payload?.fav
			const modelName = action?.payload?.model_name
			const updatedResults = [...state.forwardPredResult?.predictions]
			updatedResults.forEach((res: any) => {
				if (!!Object.keys(res?.property_data || {}).length) {
					Object.entries(res.property_data[property] || {}).forEach(([key, value]: any) => {
						if (key === modelName && fav) {
							res.property_data[property][key] = { ...value, favourite: true }
						} else {
							res.property_data[property][key] = { ...value, favourite: false }
						}
					})
				}
			})
			return ({
				...state,
				forwardPredResult: {
					...state.forwardPredResult,
					predictions: updatedResults
				},
				favouriteModelStatus: AsyncStates.SUCCESS,
			})
		},
		[FavouriteModelActionTypes.FAILURE]: (state, action) => ({
			...state,
			favouriteModelStatus: AsyncStates.ERROR,
		}),
		[FavouriteModelActionTypes.CLEANUP]: (state, action) => ({
			...state,
			favouriteModelStatus: AsyncStates.INITIAL,
		}),
	},
	defaultState
)

export default formulateReducer
