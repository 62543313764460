import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import React, { forwardRef } from "react";

export const StyledCard = forwardRef<HTMLDivElement, CardProps>(
  (props, ref) => {
    const { style, ...rest } = props;
    return (
      <Card
        bodyStyle={{ padding: "0.5rem 1rem" }}
        headStyle={{ padding: "0rem 1rem" }}
        ref={ref}
        style={{
          borderRadius: 5,
          background: "none",
          // border: "1px solid white",
          boxShadow: "24px 24px 24px 24px #617c960a",
          ...style,
        }}
        {...rest}
      />
    );
  }
);
