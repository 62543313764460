import { handleActions } from "redux-actions";
import { SignupActionTypes } from "../actions/signup";
import { AsyncStates } from "../../constants";
import { SignupResponse, CheckUserExists } from "../../services/login/interface";

export interface FirebaseResponse extends CheckUserExists {
  displayName: string;
  isUserExists?: boolean;
}

export enum SignupProgress {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export type SignupReducerState = {
  status: AsyncStates;
  error: string;
  signupResponse: SignupResponse;
  firebaseStatus: AsyncStates;
  firebaseError: string;
  firebaseResponse: FirebaseResponse;
  isSignupComplete: SignupProgress;
  firebaseCompleteStatus: AsyncStates;
  firebaseCompleteError: string;
};

const defaultState: SignupReducerState = {
  isSignupComplete: SignupProgress.NOT_STARTED,
  status: AsyncStates.INITIAL,
  error: "",
  signupResponse: {
    company: "",
    company_location: "",
    message: "",
    status: "",
    token: "",
    user_access: "",
    user_email: "",
    user_id: "",
    user_location: "",
    user_loggedin_IP: "",
    user_name: "",
    user_role: "",
    user_trial_days: 0,
    user_verified: false,
    job_title: ""
  },
  firebaseStatus: AsyncStates.INITIAL,
  firebaseError: "",
  firebaseResponse: {
    user_email: "",
    uid: "",
    firebase_token: "",
    firebase_accessToken: "",
    displayName: "",
    isUserExists: false,
    user_name: ""
  },
  firebaseCompleteStatus: AsyncStates.INITIAL,
  firebaseCompleteError: "",
};

const signupReducer = handleActions(
  {
    [SignupActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [SignupActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        isSignupComplete: SignupProgress.COMPLETE,
        status: AsyncStates.SUCCESS,
        error: "",
        signupResponse: action.payload.signupResponse,
      };
    },
    [SignupActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [SignupActionTypes.CLEAR]: (state) => ({
      ...state,
      status: AsyncStates.INITIAL,
      error: "",
      firebaseStatus: AsyncStates.INITIAL,
      firebaseError: "",
      isSignupComplete: SignupProgress.NOT_STARTED,
    })
  },
  defaultState
);

export default signupReducer;
