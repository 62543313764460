import { createActions } from "redux-actions";

export enum ConnectActionTypes {
    RECONNECT = "RECONNECT",
    CONNECTED = "CONNECTED"
}

export const {
    reconnect,
    connected
} = createActions({
    [ConnectActionTypes.RECONNECT]: payload => payload,
    [ConnectActionTypes.CONNECTED]: payload => payload,
});
