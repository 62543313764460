import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  createCelsureWoApi, updateCelsureWorApi, deleteCelsureWoApi, celsureWoListApi, gelCelsureWoTrialsApi,
  celsureFileUploadApi, celsureFileHistoryApi, celsureGetPlotsApi, celsureApproveTrialsApi
} from "src/services/celsure"
import { notification } from "antd"
import {
  celsureWoCreateRequest, celsureWoCreateSuccess, celsureWoCreateFailure,
  celsureWoUpdateFailure, celsureWoUpdateRequest, celsureWoUpdateSuccess,
  celsureWoDeleteFailure, celsureWoDeleteRequest, celsureWoDeleteSuccess,
  celsureWoListFailure, celsureWoListRequest, celsureWoListSuccess,
  celsureWoTrialsFailure, celsureWoTrialsRequest, celsureWoTrialsSuccess, setCelsureWo,
  celsureFileHistoryFailure, celsureFileHistoryRequest, celsureFileHistorySuccess,
  celsureFileUploadFailure, celsureFileUploadRequest, celsureFileUploadSuccess,
  celsureGetPlotsFailure, celsureGetPlotsRequest, celsureGetPlotsSuccess,
  celsureApproveTrialsFailure, celsureApproveTrialsRequest, celsureApproveTrialsSuccess
} from "src/store/actions/celsureWo"
import jwtManager from "src/utils/jwtManager";


function* celsureWoCreateSaga({ payload }: any): Generator<any, any, any> {
  try {
    const project_id = yield select(state => state.projects.current)
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data, message } } } = yield call(createCelsureWoApi, payload, headers);
    if (status === "Success") {
      yield put(celsureWoCreateSuccess())
      notification.success({ message })
      yield put(setCelsureWo(data))
      if (payload.duplicate_work_order) {
        yield put(celsureWoListRequest({ project_id: project_id }))
      }
    } else {
      yield put(celsureWoCreateFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(celsureWoCreateFailure())
  }
}

function* celsureWoUpdateSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data, message } } } = yield call(updateCelsureWorApi, payload, headers);
    if (status === "Success") {
      yield put(celsureWoUpdateSuccess())
      notification.success({ message })
      yield put(setCelsureWo(data))
    } else {
      yield put(celsureWoUpdateFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(celsureWoUpdateFailure())
  }
}

function* celsureWoDeleteSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { current } = yield select(state => state.projects)
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(deleteCelsureWoApi, payload, headers);
    if (status === "Success") {
      yield put(celsureWoDeleteSuccess())
      notification.success({ message })
      yield put(celsureWoListRequest({ project_id: current }))
    } else {
      yield put(celsureWoDeleteFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(celsureWoDeleteFailure())
  }
}

function* celsureWoListSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data } } } = yield call(celsureWoListApi, payload, headers);
    if (status === "Success") {
      yield put(celsureWoListSuccess(data))
    } else {
      yield put(celsureWoListFailure())
    }
  } catch (error) {
    yield put(celsureWoListFailure())
  }
}

function* celsureWoTrialsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data } } } = yield call(gelCelsureWoTrialsApi, payload, headers);
    if (status === "Success") {
      yield put(celsureWoTrialsSuccess(data))
    } else {
      yield put(celsureWoTrialsFailure())
    }
  } catch (error) {
    yield put(celsureWoTrialsFailure())
  }
}

function* celsureFileUploadSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(celsureFileUploadApi, payload, headers);
    if (status === "Success") {
      yield put(celsureFileUploadSuccess())
      notification.success({ message })
      yield put(celsureGetPlotsRequest({ work_order_id: payload.get("work_order_id") }))
      yield put(celsureFileHistoryRequest({ work_order_id: payload.get("work_order_id") }))
    } else {
      yield put(celsureFileUploadFailure())
      notification.success({ message })
    }
  } catch (error) {
    yield put(celsureFileUploadFailure())
  }
}

function* celsurePlotsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data } } } = yield call(celsureGetPlotsApi, payload, headers);
    if (status === "Success") {
      yield put(celsureGetPlotsSuccess(data))
    } else {
      yield put(celsureGetPlotsFailure())
    }
  } catch (error) {
    yield put(celsureGetPlotsFailure())
  }
}

function* celsureFileHistorySaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data } } } = yield call(celsureFileHistoryApi, payload, headers);
    if (status === "Success") {
      yield put(celsureFileHistorySuccess(data))
    } else {
      yield put(celsureFileHistoryFailure())
    }
  } catch (error) {
    yield put(celsureFileHistoryFailure())
  }
}

function* celsureApproveTrialsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const celsureWo = yield select(state => state.celsureWo.celsureWo)
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message, data } } } = yield call(celsureApproveTrialsApi, payload, headers);
    if (status === "Success") {
      yield put(celsureApproveTrialsSuccess(data))
      notification.success({ message })
      yield put(celsureWoTrialsRequest({ work_order_id: celsureWo.work_order_id }))
    } else {
      yield put(celsureApproveTrialsFailure())
      notification.success({ message })
    }
  } catch (error) {
    yield put(celsureApproveTrialsFailure())
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(celsureWoCreateRequest, celsureWoCreateSaga)
  yield takeLatest(celsureWoUpdateRequest, celsureWoUpdateSaga)
  yield takeLatest(celsureWoDeleteRequest, celsureWoDeleteSaga)
  yield takeLatest(celsureWoListRequest, celsureWoListSaga)
  yield takeLatest(celsureWoTrialsRequest, celsureWoTrialsSaga)
  yield takeLatest(celsureFileUploadRequest, celsureFileUploadSaga)
  yield takeLatest(celsureGetPlotsRequest, celsurePlotsSaga)
  yield takeLatest(celsureFileHistoryRequest, celsureFileHistorySaga)
  yield takeLatest(celsureApproveTrialsRequest, celsureApproveTrialsSaga)
}