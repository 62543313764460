import { handleActions } from "redux-actions";
import { SidebarActionTypes } from "../actions/sidebar";

export type SidebarState = {
    collapsed: boolean
};

const defaultState: SidebarState = {
    collapsed: false,
};

const sidebarReducer = handleActions(
    {
        [SidebarActionTypes.TOGGLE]: (state, action) => ({
            ...state,
            collapsed: action.payload.collapsed,
        }),
    },
    defaultState
);

export default sidebarReducer;
