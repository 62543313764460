import { sendPost, sendPostML } from "src/utils/sendApi"
import axios from "axios"
import { Headers } from "../interface"

export const fetchModelsConfigList = (params: any, headers: Headers) => {
	return axios.post<any>(process.env.REACT_APP_ML_URL + "model_configs_list", null, {
		headers,
	})
}

export const fetchModelsConfig = (params: any, headers: Headers) => {
	return axios.post<any>(process.env.REACT_APP_ML_URL + "model_configs", params, {
		headers,
	})
}

export const predictApi = (params: any, headers: Headers) => {
	return axios.post<any>(process.env.REACT_APP_ML_URL + "predict", params, {
		headers,
	})
}

export const predictNewApi = (params: any, headers: Headers) => {
	return axios.post<any>(process.env.REACT_APP_ML_URL + "predict_new", params, {
		headers,
	})
}

export const maxellForwardApi = (params: any, headers: Headers) => {
	return axios.post<any>(process.env.REACT_APP_ML_URL + "predict_company1", params, {
		headers,
	})
}

// API for pluss custom model
export const predictPlussApi = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "predict_pluss",
		params,
		{
			headers,
		}
	)
}

export const getForwardConfigsApi = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "get_forward_configs",
		params,
		{
			headers,
		}
	)
}
export const getForwardConfigsZeonApi = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "zeon/get_forward_configs",
		params,
		{
			headers,
		}
	)
}
export const getCharacterizationMethodsZeonApi = (
	params: {
		characterization_method_id: string
	},
	headers: Headers
) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "zeon/get_characterization_methods",
		params,
		{
			headers,
		}
	)
}

export const getCharacterizationMethodsZeonDsmApi = (
	params: {
		characterization_method_id: string
	},
	headers: Headers
) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "zeon/get_characterization_dsm",
		params,
		{
			headers,
		}
	)
}

export const predictNewZeonAPI = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "zeon/predict_new",
		params,
		{
			headers,
		}
	)
}

export const forwardPredListApi = sendPost("v1/data/forward_preds/list")
export const forwardPredRetrieveApi = sendPost("v1/data/forward_preds/retrieve")
export const forwardPredRetrieveZeonApi = sendPost(
	"v1/data/forward_preds/zeon/retrieve"
)
export const forwardPredDeleteApi = sendPost("v1/data/forward_preds/delete")

export const predictCeslureApi = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_ML_URL + "predict_celsure",
		params,
		{
			headers,
		}
	)
}

export const addFavoriteApi = sendPostML("store_favourite")

export const getFavoritesApi = sendPostML("get_favourites")

export const deleteFavoritesApi = sendPostML("delete_favourites")

export const forwardPredFavApi = sendPost("v1/data/forward_preds/_fav_model")