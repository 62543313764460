import React from 'react'
import { Typography, Space } from 'antd'

const { Link, Title, Text } = Typography


export const UnsecuredPageWarning = () => {
    return (
        <Space direction="vertical" style={{ width: "100%", textAlign: "center", marginTop: 20, padding: 100 }} size="large">
            <Title level={4}>If you see this page, Web App link you have clicked on is under Clickjacking security attack.</Title>
            <Text>Please inform team with the reference of the application from where you clicked this link.</Text>
            <Text>Click <Link href={window.self.location.href} title='Web Application' target='blank'>here</Link> to access WebApp safely.</Text>
        </Space>
    )
}
