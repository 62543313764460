import axios from "axios"
import { sendPost } from "src/utils/sendApi"
import { Headers } from "../interface"
import { AutoCompleteRequest } from "./interface"

export const editFormulationDetails = sendPost("v1/data/_init_formulation")

export const autoSaveExperimentDetails = sendPost("v1/data/_update_single_trial_cell")

export const getFormulationDetails = sendPost("v1/data/_formulation")

export const archiveFormulation = sendPost("v1/data/_archive_formulation")

export const filterApi = sendPost("v1/data/_filter")

export const markForMlAPi = sendPost("v1/data/_mark_for_ml")

export const fetchSuggestionsAPi = (
	payload: AutoCompleteRequest,
	headers: any
) => {
	return axios
		.post(
			process.env.REACT_APP_API_URL + "v1/data/_autocomplete",
			payload,
			(headers = { headers })
		)
		.then((res: any) => {
			return res?.data?.result?.data?.map((keyword: any) => {
				return { text: keyword.parameter, value: keyword.parameter_id, meta_data: keyword }
			})
		})
}

export type CreateNewFilterParams = {
	name: string
	description: string
	query: object
}
export const createNewFormulationFilter = async (params: any, headers: any) => {
	const data = await sendPost("v1/data/_save_filter")(params, headers)
	return data.data
}
export type GetSavedFormulationFiltersParams = {
	user_id: string
}
export const getSavedFormulationFilters = async (
	params: GetSavedFormulationFiltersParams,
	headers: Headers
) => {
	const data = await sendPost("v1/data/_get_filters")(params, headers)
	return data.data
}
export type DeleteFormulationsFiltersParams = {
	filter_ids: string[]
}
export const deleteFormulationsFilters = async (
	params: DeleteFormulationsFiltersParams,
	headers: Headers
) => {
	const data = await sendPost("v1/data/_delete_filters")(params, headers)
	return data.data
}
