import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import {
  ChangeAuditLogPageLimitCount,
  FetchAuditLogActionsActionTypes,
  FetchAuditLogListActionTypes,
} from "../actions/auditLog";

export type AuditLogState = {
  auditLogListStatus: AsyncStates;
  auditLogList: any[];
  auditLogListError: string;
  auditLogActionsListStatus: AsyncStates;
  auditLogActionsList: any[];
  auditLogActionsListError: string;
  auditLogListTotalCount: number;
};

const defaultState: AuditLogState = {
  auditLogListStatus: AsyncStates.INITIAL,
  auditLogList: [],
  auditLogListError: "",
  auditLogActionsListStatus: AsyncStates.INITIAL,
  auditLogActionsList: [],
  auditLogActionsListError: "",
  auditLogListTotalCount: 0,
};

const auditLogReducer = handleActions<AuditLogState, any>(
  {
    [FetchAuditLogListActionTypes.REQUEST]: (state) => ({
      ...state,
      auditLogListStatus: AsyncStates.LOADING,
    }),
    [FetchAuditLogListActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      auditLogListStatus: AsyncStates.SUCCESS,
      auditLogList: action.payload?.data ?? [],
    }),
    [FetchAuditLogListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      auditLogListStatus: AsyncStates.ERROR,
      auditLogListError: action.payload.error,
    }),
    [FetchAuditLogActionsActionTypes.REQUEST]: (state) => ({
      ...state,
      auditLogActionsListStatus: AsyncStates.LOADING,
    }),
    [FetchAuditLogActionsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      auditLogActionsListStatus: AsyncStates.SUCCESS,
      auditLogActionsList: action.payload?.data ?? [],
    }),
    [FetchAuditLogActionsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      auditLogActionsListStatus: AsyncStates.ERROR,
      auditLogActionsListError: action.payload.error,
    }),
    [ChangeAuditLogPageLimitCount.COUNT]: (state, action) => ({
      ...state,
      auditLogListTotalCount: action.payload,
    }),
  },
  defaultState,
);

export default auditLogReducer