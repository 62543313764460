import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	compareFormulationsRequest,
	compareFormulationsSuccess,
	compareFormulationsFailure,
	reportUploadRequest,
	reportUploadSuccess,
	reportUploadFailure,
	reportsRetrieveRequest,
	reportsRetrieveSuccess,
	reportsRetrieveFailure,
	reportShareRequest,
	reportDeleteRequest,
	reportDeleteSuccess,
	reportDeleteFailure,
	reportShareSuccess,
	reportShareFailure,
	linkedCompareFormulationsRequest,
	linkedCompareFormulationsSuccess,
	linkedCompareFormulationsFailure,
} from "../actions/compareFormulations"
import { getFormulationDetails } from "../../services/formulation/index"
import {
	reportUpload,
	reportsRetrieve,
	reportSharing,
	reportDeletion,
} from "src/services/report"
import { notification } from "antd"
import { history } from "src"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { messages } from "src/utils/hooks"
import jwtManager from "src/utils/jwtManager"

type formulationDetailsAction = {
	type: string
	payload: {
		formulationId: Array<string>
	}
}

type reportUploadAction = {
	type: string
	payload: {
		report: Blob
		metadata: any
	}
}

type reportShareAction = {
	type: string
	payload: {
		receiver_ids: string
		message: string
		report_id: string
	}
}

type reportDeleteAction = {
	type: string
	payload: {
		report_id: string
	}
}

function* compareFormulationsSaga({
	payload: { formulationId },
}: formulationDetailsAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			formulation_id: formulationId,
		}
		const {
			data: {
				result: { data, status },
			},
		} = yield call(getFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(compareFormulationsSuccess(data))
			history.push("/formulations/compare", {
				formulationId,
			})
		} else {
			yield put(compareFormulationsFailure())
		}
	} catch (error) {
		yield put(compareFormulationsFailure())
	}
}

function* reportUploadSaga({ payload }: reportUploadAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const ln: LanguageUnion = yield select(
			(state: StoreState) => state.language.current
		)

		const params = new FormData()
		params.append("report", payload.report)
		params.append("metadata", JSON.stringify(payload.metadata))
		const {
			data: {
				result: { message, status },
			},
		} = yield call(reportUpload, params, headers)

		if (status === "Success") {
			notification.success({
				message: message,
				description: messages[ln].scroll_down_to_see_reports,
			})
			yield put(reportUploadSuccess())
			yield put(reportsRetrieveRequest())
		} else {
			yield put(reportUploadFailure(message))
		}
	} catch (error) {
		yield put(reportUploadFailure(error))
	}
}

function* reportShareSaga({ payload }: reportShareAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(reportSharing, payload, headers)

		if (status === "Success") {
			notification.success({ message })
			yield put(reportShareSuccess())
			yield put(reportsRetrieveRequest())
		} else {
			yield put(reportShareFailure(message))
		}
	} catch (error) {
		yield put(reportShareFailure(error))
	}
}

function* reportDeleteSaga({ payload }: reportDeleteAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(reportDeletion, payload, headers)
		if (status === "Success") {
			notification.warning({ message })
			yield put(reportDeleteSuccess())
			yield put(reportsRetrieveRequest())
		} else {
			yield put(reportDeleteFailure(message))
		}
	} catch (error) {
		yield put(reportDeleteFailure(error))
	}
}

function* reportsRetrieveSaga(): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(reportsRetrieve, null, headers)

		if (status === "Success") {
			yield put(reportsRetrieveSuccess(data))
		} else {
			yield put(reportsRetrieveFailure(message))
		}
	} catch (error) {
		yield put(reportsRetrieveFailure(error))
	}
}

function* linkedCompareFormulationsSaga({
	payload,
}: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(getFormulationDetails, payload, headers)
		if (status === "Success") {
			yield put(linkedCompareFormulationsSuccess(data))
		} else {
			yield put(linkedCompareFormulationsFailure())
		}
	} catch (error) {
		yield put(linkedCompareFormulationsFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(compareFormulationsRequest, compareFormulationsSaga)
	yield takeLatest(reportUploadRequest, reportUploadSaga)
	yield takeLatest(reportShareRequest, reportShareSaga)
	yield takeLatest(reportDeleteRequest, reportDeleteSaga)
	yield takeLatest(reportsRetrieveRequest, reportsRetrieveSaga)
	yield takeLatest(
		linkedCompareFormulationsRequest,
		linkedCompareFormulationsSaga
	)
}
