import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import {
	TeamsActionTypes,
	UpdateUserRolesActionTypes,
	UpdateUserAccessActionTypes,
	RevokeUserAccessActionTypes,
	ReactivateAccountActionTypes,
	ApproveInvitationActionTypes,
} from "../actions/teams"

export type TeamsState = {
	status: AsyncStates
	updateUserAccessStatus: AsyncStates
	error: string
	updateStatus: AsyncStates
	updateError: string
	data: any[]
	revokeUserAccessStatus: AsyncStates,
	revokeUserAccessErrorMessage: string,
	reactivateAccountStatus: AsyncStates,
	reactivateAccountErrorMessage: string,
	invitationApprovalStatus: AsyncStates,
	invitationApprovalErrorMessage: string,
}

const defaultState: TeamsState = {
	status: AsyncStates.INITIAL,
	updateUserAccessStatus: AsyncStates.INITIAL,
	error: "",
	updateStatus: AsyncStates.INITIAL,
	updateError: "",
	data: [],
	revokeUserAccessStatus: AsyncStates.INITIAL,
	revokeUserAccessErrorMessage: "",
	reactivateAccountStatus: AsyncStates.INITIAL,
	reactivateAccountErrorMessage: "",
	invitationApprovalStatus: AsyncStates.INITIAL,
	invitationApprovalErrorMessage: "",
}

const teamsReducer = handleActions(
	{
		[TeamsActionTypes.REQUEST]: (state) => {
			return {
				...state,
				status: AsyncStates.LOADING,
				error: "",
			}
		},
		[TeamsActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				status: AsyncStates.SUCCESS,
				error: "",
				data: action.payload,
			}
		},
		[TeamsActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[UpdateUserRolesActionTypes.REQUEST]: (state) => {
			return {
				...state,
				updateStatus: AsyncStates.LOADING,
				updateError: "",
			}
		},
		[UpdateUserRolesActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				updateStatus: AsyncStates.SUCCESS,
				updateError: "",
			}
		},
		[UpdateUserRolesActionTypes.FAILURE]: (state, action) => ({
			...state,
			updateStatus: AsyncStates.ERROR,
			updateError: action.payload.error,
		}),
		[UpdateUserAccessActionTypes.REQUEST]: (state) => {
			return {
				...state,
				updateUserAccessStatus: AsyncStates.LOADING,
			}
		},
		[UpdateUserAccessActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				updateUserAccessStatus: AsyncStates.SUCCESS,
			}
		},
		[UpdateUserAccessActionTypes.FAILURE]: (state, action) => ({
			...state,
			updateUserAccessStatus: AsyncStates.ERROR,
		}),
		[RevokeUserAccessActionTypes.REQUEST]: (state) => {
			return {
				...state,
				revokeUserAccessStatus: AsyncStates.LOADING,
				revokeUserAccessErrorMessage: "",
			}
		},
		[RevokeUserAccessActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				revokeUserAccessStatus: AsyncStates.SUCCESS,
				revokeUserAccessErrorMessage: "",
			}
		},
		[RevokeUserAccessActionTypes.FAILURE]: (state, action) => ({
			...state,
			revokeUserAccessStatus: AsyncStates.ERROR,
			revokeUserAccessErrorMessage: action.payload.error,
		}),
		[ReactivateAccountActionTypes.REQUEST]: (state) => {
			return {
				...state,
				reactivateAccountStatus: AsyncStates.LOADING,
				reactivateAccountErrorMessage: "",
			}
		},
		[ReactivateAccountActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				reactivateAccountStatus: AsyncStates.SUCCESS,
				reactivateAccountErrorMessage: "",
			}
		},
		[ReactivateAccountActionTypes.FAILURE]: (state, action) => ({
			...state,
			reactivateAccountStatus: AsyncStates.ERROR,
			reactivateAccountErrorMessage: action.payload.error,
		}),
		[ApproveInvitationActionTypes.REQUEST]: (state) => {
			return {
				...state,
				invitationApprovalStatus: AsyncStates.LOADING,
				invitationApprovalErrorMessage: "",
			}
		},
		[ApproveInvitationActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				invitationApprovalStatus: AsyncStates.SUCCESS,
				invitationApprovalErrorMessage: "",
			}
		},
		[ApproveInvitationActionTypes.FAILURE]: (state, action) => {
			return {
				...state,
				invitationApprovalStatus: AsyncStates.ERROR,
				invitationApprovalErrorMessage: action.payload.error,
			}
		},
	},
	defaultState
)

export default teamsReducer
