import { handleActions } from "redux-actions";
import {
    DeleteProjectActionTypes, FetchProjectListActionTypes, NewProjectActionTypes, ProjectActionTypes, UpdateProjectActionTypes,
    ProjectNotesActionTypes, ProjectActivityTypes
} from "../actions/projects";
import { AsyncStates } from "../../constants";


export type ProjectsState = {
    current: string;
    projectListStatus: AsyncStates,
    projectList: any[],
    projectListerror: string,
    projectListmessage: string
    createProjectStatus: AsyncStates,
    createProjectError: string,
    createProjectMessage: string,
    updateProjectStatus: AsyncStates,
    updateProjectError: string,
    updateProjectMessage: string
    editProjectId: string
    deleteProjectStatus: AsyncStates,
    deleteProjectError: string,
    deleteProjectMessage: string,
    projectNotes: any,
    getNotesStatus: AsyncStates
    createNoteStatus: AsyncStates,
    updateNoteStatus: AsyncStates,
    deleteNoteStatus: AsyncStates,
    activityStatus: AsyncStates,
    activityData: any[],
    selectAllProjects: boolean
}

const defaultState: ProjectsState = {
    current: '',
    projectListStatus: AsyncStates.INITIAL,
    projectList: [],
    projectListerror: '',
    projectListmessage: '',
    createProjectStatus: AsyncStates.INITIAL,
    createProjectError: '',
    createProjectMessage: '',
    updateProjectStatus: AsyncStates.INITIAL,
    updateProjectError: '',
    updateProjectMessage: '',
    editProjectId: '',
    deleteProjectStatus: AsyncStates.INITIAL,
    deleteProjectError: '',
    deleteProjectMessage: '',
    projectNotes: [],
    getNotesStatus: AsyncStates.INITIAL,
    createNoteStatus: AsyncStates.INITIAL,
    updateNoteStatus: AsyncStates.INITIAL,
    deleteNoteStatus: AsyncStates.INITIAL,
    activityStatus: AsyncStates.INITIAL,
    activityData: [],
    selectAllProjects: false
}

const projectsReducer = handleActions<ProjectsState, any>({
    [NewProjectActionTypes.REQUEST]: (state) => ({
        ...state,
        createProjectStatus: AsyncStates.LOADING,
    }),
    [NewProjectActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        createProjectStatus: AsyncStates.SUCCESS,
        createProjectMessage: action.payload.message
    }),
    [NewProjectActionTypes.FAILURE]: (state, action) => ({
        ...state,
        createProjectStatus: AsyncStates.ERROR,
        createProjectError: action.payload.error
    }),
    [UpdateProjectActionTypes.REQUEST]: (state) => ({
        ...state,
        updateProjectStatus: AsyncStates.LOADING,
    }),
    [UpdateProjectActionTypes.SUCCESS]: (state, action) => {
        return ({
            ...state,
            updateProjectStatus: AsyncStates.SUCCESS,
            updateProjectMessage: action.payload.message,
            projectList: [action.payload, ...state.projectList.filter((res: any) => res.project_id !== action.payload.project_id)],
        })
    },
    [UpdateProjectActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateProjectStatus: AsyncStates.ERROR,
        updateProjectError: action.payload.error
    }),
    [UpdateProjectActionTypes.SET_EDIT_PROJECT_ID]: (state, action) => ({
        ...state,
        editProjectId: action.payload
    }),
    [UpdateProjectActionTypes.CLEAR_EDIT_PROJECT_ID]: (state, action) => ({
        ...state,
        editProjectId: ""
    }),
    [DeleteProjectActionTypes.REQUEST]: (state) => ({
        ...state,
        deleteProjectStatus: AsyncStates.LOADING,
    }),
    [DeleteProjectActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        deleteProjectStatus: AsyncStates.SUCCESS,
        deleteProjectMessage: action.payload.message
    }),
    [DeleteProjectActionTypes.FAILURE]: (state, action) => ({
        ...state,
        deleteProjectStatus: AsyncStates.ERROR,
        deleteProjectError: action.payload.error
    }),
    [FetchProjectListActionTypes.REQUEST]: (state) => ({
        ...state,
        projectListStatus: AsyncStates.LOADING,
    }),
    [FetchProjectListActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        projectListStatus: AsyncStates.SUCCESS,
        projectList: action.payload?.data ?? []
    }),
    [FetchProjectListActionTypes.FAILURE]: (state, action) => ({
        ...state,
        projectListStatus: AsyncStates.ERROR,
        projectListerror: action.payload.error
    }),
    [ProjectActionTypes.SET]: (state, action) => ({
        ...state,
        current: action.payload
    }),
    [ProjectActionTypes.SELECT_ALL_PROJECTS]: (state, action) => ({
        ...state,
        selectAllProjects: action.payload
    }),
    [ProjectNotesActionTypes.GET_REQUEST]: (state) => ({
        ...state,
        getNotesStatus: AsyncStates.LOADING,
    }),
    [ProjectNotesActionTypes.GET_SUCCESS]: (state, action) => ({
        ...state,
        getNotesStatus: AsyncStates.SUCCESS,
        projectNotes: action.payload
    }),
    [ProjectNotesActionTypes.GET_FAILURE]: (state, action) => ({
        ...state,
        getNotesStatus: AsyncStates.ERROR
    }),
    [ProjectNotesActionTypes.CREATE_REQUEST]: (state) => ({
        ...state,
        createNoteStatus: AsyncStates.LOADING,
    }),
    [ProjectNotesActionTypes.CREATE_SUCCESS]: (state, action) => ({
        ...state,
        createNoteStatus: AsyncStates.SUCCESS,
    }),
    [ProjectNotesActionTypes.CREATE_FAILURE]: (state, action) => ({
        ...state,
        createNoteStatus: AsyncStates.ERROR
    }),
    [ProjectNotesActionTypes.UPDATE_REQUEST]: (state) => ({
        ...state,
        updateNoteStatus: AsyncStates.LOADING,
    }),
    [ProjectNotesActionTypes.UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updateNoteStatus: AsyncStates.SUCCESS,
    }),
    [ProjectNotesActionTypes.UPDATE_FAILURE]: (state, action) => ({
        ...state,
        updateNoteStatus: AsyncStates.ERROR
    }),
    [ProjectNotesActionTypes.DELETE_REQUEST]: (state) => ({
        ...state,
        deleteNoteStatus: AsyncStates.LOADING,
    }),
    [ProjectNotesActionTypes.DELETE_SUCCESS]: (state, action) => ({
        ...state,
        deleteNoteStatus: AsyncStates.SUCCESS,
    }),
    [ProjectNotesActionTypes.DELETE_FAILURE]: (state, action) => ({
        ...state,
        deleteNoteStatus: AsyncStates.ERROR
    }),
    [ProjectActivityTypes.REQUEST]: (state) => ({
        ...state,
        activityStatus: AsyncStates.LOADING,
    }),
    [ProjectActivityTypes.SUCCESS]: (state, action) => ({
        ...state,
        activityStatus: AsyncStates.SUCCESS,
        activityData: action.payload
    }),
    [ProjectActivityTypes.FAILURE]: (state, action) => ({
        ...state,
        activityStatus: AsyncStates.ERROR
    }),
}, defaultState)


export default projectsReducer