import { sendPost } from "src/utils/sendApi";


export const fetchMethodListApi = sendPost("v1/data/methods/_list_methods")

export const createMethodApi = sendPost("v1/data/methods/_create_method")

export const deleteCharacterizationMethodsApi = sendPost("v1/data/methods/_delete_method")

export const updateCharacterizationMethodApi = sendPost("v1/data/methods/_update_method")

export const saveCharacterizationSetsApi = sendPost("v1/data/methods/_save_sets")

export const deleteCharacterizationSetsApi = sendPost("v1/data/methods/_delete_sets")

export const checkVariationUsedApi = sendPost("v1/data/methods/_check_variation")

