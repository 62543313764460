import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import {
  GetModelInfoActionTypes, SetModelDataActionTypes, ModelCommentListActionTypes, CreateModelCommentActionTypes,
  DeleteModelCommentActionTypes, EditModelCommentActionTypes, RequestInverseActionTypes, ModelInfoChangeActionTypes, GetCustomModelDataActionTypes, SetCustomModal
} from "../actions/modelAnalysis";

export type ModelAnalysisState = {
  status: AsyncStates;
  error: string;
  modelInfo: any;
  modelData: any;
  setAnalysisTabValue: boolean;
  setModelVersionValue: any;
  createModelCommentStatus: AsyncStates
  deleteModelCommentStatus: AsyncStates
  editModelCommentStatus: AsyncStates
  modelCommentListStatus: AsyncStates
  modelCommentList: any
  modelCommentsNotificationClick: any,
  requestInverseStatus: AsyncStates,
  modelInfoChangeStatus: AsyncStates,
  getCustomModelDataStatus: AsyncStates,
  customModelData: any,
  getCustomModelDataError: string,
  selectedCustomModel: any
};

const defaultState: ModelAnalysisState = {
  status: AsyncStates.INITIAL,
  error: "",
  modelInfo: { status: "", ingredient_data: {}, property_data: {} },
  modelData: {},
  setAnalysisTabValue: false,
  setModelVersionValue: { value: false, type: "" },
  createModelCommentStatus: AsyncStates.INITIAL,
  deleteModelCommentStatus: AsyncStates.INITIAL,
  editModelCommentStatus: AsyncStates.INITIAL,
  modelCommentListStatus: AsyncStates.INITIAL,
  modelCommentList: { total_records: 0 },
  modelCommentsNotificationClick: { click: false, version: "" },
  requestInverseStatus: AsyncStates.INITIAL,
  modelInfoChangeStatus: AsyncStates.INITIAL,
  getCustomModelDataStatus: AsyncStates.INITIAL,
  customModelData: [],
  getCustomModelDataError: "",
  selectedCustomModel: ''
};

const modelAnalysisReducer = handleActions(
  {
    [GetModelInfoActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        status: AsyncStates.LOADING,
        error: "",
      })
    },
    [GetModelInfoActionTypes.SUCCESS]: (state, action: any) => {
      const { message, status, data: { ingredient_data, processing_data, property_data, ...modelData } } = action.payload

      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        modelInfo: { message, status, ingredient_data, processing_data, property_data },
        modelData: modelData
      };
    },
    [GetModelInfoActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [GetModelInfoActionTypes.CLEAR]: (state, action) => ({
      ...state,
      status: AsyncStates.INITIAL,
      modelInfo: { status: "", ingredient_data: {}, property_data: {} },
      error: "",
    }),
    [SetModelDataActionTypes.SET_MODEL_DATA]: (state, action: any) => {
      return {
        ...state,
        modelData: action.payload
      };
    },
    [SetModelDataActionTypes.SET_ANALYSIS_TAB]: (state, action: any) => ({
      ...state,
      setAnalysisTabValue: action.payload
    }),
    [SetModelDataActionTypes.SET_MODEL_VERSION]: (state, action: any) => ({
      ...state,
      setModelVersionValue: action.payload
    }),
    [SetModelDataActionTypes.SET_MODEL_COMMENTS_NOTIFICATION_CLICK]: (state, action: any) => ({
      ...state,
      modelCommentsNotificationClick: action.payload
    }),
    [CreateModelCommentActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        createModelCommentStatus: AsyncStates.LOADING,
        error: "",
      })
    },
    [CreateModelCommentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createModelCommentStatus: AsyncStates.SUCCESS,
        error: "",
      };
    },
    [CreateModelCommentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      createModelCommentStatus: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [DeleteModelCommentActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        deleteModelCommentStatus: AsyncStates.LOADING,
        error: "",
      })
    },
    [DeleteModelCommentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteModelCommentStatus: AsyncStates.SUCCESS,
        error: "",
      };
    },
    [DeleteModelCommentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteModelCommentStatus: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [EditModelCommentActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        editModelCommentStatus: AsyncStates.LOADING,
        error: "",
      })
    },
    [EditModelCommentActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        editModelCommentStatus: AsyncStates.SUCCESS,
        error: "",
      };
    },
    [EditModelCommentActionTypes.FAILURE]: (state, action) => ({
      ...state,
      editModelCommentStatus: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [ModelCommentListActionTypes.REQUEST]: (state) => {
      return ({
        ...state,
        modelCommentListStatus: AsyncStates.LOADING,
        error: "",
      })
    },
    [ModelCommentListActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        modelCommentListStatus: AsyncStates.SUCCESS,
        modelCommentList: {
          previous_id: action.payload.modelCommentList.previous_id,
          total_records: action.payload.modelCommentList.total,
          comments_data: action.payload.reset ?
            [...(action.payload.modelCommentList.comments_data || [])].reverse() :
            [
              ...(action.payload.modelCommentList.comments_data || []).reverse(),
              ...(state.modelCommentList.comments_data || [])
            ]
        },
        error: "",
      };
    },
    [ModelCommentListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      modelCommentListStatus: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [ModelCommentListActionTypes.CLEAR]: (state) => {
      return {
        ...state,
        modelCommentListStatus: AsyncStates.INITIAL,
        modelCommentList: { total_records: 0 },
        error: "",
      };
    },
    [RequestInverseActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        requestInverseStatus: AsyncStates.LOADING,
      };
    },
    [RequestInverseActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      requestInverseStatus: AsyncStates.SUCCESS,
    }),
    [RequestInverseActionTypes.FAILURE]: (state) => {
      return ({
        ...state,
        requestInverseStatus: AsyncStates.ERROR,
      })
    },
    [ModelInfoChangeActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        modelInfoChangeStatus: AsyncStates.LOADING,
      };
    },
    [ModelInfoChangeActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      modelInfoChangeStatus: AsyncStates.SUCCESS,
      modelData: {
        ...state.modelData, comments: action?.payload?.comments, objective
          : action?.payload?.objective
      }
    }
    ),
    [ModelInfoChangeActionTypes.FAILURE]: (state) => {
      return ({
        ...state,
        modelInfoChangeStatus: AsyncStates.ERROR,
      })
    },
    [GetCustomModelDataActionTypes.REQUEST]: (state) => ({
      ...state,
      filterData: { dataframe: [], version: "" },
      getCustomModelDataStatus: AsyncStates.LOADING,
    }),
    [GetCustomModelDataActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      getCustomModelDataStatus: AsyncStates.SUCCESS,
      customModelData: action.payload
    }),
    [GetCustomModelDataActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      customModelData: [],
      getCustomModelDataStatus: AsyncStates.ERROR,
      getCustomModelDataError: action?.payload
    }),
    [GetCustomModelDataActionTypes.CLEAR]: (state, action) => ({
      ...state,
      customModelData: [],
      getCustomModelDataStatus: AsyncStates.INITIAL,
      getCustomModelDataError: ""
    }),
    [SetCustomModal.SetModal]: (state, action) => ({
      ...state,
      selectedCustomModel: action.payload
    }),
  },
  defaultState
);

export default modelAnalysisReducer
