import { put, takeLatest, call, select } from "redux-saga/effects"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore"
import jwtManager from "src/utils/jwtManager"
import { preferencesFailure, preferencesRequest, preferencesSuccess, preferencesUpdateFailure, preferencesUpdateRequest, preferencesUpdateSuccess } from "../actions/preferences"
import { preferencesApi, preferencesUpdateApi } from "src/services/preferences"

function* preferencesSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(preferencesApi, {}, headers)
		if (result.status === "Success") {
			yield put(preferencesSuccess(result))
		} else {
			yield put(preferencesFailure(result.message))
		}
	} catch (error) {
		yield put(preferencesFailure(messages[ln].internal_server_error))
	}
}

function* preferencesUpdateSaga({
	payload,
}: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(preferencesUpdateApi, payload, headers)
		if (result.status === "Success") {
			yield put(preferencesUpdateSuccess(result))
		} else {
			yield put(preferencesUpdateFailure(result.message))
		}
	} catch (error) {
		yield put(preferencesUpdateFailure(messages[ln].internal_server_error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(preferencesRequest, preferencesSaga)
	yield takeLatest(preferencesUpdateRequest, preferencesUpdateSaga)
}