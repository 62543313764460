import { handleActions } from "redux-actions";
import { editStateActionTypes } from "../actions/isEditing";

export type isEditingState = boolean;

const defaultState: isEditingState = false;

const isEditingReducer = handleActions<isEditingState, any>(
  {
    [editStateActionTypes.SET_IS_EDITING]: (state, action) => action.payload,
  },
  defaultState
);
export default isEditingReducer;