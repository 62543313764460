import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	workOrdersRequest,
	workOrdersSuccess,
	workOrdersFailure,
	checkWorkOrderExistsRequest,
	checkWorkOrderExistsSuccess,
	checkWorkOrderExistsFailure,
} from "../actions/workOrders"
import { checkWorkOrderExistsApi, workOrders } from "src/services/workOrders"
import jwtManager from "src/utils/jwtManager"

type workOrdersAction = {
	type: string
	payload: any
}

function* workOrdersSaga(action: workOrdersAction): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(workOrders, action.payload, headers)
		if (status === "Success") {
			yield put(workOrdersSuccess(data))
		} else {
			yield put(workOrdersFailure(message))
		}
	} catch (error) {
		yield put(workOrdersFailure(error))
	}
}


function* checkWorkOrderExistsSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(checkWorkOrderExistsApi, payload, headers)
		if (status === "Success") {
			yield put(checkWorkOrderExistsSuccess(data))
		} else {
			yield put(checkWorkOrderExistsFailure())
		}
	} catch (error) {
		yield put(checkWorkOrderExistsFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(workOrdersRequest, workOrdersSaga)
	yield takeLatest(checkWorkOrderExistsRequest, checkWorkOrderExistsSaga)
}
