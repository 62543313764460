import { createActions } from "redux-actions";

export enum FetchAuditLogListActionTypes {
    REQUEST = "FETCH_AUDIT_LOG_LIST_REQUEST",
    SUCCESS = "FETCH_AUDIT_LOG_LIST_SUCCESS",
    FAILURE = "FETCH_AUDIT_LOG_LIST_FAILURE",
}

export enum FetchAuditLogActionsActionTypes {
    REQUEST = "FETCH_AUDIT_LOG_ACTIONS_REQUEST",
    SUCCESS = "FETCH_AUDIT_LOG_ACTIONS_SUCCESS",
    FAILURE = "FETCH_AUDIT_LOG_ACTIONS_FAILURE",
}

export enum ChangeAuditLogPageLimitCount {
  COUNT = "CHANGE_AUDIT_LOG_COUNT"
}

export const {
    fetchAuditLogListRequest,
    fetchAuditLogListSuccess,
    fetchAuditLogListFailure,
    fetchAuditLogActionsRequest,
    fetchAuditLogActionsSuccess,
    fetchAuditLogActionsFailure,
    changeAuditLogCount
} = createActions({
    [FetchAuditLogListActionTypes.REQUEST]: (payload) => payload,
    [FetchAuditLogListActionTypes.SUCCESS]: (payload) => payload,
    [FetchAuditLogListActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchAuditLogActionsActionTypes.REQUEST]: (payload) => payload,
    [FetchAuditLogActionsActionTypes.SUCCESS]: (payload) => payload,
    [FetchAuditLogActionsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ChangeAuditLogPageLimitCount.COUNT]: (payload) => payload
})