import { createActions } from "redux-actions";

export enum WorkOrdersActionTypes {
    REQUEST = "WORK_ORDERS_REQUEST",
    SUCCESS = "WORK_ORDERS_SUCCESS",
    FAILURE = "WORK_ORDERS_FAILURE",
    CLEAR = "WORK_ORDERS_CLEAR",
}

export enum SelectTrialsActionTypes {
    SET_SELECTED_TRIALS = "SET_SELECTED_TRIALS"
}

export enum CheckWorkOrderExistsActionTypes {
    REQUEST = "CHECK_WORK_ORDER_EXISTS_REQUEST",
    SUCCESS = "CHECK_WORK_ORDER_EXISTS_SUCCESS",
    FAILURE = "CHECK_WORK_ORDER_EXISTS_FAILURE",
    CLEAR = "CHECK_WORK_ORDER_EXISTS_CLEAR",
}

export const {
    workOrdersRequest,
    workOrdersSuccess,
    workOrdersFailure,
    workOrdersClear,
    setSelectedTrials,
    checkWorkOrderExistsRequest,
    checkWorkOrderExistsSuccess,
    checkWorkOrderExistsFailure,
    checkWorkOrderExistsClear
} = createActions({
    [WorkOrdersActionTypes.REQUEST]: payload => payload,
    [WorkOrdersActionTypes.SUCCESS]: (data: any) => { return ({ data }) },
    [WorkOrdersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [WorkOrdersActionTypes.CLEAR]: () => { },
    [SelectTrialsActionTypes.SET_SELECTED_TRIALS]: payload => payload,
    [CheckWorkOrderExistsActionTypes.REQUEST]: (payload) => payload,
    [CheckWorkOrderExistsActionTypes.SUCCESS]: (response) => response,
    [CheckWorkOrderExistsActionTypes.FAILURE]: (error: string) => ({ error }),
    [CheckWorkOrderExistsActionTypes.CLEAR]: () => { },
});
