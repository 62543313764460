import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { PreferencesActionTypes } from "../actions/preferences";

export type PreferencesReducerState = {
    status: AsyncStates;
    updatedStatus: AsyncStates;
    error: string;
    data: any,
}

const defaultState: PreferencesReducerState = {
    status: AsyncStates.INITIAL,
    updatedStatus: AsyncStates.INITIAL,
    error: "",
    data: { onboarding_progress: 0 },
}

const preferencesReducer = handleActions({
    [PreferencesActionTypes.REQUEST]: (state) => ({
        ...state,
        status: AsyncStates.LOADING,
        updatedStatus: AsyncStates.INITIAL,
        error: "",
    }),
    [PreferencesActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            status: AsyncStates.SUCCESS,
            error: "",
            data: action.payload.data,
        };
    },
    [PreferencesActionTypes.FAILURE]: (state, action) => ({
        ...state,
        status: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [PreferencesActionTypes.UPDATE_REQUEST]: (state) => ({
        ...state,
        updatedStatus: AsyncStates.LOADING,
        error: '',
    }),
    [PreferencesActionTypes.UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.SUCCESS,
        error: '',
        data: { ...state.data, ...action.payload.data },
    }),
    [PreferencesActionTypes.UPDATE_FAILURE]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.ERROR,
        error: action.payload.error,
    }),

}, defaultState)


export default preferencesReducer;