import { put, takeLatest, call, select } from "redux-saga/effects"
import { notification } from "antd"
import {
	conversionFailure,
	conversionRequest,
	conversionSuccess,
	unitListFailure,
	unitListRequest,
	unitListSuccess,
} from "../actions/conversion"
import { conversion, fetchUnitList } from "src/services/conversion"
import jwtManager from "src/utils/jwtManager"


function* unitListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(fetchUnitList, payload, headers)

		if (status === "Success") {
			yield put(unitListSuccess(data))
		} else {
			yield put(unitListFailure())
		}
	} catch (error) {
		yield put(unitListFailure())
	}
}

function* conversionSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(conversion, payload, headers)
		if (status === "Success") {
			yield put(conversionSuccess(data))
		} else {
			notification.error({ message })
			yield put(conversionFailure())
		}
	} catch (error) {
		yield put(conversionFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(unitListRequest, unitListSaga)
	yield takeLatest(conversionRequest, conversionSaga)
}
