import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { GetExplainabilityActionTypes, GetExplainableAiReportActionTypes } from "../actions/explainableAI";


export type ExplainableAIReducerState = {
    explainableAIData: any,
    getExplainabiltyStatus: AsyncStates,
    getExplainabiltyError: string,
    explainableAIReportStatus: AsyncStates
    explainableAIReportError: string
}

const defaultState: ExplainableAIReducerState = {
    explainableAIData: {},
    getExplainabiltyStatus: AsyncStates.INITIAL,
    getExplainabiltyError: "",
    explainableAIReportStatus: AsyncStates.INITIAL,
    explainableAIReportError: ""
}

const explainableAIReducer = handleActions<ExplainableAIReducerState, any>(
    {
        [GetExplainabilityActionTypes.REQUEST]: (state) => {
            return ({
                ...state,
                getExplainabiltyStatus: AsyncStates.LOADING,
                getExplainabiltyError: "",
            })
        },
        [GetExplainabilityActionTypes.SUCCESS]: (state, action: any) => {
            const data = action.payload
            return {
                ...state,
                getExplainabiltyStatus: AsyncStates.SUCCESS,
                getExplainabiltyError: "",
                explainableAIData: data.explainable_ai_data,
            };
        },
        [GetExplainabilityActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            getExplainabiltyStatus: AsyncStates.ERROR,
            getExplainabiltyError: action.payload.error,
        }),
        [GetExplainabilityActionTypes.CLEAR]: (state, action) => ({
            ...state,
            getExplainabiltyStatus: AsyncStates.INITIAL,
            explainableAIData: {},
            getExplainabiltyError: "",
        }),
        [GetExplainableAiReportActionTypes.REQUEST]: (state, action: any) => ({
            ...state,
            explainableAIReportStatus: action.payload.status,
        }),
        [GetExplainableAiReportActionTypes.SUCCESS]: (state, action: any) => ({
            ...state,
            explainableAIReportStatus: AsyncStates.SUCCESS,
        }),
        [GetExplainableAiReportActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            explainableAIReportStatus: AsyncStates.ERROR,
            explainableAIReportError: action.payload.error
        }),
        [GetExplainableAiReportActionTypes.CLEAR]: (state, action) => ({
            ...state,
            explainableAIReportStatus: AsyncStates.INITIAL,
            explainableAIReportError: ""
        }),
    },
    defaultState
);

export default explainableAIReducer