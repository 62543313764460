import { createActions } from "redux-actions";
import { FormulationsFilterData } from "../../services/formulation/interface";

export enum FormulationsFilterActionTypes {
  REQUEST = "FORMULATIONS_FILTER_REQUEST",
  SUCCESS = "FORMULATIONS_FILTER_SUCCESS",
  FAILURE = "FORMULATIONS_FILTER_FAILURE",
  CLEANUP = "FORMULATIONS_FILTER_CLEANUP",
}

export const {
  formulationsFilterRequest,
  formulationsFilterSuccess,
  formulationsFilterFailure,
  formulationsFilterCleanup,
} = createActions({
  [FormulationsFilterActionTypes.REQUEST]: payload => payload,
  [FormulationsFilterActionTypes.SUCCESS]: (
    formulationsFilterData: FormulationsFilterData, reset: boolean
  ) => ({
    formulationsFilterData, reset
  }),
  [FormulationsFilterActionTypes.FAILURE]: (error: string) => ({ error }),
  [FormulationsFilterActionTypes.CLEANUP]: () => { },
});
