import { Action, ActionFunction1, createAction } from "redux-actions"
import { Language } from "../reducers/language"

export enum LanguageActionTypes {
	TOGGLE = "LANGUAGE_TOGGLE",
}

export type LanguageToggleAction = ActionFunction1<
	Language,
	Action<{
		current: string
	}>
>

export const languageToggle: LanguageToggleAction = createAction(
	LanguageActionTypes.TOGGLE,
	(current: Language) => ({ current })
)
