import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { FetchDashboardDataActionTypes, FetchNewsActionTypes } from "../actions/dashboard";


export type DashboardState = {
    dashboardData:any,
    dashboardDataStatus:AsyncStates,
    dashboardDataError:String,
    newsData:any[],
    newsDataStatus:AsyncStates,
    newsDataError:String
}

const defaultState: DashboardState={
    dashboardData:{},
    dashboardDataStatus:AsyncStates.INITIAL,
    dashboardDataError:"",
    newsData:[],
    newsDataStatus:AsyncStates.INITIAL,
    newsDataError:""
}

const dashBoardReducer = handleActions<DashboardState,any>({
    [FetchDashboardDataActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardDataStatus: AsyncStates.LOADING,
    }),
    [FetchDashboardDataActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardDataStatus: AsyncStates.SUCCESS,
        dashboardData: action.payload ?? {}
    }),
    [FetchDashboardDataActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardDataStatus: AsyncStates.ERROR,
        dashboardDataError: action.payload
    }),
    [FetchNewsActionTypes.REQUEST]: (state) => ({
        ...state,
        newsDataStatus: AsyncStates.LOADING,
    }),
    [FetchNewsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        newsDataStatus: AsyncStates.SUCCESS,
        newsData: action.payload ?? []
    }),
    [FetchNewsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        newsDataStatus: AsyncStates.ERROR,
        newsDataError: action.payload
    })
},defaultState)

export default dashBoardReducer