import { notification } from "antd";
import { put, takeLatest, call, select } from "redux-saga/effects";
import { createTemplateApi, deleteTemplateApi, editTemplateApi, listTemplateApi } from "src/services/templates";
import jwtManager from "src/utils/jwtManager";
import {
    createTemplateFailure, createTemplateRequest, createTemplateSuccess,
    deleteTemplateFailure, deleteTemplateRequest, deleteTemplateSuccess,
    editTemplateFailure, editTemplateRequest, editTemplateSuccess,
    listTemplateFailure, listTemplateRequest, listTemplateSuccess
} from "../actions/templates"


function* createTemplateSaga({ payload }: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(createTemplateApi, payload, headers)
        if (status === "Success") {
            yield put(createTemplateSuccess())
            notification.success({ message })
            yield put(listTemplateRequest())
        } else {
            yield put(createTemplateFailure(message))
        }
    } catch (error) {
        yield put(createTemplateFailure())
    }
}

function* deleteTemplateSaga({ payload }: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(deleteTemplateApi, payload, headers)
        if (status === "Success") {
            yield put(deleteTemplateSuccess())
            yield put(listTemplateRequest())
            notification.success({
                message: message,
                placement: "bottomRight",
            })
        } else {
            yield put(deleteTemplateFailure(message))
        }
    } catch (error) {
        yield put(deleteTemplateFailure())
    }
}


function* editTemplateSaga({ payload }: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    status,
                    message
                },
            },
        } = yield call(editTemplateApi, payload, headers)
        if (status === "Success") {
            yield put(editTemplateSuccess())
            yield put(listTemplateRequest())
            notification.success({
                message: message,
                placement: "bottomRight",
            })
        } else {
            yield put(editTemplateFailure(message))
        }
    } catch (error) {
        yield put(editTemplateFailure())
    }
}

function* listTemplateSaga({ payload }: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: {
                    data,
                    status,
                    message
                },
            },
        } = yield call(listTemplateApi, payload, headers)
        if (status === "Success") {
            yield put(listTemplateSuccess(data))
        } else {
            yield put(listTemplateFailure(message))
        }
    } catch (error) {
        yield put(listTemplateFailure())
    }
}



export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(createTemplateRequest, createTemplateSaga)
    yield takeLatest(deleteTemplateRequest, deleteTemplateSaga)
    yield takeLatest(editTemplateRequest, editTemplateSaga)
    yield takeLatest(listTemplateRequest, listTemplateSaga)
}
