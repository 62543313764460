import { createActions } from "redux-actions";


export enum ReportPreviewActionTypes {
  WO_UPLOAD_REPORT_REQUEST = "WO_UPLOAD_REPORT_REQUEST",
  WO_UPLOAD_REPORT_SUCCESS = "WO_UPLOAD_REPORT_SUCCESS",
  WO_UPLOAD_REPORT_FAILURE = "WO_UPLOAD_REPORT_FAILURE",
  WO_RETRIEVE_REPORT_REQUEST = "WO_RETRIEVE_REPORT_REQUEST",
  WO_RETRIEVE_REPORT_SUCCESS = "WO_RETRIEVE_REPORT_SUCCESS",
  WO_RETRIEVE_REPORT_FAILURE = "WO_RETRIEVE_REPORT_FAILURE",
  WO_DELETE_REPORT_REQUEST = "WO_DELETE_REPORT_REQUEST",
  WO_DELETE_REPORT_SUCCESS = "WO_DELETE_REPORT_SUCCESS",
  WO_DELETE_REPORT_FAILURE = "WO_DELETE_REPORT_FAILURE",
}

export const {
  woUploadReportRequest,
  woUploadReportSuccess,
  woUploadReportFailure,
  woRetrieveReportRequest,
  woRetrieveReportSuccess,
  woRetrieveReportFailure,
  woDeleteReportRequest,
  woDeleteReportSuccess,
  woDeleteReportFailure,
} = createActions({
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_REQUEST]: (payload) => payload,
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_SUCCESS]: (response) => response,
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_FAILURE]: (error: string) => ({ error }),
  [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_REQUEST]: (payload) => payload,
  [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_SUCCESS]: (response) => response,
  [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_FAILURE]: (error: string) => ({ error }),
  [ReportPreviewActionTypes.WO_DELETE_REPORT_REQUEST]: (payload) => payload,
  [ReportPreviewActionTypes.WO_DELETE_REPORT_SUCCESS]: (response) => response,
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_FAILURE]: (error: string) => ({ error }),
})
