import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { productGuideSettingsUpdate } from "src/store/actions/product-guide-settings"
import { StoreState } from "src/store/configureStore"

export const tooltipModules = [
	"projects",
	"work_orders",
	"formulations",
	"data_management_import",
	"properties_prediction",
	"processing_prediction",
	"data_summary",
	"custom_insights",
	"custom_ml",
	"model_analysis",
	"inventory",
] as const

export type TooltipModule = typeof tooltipModules[number]

export const tooltipModuleToTitle: {
	[key in TooltipModule]: string
} = {
	projects: "Projects",
	work_orders: "Work Orders",
	formulations: "Formulations",
	data_management_import: "Data Management - Import data",
	properties_prediction: "Properties Prediction",
	processing_prediction: "Formulation/Processing Prediction",
	data_summary: "Data Summary",
	custom_insights: "Custom Insights",
	custom_ml: "Custom ML",
	model_analysis: "Model Analysis",
	inventory: "Inventory",
}

export const moduleToVideoLink: {
	[key in TooltipModule]: string
} = {
	work_orders:
		"https://player.vimeo.com/video/770655043?h=df57d6ad85&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	projects:
		"https://player.vimeo.com/video/770654803?h=eabd4a77e9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	formulations:
		"https://player.vimeo.com/video/770655738?h=f75fc6f262&badge=0&autopause=0&player_id=0&amp;app_id=58479",
	data_management_import:
		"https://player.vimeo.com/video/770655430?h=db29737ea4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	properties_prediction:
		"https://player.vimeo.com/video/770655901?h=828e5959d7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	processing_prediction:
		"https://player.vimeo.com/video/770656028?h=6de13013cd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	data_summary:
		"https://player.vimeo.com/video/770658238?h=32e6e1861f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	custom_insights:
		"https://player.vimeo.com/video/770657888?h=922bedf94d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	custom_ml:
		"https://player.vimeo.com/video/770657849?h=6946f20f24&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	model_analysis:
		"https://player.vimeo.com/video/770657849?h=6946f20f24&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
	inventory:
		"https://player.vimeo.com/video/773660572?h=73b1dca5df&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
}

export type ModuleSetting = {
	[key in TooltipModule]: boolean
}

export const defaultSettings: ModuleSetting = {
	projects: false,
	custom_ml: false,
	formulations: false,
	model_analysis: false,
	work_orders: false,
	data_summary: false,
	properties_prediction: false,
	processing_prediction: false,
	custom_insights: false,
	data_management_import: false,
	inventory: false,
}
const firstTimeSettings: ModuleSetting = {
	projects: true,
	custom_ml: true,
	formulations: true,
	model_analysis: true,
	work_orders: true,
	data_summary: true,
	properties_prediction: true,
	processing_prediction: true,
	custom_insights: true,
	data_management_import: true,
	inventory: true,
}

export const useGuideTooltip = () => {
	const dispatch = useDispatch()
	const moduleSetting = useSelector(
		(state: StoreState) => state.productGuideSettings
	)

	const setModuleSetting = useCallback(
		(newSettings: ModuleSetting) => {
			dispatch(productGuideSettingsUpdate(newSettings))
		},
		[dispatch]
	)

	useEffect(() => {
		const settings = localStorage.getItem("product-guide-tooltip-settings")

		// If there is no settings in local storage, set the first time settings
		if (!settings) {
			localStorage.setItem(
				"product-guide-tooltip-settings",
				JSON.stringify(firstTimeSettings)
			)
			setModuleSetting(firstTimeSettings)
			return
		}

		const parsedSettings = JSON.parse(settings)

		// WHENEVER YOU ADD A NEW MODULE, update the existing settings in local storage
		if (
			Object.keys(parsedSettings).length !==
			Object.keys(firstTimeSettings).length
		) {
			Object.keys(firstTimeSettings).forEach((key) => {
				const typedKey = key as TooltipModule
				if (parsedSettings[typedKey] === undefined) {
					parsedSettings[typedKey] = firstTimeSettings[typedKey]
				}
			})
			setModuleSetting(parsedSettings)
			return
		}

		setModuleSetting(parsedSettings)
	}, [setModuleSetting])

	const setModule = useCallback(
		(module: TooltipModule, value: boolean) => {
			const newSettings = { ...moduleSetting, [module]: value }
			localStorage.setItem(
				"product-guide-tooltip-settings",
				JSON.stringify(newSettings)
			)
			setModuleSetting(newSettings)
		},
		[moduleSetting, setModuleSetting]
	)

	const isModuleVisible = useCallback(
		(module: TooltipModule) => {
			return moduleSetting[module]
		},
		[moduleSetting]
	)

	return { isModuleVisible, setModule }
}

export type IconType = "question" | "info"
