import { sendPost } from "src/utils/sendApi";

export const workOrders = sendPost("v1/data/_wo_list");

export const getWorkOrder = sendPost("v1/data/_get_work_order_details");

export const createWorkOrder =
  sendPost("v1/data/_wo_create");

export const editWorkOrder = sendPost("v1/data/_update_work_order");

export const deleteWorkOrder =
  sendPost("v1/data/_wo_delete");

export const dataExportApi = sendPost("v1/data/export_data");

export const ZeonDataExportApi = sendPost("v1/data/_zeon/_export_data");

export const uploadGraphsDataApi = sendPost("v1/data/_graph_files_upload")

export const plotsDataApi = sendPost("v1/data/_get_formulation_plots")

export const graphsDataHistoryApi = sendPost("v1/data/_graph_files_history")

export const changeWoStatusApi = sendPost("v1/data/_wo_status_change")

export const listProcessingSetsApi = sendPost("v1/data/list_processing_sets")

export const createProcessingSetApi = sendPost("v1/data/create_processing_sets")

export const uploadFileAttachmentApi = sendPost("v1/data/_custom_uploads/upload")

export const fetchFileListApi = sendPost("v1/data/_custom_uploads/_list")

export const deleteFileListApi = sendPost("v1/data/_custom_uploads/_delete")

export const checkWorkOrderExistsApi = sendPost("v1/data/_work_orders/_check_exists")

export const stageExistsApi = sendPost("v1/data/_work_orders/_stage_exists")

export const dataSanityApi = sendPost("v1/data/data_sanity_check")
export const dataSanityDataApi = sendPost("v1/data/get_data_sanity")