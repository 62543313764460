import React, { ComponentPropsWithoutRef } from "react"

type P = ComponentPropsWithoutRef<"svg"> & {
	size?: number
}

export const JapanFlag = ({ size = 24, ...props }: P) => {
	return (
		<svg
			width={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 480"
			{...props}
		>
			<defs>
				<clipPath id="jp-a">
					<path fillOpacity="0.7" d="M-88 32h640v480H-88z"></path>
				</clipPath>
			</defs>
			<g
				fillRule="evenodd"
				strokeWidth="1pt"
				clipPath="url(#jp-a)"
				transform="translate(88 -32)"
			>
				<path fill="#fff" d="M-128 32h720v480h-720z"></path>
				<circle
					cx="523.1"
					cy="344.1"
					r="194.9"
					fill="#d30000"
					transform="translate(-168.4 8.6) scale(.76554)"
				></circle>
			</g>
		</svg>
	)
}
