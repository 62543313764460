import { put, takeLatest, call, select } from "redux-saga/effects";
import { celsureWoUploadReportApi, celsureWoRetrieveReportApi, celsureWoDeleteReportApi } from "../../services/celsure/index"
import { messages } from "src/utils/hooks";
import { celsureWoDeleteReportFailure, celsureWoDeleteReportRequest, celsureWoDeleteReportSuccess, celsureWoRetrieveReportFailure, celsureWoRetrieveReportRequest, celsureWoRetrieveReportSuccess, celsureWoUploadReportFailure, celsureWoUploadReportRequest, celsureWoUploadReportSuccess } from "../actions/celsureReportPreview"
import { notification } from 'antd'
import { LanguageUnion } from 'src/utils/useTranslate';
import { StoreState } from '../configureStore';
import jwtManager from "src/utils/jwtManager";


function* celsureWoUploadReportSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const params = new FormData()

        payload?.report && params.append("report", payload.report)
        params.append('payload', JSON.stringify(payload.payload))

        const { data: { result } } = yield call(celsureWoUploadReportApi, params, headers)

        if (result.status === "Success") {
            yield put(celsureWoUploadReportSuccess(result))
            if (payload?.report) {
                notification.success({ message: result.message })
                window.open(result.data.download_link, '_blank')
                yield put(celsureWoRetrieveReportRequest({ workOrderId: payload.payload.workOrderId }))
            } else {
                yield put(celsureWoRetrieveReportRequest({ workOrderId: payload.payload.workOrderId }))
            }
        } else {
            yield put(celsureWoUploadReportFailure(result.message))
        }
    } catch (error) {
        yield put(celsureWoUploadReportFailure(messages[ln].internal_server_error))
    }
}

function* celsureWoUploadRetrieveSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const { data: { result } } = yield (call(celsureWoRetrieveReportApi, payload, headers))
        if (result.status === "Success") {
            yield put(celsureWoRetrieveReportSuccess(result.data))
        } else {
            yield put(celsureWoRetrieveReportFailure(result.message))
        }
    } catch (error) {
        yield put(celsureWoRetrieveReportFailure(messages[ln].internal_server_error))

    }
}

function* celsureWoDeleteReportSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const { data: { result } } = yield call(celsureWoDeleteReportApi, payload, headers)
        if (result.status === "Success") {
            yield put(celsureWoDeleteReportSuccess(result.data))
            yield put(celsureWoRetrieveReportRequest({ workOrderId: payload.workOrderId }))
            notification.success({ message: result.message })
        } else {
            yield put(celsureWoDeleteReportFailure(result.message))
        }

    } catch (error) {
        yield put(celsureWoDeleteReportFailure(messages[ln].internal_server_error))

    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(celsureWoUploadReportRequest, celsureWoUploadReportSaga)
    yield takeLatest(celsureWoRetrieveReportRequest, celsureWoUploadRetrieveSaga)
    yield takeLatest(celsureWoDeleteReportRequest, celsureWoDeleteReportSaga)
}