import { handleActions } from "redux-actions";
import { ForgotPasswordActionTypes } from "../actions/forgotPassword";
import { AsyncStates } from "../../constants";


export type ForgotPasswordReducerState = {
    status: AsyncStates;
    error: string;
    message: string;
}

const defaultState: ForgotPasswordReducerState = {
    status: AsyncStates.INITIAL,
    error: "",
    message: ""
}

const forgotPasswordReducer = handleActions({
    [ForgotPasswordActionTypes.REQUEST]: (state) => ({
        ...state,
        status: AsyncStates.LOADING,
        error: "",
    }),
    [ForgotPasswordActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            status: AsyncStates.SUCCESS,
            error: "",
            message: action.payload.message,
        };
    },
    [ForgotPasswordActionTypes.FAILURE]: (state, action) => ({
        ...state,
        status: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [ForgotPasswordActionTypes.CLEAR]: (state) => ({
        ...state,
        status: AsyncStates.INITIAL,
        error: "",
        message: ""
    }),
}, defaultState)

export default forgotPasswordReducer;