import { createActions } from "redux-actions";

export enum DeleteFileActionTypes {
    REQUEST = "DELETE_FILE_REQUEST",
    SUCCESS = "DELETE_FILE_SUCCESS",
    FAILURE = "DELETE_FILE_FAILURE",
}

export enum UploadFileActionTypes {
    REQUEST = "UPLOAD_FILE_REQUEST",
    SUCCESS = "UPLOAD_FILE_SUCCESS",
    FAILURE = "UPLOAD_FILE_FAILURE",
    CLEAR = "UPLOAD_FILE_CLEAR"
}

export enum FileHistoryActionTypes {
    REQUEST = "FILE_HISTORY_REQUEST",
    SUCCESS = "FILE_HISTORY_SUCCESS",
    FAILURE = "FILE_HISTORY_FAILURE",
}


export const {
    deleteFileRequest,
    deleteFileSuccess,
    deleteFileFailure,
    uploadFileRequest,
    uploadFileSuccess,
    uploadFileFailure,
    uploadFileClear,
    fileHistoryRequest,
    fileHistorySuccess,
    fileHistoryFailure,
} = createActions({
    [DeleteFileActionTypes.REQUEST]: (payload) => payload,
    [DeleteFileActionTypes.SUCCESS]: (data: any[]) => data,
    [DeleteFileActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [UploadFileActionTypes.REQUEST]: (payload) => payload,
    [UploadFileActionTypes.SUCCESS]: (data) => data,
    [UploadFileActionTypes.FAILURE]: (message) => ({ error: message }),
    [UploadFileActionTypes.CLEAR]: () => { },
    [FileHistoryActionTypes.REQUEST]: (payload) => payload,
    [FileHistoryActionTypes.SUCCESS]: (payload) => payload,
    [FileHistoryActionTypes.FAILURE]: (payload) => payload,
});
