import { call, put, select, takeLatest } from "redux-saga/effects";
import { fetchAuditLogActionsListApi, fetchAuditLogListApi } from "src/services/auditLog";
import jwtManager from "src/utils/jwtManager";
import {
  changeAuditLogCount,
  fetchAuditLogActionsRequest,
  fetchAuditLogActionsSuccess,
  fetchAuditLogListFailure,
  fetchAuditLogListRequest,
  fetchAuditLogListSuccess,
} from "../actions/auditLog";

function* fetchAuditLogListSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = action.payload;
    const response = yield call(fetchAuditLogListApi, params, headers);
    const {
      data: {
        result: { message, status, data, count },
      },
    } = response;

    if (status === "Success") {
      yield put(fetchAuditLogListSuccess({ data: data }));
      yield put(changeAuditLogCount(count));
    } else {
      yield put(fetchAuditLogListFailure(message));
    }
  } catch (error) {
    yield put(fetchAuditLogListFailure(error));
  }
}

function* fetchAuditLogActionsListSaga(action: any): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const params = action.payload;
        const response = yield call(fetchAuditLogActionsListApi, params, headers);
        const {
            data: {
                result: { message, status, data },
            },
        } = response

        if (status === "Success") {
            yield put(fetchAuditLogActionsSuccess({ data }));
        } else {
            yield put(fetchAuditLogActionsSuccess(message));
        }
    } catch (error) {
        yield put(fetchAuditLogActionsSuccess(error));
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(fetchAuditLogListRequest, fetchAuditLogListSaga);
    yield takeLatest(fetchAuditLogActionsRequest, fetchAuditLogActionsListSaga);
}