import { put, takeLatest, call, select } from "redux-saga/effects";
import { celsureCompareFormulationsFailure, celsureCompareFormulationsRequest, celsureCompareFormulationsSuccess, celsureReportDeleteFailure, celsureReportDeleteRequest, celsureReportDeleteSuccess, celsureReportShareFailure, celsureReportShareRequest, celsureReportShareSuccess, celsureReportsRetrieveFailure, celsureReportsRetrieveRequest, celsureReportsRetrieveSuccess, celsureReportUploadFailure, celsureReportUploadRequest, celsureReportUploadSuccess } from "../actions/celsureCompareFormulation";
import { celsureReportDeletion, celsureReportSharing, celsureReportUpload, celsureRetrieveReports, getCelsureFormulationDetails } from "src/services/celsure";
import { history } from "src";
import { notification } from "antd";
import jwtManager from "src/utils/jwtManager";


function* celsureCompareFormulationsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { data, status },
      },
    } = yield call(getCelsureFormulationDetails, payload, headers);
    if (status === "Success") {
      yield put(celsureCompareFormulationsSuccess(data))
      if (payload?.from === "compare") {
        yield call(history.push, "/formulations/compare")
      }
    } else {
      yield put(celsureCompareFormulationsFailure());
    }
  } catch (error) {
    yield put(celsureCompareFormulationsFailure());
  }
}
function* celsureReportUploadSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const params = new FormData()
    params.append('report', payload.report)
    params.append('metadata', JSON.stringify(payload.metadata))
    const {
      data: {
        result: { message, description, status }
      },
    } = yield call(celsureReportUpload, params, headers);
    if (status === "Success") {
      notification.success({
        message,
        description: description ?? undefined,
      });
      yield put(celsureReportUploadSuccess());
      yield put(celsureReportsRetrieveRequest());
    } else {
      yield put(celsureReportUploadFailure(message));
      notification.error({ message })
    }
  } catch (error) {
    yield put(celsureReportUploadFailure(error));
  }
}

function* celsureReportShareSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { message, status }
      },
    } = yield call(celsureReportSharing, payload, headers);
    if (status === "Success") {
      notification["success"]({ message });
      yield put(celsureReportShareSuccess());
      yield put(celsureReportsRetrieveRequest());
    } else {
      yield put(celsureReportShareFailure(message));
    }
  } catch (error) {
    yield put(celsureReportShareFailure(error));
  }
}

function* celsureReportDeleteSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { message, status }
      },
    } = yield call(celsureReportDeletion, payload, headers);
    if (status === "Success") {
      notification.warning({ message });
      yield put(celsureReportDeleteSuccess());
      yield put(celsureReportsRetrieveRequest());
    } else {
      yield put(celsureReportDeleteFailure(message));
    }
  } catch (error) {
    yield put(celsureReportDeleteFailure(error));
  }
}



function* celsureReportsRetrieveSaga(): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { data, status, message }
      }
    } = yield call(celsureRetrieveReports, null, headers);
    if (status === 'Success') {
      yield put(celsureReportsRetrieveSuccess(data));
    } else {
      yield put(celsureReportsRetrieveFailure(message));
    }
  } catch (error) {
    yield put(celsureReportsRetrieveFailure(error));
  }
}


export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(celsureCompareFormulationsRequest, celsureCompareFormulationsSaga);
  yield takeLatest(celsureReportUploadRequest, celsureReportUploadSaga);
  yield takeLatest(celsureReportShareRequest, celsureReportShareSaga);
  yield takeLatest(celsureReportDeleteRequest, celsureReportDeleteSaga);
  yield takeLatest(celsureReportsRetrieveRequest, celsureReportsRetrieveSaga);
}