import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	fileuploadDetailsSuccess,
	fileuploadDetailsFailure,
	FileUploadDetailsActionTypes,
} from "../actions/development"
import { displayNamesRequest } from "../actions/displayNames"
import { fileUploadApi } from "src/services/development"
import jwtManager from "src/utils/jwtManager"

type fileuploadDetailsAction = {
	type: string
	payload: {
		files: string
	}
}

function* fileuploadDetailsSaga({
	payload: { files },
}: fileuploadDetailsAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			fileR: files,
		}
		const {
			data: {
				result: { status },
			},
		} = yield call(fileUploadApi, params, headers)
		if (status === "Success") {
			yield put(fileuploadDetailsSuccess())
			yield put(displayNamesRequest())
		} else {
			yield put(fileuploadDetailsFailure())
		}
	} catch (error) {
		yield put(fileuploadDetailsFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(FileUploadDetailsActionTypes.REQUEST, fileuploadDetailsSaga)
}
