import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import { TemplatesCreateActionTypes, TemplatesDeleteActionTypes, TemplatesListsActionTypes, TemplatesUpdateActionTypes } from "../actions/templates"

export type TemplatesState = {
    createTemplate: {
        status: AsyncStates
    }
    listTemplate: {
        status: AsyncStates
        data?: any
    }
    editTemplate: {
        status: AsyncStates
    }
    deleteTemplate: {
        status: AsyncStates
    }
}

const defaultState: TemplatesState = {
    createTemplate: {
        status: AsyncStates.INITIAL
    },
    listTemplate: {
        status: AsyncStates.INITIAL,
        data: []
    },
    editTemplate: {
        status: AsyncStates.INITIAL
    },
    deleteTemplate: {
        status: AsyncStates.INITIAL
    },
}

const templatesReducer = handleActions(
    {
        [TemplatesCreateActionTypes.REQUEST]: (state, action) => ({
            ...state,
            createTemplate: {
                status: AsyncStates.LOADING
            }
        }),
        [TemplatesCreateActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            createTemplate: {
                status: AsyncStates.SUCCESS
            }
        }),
        [TemplatesCreateActionTypes.FAILURE]: (state, action) => ({
            ...state,
            createTemplate: {
                status: AsyncStates.ERROR
            }
        }),
        [TemplatesDeleteActionTypes.REQUEST]: (state, action) => ({
            ...state,
            deleteTemplate: {
                status: AsyncStates.LOADING
            }
        }),
        [TemplatesDeleteActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            deleteTemplate: {
                status: AsyncStates.SUCCESS
            }
        }),
        [TemplatesDeleteActionTypes.FAILURE]: (state, action) => ({
            ...state,
            deleteTemplate: {
                status: AsyncStates.ERROR
            }
        }),
        [TemplatesUpdateActionTypes.REQUEST]: (state, action) => ({
            ...state,
            editTemplate: {
                status: AsyncStates.LOADING
            }
        }),
        [TemplatesUpdateActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            editTemplate: {
                status: AsyncStates.SUCCESS
            }
        }),
        [TemplatesUpdateActionTypes.FAILURE]: (state, action) => ({
            ...state,
            editTemplate: {
                status: AsyncStates.ERROR
            }
        }),
        [TemplatesListsActionTypes.REQUEST]: (state, action) => ({
            ...state,
            listTemplate: {
                ...state.listTemplate,
                status: AsyncStates.LOADING
            }
        }),
        [TemplatesListsActionTypes.SUCCESS]: (state, action) => ({
            ...state,
            listTemplate: {
                status: AsyncStates.SUCCESS,
                data: action.payload
            }
        }),
        [TemplatesListsActionTypes.FAILURE]: (state, action) => ({
            ...state,
            listTemplate: {
                data: [],
                status: AsyncStates.ERROR
            }
        }),
    },
    defaultState
);

export default templatesReducer
