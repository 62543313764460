import React, { Suspense } from "react";
import { Link } from "react-router-dom";
// import { DoE } from "src/components/DoE";
import { Result, Skeleton, Space } from "antd";
import { CommentsSection } from "src/components/CommentsSection";
import { SectionLoader } from "./AppRouter";
import { currentPlatform } from "src/App";

export interface RouteObj {
  path: string;
  render: () => JSX.Element;
  exact: boolean;
  protectedRoute: boolean;
  enable: boolean
}

export interface RoutesObj {
  [key: string]: RouteObj
}

const Login = React.lazy(() => import(/* webpackChunkName: "Login" */  'src/components/Login/Login'))
const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "ForgotPassword" */  'src/components/ForgotPassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "ResetPassword" */  'src/components/ResetPassword/ResetPassword'))
const Dashboard = React.lazy(() => import(/* webpackChunkName: "Dashboard" */  'src/components/Dashboard/Dashboard'))
const Formulation = React.lazy(() => import(/* webpackChunkName: "Formulation" */  'src/components/Formulation/Formulation'))
const SignUp = React.lazy(() => import(/* webpackChunkName: "SignUp" */  'src/components/SignUp/SignUp'))
const Settings = React.lazy(() => import(/* webpackChunkName: "Settings" */  'src/components/Settings/Settings'))
const Profile = React.lazy(() => import(/* webpackChunkName: "Profile" */  'src/components/Profile'))
const CompareFormulation = React.lazy(() => import(/* webpackChunkName: "CompareFormulation" */  'src/components/Formulation/Compare'))
const Teams = React.lazy(() => import(/* webpackChunkName: "Teams" */  'src/components/Teams').then(module => ({ default: module.Teams })))
const ConnectTeams = React.lazy(() => import(/* webpackChunkName: "ConnectTeams" */  'src/components/ConnectTeams').then(module => ({ default: module.ConnectTeams })))
const AccessDenied = React.lazy(() => import(/* webpackChunkName: "AccessDenied" */  'src/components/AccessDenied').then(module => ({ default: module.AccessDenied })))
const Error = React.lazy(() => import(/* webpackChunkName: "Error" */  'src/components/Error').then(module => ({ default: module.Error })))
const Feedback = React.lazy(() => import(/* webpackChunkName: "Feedback" */  'src/components/Feedback').then(module => ({ default: module.Feedback })))
const WorkOrderDetails = React.lazy(() => import(/* webpackChunkName: "WorkOrderDetails" */  'src/components/WorkOrderDetails').then(module => ({ default: module.WorkOrderDetails })))
const Notifications = React.lazy(() => import(/* webpackChunkName: "Notifications" */  'src/components/Notifications').then(module => ({ default: module.Notifications })))
const Verify = React.lazy(() => import(/* webpackChunkName: "Verify" */  'src/components/Verify').then(module => ({ default: module.Verify })))
const Projects = React.lazy(() => import(/* webpackChunkName: "Projects" */  'src/components/Projects').then(module => ({ default: module.Projects })))
const Project = React.lazy(() => import(/* webpackChunkName: "Project" */  'src/components/Project').then(module => ({ default: module.Project })))
const WorkOrders = React.lazy(() => import(/* webpackChunkName: "WorkOrders" */  'src/components/WorkOrders').then(module => ({ default: module.WorkOrders })))
const AfterForgotPassword = React.lazy(() => import(/* webpackChunkName: "AfterForgotPassword" */  'src/components/AfterForgotPassword/AfterForgotPassword').then(module => ({ default: module.AfterForgotPassword })))
const AfterResetPassword = React.lazy(() => import(/* webpackChunkName: "AfterResetPassword" */  'src/components/AfterResetPassword/AfterResetPassword').then(module => ({ default: module.AfterResetPassword })))
const Inventory = React.lazy(() => import(/* webpackChunkName: "Inventory" */  'src/components/Inventory').then(module => ({ default: module.InventoryPage })))
const InventoryAddData = React.lazy(() => import(/* webpackChunkName: "Inventory" */  'src/components/Inventory/AddData').then(module => ({ default: module.AddData })))
const NewExperiments = React.lazy(() => import(/* webpackChunkName: "NewExperiments" */  'src/components/DoE/NewExperiment'))
const ExpHistory = React.lazy(() => import(/* webpackChunkName: "ExpHistory" */  'src/components/DoE/ExpHistory').then(module => ({ default: module.ExpHistory })))
const ModelDetails = React.lazy(() => import(/* webpackChunkName: "ModelDetails" */  'src/components/AIEngine/ModelAnalysis/ModelDetails').then(module => ({ default: module.ModelDetails })))
const DataImport = React.lazy(() => import(/* webpackChunkName: "DataMapper" */  'src/components/DataManagement/DataMapper/DataImport').then(module => ({ default: module.DataImport })))
const DataHistory = React.lazy(() => import(/* webpackChunkName: "DataMapper" */  'src/components/DataManagement/DataHistory/DataHistory').then(module => ({ default: module.DataHistory })))
const AuditLog = React.lazy(() => import(/* webpackChunkName: "AuditLog" */  'src/components/AuditLog').then(module => ({ default: module.AuditLog })))
const CreateProject = React.lazy(() => import(/* webpackChunkName: "Project" */  'src/components/Project/CreateProject/CreateProject').then(module => ({ default: module.CreateProject })))
const Templates = React.lazy(() => import(/* webpackChunkName: "Templates" */  'src/components/Templates/Templates').then(module => ({ default: module.Templates })))

const AIEngine = React.lazy(() => import(/* webpackChunkName: "AIEngine" */  'src/components/AIEngine/AIEnginePage').then(module => ({ default: module.AIEnginePage })))
const UserVerified = React.lazy(() => import(/* webpackChunkName: "UserVerified" */  'src/components/UserVerified/UserVerified').then(module => ({ default: module.UserVerified })))


const NoMatch = () => (
  <Result
    style={{ height: "80vh", display: "flex", flexDirection: "column" }}
    status="404"
    title="Work in Progress!"
    subTitle=""
    extra={
      <Link type="primary" to={"/formulations"}>
        Back Home
      </Link>
    }
  />
)

export const getRoutes = (navConfig: any) => {
  return {
    accessDenied: {
      path: "/access-denied",
      render: () => <Suspense fallback={<SectionLoader />}><AccessDenied /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    afterForgotPassword: {
      path: "/after-forgot",
      render: () => <Suspense fallback={<SectionLoader />}><AfterForgotPassword /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    afterResetPassword: {
      path: "/after-reset",
      render: () => <Suspense fallback={<SectionLoader />}><AfterResetPassword /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    verify: {
      path: "/verify",
      render: () => <Suspense fallback={<SectionLoader />}><Verify /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    login: {
      path: "/login",
      render: () => <Suspense fallback={<SectionLoader />}><Login /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    signup: {
      path: "/signup",
      render: () => <Suspense fallback={<SectionLoader />}><SignUp /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    forgotPassword: {
      path: "/forgot_password",
      render: () => <Suspense fallback={<SectionLoader />}><ForgotPassword /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    resetPassword: {
      path: "/reset-password",
      render: () => <Suspense fallback={<SectionLoader />}><ResetPassword /></Suspense>,
      exact: false,
      protectedRoute: false,
      enable: true
    },
    formulations: {
      path: "/formulations",
      render: () => <Suspense fallback={<SectionLoader />}><Formulation /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.formulations
    },
    compareFormulation: {
      path: "/formulations/compare",
      render: () => <Suspense fallback={<SectionLoader />}><CompareFormulation /></Suspense>,
      exact: false,
      protectedRoute: true,
      enable: navConfig.formulations
    },
    dashboard: {
      path: "/dashboard",
      render: () => <Suspense fallback={<SectionLoader />}><Dashboard /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.dashboard
    },
    inventory: {
      path: "/inventory",
      render: () => <Suspense fallback={<SectionLoader />}><Inventory /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory
    },
    inventoryAddData: {
      path: "/inventory/update",
      render: () => <Suspense fallback={<SectionLoader />}><InventoryAddData /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.inventory
    },
    profile: {
      path: "/profile",
      render: () => <Suspense fallback={<SectionLoader />}><Profile /></Suspense>,
      exact: false,
      protectedRoute: true,
      enable: navConfig.profile
    },
    aiEngine: {
      path: "/ai-engine/:aiconfig/:aiEngine",
      render: () => <Suspense fallback={<SectionLoader />}><AIEngine /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.ai_engine?.predict
    },
    doe: {
      path: "/design-of-experiments",
      render: () => <Suspense fallback={<SectionLoader />}><NoMatch /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: false
    },
    workOrders: {
      path: "/work-orders",
      render: () => <Suspense fallback={<SectionLoader />}><WorkOrders /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders
    },
    workOrderDetails: {
      path: "/work-orders/details",
      render: () => (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Suspense fallback={<SectionLoader />}>
            <WorkOrderDetails />
          </Suspense>
          <Suspense fallback={<Skeleton active avatar />}>
            <CommentsSection />
          </Suspense>
        </Space>),
      exact: true,
      protectedRoute: true,
      enable: navConfig.work_orders
    },
    reporting: {
      path: "/reporting",
      render: () => <Suspense fallback={<SectionLoader />}><NoMatch /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: true
    },
    teams: {
      path: "/teams",
      render: () => <Suspense fallback={<SectionLoader />}>{currentPlatform === "connect" ? <ConnectTeams /> : <Teams />}</Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.teams
    },
    notifications: {
      path: "/notifications",
      render: () => <Suspense fallback={<SectionLoader />}><Notifications /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: true
    },
    error: {
      path: "/error",
      render: () => <Suspense fallback={<SectionLoader />}><Error /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    },
    feedback: {
      path: "/feedback",
      render: () => <Suspense fallback={<SectionLoader />}><Feedback /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.feedback
    },
    settings: {
      path: "/settings",
      render: () => <Suspense fallback={<SectionLoader />}><Settings /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.settings
    },
    projects: {
      path: "/projects",
      render: () => <Suspense fallback={<SectionLoader />}><Projects /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects
    },
    project: {
      path: "/projects/details/:id",
      render: () => <Suspense fallback={<SectionLoader />}><Project /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects
    },
    newExperiments: {
      path: "/generate-new-experiments",
      render: () => <Suspense fallback={<SectionLoader />}><NewExperiments /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.generate_new_experiments
    },
    expHistory: {
      path: "/experiment-history",
      render: () => <Suspense fallback={<SectionLoader />}><ExpHistory /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.doe?.experiment_history
    },
    modelDetails: {
      path: "/model-details",
      render: () => <Suspense fallback={<SectionLoader />}><ModelDetails /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.ai_engine?.new_model
    },
    dataMapper: {
      path: "/import_data",
      render: () => <Suspense fallback={<SectionLoader />}><DataImport /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.data_management?.import_data
    },
    dataHistory: {
      path: "/import_history",
      render: () => <Suspense fallback={<SectionLoader />}><DataHistory /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.data_management?.import_history
    },
    auditLog: {
      path: "/audit-log",
      render: () => <Suspense fallback={<SectionLoader />}><AuditLog /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.audit_log
    },
    createProject: {
      path: "/project/update",
      render: () => <Suspense fallback={<SectionLoader />}><CreateProject /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.projects
    },
    templates: {
      path: "/templates",
      render: () => <Suspense fallback={<SectionLoader />}><Templates /></Suspense>,
      exact: true,
      protectedRoute: true,
      enable: navConfig.templates
    },
    verified: {
      path: "/verified",
      render: () => <Suspense fallback={<SectionLoader />}><UserVerified /></Suspense>,
      exact: true,
      protectedRoute: false,
      enable: true
    }
  }
}
