import React, { memo, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./Navbar.scss";
import styles from "./Navbar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { sidebarToggle } from "../../store/actions";
import {
  Menu,
  Layout,
  // Typography,
  Space,
  Button,
  Row,
  Tooltip,
  // Badge,
  Select,
  Divider,
  Col,
  Popover,
  Modal,
  Spin,
  Input,
  Tour,
  TourProps,
  Steps,
  Segmented,
} from "antd";
import {
  BellOutlined,
  // CopyrightOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  ExperimentOutlined,
  HeatMapOutlined,
  ContainerOutlined,
  LoadingOutlined,
  LogoutOutlined,
  NotificationOutlined,
  TeamOutlined,
  UserOutlined,
  FolderOutlined,
  DiffOutlined,
  AreaChartOutlined,
  SettingOutlined,
  // SearchOutlined,
  AuditOutlined,
  PlusOutlined,
  ProfileOutlined,
  RightOutlined,
  LeftOutlined,
  QuestionOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
import { StoreState } from "../../store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { blue, orange } from "@ant-design/colors";
import { setProject, selectAllProjects } from "src/store/actions/projects";
import { AsyncStates, projectType } from "src/constants";
import {
  setEditingProceduresState,
  setEditingState,
} from "src/store/actions/workOrderDetails";
import { logoutRequest, setProjectTypeRequest } from "src/store/actions/login";
import {
  setCelsureEditingTrialsState,
  setCelsureEditingWoState,
} from "src/store/actions/celsure";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  useMatches,
  KBarResults,
  // useKBar,
} from "kbar";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { StyledButton } from "src/styled_components/StyledButton";
import { trackEvent } from "src/analytics";
import ConnectTrialEndsIn from "../Settings/tabs/ConnectBilling/ConnectTrialEndsIn";
import { currentPlatform } from "src/App";
// import { GuideTooltip } from "src/components/product-guide"

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { preferencesUpdateRequest } from "src/store/actions/preferences";
import introImg from "../../assets/svgs/intro.svg";
import outroImg from "../../assets/imgs/video_guides.gif";
import { version } from "../../../package.json";
import { SegmentedValue } from "antd/es/segmented";
import { setIsEditing } from "src/store/actions/isEditing";

dayjs.extend(isSameOrAfter);

// const { Title } = Typography;
const { Content, Sider } = Layout;
const { Option, OptGroup } = Select;

const HeadwayWidget = () => {
  const [t] = useTranslate();

  useEffect(() => {
    (window as any)?.Headway?.init({
      selector: "." + styles[`headway-badge`], // CSS selector where to inject the badge
      account: "7gbPXx", // your account ID, you can find it in Settings->Widget
      position: {
        y: "top",
        x: "right",
      },
      trigger: "." + styles[`headway-badge`],
      translations: {
        title: "Updates",
        footer: "",
      },
    });
  }, []);

  return (window as any).Headway ? (
    <div className={styles[`headway-badge`]}>
      <Text type="warning">{t("navbar.whatsnew")}</Text>
    </div>
  ) : null;
};

function RenderResults() {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div
            className={styles[`search-item`]}
            style={{ background: "#e6f7ff", color: "#1890ff" }}
          >
            {item}
          </div>
        ) : (
          <div
            className={styles[`search-item`]}
            style={{
              background: active ? "#1890ff" : "white",
              color: active ? "white" : "#000",
            }}
          >
            {item.name}
          </div>
        )
      }
    />
  );
}

const NavbarMenu = ({ children }: { children: React.ReactNode }) => {
  const [t] = useTranslate();

  const { features: featuresConfigs, modules: navbarConfigs } = useSelector(
    (state: StoreState) => state.configs
  );

  const {
    loginResponse: { user_name, image_url, user_role, project_type },
    setProjectTypeStatus,
    logoutStatus,
  } = useSelector((state: StoreState) => state.login);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { unread = 0 } = useSelector(
    (state: StoreState) => state.notifications.data
  );
  const collapsed = useSelector((state: StoreState) => state.sidebar.collapsed);
  const [projectModalvisible, setProjectModalvisible] = useState(false);
  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  );
  const projects = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const projectListStatus = useSelector(
    (state: StoreState) => state.projects.projectListStatus
  );
  const { celsureEditingTrialsState, celsureEditingWoState } = useSelector(
    (state: StoreState) => state.celsure
  );
  const [navMenuStatus, setNavMenuStatus] = useState<string[]>();
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );

  const userInfo = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  useEffect(() => {

    const getLocation = () => {
      if (collapsed) return []
      else if (window.location.pathname.includes('experiment-history'))
        return ['doe', 'experiment-history']
      else if (window.location.pathname.includes('generate-new-experiments'))
        return ['doe', 'generate-new-experiments']
      else if (window.location.pathname.includes('import_data'))
        return ['data-management', 'import_data']
      else if (window.location.pathname.includes('import_history'))
        return ['data-management', 'import_history']
      else return window.location.pathname.split("/")
    }

    setNavMenuStatus(getLocation())
  }, [collapsed])

  useEffect(() => {
    if (
      currentPlatform === "connect" &&
      userInfo.user_role === "admin" &&
      (userInfo?.subscription_status === "canceled" ||
        dayjs().isAfter(userInfo.license_period_end))
    ) {
      push("/settings?tab=billing");
    }
  }, [userInfo, push]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "dashboard",
        label: t("navBar.menuItem.dashboard"),
        route: "/dashboard",
        icon: <DashboardOutlined />,
        enable: navbarConfigs.dashboard,
      },
      {
        key: "work-orders",
        label: (
          <div>
            <span>{t("common.workOrders")}</span>
            {/* <GuideTooltip module="work_orders" /> */}
          </div>
        ),
        route: "/work-orders",
        icon: <ContainerOutlined />,
        enable: navbarConfigs.work_orders,
      },
      {
        key: "formulations",
        label: (
          <div>
            <span>{t("navBar.menuItem.formulations")}</span>
            {/* <GuideTooltip module="formulations" /> */}
          </div>
        ),
        route: "/formulations",
        icon: <ExperimentOutlined />,
        enable: navbarConfigs.formulations,
      },
      {
        key: "ai-engine",
        label: t("navBar.menuItem.aiEngine"),
        icon: <HeatMapOutlined />,
        enable: !!Object.keys(navbarConfigs.ai_engine || {}).length,
        ...(project_type === projectType.celsure
          ? { route: "/ai-engine/predict/properties_prediction" }
          : {
            children: [
              {
                key: "predict",
                label: t("aiEngine.predict"),
                onClick: () => {
                  trackEvent(
                    userId,
                    "AI Engine -> Properties prediction was viewed"
                  );
                  push(`/ai-engine/predict/properties_prediction`);
                },
                enable: navbarConfigs.ai_engine?.predict,
              },
              {
                ...(!Boolean(featuresConfigs?.ai_engine_with_methods)
                  ? {
                    key: "new_model",
                    label: t("navBar.menuItem.aiEngine.newModel"),
                    onClick: () => {
                      trackEvent(
                        userId,
                        "AI Engine -> New Model was viewed"
                      );
                      push(`/ai-engine/new_model/custom_ml`);
                    },
                    enable: navbarConfigs.ai_engine?.new_model,
                  }
                  : {}),
              },
              {
                key: "analytics",
                label: t("navBar.menuItem.aiEngine.analytics"),
                onClick: () => {
                  trackEvent(userId, "AI Engine -> Analytics was viewed");
                  push(`/ai-engine/analytics/data_summary`);
                },
                enable: navbarConfigs.ai_engine?.analytics,
              },
            ].filter((values) => JSON.stringify(values) !== "{}"),
          }),
      },
      project_type !== projectType.celsure
        ? {
          key: "doe",
          label: t("navBar.menuItem.DOE"),
          icon: <AreaChartOutlined />,
          children: [
            {
              key: "experiment-history",
              label: t("navBar.menuItem.experimentHistory"),
              onClick: () => {
                trackEvent(userId, "DOE -> Experiment History was viewed");
                push("/experiment-history");
              },
              enable: navbarConfigs.doe?.experiment_history,
            },
            {
              key: "generate-new-experiments",
              label: t("common.generateNewExperiments"),
              onClick: () => {
                trackEvent(
                  userId,
                  "DOE -> Generate new Experiments was viewed"
                );
                push("/generate-new-experiments");
              },
              enable: navbarConfigs.doe?.generate_new_experiments,
            },
          ],
          enable: !!Object.keys(navbarConfigs.doe || {}).length,
        }
        : {},
      {
        type: "divider",
        dashed: true,
        enable: true,
        style: { borderColor: "#A9A9A9", width: "80%", margin: "15px auto" },
      },
      {
        key: "projects",
        label: (
          <div>
            <span>{t("common.allProjects")}</span>
            {/* <GuideTooltip module="projects" /> */}
          </div>
        ),
        route: "/projects",
        icon: <FolderOutlined />,
        enable: navbarConfigs.projects,
      },
      {
        key: "data-management",
        label: (
          <div>
            <span>{t("navBar.menuItem.dataManagement")}</span>
            {/* <GuideTooltip module="data_management_import" /> */}
          </div>
        ),
        icon: <DeploymentUnitOutlined />,
        children: [
          {
            key: "import_data",
            label: t("dataManagement.importData"),
            onClick: () => {
              trackEvent(userId, "Data Management -> Import Data was viewed");
              push("/import_data");
            },
            enable: navbarConfigs.data_management?.import_data,
          },
          {
            key: "import_history",
            label: t("dataManagement.importHistory"),
            onClick: () => {
              trackEvent(
                userId,
                "Data Management -> Import History was viewed"
              );
              push("/import_history");
            },
            enable: navbarConfigs.data_management?.import_history,
          },
        ],
        enable: !!Object.keys(navbarConfigs.data_management || {}).length,
      },
      {
        key: "inventory",
        label: (
          <div>
            <span>{t("common.title.inventory")}</span>
            {/* <GuideTooltip module="inventory" /> */}
          </div>
        ),
        route: "/inventory",
        icon: <DiffOutlined />,
        enable: navbarConfigs.inventory,
      },
      {
        key: "teams",
        label: t("navBar.menuItem.teams"),
        route: "/teams",
        icon: <TeamOutlined />,
        enable: navbarConfigs.teams,
      },
      {
        key: "templates",
        label: (
          <>
            <Space
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => {
                trackEvent(userId, "User viewed templates");
              }}
            >
              {t("common.templateLibrary")}
            </Space>
          </>
        ),
        route: "/templates",
        icon: <ProfileOutlined />,
        enable: navbarConfigs.templates,
      },
      //   {
      //     key: "notifications",
      //     label: (
      //       <>
      //         <Space
      //           size="small"
      //           style={{ cursor: "pointer" }}
      //           onClick={() => {
      //             trackEvent(userId, "User viewed notifications");
      //           }}
      //         >
      //           {!collapsed && t("navBar.notifications")}
      //           <Button
      //             size="small"
      //             shape="round"
      //             style={{ background: blue.primary, color: "white" }}
      //           >
      //             {unread}
      //           </Button>
      //         </Space>
      //       </>
      //     ),
      //     route: "/notifications",
      //     icon: <BellOutlined />,
      //     // enable: navbarConfigs.notifications,
      // enable: true
      //   },
      // {
      //   key: "feedback",
      //   label: t("navBar.menuItem.feedback"),
      //   route: "/feedback",
      //   icon: <NotificationOutlined />,
      //   enable: navbarConfigs.feedback,
      // },
    ].reduce((acc: any[], res: any) => {
      if (res?.enable) {
        if (!!res?.children?.length) {
          const children = res.children.filter((route: any) => {
            if (route.enable) return true;
            return false;
          });
          acc.push({ ...res, children });
        } else if (res.enable) {
          acc.push(res);
        }
      }
      return acc;
    }, []);
  }, [
    t,
    project_type,
    featuresConfigs?.ai_engine_with_methods,
    // collapsed,
    // unread,
    userId,
    navbarConfigs,
    push,
  ]);

  const siderWidth = useMemo(() => (collapsed ? 80 : 250), [collapsed]);
  const projectOptions = useMemo(
    () =>
      [...new Set(projects.map((project) => project.category))].map(
        (category) => (
          <OptGroup label={category} key={category}>
            {projects
              .filter((project) => project.category === category)
              .filter((project) =>
                project?.name.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((project) => (
                <Option
                  value={project.project_id}
                  className="projects-title-menu"
                  style={{ width: "17em" }}
                  key={project.project_id}
                >
                  {project.name}
                </Option>
              ))}
          </OptGroup>
        )
      ),
    [projects, searchText]
  );

  useEffect(() => {
    if (!currentProject && projectListStatus === AsyncStates.SUCCESS) {
      push("/project/update");
    }
  }, [dispatch, currentProject, projectListStatus, push]);

  const logout = () => {
    dispatch(logoutRequest());
  };

  const onCollapse = (collapsed: any) => {
    dispatch(sidebarToggle(collapsed));
  };

  const changeProject = (value: string) => {
    trackEvent(userId, "User changed project", {
      "Project details": {
        "current project": currentProject,
        "new selected project": value,
      },
    });
    if (window.location.pathname === "/work-orders/details") {
      dispatch(setEditingProceduresState(false));
      dispatch(setEditingState(false));
      dispatch(setProject(value));
      push("/work-orders");
    } else {
      dispatch(selectAllProjects(false));
      dispatch(setProject(value));
    }
  };

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)

  useEffect(() => {
    if (document) {
      const projectLabel =
        document.querySelector(".project-selection")?.firstChild;
      if (projectLabel) {
        (projectLabel as HTMLDivElement).dataset.label = t("common.project");
      }
    }
  }, [t, collapsed, popoverVisible]);

  // const { query } = useKBar()
  const [collapseButtonVisible, SetCollapseButtonVisible] = useState(
    !collapsed
  );

  const onboardingState = useSelector(
    (state: StoreState) => state.preferences.data?.onboarding_progress
  );

  const { logo } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );

  const defaultCallbacks = {
    nextButtonProps: {
      onClick: () => {
        setCurrentTourStep((step) => step + 1);
      },
    },
    prevButtonProps: {
      onClick: () => {
        setCurrentTourStep((step) => step - 1);
      },
    },
  };

  const steps: TourProps["steps"] = [
    {
      title: "Welcome to Polymerize Connect!",
      description:
        "Connect is a unified data management platform that aims to make data collection and team collaboration a breeze. It supports seamless data import and entry, with data sheets designed according to your experiment workflow. With in-built data analytics and visualization features, you can now make more data-driven decisions in your research journey.",
      cover: <img alt="Welcome to Polymerize Connect" src={introImg} />,
      style: {
        top: "calc(50% - 325px)",
      },
      ...defaultCallbacks,
    },
    {
      title: "Getting Started",
      description:
        "To start off, you will need to create a project, followed by Work Orders to insert your data. This video briefly introduces you to project creation, followed by Work Order creation within the project. A Work Order functions like a data sheet containing various trials and their experimental data, such as ingredient compositions, processing conditions, and output property results.",
      cover: (
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            title="getting started"
            src="https://player.vimeo.com/video/821181703?h=8002de1373&badge=0&autopause=0&player_id=0&app_id=58479/embed"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder={0}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      ),
      style: {
        height: "100%",
        width: 854,
        top: "calc(50% - 340px)",
      },
      ...defaultCallbacks,
    },
    {
      title: "Video Guides",
      description:
        "Detailed user guide videos explaining the functions within each component are accessible by hovering over the ‘?’ icon right beside each component on the navigation menu on the left. We recommend you watch the videos in this order to get a better understanding of the entire flow of the platform: Projects, Work Orders, Data Management, Formulations, then Inventory. The videos also have chapter timestamps to indicate the start of a new chapter. You can skip to a specific chapter if you wish to understand a function better. Watch the GIF above to understand how you can access specific chapters.",
      cover: <img alt="Video Guides" src={outroImg} />,
      style: {
        width: "70%",
        maxWidth: 854,
        top: "calc(50% - 366px)",
      },
      ...defaultCallbacks,
    },
  ];

  const [open, setOpen] = useState<boolean>(false);

  const startTour = (step: number) => {
    setCurrentTourStep(step);
    setOpen(true);
  };

  const [onboardingProgress, setOnboardingProgress] = useState<number>(0);

  const [currentTourStep, setCurrentTourStep] =
    useState<number>(onboardingState);

  const finishTour = () => {
    setOnboardingProgress(3);
  };

  const resetOnboardingProgress = () => {
    setOnboardingProgress(0);
  };

  useEffect(() => {
    let progress = 0;
    if (currentTourStep < 1) {
      progress = 0;
    } else if (currentTourStep < 2) {
      progress = 1;
    } else if (currentTourStep < 3) {
      progress = 2;
    } else {
      progress = 3;
    }
    setOnboardingProgress(progress);
    if (
      typeof currentTourStep !== "undefined" &&
      currentPlatform === "connect"
    ) {
      dispatch(
        preferencesUpdateRequest({ onboarding_progress: currentTourStep })
      );
    }
  }, [currentTourStep, dispatch]);

  const handlePlussProjectChange = (projectType: SegmentedValue) => {
    if (celsureEditingWoState || celsureEditingTrialsState || isEditing) {
      dispatch(setCelsureEditingTrialsState(false));
      dispatch(setCelsureEditingWoState(false));
      dispatch(setIsEditing(false));
    }
    dispatch(setProjectTypeRequest({ project_type: projectType }));
    if (window.location.pathname.includes("/ai-engine/")) {
      push("/ai-engine/predict/properties_prediction");
    }
  };

  return (
    <Layout style={{ minHeight: "100%" }}>
      {currentPlatform === "connect" && (
        <Tour
          zIndex={1500}
          current={currentTourStep}
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
          onFinish={finishTour}
        />
      )}
      <Sider
        collapsible
        collapsed={collapsed}
        trigger={null}
        theme="light"
        style={{
          height: "100%",
          position: "fixed",
          zIndex: 100,
          scrollBehavior: "smooth",
          left: 0,
        }}
        width={siderWidth}
        className="styled-sider"
        onMouseEnter={() => collapsed && SetCollapseButtonVisible(true)}
        onMouseLeave={() => collapsed && SetCollapseButtonVisible(false)}
      >
        <div style={{ position: "absolute" }}>
          <Button
            type="default"
            shape="circle"
            icon={
              collapsed ? (
                <RightOutlined style={{ fontSize: 10 }} />
              ) : (
                <LeftOutlined style={{ fontSize: 10 }} />
              )
            }
            onClick={() => {
              onCollapse(!collapsed);
            }}
            style={{
              outline: "none",
              fontSize: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 20,
              left: collapsed ? 60 : 230,
              borderColor: collapseButtonVisible ? "#4096ff" : "transparent",
              backgroundColor: collapseButtonVisible ? "#fff" : "transparent",
              color: collapseButtonVisible ? "#4096ff" : "transparent",
              ...(collapseButtonVisible
                ? {}
                : {
                  boxShadow: "none",
                  border: "none",
                }),
            }}
            onMouseEnter={() => collapsed && SetCollapseButtonVisible(true)}
            onMouseLeave={() => collapsed && SetCollapseButtonVisible(false)}
          />
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            scrollBehavior: "smooth",
          }}
        >
          <Row
            justify="start"
            align="bottom"
            gutter={16}
            style={{
              margin: "50px 0 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 6,
            }}
          >
            <a
              target="_self"
              href="/"
              rel="noopener noreferrer"
              style={{ margin: 0 }}
            >
              <Space align="center" style={{ backgroundColor: "rgb(246 250 253)" }}>
                {!collapsed && (
                  <img src="/logo192.png" alt="Polymerize Logo" height={"30"} />
                )}
                {collapsed &&
                  (logo ? (
                    <img
                      src={logo}
                      alt="User Logo"
                      style={{
                        maxWidth: "64px",
                        maxHeight: "30px",
                        width: "auto",
                        height: "auto",
                        mixBlendMode: "multiply",
                        filter: "contrst(1)"
                      }}
                    />
                  ) : (
                    <img
                      src="/logo192.png"
                      alt="Polymerize Logo"
                      height={"30"}
                    />
                  ))}
                {/* {!collapsed && (
                  <Title
                    className="main-sidebar-company-logo"
                    level={4}
                    style={{
                      margin: 0,
                    }}
                    editable={{
                      icon: (
                        <sup>
                          <CopyrightOutlined
                            style={{ fontSize: 7, color: "grey" }}
                          />
                        </sup>
                      ),
                      tooltip: false,
                      editing: false,
                    }}
                  >
                    Polymerize
                  </Title>
                )} */}
              </Space>
            </a>

            {!collapsed && logo && (
              <Divider
                type="vertical"
                style={{
                  height: "40px",
                }}
              />
            )}

            {!collapsed && logo && (
              <div style={{ backgroundColor: "rgb(246 250 253)" }}>
                <img
                  src={logo}
                  alt="User Logo"
                  style={{
                    maxWidth: "128px",
                    maxHeight: "50px",
                    width: "auto",
                    height: "auto",
                    mixBlendMode: "multiply",
                    filter: "contrst(1)"
                  }}
                />
              </div>
            )}

            {/* {!collapsed && (
							<Tooltip
								placement="right"
								overlayInnerStyle={{
									backgroundColor: "white",
									width: "290px",
								}}
								color="white"
								title={
									<p
										style={{
											display: "flex",
											gap: "4px",
											padding: "10px",
											color: "#222",
											margin: 0,
										}}
									>
										Click to start{" "}
										<Link
											onClick={() => {
												sessionStorage.removeItem(
													"intercom.tour_triggered_from_url"
												)
											}}
											href="/formulations?product_tour_id=378766"
											style={{
												color: blue.primary,
												display: "inline-block",
												cursor: "pointer",
											}}
										>
											Labs Introduction Tour
										</Link>
									</p>
								}
							>
								<Link
									onClick={() => {
										sessionStorage.removeItem(
											"intercom.tour_triggered_from_url"
										)
									}}
									href="/formulations?product_tour_id=378766"
									style={{
										background: "#ff8a01",
										cursor: "pointer",
										padding: "2px 5px",
										borderRadius: "5px",
										color: "#fff",
									}}
								>
									Tour
								</Link>
							</Tooltip>
						)} */}
          </Row>

          {Boolean(featuresConfigs?.celsure) && (
            <Row justify="center">
              <Tooltip title={t("navbar.changeProjectType")}>
                <Spin
                  size="small"
                  spinning={setProjectTypeStatus === AsyncStates.LOADING}
                  indicator={<LoadingOutlined />}
                >
                  <Segmented
                    className={project_type + "_segment"}
                    value={project_type}
                    onChange={(value) => {
                      if (
                        celsureEditingWoState ||
                        celsureEditingTrialsState ||
                        isEditing
                      ) {
                        Modal.confirm({
                          title: (
                            <Text style={{ color: orange.primary }} strong>
                              {t("navbar.unsavedChangesPresent")}
                            </Text>
                          ),
                          icon: <InfoCircleFilled />,
                          okText: t("common.confirm"),
                          cancelText: t("common.cancel"),
                          okButtonProps: { style: { borderRadius: "12px" } },
                          cancelButtonProps: {
                            style: { borderRadius: "12px" },
                          },
                          onOk: () => handlePlussProjectChange(value),
                        });
                      } else {
                        handlePlussProjectChange(value);
                      }
                    }}
                    options={[
                      {
                        value: "celsure",
                        label: collapsed ? "C" : "Celsure",
                        title: "Celsure",
                      },
                      {
                        value: "pcm",
                        label: collapsed ? "P" : "PCM",
                        title: "PCM",
                      },
                    ]}
                  />
                </Spin>
              </Tooltip>
            </Row>
          )}

          {!Boolean(featuresConfigs?.project_selection_disabled) && (
            <Row justify="center" style={{ padding: "16px 24px", width: "100%" }}>
              {collapsed ? (
                <Popover
                  onVisibleChange={() => {
                    setPopoverVisible(true)
                  }}
                  style={{ margin: "32px auto" }}
                  overlayStyle={{ width: 250 }}
                  placement="right"
                  content={
                    <Select
                      loading={projectListStatus === AsyncStates.LOADING}
                      style={{ width: "100%" }}
                      className="project-selection"
                      popupMatchSelectWidth={false}
                      size="large"
                      getPopupContainer={(trigger) =>
                        trigger.parentNode as HTMLElement
                      }
                      value={currentProject}
                      onSelect={() => {
                        changeProject(currentProject);
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          <Text strong style={{ paddingLeft: 12 }}>
                            {t("projects.header.title")}
                          </Text>
                          <Divider style={{ margin: 8 }} />
                          <Input
                            placeholder={t("projects.searchProjects")}
                            onChange={(event) =>
                              setSearchText(event.target.value)
                            }
                            style={{ marginInline: "1rem", width: 220 }}
                          />
                          <Divider style={{ margin: 8 }} />
                          {menu}
                          <Divider style={{ margin: 8 }} />
                          <Row justify="center" align="top">
                            <Col>
                              <StyledButton
                                type="primary"
                                onClick={() => push("/project/update")}
                                icon={<PlusOutlined />}
                                size="large"
                              >
                                {t("projects.header.newProject")}
                              </StyledButton>
                            </Col>
                          </Row>
                        </div>
                      )}
                    >
                      {projectOptions}
                    </Select>
                  }
                >
                  <Button
                    type="primary"
                    shape="round"
                    icon={<FolderOutlined />}
                  />
                </Popover>
              ) : (
                <Select
                  loading={projectListStatus === AsyncStates.LOADING}
                  className="project-selection"
                  popupMatchSelectWidth={false}
                  value={currentProject}
                  onSelect={changeProject}
                  getPopupContainer={(trigger) =>
                    trigger.parentNode as HTMLElement
                  }
                  dropdownRender={(menu) => (
                    <div style={{ maxWidth: 250 }}>
                      <Text strong style={{ paddingLeft: 12 }}>
                        {t("projects.header.title")}
                      </Text>
                      <Divider style={{ margin: 8 }} />
                      <Input
                        placeholder={t("projects.searchProjects")}
                        onChange={(event) => setSearchText(event.target.value)}
                        style={{ marginInline: "1rem", width: 220 }}
                      />
                      <Divider style={{ margin: 8 }} />
                      {menu}
                      <Divider style={{ margin: 8 }} />
                      <Row justify="center" align="top">
                        <Col>
                          <StyledButton
                            type="primary"
                            onClick={() => push("/project/update")}
                            icon={<PlusOutlined />}
                            size="large"
                          >
                            {t("projects.header.newProject")}
                          </StyledButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                >
                  {projectOptions}
                </Select>
              )}
            </Row>
          )}
          <Menu
            key="main-menu"
            mode="inline"
            // onClick={handleMenu}
            onOpenChange={(menukeys) => setNavMenuStatus(menukeys)}
            openKeys={navMenuStatus}
            selectedKeys={[
              window.location.pathname.split("/")[
              window.location.pathname.split("/")[1] === "ai-engine" ? 2 : 1
              ],
            ]}
            selectable
            style={{
              borderRight: "1px solid transparent",
              maxHeight: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            items={menuItems.map((item: any) => {
              const route = item.route;
              return {
                ...item,
                onClick: () => {
                  trackEvent(userId, "User viewed " + item.key, {
                    "User Id": userId,
                    "Current Project": currentProject,
                    "User Name": user_name,
                    "User Role": user_role,
                    Company: project_type,
                  });
                  if (route) {
                    push(route);
                  }
                },
              };
            })}
          />
          {currentPlatform === "connect" && (
            <>
              <Row
                justify={"center"}
                gutter={[8, 8]}
                style={{ marginTop: "auto" }}
              >
                {/* <Col>
							<Button type="primary" shape="circle">?</Button>
						</Col> */}
                <Col>
                  <Popover
                    arrow={{ pointAtCenter: true }}
                    placement="rightBottom"
                    content={
                      <>
                        <Row>
                          <Steps
                            direction="vertical"
                            size="small"
                            current={onboardingProgress}
                            items={[
                              {
                                title: (
                                  <Button
                                    type="link"
                                    onClick={() => startTour(0)}
                                  >
                                    Introduction
                                  </Button>
                                ),
                              },
                              {
                                title: (
                                  <Button
                                    disabled={onboardingProgress < 1}
                                    type="link"
                                    onClick={() => startTour(1)}
                                  >
                                    Getting Started
                                  </Button>
                                ),
                              },
                              {
                                title: (
                                  <Button
                                    disabled={onboardingProgress < 2}
                                    type="link"
                                    onClick={() => startTour(2)}
                                  >
                                    Feature Videos
                                  </Button>
                                ),
                              },
                            ]}
                          />
                        </Row>
                        <Row justify={"end"}>
                          <Button
                            size="small"
                            onClick={resetOnboardingProgress}
                            type="link"
                          >
                            Reset
                          </Button>
                        </Row>
                      </>
                    }
                    title="Onboarding Guide"
                    trigger="click"
                  >
                    {collapsed ? (
                      <Tooltip
                        placement="right"
                        title={t("navbar.gettingStarted")}
                      >
                        <Button shape="round">
                          <QuestionOutlined />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button shape="round">
                        {t("navbar.gettingStarted")}
                        <RightOutlined />
                      </Button>
                    )}
                  </Popover>
                </Col>
              </Row>
              <br />
            </>
          )}

          <Menu
            key="settings-menu"
            mode="inline"
            style={
              !collapsed
                ? { marginTop: "auto" }
                : { marginTop: "auto", borderRight: "unset" }
            }
          >
            <HeadwayWidget />
            <Menu.Item
              style={
                !collapsed
                  ? { cursor: "pointer" }
                  : {
                    cursor: "pointer",
                    padding: 0,
                    paddingLeft: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }
              }
              onClick={() => {
                trackEvent(userId, "User viewed notifications");
                push("/notifications");
              }}
              icon={
                collapsed ? (
                  <Space style={{ position: "absolute" }}>
                    <Button
                      size="small"
                      shape="round"
                      style={{ background: blue.primary, color: "white" }}
                    >
                      {unread}
                    </Button>
                  </Space>
                ) : (
                  <BellOutlined />
                )
              }
              key="/notifications"
            >
              <Space size={"small"}>
                {t("navBar.notifications")}
                {!collapsed && (
                  <Button
                    size="small"
                    shape="round"
                    style={{ background: blue.primary, color: "white" }}
                  >
                    {unread}
                  </Button>
                )}
              </Space>
            </Menu.Item>
            <Menu.SubMenu
              icon={
                collapsed ? (
                  <Avatar
                    src={image_url}
                    alt={user_name}
                    style={{ marginLeft: -8 }}
                  />
                ) : (
                  <Space size="middle" align="start">
                    <Avatar src={image_url} alt={user_name} />
                    <Text strong style={{ lineHeight: 3, width: '130px'}} ellipsis={{ tooltip: user_name[0].toUpperCase() + user_name.slice(1) }}>
                      {user_name[0].toUpperCase() + user_name.slice(1)}
                    </Text>
                  </Space>
                )
              }
              popupOffset={[0, 0]}
              style={{ border: "1px solid #f0f0f0" }}
              data-cy="user"
              key="submenuMustDefineThis"
            >
              {user_role === "admin" && (
                <Menu.Item
                  icon={<AuditOutlined />}
                  onClick={() => {
                    trackEvent(userId, "User viewed audit log");
                    push("/audit-log");
                  }}
                  key="/audit-log"
                  style={{ paddingLeft: 24 }}
                >
                  {t("common.auditLog")}
                </Menu.Item>
              )}
              <Menu.Item
                icon={<UserOutlined />}
                onClick={() => {
                  trackEvent(userId, "User viewed profile");
                  push("/profile/my-details");
                }}
                key="/profile/my-details"
                style={{ paddingLeft: 24 }}
              >
                {t("common.profile")}
              </Menu.Item>
              <Menu.Item
                icon={<SettingOutlined />}
                onClick={() => {
                  trackEvent(userId, "User viewed settings");
                  push("/settings");
                }}
                key="/settings"
                style={{ paddingLeft: 24 }}
              >
                {t("navBar.menuItem.settings")}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  push("/feedback");
                }}
                icon={<NotificationOutlined />}
                style={{ paddingLeft: 24 }}
              >
                {t("navBar.menuItem.feedback")}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  trackEvent(userId, "User Logged out");
                  logout();
                }}
                icon={<LogoutOutlined />}
                key="/logout"
                style={{ paddingLeft: 24 }}
              >
                <Button
                  loading={logoutStatus === AsyncStates.LOADING}
                  type="link"
                  style={{ paddingLeft: 0, color: "inherit" }}
                >
                  {t("navBar.menuItem.logout")}
                </Button>
              </Menu.Item>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "center", padding: 2 }}
              >
                <Text
                  type="secondary"
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  v{version}
                  {!collapsed && <br />}
                  {`${!collapsed
                    ? `${t(
                      "navbar.footer.rightsReserved"
                    )} © ${new Date().getFullYear()}`
                    : ""
                    }`}
                </Text>
              </Space>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Sider>

      <Layout
        style={{
          backgroundColor: "#f3f8fc",
          backgroundImage:
            "radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%)",
          backgroundBlendMode: "screen, overlay",
        }}
      >
        <Content
          style={{
            marginLeft: siderWidth,
            padding: "12px 32px 32px 32px",
            paddingBottom: 0,
          }}
        >
          {currentPlatform === "connect" && <ConnectTrialEndsIn />}
          <div>{children}</div>
        </Content>
        {/* <Footer
          style={{
            marginLeft: siderWidth,
            textAlign: "center",
            color: grey[3],
            padding: 8,
            background: "none",
          }}
        >
          {t("navbar.footer.rightsReserved")} © {new Date().getFullYear()}
        </Footer> */}
      </Layout>

      <Modal
        okText={t("common.ok")}
        cancelText={t("common.cancel")}
        title={t("common.selectProjectType")}
        visible={projectModalvisible}
        footer={null}
        onCancel={() => setProjectModalvisible(false)}
      >
        <Spin
          size="large"
          spinning={setProjectTypeStatus === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Space direction="vertical" style={{ width: "100%", height: 100 }}>
            {(celsureEditingWoState || celsureEditingTrialsState) && (
              <Text style={{ color: orange.primary }} strong>
                {t("navbar.unsavedChangesPresent")}
              </Text>
            )}
            <div style={{ display: "flex", width: "100%" }}>
              {["PCM", "Celsure"].map((tag) => (
                <CheckableTag
                  style={{
                    flex: 1,
                    textAlign: "center",
                    fontSize: "20px",
                    height: 60,
                    lineHeight: "60px",
                  }}
                  key={tag}
                  checked={project_type === tag.toLowerCase()}
                  onChange={() => {
                    if (celsureEditingWoState || celsureEditingTrialsState) {
                      dispatch(setCelsureEditingTrialsState(false));
                      dispatch(setCelsureEditingWoState(false));
                    }
                    dispatch(
                      setProjectTypeRequest({ project_type: tag.toLowerCase() })
                    );
                    if (window.location.pathname.includes("/ai-engine/")) {
                      push("/ai-engine/predict/properties_prediction");
                    }
                  }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
          </Space>
        </Spin>
      </Modal>
    </Layout>
  );
};

const _Navbar = ({ children }: { children: React.ReactNode }) => {
  const [t] = useTranslate();

  const dispatch = useDispatch();

  const { push } = useHistory();

  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  );
  const projectListStatus = useSelector(
    (state: StoreState) => state.projects.projectListStatus
  );

  const { modules: navbarConfigs } = useSelector(
    (state: StoreState) => state.configs
  );

  const {
    loginResponse: { project_type },
  } = useSelector((state: StoreState) => state.login);

  useEffect(() => {
    if (!currentProject && projectListStatus === AsyncStates.SUCCESS) {
      push("/project/update");
    }
  }, [dispatch, currentProject, projectListStatus, push]);

  const actions: any = useMemo(
    () =>
      [
        {
          id: "dashboard",
          name: t("navBar.menuItem.dashboard"),
          shortcut: ["d"],
          keywords: "dashboard",
          perform: () => push("/dashboard"),
          enable: navbarConfigs.dashboard,
        },
        {
          id: "projects",
          name: t("projects.header.title"),
          shortcut: ["p"],
          keywords: "projects",
          perform: () => push("/projects"),
          enable: navbarConfigs.projects,
        },
        {
          id: "work_orders",
          name: t("common.workOrders"),
          shortcut: ["w"],
          keywords: "Work Orders",
          perform: () => push("/work-orders"),
          enable: navbarConfigs.work_orders,
        },
        {
          id: "formulations",
          name: t("navBar.menuItem.formulations"),
          shortcut: ["f"],
          keywords: "formulations",
          perform: () => push("/formulations"),
          enable: navbarConfigs.formulations,
        },
        {
          id: "experiment_history",
          name: t("navBar.menuItem.experimentHistory"),
          shortcut: ["e"],
          keywords: "experiment history",
          perform: () => push("/experiment-history"),
          section: t("navBar.menuItem.DOE"),
          enable: navbarConfigs.doe?.experiment_history,
        },
        {
          id: "generate_new_experiments",
          name: t("common.generateNewExperiments"),
          shortcut: ["g"],
          keywords: "generate new experiments",
          perform: () => push("/generate-new-experiments"),
          section: t("navBar.menuItem.DOE"),
          enable: navbarConfigs.doe?.generate_new_experiments,
        },
        project_type === projectType.celsure
          ? {
            id: "ai_engine",
            name: t("navBar.menuItem.aiEngine"),
            shortcut: ["ai"],
            keywords: "ai engine",
            perform: () => push("/ai-engine/predict/properties_prediction"),
            enable: !!Object.keys(navbarConfigs.ai_engine || {}).length,
          }
          : {},
        {
          id: "predict",
          name: t("aiEngine.predict"),
          shortcut: ["ai"],
          keywords: "predict",
          perform: () => push("/ai-engine/predict/properties_prediction"),
          enable:
            navbarConfigs.ai_engine?.predict &&
            project_type !== projectType.celsure,
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "new_model",
          name: t("navBar.menuItem.aiEngine.newModel"),
          shortcut: ["m"],
          keywords: "New Model",
          perform: () => push("/ai-engine/new_model/custom_ml"),
          enable:
            navbarConfigs.ai_engine?.new_model &&
            project_type !== projectType.celsure,
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "analytics",
          name: t("navBar.menuItem.aiEngine.analytics"),
          shortcut: ["a"],
          keywords: "Analytics",
          perform: () => push("/ai-engine/analytics/data_summary"),
          enable:
            navbarConfigs.ai_engine?.analytics &&
            project_type !== projectType.celsure,
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "inventory",
          name: t("common.title.inventory"),
          shortcut: ["i"],
          keywords: "inventory",
          perform: () => push("/inventory"),
          enable: navbarConfigs.inventory,
        },
        {
          id: "import_data",
          name: t("common.import"),
          shortcut: ["u"],
          keywords: "import data",
          perform: () => push("/import_data"),
          section: t("navBar.menuItem.dataManagement"),
          enable: navbarConfigs.data_management?.import_data,
        },
        {
          id: "import_history",
          name: t("dataManagement.importHistory"),
          shortcut: ["h"],
          keywords: "import history",
          perform: () => push("/import_history"),
          section: t("navBar.menuItem.dataManagement"),
          enable: navbarConfigs.data_management?.import_history,
        },
        {
          id: "teams",
          name: t("navBar.menuItem.teams"),
          shortcut: ["t"],
          keywords: "teams",
          perform: () => push("/teams"),
          enable: navbarConfigs.teams,
        },
        {
          id: "feedback",
          name: t("navBar.menuItem.feedback"),
          shortcut: ["fe"],
          keywords: "feedback",
          perform: () => push("/feedback"),
          enable: navbarConfigs.feedback,
        },
      ].reduce((acc: any[], res: any) => {
        if (res?.enable) {
          acc.push(res);
        }
        return acc;
      }, []),
    [push, t, navbarConfigs, project_type]
  );

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner className={styles[`kbar`]}>
          <KBarAnimator className={styles["content"]}>
            <KBarSearch
              className={styles[`search-bar`]}
              placeholder={t("kbar.placeholder.search")}
            />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      <NavbarMenu>{children}</NavbarMenu>
    </KBarProvider>
  );
};

export default memo(_Navbar);
