import * as Amplitude from '@amplitude/analytics-browser'
import { memoize } from 'lodash'

const initialize = memoize((userId) => {
    const key = process.env.REACT_APP_AMPLITUDE_API_KEY
    if(!key) return console.log('Amplitude key unavailaible')

    Amplitude.init(key, userId)
})

export const trackEvent = (userId: string, name: string, data?: Record<string, any>) => {
    if (process.env.REACT_APP_CURRENT_APP === 'connect') return

    initialize(userId)

    Amplitude.track(name, data)
}

export const setUserForTracking = (id: string, name?: string) => {
    if (process.env.REACT_APP_CURRENT_APP === 'connect') return

    initialize(id)

    Amplitude.setUserId(id)

    const identify = new Amplitude.Identify()
    name && identify.set("name", {name})
    name && Amplitude.identify(identify)
}

/* 
Notes: Where is this tracking being used:

1. Login -> with email, google and microsoft
2. Signup -> with email, google and microsoft
3. LeftSide Navbar, when user clicks on item on the navbar.
4. When user changes the project from the navbar.
5. When clicking on project details.
6. When clicking on work order details.
7. When user changes the stage of the Work Order.
8. When user changes tabs(eg data summary) in AI Engine.
9. When user creates a new or duplicate work order.
*/