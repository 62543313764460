import axios from 'axios'
import { Headers } from "../interface"
import { sendPost, sendPostML } from "src/utils/sendApi"


export const getModelInfoApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_model_info',
    params,
    {
      headers
    }
  )
}


export const requestInverseApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'request_inverse',
    params,
    {
      headers
    }
  )
}

export const modelInfoChangeApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'model_info_change',
    params,
    {
      headers
    }
  )
}

export const createModelCommentApi = sendPost("v1/data/_model_comment_create")

export const modelCommentsListApi = sendPost("v1/data/_model_comment_list")

export const deleteModelCommentApi = sendPost("v1/data/_model_comment_delete")

export const editModelCommentApi = sendPost("v1/data/_model_comment_edit")

export const getCustomModelDataApi = sendPostML("get_custom_model_data")
