import { handleActions } from "redux-actions";
import { ProcedureActionTypes } from "../actions/procedures";
import { AsyncStates } from "../../constants";


export type ProcedureReducerState = {
    saveProcedureStatus: AsyncStates
    retrieveProcedureStatus: AsyncStates
    data: any[],
    error: string
    message: string
}

const defaultState: ProcedureReducerState = {
    saveProcedureStatus: AsyncStates.INITIAL,
    retrieveProcedureStatus: AsyncStates.INITIAL,
    data: [],
    error: '',
    message: ''
}

const proceduresReducer = handleActions({
    [ProcedureActionTypes.CREATE_PROCEDURE_REQUEST]: (state) => ({
        ...state,
        saveProcedureStatus: AsyncStates.LOADING,
    }),
    [ProcedureActionTypes.CREATE_PROCEDURE_SUCCESS]: (state, action) => ({
        ...state,
        saveProcedureStatus: AsyncStates.SUCCESS,
        message: action.payload.message
    }),
    [ProcedureActionTypes.CREATE_PROCEDURE_FAILURE]: (state, action) => ({
        ...state,
        saveProcedureStatus: AsyncStates.ERROR,
        error: action.payload.error
    }),
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_REQUEST]: (state) => ({
        ...state,
        retrieveProcedureStatus: AsyncStates.LOADING,
        data: []
    }),
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_SUCCESS]: (state, action) => ({
        ...state,
        retrieveProcedureStatus: AsyncStates.SUCCESS,
        data: action.payload?.data ?? []
    }),
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_FAILURE]: (state, action) => ({
        ...state,
        retrieveProcedureStatus: AsyncStates.ERROR,
        data: [],
        error: action.payload.error
    }),
    [ProcedureActionTypes.CLEAR_PROCEDURE]: () => ({
        saveProcedureStatus: AsyncStates.INITIAL,
        retrieveProcedureStatus: AsyncStates.INITIAL,
        data: [],
        error: '',
        message: ''
    })
}, defaultState)


export default proceduresReducer