import { put, takeLatest, call, select } from "redux-saga/effects";
import { saveCelsureDataApi, celsureDisplayNamesApi, celsuredeleteTrialsApi, celsureEditTrialApi, celsurefilterTrialsApi, celsureSaveIstaProfilesApi, celsureDeleteIstaProfilesApi, celsureInventoryApi } from "../../services/celsure"
import { message, notification } from "antd"
import {
  saveCelsureDataFailure, saveCelsureDataRequest, saveCelsureDataSuccess,
  getCelsureTrialsFailure, getCelsureTrialsRequest, getCelsureTrialsSuccess,
  celsureDisplayNamesFailure, celsureDisplayNamesRequest, celsureDisplayNamesSuccess,
  celsureDeleteTrialsFailure, celsureDeleteTrialsRequest, celsureDeleteTrialsSuccess,
  celsureEditTrialFailure, celsureEditTrialRequest, celsureEditTrialSuccess,
  saveIstaProfilesFailure, saveIstaProfilesRequest, saveIstaProfilesSuccess,
  deleteIstaProfilesFailure, deleteIstaProfilesRequest, deleteIstaProfilesSuccess,
  celsureInventoryFailure, celsureInventoryRequest, celsureInventorySuccess
} from "../../store/actions/celsure"
import { celsureWoTrialsRequest, celsureGetPlotsRequest, celsureFileHistoryRequest } from "../actions/celsureWo";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from 'src/utils/useTranslate';
import { StoreState } from '../configureStore';
import jwtManager from "src/utils/jwtManager";


function* saveCelsureDataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(saveCelsureDataApi, payload, headers)
    if (status === "Success") {
      yield put(saveCelsureDataSuccess())
      notification.success({ message, placement: "topRight" })
      yield put(celsureWoTrialsRequest({ work_order_id: payload.work_order_id }))
      yield put(celsureDisplayNamesRequest())
    } else {
      yield put(saveCelsureDataFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(saveCelsureDataFailure())
  }
}

function* getCelsureTrialsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { reset } = payload
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data, message } } } = yield call(celsurefilterTrialsApi, payload, headers)
    if (status === "Success") {
      yield put(getCelsureTrialsSuccess({ ...data, reset }))
    } else {
      yield put(getCelsureTrialsFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(getCelsureTrialsFailure())
  }
}


function* celsureDisplayNamesSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, data } } } = yield call(celsureDisplayNamesApi, payload, headers)
    if (status === "Success") {
      yield put(celsureDisplayNamesSuccess(data))
    } else {
      yield put(celsureDisplayNamesFailure())
    }
  } catch (error) {
    yield put(celsureDisplayNamesFailure())
  }
}

function* celsureDeleteTrialsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { celsureWo } = yield select(state => state.celsureWo)
    const { data: { result: { status, message } } } = yield call(celsuredeleteTrialsApi, payload, headers)
    if (status === "Success") {
      yield put(celsureDeleteTrialsSuccess())
      notification.error({ message })
      if (celsureWo?.work_order_id) {
        yield put(celsureWoTrialsRequest({ work_order_id: celsureWo?.work_order_id }))
        if (celsureWo?.work_order_stage === "work_order_status") {
          yield put(celsureGetPlotsRequest({ work_order_id: celsureWo?.work_order_id }))
          yield put(celsureFileHistoryRequest({ work_order_id: celsureWo?.work_order_id }))
        }
      }
    } else {
      yield put(celsureDeleteTrialsFailure())
    }
  } catch (error) {
    yield put(celsureDisplayNamesFailure())
  }
}


function* celsureEditTrialSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { celsureWo } = yield select(state => state.celsureWo)
    const { data: { result: { status, message } } } = yield call(celsureEditTrialApi, payload, headers);
    if (status === "Success") {
      yield put(celsureEditTrialSuccess())
      notification.success({ message })
      yield put(celsureDisplayNamesRequest())
      if (celsureWo?.work_order_id) {
        yield put(celsureWoTrialsRequest({ work_order_id: celsureWo?.work_order_id }))
      }
    } else {
      yield put(celsureEditTrialFailure())
      notification.error({ message: message })
    }
  } catch (error) {
    yield put(celsureEditTrialFailure())
  }
}


function* saveIstaProfilesSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(celsureSaveIstaProfilesApi, payload, headers)
    if (status === "Success") {
      yield put(saveIstaProfilesSuccess())
      notification.success({ message })
      yield put(celsureDisplayNamesRequest())
    } else {
      yield put(saveIstaProfilesFailure())
      notification.error({ message })
    }
  } catch (error) {
    yield put(saveIstaProfilesFailure())
  }
}


function* deleteIstaProfilesSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )

  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(celsureDeleteIstaProfilesApi, payload, headers);
    if (status === "Success") {
      yield put(deleteIstaProfilesSuccess())
      notification.success({ message })
      yield put(celsureDisplayNamesRequest())
    } else {
      yield put(deleteIstaProfilesFailure())
      notification.error({ message })
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error)
    yield put(deleteIstaProfilesFailure())
  }
}


function* celsureInventorysSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { data: { result: { status, message } } } = yield call(celsureInventoryApi, payload, headers);
    if (status === "Success") {
      yield put(celsureInventorySuccess())
      notification.success({ message })
      yield put(celsureDisplayNamesRequest())
    } else {
      yield put(celsureInventoryFailure())
      notification.error({ message })
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error)
    yield put(celsureInventoryFailure())
  }
}


export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(saveCelsureDataRequest, saveCelsureDataSaga)
  yield takeLatest(getCelsureTrialsRequest, getCelsureTrialsSaga)
  yield takeLatest(celsureDisplayNamesRequest, celsureDisplayNamesSaga)
  yield takeLatest(celsureDeleteTrialsRequest, celsureDeleteTrialsSaga)
  yield takeLatest(celsureEditTrialRequest, celsureEditTrialSaga)
  yield takeLatest(saveIstaProfilesRequest, saveIstaProfilesSaga)
  yield takeLatest(deleteIstaProfilesRequest, deleteIstaProfilesSaga)
  yield takeLatest(celsureInventoryRequest, celsureInventorysSaga)
}