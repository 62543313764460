import { createActions } from "redux-actions";

export enum ResetWorkOrderActionTypes {
    RESET = "RESET_WORK_ORDER",
}

export enum IncludeExpActionTypes {
    INCLUDE_SUGGESTED_EXP = "INCLUDE_SUGGESTED_EXP",
    INCLUDE_PREDICTION_EXP = "INCLUDE_PREDICTION_EXP",
    SET_PREDICTION_ID = 'SET_PREDICTION_ID'
}

export enum IncludeDoeExpActionTypes {
    INCLUDE_DOE_EXP = "INCLUDE_DOE_EXP"
}

export enum CreateWorkOrderActionTypes {
    REQUEST = "CREATE_WORK_ORDER_REQUEST",
    SUCCESS = "CREATE_WORK_ORDER_SUCCESS",
    FAILURE = "CREATE_WORK_ORDER_FAILURE",
}

export enum EditWorkOrderActionTypes {
    REQUEST = "EDIT_WORK_ORDER_REQUEST",
    SUCCESS = "EDIT_WORK_ORDER_SUCCESS",
    FAILURE = "EDIT_WORK_ORDER_FAILURE",
    CLEAR = "EDIT_WORK_ORDER_CLEAR",
}

export enum DeleteWorkOrderActionTypes {
    REQUEST = "DELETE_WORK_ORDER_REQUEST",
    SUCCESS = "DELETE_WORK_ORDER_SUCCESS",
    FAILURE = "DELETE_WORK_ORDER_FAILURE",
}

export enum WorkOrderActionTypes {
    REQUEST = "WORK_ORDER_REQUEST",
    SUCCESS = "WORK_ORDER_SUCCESS",
    FAILURE = "WORK_ORDER_FAILURE",
}

export enum ReferenceFormulationsActionTypes {
    REQUEST = "REFERENCE_FORMULATIONS_REQUEST",
    SUCCESS = "REFERENCE_FORMULATIONS_SUCCESS",
    FAILURE = "REFERENCE_FORMULATIONS_FAILURE",
}

export enum FormulationActionTypes {
    REQUEST = "FORMULATION_REQUEST",
    SUCCESS = "FORMULATION_SUCCESS",
    FAILURE = "FORMULATION_FAILURE",
}

export enum EditExperimentActionTypes {
    REQUEST = "EDIT_EXPERIMENT_REQUEST",
    SUCCESS = "EDIT_EXPERIMENT_SUCCESS",
    FAILURE = "EDIT_EXPERIMENT_FAILURE",
    CLEANUP = "EDIT_EXPERIMENT_CLEANUP"
}

export enum AutoSaveExperimentActionTypes {
    REQUEST = "AUTO_SAVE_EXPERIMENT_REQUEST",
    SUCCESS = "AUTO_SAVE_EXPERIMENT_SUCCESS",
    FAILURE = "AUTO_SAVE_EXPERIMENT_FAILURE",
    CLEANUP = "AUTO_SAVE_EXPERIMENT_CLEANUP"
}

export enum ArchiveFormulationActionTypes {
    REQUEST = "ARCHIVE_FORMULATION_REQUEST",
    SUCCESS = "ARCHIVE_FORMULATION_SUCCESS",
    FAILURE = "ARCHIVE_FORMULATION_FAILURE",
}

export enum DataExportTypes {
    REQUEST = "DATA_EXPORT_REQUEST",
    SUCCESS = "DATA_EXPORT_SUCCESS",
    FAILURE = "DATA_EXPORT_FAILURE",
    CLEAR = "DATA_EXPORT_CLEAR",
}

export enum EditDisplayIdActionTypes {
    REQUEST = "EDIT_DISPLAY_ID_REQUEST",
    SUCCESS = "EDIT_DISPLAY_ID_SUCCESS",
    FAILURE = "EDIT_DISPLAY_ID_FAILURE",
    RESET = "EDIT_DISPLAY_ID_RESET",
}

export enum EditingStateActionTypes {
    SET_EDITING_STATE = "SET_EDITING_STATE",
    SET_EDITING_PROCEDURES_STATE = "SET_EDITING_PROCEDURES_STATE"
}

export enum UploadGraphsDataActionTypes {
    REQUEST = "UPLOAD_GRAPHS_DATA_REQUEST",
    SUCCESS = "UPLOAD_GRAPHS_DATA_SUCCESS",
    FAILURE = "UPLOAD_GRAPHS_DATA_FAILURE",
}

export enum PlotsDataActionTypes {
    REQUEST = "PLOTS_DATA_REQUEST",
    SUCCESS = "PLOTS_DATA_SUCCESS",
    FAILURE = "PLOTS_DATA_FAILURE",
}

export enum GraphsDataHistoryActionTypes {
    REQUEST = "GRAPHS_DATA_HISTORY_REQUEST",
    SUCCESS = "GRAPHS_DATA_HISTORY_SUCCESS",
    FAILURE = "GRAPHS_DATA_HISTORY_FAILURE",
}

export enum WorkOrderStatusChangeActionTypes {
    REQUEST = "WO_STATUS_CHANGE_REQUEST",
    SUCCESS = "WO_STATUS_CHANGE_SUCCESS",
    FAILURE = "WO_STATUS_CHANGE_FAILURE",
}

export enum DuplicateWoActionTypes {
    SET = "SET_DUPLICATE_WO"
}

export enum FetchSetDetailsActionTypes {
    REQUEST = "FETCH_SET_DETAILS_REQUEST",
    SUCCESS = "FETCH_SET_DETAILS_SUCCESS",
    FAILURE = "FETCH_SET_DETAILS_FAILURE",
}

export enum FetchFileListActionTypes {
    REQUEST = "FETCH_FILE_LIST_REQUEST",
    SUCCESS = "FETCH_FILE_LIST_SUCCESS",
    FAILURE = "FETCH_FILE_LIST_FAILURE",
}

export enum UploadFileAttachmentActionTypes {
    REQUEST = "UPLOAD_FILE_ATTACHMENT_REQUEST",
    SUCCESS = "UPLOAD_FILE_ATTACHMENT_SUCCESS",
    FAILURE = "UPLOAD_FILE_ATTACHMENT_FAILURE",
}

export enum DeleteFileAttachmentActionTypes {
    REQUEST = "DELETE_FILE_ATTACHMENT_REQUEST",
    SUCCESS = "DELETE_FILE_ATTACHMENT_SUCCESS",
    FAILURE = "DELETE_FILE_ATTACHMENT_FAILURE",
}

export enum StageExistsActionTypes {
    REQUEST = "STAGE_EXISTS_REQUEST",
    SUCCESS = "STAGE_EXISTS_SUCCESS",
    FAILURE = "STAGE_EXISTS_FAILURE",
    CLEANUP = "STAGE_EXISTS_CLEANUP",
}

export enum SaveProcessingProfilesActionTypes {
    REQUEST = "SAVE_PROCESSING_PROFILES_REQUEST",
    SUCCESS = "SAVE_PROCESSING_PROFILES_SUCCESS",
    FAILURE = "SAVE_PROCESSING_PROFILES_FAILURE",
}

export enum DeleteProcessingProfilesActionTypes {
    REQUEST = "DELETE_PROCESSING_PROFILES_REQUEST",
    SUCCESS = "DELETE_PROCESSING_PROFILES_SUCCESS",
    FAILURE = "DELETE_PROCESSING_PROFILES_FAILURE",
}

export enum ListProcessingProfilesActionTypes {
    REQUEST = "LIST_PROCESSING_PROFILES_REQUEST",
    SUCCESS = "LIST_PROCESSING_PROFILES_SUCCESS",
    FAILURE = "LIST_PROCESSING_PROFILES_FAILURE",
}
export enum DataSanityActionTypes {
    REQUEST = "DATA_SANITY_REQUEST",
    SUCCESS = "DATA_SANITY_SUCCESS",
    FAILURE = "DATA_SANITY_FAILURE",
}
export enum DataSanityDataActionTypes {
    REQUEST = "DATA_SANITY_DATA_REQUEST",
    SUCCESS = "DATA_SANITY_DATA_SUCCESS",
    FAILURE = "DATA_SANITY_DATA_FAILURE",
}


export const {
    includeSuggestedExp,
    includeDoeExp,
    includePredictionExp,
    setPredictionId,
    createWorkOrderRequest,
    createWorkOrderSuccess,
    createWorkOrderFailure,
    editWorkOrderRequest,
    editWorkOrderSuccess,
    editWorkOrderFailure,
    editWorkOrderClear,
    deleteWorkOrderRequest,
    deleteWorkOrderSuccess,
    deleteWorkOrderFailure,
    workOrderRequest,
    workOrderSuccess,
    workOrderFailure,
    referenceFormulationsRequest,
    referenceFormulationsSuccess,
    referenceFormulationsFailure,
    formulationRequest,
    formulationSuccess,
    formulationFailure,
    editExperimentCleanup,
    editExperimentRequest,
    editExperimentSuccess,
    editExperimentFailure,
    archiveFormulationRequest,
    archiveFormulationSuccess,
    archiveFormulationFailure,
    resetWorkOrder,
    dataExportRequest,
    dataExportSuccess,
    dataExportFailure,
    dataExportClear,
    editDisplayIdRequest,
    editDisplayIdSuccess,
    editDisplayIdFailure,
    editDisplayIdReset,
    setEditingState,
    setEditingProceduresState,
    uploadGraphsDataRequest,
    uploadGraphsDataSuccess,
    uploadGraphsDataFailure,
    plotsDataRequest,
    plotsDataSuccess,
    plotsDataFailure,
    graphsDataHistoryRequest,
    graphsDataHistorySuccess,
    graphsDataHistoryFailure,
    woStatusChangeRequest,
    woStatusChangeSuccess,
    woStatusChangeFailure,
    setDuplicateWo,
    fetchSetDetailsRequest,
    fetchSetDetailsSuccess,
    fetchSetDetailsFailure,
    fetchFileListRequest,
    fetchFileListSuccess,
    fetchFileListFailure,
    uploadFileAttachmentRequest,
    uploadFileAttachmentSuccess,
    uploadFileAttachmentFailure,
    deleteFileAttachmentRequest,
    deleteFileAttachmentSuccess,
    deleteFileAttachmentFailure,
    stageExistsRequest,
    stageExistsSuccess,
    stageExistsFailure,
    stageExistsCleanup,
    saveProcessingProfilesRequest,
    saveProcessingProfilesSuccess,
    saveProcessingProfilesFailure,
    deleteProcessingProfilesRequest,
    deleteProcessingProfilesSuccess,
    deleteProcessingProfilesFailure,
    listProcessingProfilesRequest,
    listProcessingProfilesSuccess,
    listProcessingProfilesFailure,
    autoSaveExperimentCleanup,
    autoSaveExperimentRequest,
    autoSaveExperimentSuccess,
    autoSaveExperimentFailure,
    dataSanityRequest,
    dataSanitySuccess,
    dataSanityFailure,
    dataSanityDataRequest,
    dataSanityDataSuccess,
    dataSanityDataFailure,
} = createActions({
    [IncludeExpActionTypes.INCLUDE_SUGGESTED_EXP]: payload => payload,
    [IncludeDoeExpActionTypes.INCLUDE_DOE_EXP]: payload => payload,
    [IncludeExpActionTypes.INCLUDE_PREDICTION_EXP]: payload => payload,
    [IncludeExpActionTypes.SET_PREDICTION_ID]: payload => payload,
    [CreateWorkOrderActionTypes.REQUEST]: payload => payload,
    [CreateWorkOrderActionTypes.SUCCESS]: payload => payload,
    [CreateWorkOrderActionTypes.FAILURE]: payload => payload,
    [EditWorkOrderActionTypes.REQUEST]: payload => payload,
    [EditWorkOrderActionTypes.SUCCESS]: payload => payload,
    [EditWorkOrderActionTypes.FAILURE]: payload => payload,
    [EditWorkOrderActionTypes.CLEAR]: () => { },
    [DeleteWorkOrderActionTypes.REQUEST]: payload => payload,
    [DeleteWorkOrderActionTypes.SUCCESS]: payload => payload,
    [DeleteWorkOrderActionTypes.FAILURE]: payload => payload,
    [WorkOrderActionTypes.REQUEST]: payload => payload,
    [WorkOrderActionTypes.SUCCESS]: payload => payload,
    [WorkOrderActionTypes.FAILURE]: payload => payload,
    [ReferenceFormulationsActionTypes.REQUEST]: payload => payload,
    [ReferenceFormulationsActionTypes.SUCCESS]: payload => payload,
    [ReferenceFormulationsActionTypes.FAILURE]: payload => payload,
    [FormulationActionTypes.REQUEST]: payload => payload,
    [FormulationActionTypes.SUCCESS]: payload => payload,
    [FormulationActionTypes.FAILURE]: payload => payload,
    [EditExperimentActionTypes.CLEANUP]: () => { },
    [EditExperimentActionTypes.REQUEST]: payload => payload,
    [EditExperimentActionTypes.SUCCESS]: payload => payload,
    [EditExperimentActionTypes.FAILURE]: payload => payload,
    [ArchiveFormulationActionTypes.REQUEST]: payload => payload,
    [ArchiveFormulationActionTypes.SUCCESS]: payload => payload,
    [ArchiveFormulationActionTypes.FAILURE]: payload => payload,
    [DataExportTypes.REQUEST]: payload => payload,
    [DataExportTypes.SUCCESS]: payload => payload,
    [DataExportTypes.FAILURE]: error => ({ error }),
    [DataExportTypes.CLEAR]: () => ({}),
    [EditDisplayIdActionTypes.REQUEST]: payload => payload,
    [EditDisplayIdActionTypes.SUCCESS]: payload => payload,
    [EditDisplayIdActionTypes.FAILURE]: payload => payload,
    [EditDisplayIdActionTypes.RESET]: payload => payload,
    [EditingStateActionTypes.SET_EDITING_STATE]: payload => payload,
    [EditingStateActionTypes.SET_EDITING_PROCEDURES_STATE]: payload => payload,
    [UploadGraphsDataActionTypes.REQUEST]: payload => payload,
    [UploadGraphsDataActionTypes.SUCCESS]: payload => payload,
    [UploadGraphsDataActionTypes.FAILURE]: error => ({ error }),
    [PlotsDataActionTypes.REQUEST]: payload => payload,
    [PlotsDataActionTypes.SUCCESS]: payload => payload,
    [PlotsDataActionTypes.FAILURE]: error => ({ error }),
    [GraphsDataHistoryActionTypes.REQUEST]: payload => payload,
    [GraphsDataHistoryActionTypes.SUCCESS]: payload => payload,
    [GraphsDataHistoryActionTypes.FAILURE]: error => ({ error }),
    [WorkOrderStatusChangeActionTypes.REQUEST]: payload => payload,
    [WorkOrderStatusChangeActionTypes.SUCCESS]: payload => payload,
    [WorkOrderStatusChangeActionTypes.FAILURE]: error => ({ error }),
    [DuplicateWoActionTypes.SET]: payload => payload,
    [FetchSetDetailsActionTypes.REQUEST]: (payload) => payload,
    [FetchSetDetailsActionTypes.SUCCESS]: (payload) => payload,
    [FetchSetDetailsActionTypes.FAILURE]: (payload) => payload,
    [FetchFileListActionTypes.REQUEST]: (payload) => payload,
    [FetchFileListActionTypes.SUCCESS]: (payload) => payload,
    [FetchFileListActionTypes.FAILURE]: (payload) => payload,
    [UploadFileAttachmentActionTypes.REQUEST]: (payload) => payload,
    [UploadFileAttachmentActionTypes.SUCCESS]: (payload) => payload,
    [UploadFileAttachmentActionTypes.FAILURE]: (payload) => payload,
    [DeleteFileAttachmentActionTypes.REQUEST]: (payload) => payload,
    [DeleteFileAttachmentActionTypes.SUCCESS]: (payload) => payload,
    [DeleteFileAttachmentActionTypes.FAILURE]: (payload) => payload,
    [StageExistsActionTypes.REQUEST]: (payload) => payload,
    [StageExistsActionTypes.SUCCESS]: (payload) => payload,
    [StageExistsActionTypes.FAILURE]: (payload) => payload,
    [StageExistsActionTypes.CLEANUP]: (payload) => payload,
    [SaveProcessingProfilesActionTypes.REQUEST]: payload => payload,
    [SaveProcessingProfilesActionTypes.SUCCESS]: response => response,
    [SaveProcessingProfilesActionTypes.FAILURE]: () => { },
    [DeleteProcessingProfilesActionTypes.REQUEST]: payload => payload,
    [DeleteProcessingProfilesActionTypes.SUCCESS]: response => response,
    [DeleteProcessingProfilesActionTypes.FAILURE]: () => { },
    [ListProcessingProfilesActionTypes.REQUEST]: payload => payload,
    [ListProcessingProfilesActionTypes.SUCCESS]: response => response,
    [ListProcessingProfilesActionTypes.FAILURE]: () => { },
    [AutoSaveExperimentActionTypes.CLEANUP]: () => { },
    [AutoSaveExperimentActionTypes.REQUEST]: payload => payload,
    [AutoSaveExperimentActionTypes.SUCCESS]: payload => payload,
    [AutoSaveExperimentActionTypes.FAILURE]: payload => payload,
    [DataSanityActionTypes.REQUEST]: payload => payload,
    [DataSanityActionTypes.SUCCESS]: payload => payload,
    [DataSanityActionTypes.FAILURE]: payload => payload,
    [DataSanityDataActionTypes.REQUEST]: payload => payload,
    [DataSanityDataActionTypes.SUCCESS]: payload => payload,
    [DataSanityDataActionTypes.FAILURE]: payload => payload,
},
    ResetWorkOrderActionTypes.RESET
);
