import { createActions } from "redux-actions"
import {
	UpdatePasswordParams,
	UpdatePasswordResponse,
} from "src/services/passwordReset"

export enum UpdatePasswordActionTypes {
	REQUEST = "UPDATE_PASSWORD_REQUEST",
	SUCCESS = "UPDATE_PASSWORD_SUCCESS",
	FAILURE = "UPDATE_PASSWORD_FAILURE",
	CLEAR = "UPDATE_PASSWORD_CLEAR",
}

export const {
	updatePasswordRequest,
	updatePasswordSuccess,
	updatePasswordFailure,
	updatePasswordClear,
} = createActions({
	[UpdatePasswordActionTypes.REQUEST]: (payload: UpdatePasswordParams) =>
		payload,
	[UpdatePasswordActionTypes.SUCCESS]: (response: UpdatePasswordResponse) =>
		response,
	[UpdatePasswordActionTypes.FAILURE]: (error: string) => ({ error }),
	[UpdatePasswordActionTypes.CLEAR]: () => {},
})
