import { sendPost } from "src/utils/sendApi"


export const createTemplateApi = sendPost("v1/data/_create_templates")

export const deleteTemplateApi = sendPost("v1/data/_delete_templates")

export const editTemplateApi = sendPost("v1/data/_edit_templates")

export const listTemplateApi = sendPost("v1/data/_list_templates")
