import { createActions } from "redux-actions";

export enum TeamsActionTypes {
    REQUEST = "TEAMS_REQUEST",
    SUCCESS = "TEAMS_SUCCESS",
    FAILURE = "TEAMS_FAILURE",
}
export enum UpdateUserRolesActionTypes {
    REQUEST = "UPDATE_USER_ROLES_REQUEST",
    SUCCESS = "UPDATE_USER_ROLES_SUCCESS",
    FAILURE = "UPDATE_USER_ROLES_FAILURE",
}

export enum UpdateUserAccessActionTypes {
    REQUEST = "UPDATE_USER_ACCESS_REQUEST",
    SUCCESS = "UPDATE_USER_ACCESS_SUCCESS",
    FAILURE = "UPDATE_USER_ACCESS_FAILURE",
}

export enum RevokeUserAccessActionTypes {
    REQUEST = "REVOKE_USER_ACCESS_REQUEST",
    SUCCESS = "REVOKE_USER_ACCESS_SUCCESS",
    FAILURE = "REVOKE_USER_ACCESS_FAILURE",
}

export enum ReactivateAccountActionTypes {
    REQUEST = "REACTIVATE_ACCOUNT_REQUEST",
    SUCCESS = "REACTIVATE_ACCOUNT_SUCCESS",
    FAILURE = "REACTIVATE_ACCOUNT_FAILURE",
}

export enum ApproveInvitationActionTypes {
    REQUEST = "APPROVE_INVITATION_REQUEST",
    SUCCESS = "APPROVE_INVITATION_SUCCESS",
    FAILURE = "APPROVE_INVITATION_FAILURE",
}


export const {
    teamsRequest,
    teamsSuccess,
    teamsFailure,
    updateUserRolesRequest,
    updateUserRolesSuccess,
    updateUserRolesFailure,
    updateUserAccessRequest,
    updateUserAccessSuccess,
    updateUserAccessFailure,
    revokeUserAccessRequest,
    revokeUserAccessSuccess,
    revokeUserAccessFailure,
    reactivateAccountRequest,
    reactivateAccountSuccess,
    reactivateAccountFailure,
    approveInvitationRequest,
    approveInvitationSuccess,
    approveInvitationFailure,
} = createActions({
    [TeamsActionTypes.REQUEST]: (payload) => payload,
    [TeamsActionTypes.SUCCESS]: (data: any[]) => data,
    [TeamsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [UpdateUserRolesActionTypes.REQUEST]: (payload) => payload,
    [UpdateUserRolesActionTypes.SUCCESS]: (data: any[]) => data,
    [UpdateUserRolesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [UpdateUserAccessActionTypes.REQUEST]: (payload) => payload,
    [UpdateUserAccessActionTypes.SUCCESS]: (data: any[]) => data,
    [UpdateUserAccessActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [RevokeUserAccessActionTypes.REQUEST]: (payload) => payload,
    [RevokeUserAccessActionTypes.SUCCESS]: () => { },
    [RevokeUserAccessActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ReactivateAccountActionTypes.REQUEST]: (payload) => payload,
    [ReactivateAccountActionTypes.SUCCESS]: () => { },
    [ReactivateAccountActionTypes.FAILURE]: (message) => ({ error: message }),
    [ApproveInvitationActionTypes.REQUEST]: (payload) => payload,
    [ApproveInvitationActionTypes.SUCCESS]: () => { },
    [ApproveInvitationActionTypes.FAILURE]: (message) => ({ error: message }),
});
