/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppSubscriptionVariables = {
    company_id: string;
};
export type AppSubscriptionResponse = {
    readonly displayNames: {
        readonly type: string | null;
        readonly data: ReadonlyArray<{
            readonly name: string | null;
            readonly unit: string | null;
            readonly category: string | null;
            readonly identifier: string | null;
            readonly property_type: string | null;
        } | null> | null;
    } | null;
};
export type AppSubscription = {
    readonly response: AppSubscriptionResponse;
    readonly variables: AppSubscriptionVariables;
};



/*
subscription AppSubscription(
  $company_id: String!
) {
  displayNames(company_id: $company_id) {
    type
    data {
      name
      unit
      category
      identifier
      property_type
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "company_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "company_id",
        "variableName": "company_id"
      }
    ],
    "concreteType": "DisplayNamesMessage",
    "kind": "LinkedField",
    "name": "displayNames",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DisplayNamesData",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "property_type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppSubscription",
    "selections": (v1/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4b85f47bbed0f5d2407c2138d891eee8",
    "id": null,
    "metadata": {},
    "name": "AppSubscription",
    "operationKind": "subscription",
    "text": "subscription AppSubscription(\n  $company_id: String!\n) {\n  displayNames(company_id: $company_id) {\n    type\n    data {\n      name\n      unit\n      category\n      identifier\n      property_type\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'afeb4df1a430179afec9dd148d8c872d';
export default node;
