import { handleActions } from "redux-actions"
import { GetSavedFiltersActionTypes } from "src/store/actions/saveFormulationsFilters"
import { AsyncStates } from "src/constants"
import { GetSavedFiltersResponse } from "../sagas/saveFormulationsFilters"

export type GetSavedFiltersState = {
	status: AsyncStates
	data: GetSavedFiltersResponse["result"]["data"] | null
	error: string
}

const defaultState: GetSavedFiltersState = {
	status: AsyncStates.INITIAL,
	error: "",
	data: null,
}

const savedFormulationsFiltersReducer = handleActions(
	{
		[GetSavedFiltersActionTypes.REQUEST]: (state) => ({
			...state,
			status: AsyncStates.LOADING,
			error: "",
		}),
		[GetSavedFiltersActionTypes.SUCCESS]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			data: action.payload.data,
		}),
		[GetSavedFiltersActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
			data: null,
		}),
		[GetSavedFiltersActionTypes.CLEANUP]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
			data: state.data,
		}),
		[GetSavedFiltersActionTypes.ADD]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			data: [...(action.payload.data ?? []), ...(state.data ?? [])],
		}),
		[GetSavedFiltersActionTypes.REMOVE]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			data: (state.data ?? []).filter(
				(item) =>
					!((action.payload.data as unknown) as string[]).includes(
						item.filter_id
					)
			),
		}),
	},
	defaultState
)

export default savedFormulationsFiltersReducer
