import { handleActions } from "redux-actions";
import { FormulationsFilterActionTypes } from "../actions/formulationsFilter";
import { AsyncStates } from "../../constants";
import { FormulationsFilterData } from "../../services/formulation/interface";

export type FormulationsFilterState = {
  status: AsyncStates;
  error: string;
  formulationsFilterData: FormulationsFilterData;
  reset?: boolean
};

const defaultState: FormulationsFilterState = {
  status: AsyncStates.INITIAL,
  error: "",
  formulationsFilterData: { total_records: 0 },
};

const formulationsFilterReducer = handleActions(
  {
    [FormulationsFilterActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [FormulationsFilterActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        formulationsFilterData: {
          previous_id: action.payload.formulationsFilterData.previous_id,
          total_records: action.payload.formulationsFilterData.total_records,
          formulation_data: action.payload.reset ?
            [...(action.payload.formulationsFilterData.formulation_data || [])] :
            [
              ...(state.formulationsFilterData.formulation_data || []),
              ...(action.payload.formulationsFilterData.formulation_data || [])
            ]
        },
      };
    },
    [FormulationsFilterActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
      formulationsFilterData: {
        ...state.formulationsFilterData,
        previous_id: null
      }
    }),
    [FormulationsFilterActionTypes.CLEANUP]: (state) => ({
      ...state,
      status: AsyncStates.INITIAL,
      error: "",
      formulationsFilterData: { total_records: 0 },
    }),
  },
  defaultState
);

export default formulationsFilterReducer;
