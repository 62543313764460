import { put, takeLatest, call, select } from "redux-saga/effects";
import { configsRequest, configsSuccess, configsFailure } from "../actions/configs";
import { configsApi } from "src/services/common/master";
import jwtManager from "src/utils/jwtManager";



function* configsSaga(): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const { data: { result: { data, status } } } = yield call(configsApi, null, headers)
        if (status === "Success") {
            yield put(configsSuccess(data))
        } else {
            yield put(configsFailure())
        }
    } catch (error) {
        yield put(configsFailure())
    }
}


export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(configsRequest, configsSaga)
}