import { message } from "antd";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getPerformanceApi } from "src/services/performance";
import { messages } from "src/utils/hooks";
import jwtManager from "src/utils/jwtManager";
import { LanguageUnion } from "src/utils/useTranslate";
import {
  getPerformanceFailure,
  getPerformanceRequest,
  getPerformanceSuccess,
} from "../actions/performance";
import { StoreState } from "../configureStore";

function* getPerformanceSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current,
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(getPerformanceApi, payload, headers);
    if (result.model_performance_result.status === "Success") {
      yield put(getPerformanceSuccess(result.model_performance_result));
    } else {
      yield put(getPerformanceFailure(result.message));
    }
  } catch (error) {
    yield put(getPerformanceFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(getPerformanceRequest, getPerformanceSaga);
}
