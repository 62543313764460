import { DisplayName } from "../services/common/interface"

type All = Map<string, string>

interface Keys {
    [key: string]: {
        name: string,
        data: []
    }
}

type StructuredData = All & Keys

export const displayNames = (displayNames: DisplayName[]): StructuredData => {

    const structuredData = displayNames
        .reduce((acc: any, { key, identifier, name }: DisplayName) =>
            acc[key] ?
                key === identifier ?
                    acc
                    :
                    { ...acc, [key]: { ...acc[key], data: { ...acc[key].data, [identifier]: name } } }
                :
                key === identifier ?
                    { ...acc, [key]: { name, data: {} } }
                    :
                    { ...acc, [key]: { data: { [identifier]: name } } }
            , {}
        )

    return {
        ...structuredData,
        all: new Map(displayNames.map(({ identifier, name }) => [identifier, name]))
    }
}