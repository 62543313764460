import { sendPost } from "../../utils/sendApi"

export const createEditProfileApi = sendPost("v1/data/nestle/save_profile")

export const listProfileApi = sendPost("v1/data/nestle/list_profiles")

export const DeleteProfileApi = sendPost("v1/data/nestle/delete_profile")

export const NestleFileUploadApi = sendPost("v1/data/nestle/_file_upload")

