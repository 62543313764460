import axios from 'axios'
import { Headers } from "../interface"

export const filterDataApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'filter_data',
    params,
    {
      headers
    }
  )
}

export const getCustomTraingsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_custom_training_logs',
    params,
    {
      headers
    }
  )
}

export const customDataTrainApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'custom_data_train_new',
    params,
    {
      headers
    }
  )
}

export const deleteCustomModelsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'delete_custom_models',
    params,
    {
      headers
    }
  )
}