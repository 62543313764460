import { appReducer } from "../configureStore";
import { subscriptionsClient } from "../RelayEnvironment";
import { socket, socketChannel } from "../sagas/newInverseModel";
import jwtManager from "src/utils/jwtManager";
import { signOut } from "firebase/auth";
import { auth } from "src/utils/firebase";


const rootReducer = (state: any, action: any) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        (window as any)?.Intercom("shutdown")
        signOut(auth)
        localStorage.removeItem('persist:root')
        socket?.close()
        socketChannel?.close()
        subscriptionsClient.dispose()
        jwtManager.deleteToken()
        return appReducer(undefined, action)
    }
    if (action.type.includes('REQUEST')) {
        return appReducer({ ...state, previousAction: [action, ...state.previousAction] }, action)
    }
    return appReducer(state, action)
}

export { rootReducer }