import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	suggestedExperimentsRequest,
	suggestedExperimentsSuccess,
	suggestedExperimentsFailure,
	exportPredictionRequest,
	exportPredictionFailure,
	exportPredictionSuccess,
	suggestedExperimentsFilterSuccess,
} from "src/store/actions/suggestedExp"
import { GetExperimentsIdApi, GetZeonSuggestedExperimentsIdApi, predictionDataExportApi } from "src/services/suggestedExp/suggestedExp"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore"
import { message, notification } from "antd"
import jwtManager from "src/utils/jwtManager"


function* suggestedExperimentsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	const configs = yield select((state: StoreState) => state.configs.features)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(Boolean(configs?.ai_engine_with_methods) ? GetZeonSuggestedExperimentsIdApi : GetExperimentsIdApi, payload, headers)
		if (result.status === "Success") {
			if (!payload?.filters?.length) {
				// For Maintining Filter State in Inverse Pred
				yield put(suggestedExperimentsFilterSuccess(result.data.experiments))
			}
			yield put(
				suggestedExperimentsSuccess({
					experiments: result.data.experiments,
					total: result.total,
					versions: result?.versions,
				})
			)
		} else {
			yield put(suggestedExperimentsFailure(result.message))
		}
	} catch (error) {
		yield put(suggestedExperimentsFailure(messages[ln].internal_server_error))
	}
}

function* predictionDataExportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			predictionDataExportApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(exportPredictionSuccess(data))
			window.open(data?.download_link, "_blank")
		} else {
			notification.error({
				message
			})
			yield put(exportPredictionFailure())
		}
	} catch (error: any) {
		yield put(exportPredictionFailure())
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(suggestedExperimentsRequest, suggestedExperimentsSaga)
	yield takeLatest(exportPredictionRequest, predictionDataExportSaga)
}
