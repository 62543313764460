import { put, takeLatest, call, select } from "redux-saga/effects";
import { fetchdashboardDataApi, fetchNewsApi } from "src/services/dashboard";
import { fetchDashboardDataFailure, fetchDashboardDataRequest, fetchDashboardDataSuccess, fetchNewsFailure, fetchNewsRequest, fetchNewsSuccess } from "../actions/dashboard";
import { message } from 'antd';
import jwtManager from "src/utils/jwtManager";


function* fetchDashboardDataSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const params = action.payload;

    const response = yield call(fetchdashboardDataApi, params, headers);
    const {
      data: {
        result: { message: responseMessage, status, data },
      },
    } = response

    if (status === "Success") {
      yield put(fetchDashboardDataSuccess(data));
    } else {
      message.error(responseMessage)
      yield put(fetchDashboardDataFailure(responseMessage));

    }
  } catch (error: any) {
    yield put(fetchDashboardDataFailure(error?.response?.data?.detail));
  }
}

function* newsDataSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const params = action.payload;
    const response = yield call(fetchNewsApi, params, headers);

    const {
      data: {
        result: { message: responseMessage, status, data },
      },
    } = response;

    if (status === "Success") {
      yield put(fetchNewsSuccess(data));
    } else {
      yield put(fetchNewsFailure(responseMessage));
    }
  } catch (error: any) {
    yield put(fetchNewsFailure(error?.response?.data?.detail));
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(fetchDashboardDataRequest, fetchDashboardDataSaga);
  yield takeLatest(fetchNewsRequest, newsDataSaga);
}