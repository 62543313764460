import { createActions } from "redux-actions"


export enum GenrateNewExperimentsActionTypes {
  REQUEST = "GENERATE_EXPERIMENT_REQUEST",
  SUCCESS = "GENERATE_EXPERIMENT_SUCCESS",
  FAILURE = "GENERATE_EXPERIMENT_FAILURE"
}

export enum ListAlgorithmsActionTypes {
  REQUEST = "LIST_ALGORITHMS_REQUEST",
  SUCCESS = "LIST_ALGORITHMS_SUCCESS",
  FAILURE = "LIST_ALGORITHMS_FAILURE"
}

export enum UploadDoeExperimentsActionTypes {
  REQUEST = "UPLOAD_DOE_REQUEST",
  SUCCESS = "UPLOAD_DOE_SUCCESS",
  FAILURE = "UPLOAD_DOE_FAILURE",
  CLEANUP = "UPLOAD_DOE_CLEAR"
}

export enum DoeHistoryListActionTypes {
  REQUEST = "DOE_HISTORY_LIST_REQUEST",
  SUCCESS = "DOE_HISTORY_LIST_SUCCESS",
  FAILURE = "DOE_HISTORY_LIST_FAILURE"
}

export enum GetDoeExperimentsActionTypes {
  REQUEST = "GET_DOE_EXPERIMENTS_REQUEST",
  SUCCESS = "GET_DOE_EXPERIMENTS_SUCCESS",
  FAILURE = "GET_DOE_EXPERIMENTS_FAILURE"
}

export enum DeleteDoeActionTypes {
  REQUEST = "DELETE_DOE_REQUEST",
  SUCCESS = "DELETE_DOE_SUCCESS",
  FAILURE = "DELETE_DOE_FAILURE",
  CLEANUP = "DELETE_DOE_CLEANUP"
}

export enum EditDoeActionTypes {
  REQUEST = "EDIT_DOE_REQUEST",
  SUCCESS = "EDIT_DOE_SUCCESS",
  FAILURE = "EDIT_DOE_FAILURE"
}


export const {
  generateExperimentRequest,
  generateExperimentSuccess,
  generateExperimentFailure,
  listAlgorithmsRequest,
  listAlgorithmsSuccess,
  listAlgorithmsFailure,
  uploadDoeRequest,
  uploadDoeSuccess,
  uploadDoeFailure,
  uploadDoeClear,
  doeHistoryListRequest,
  doeHistoryListSuccess,
  doeHistoryListFailure,
  getDoeExperimentsRequest,
  getDoeExperimentsSuccess,
  getDoeExperimentsFailure,
  deleteDoeCleanup,
  deleteDoeRequest,
  deleteDoeSuccess,
  deleteDoeFailure,
  editDoeRequest,
  editDoeSuccess,
  editDoeFailure
} = createActions({
  [GenrateNewExperimentsActionTypes.REQUEST]: payload => payload,
  [GenrateNewExperimentsActionTypes.SUCCESS]: resposne => resposne,
  [GenrateNewExperimentsActionTypes.FAILURE]: () => { },
  [ListAlgorithmsActionTypes.REQUEST]: payload => payload,
  [ListAlgorithmsActionTypes.SUCCESS]: resposne => resposne,
  [ListAlgorithmsActionTypes.FAILURE]: () => { },
  [UploadDoeExperimentsActionTypes.REQUEST]: payload => payload,
  [UploadDoeExperimentsActionTypes.SUCCESS]: resposne => resposne,
  [UploadDoeExperimentsActionTypes.FAILURE]: () => { },
  [UploadDoeExperimentsActionTypes.CLEANUP]: () => { },
  [DoeHistoryListActionTypes.REQUEST]: payload => payload,
  [DoeHistoryListActionTypes.SUCCESS]: resposne => resposne,
  [DoeHistoryListActionTypes.FAILURE]: () => { },
  [GetDoeExperimentsActionTypes.REQUEST]: payload => payload,
  [GetDoeExperimentsActionTypes.SUCCESS]: resposne => resposne,
  [GetDoeExperimentsActionTypes.FAILURE]: () => { },
  [DeleteDoeActionTypes.CLEANUP]: () => { },
  [DeleteDoeActionTypes.REQUEST]: payload => payload,
  [DeleteDoeActionTypes.SUCCESS]: resposne => resposne,
  [DeleteDoeActionTypes.FAILURE]: () => { },
  [EditDoeActionTypes.REQUEST]: payload => payload,
  [EditDoeActionTypes.SUCCESS]: resposne => resposne,
  [EditDoeActionTypes.FAILURE]: () => { },
})
