import { handleActions } from "redux-actions";
import { CompareFormulationsActionTypes, LinkedCompareFormulationsActionTypes, ReportDeleteActionTypes, ReportShareActionTypes, ReportsRetrieveActionTypes, ReportUploadActionTypes } from "../actions/compareFormulations";
import { AsyncStates } from "../../constants";
export type CompareFormulationState = {
  status: AsyncStates;
  retrieveStatus: AsyncStates;
  uploadStatus: AsyncStates;
  shareStatus: AsyncStates;
  deleteStatus: AsyncStates;
  error: string;
  retrieveError: string;
  uploadError: string;
  shareError: string;
  deleteError: string;
  formulationDetailsData: any[];
  linkedFormulationDetailsData:any[],
  linkedError:string,
  linkedStatus:AsyncStates,
  reports: any[]
};
const defaultState: CompareFormulationState = {
  status: AsyncStates.INITIAL,
  retrieveStatus: AsyncStates.INITIAL,
  uploadStatus: AsyncStates.INITIAL,
  shareStatus: AsyncStates.INITIAL,
  deleteStatus: AsyncStates.INITIAL,
  error: "",
  retrieveError: "",
  uploadError: "",
  shareError: "",
  deleteError: "",
  formulationDetailsData: [],
  linkedFormulationDetailsData:[],
  linkedError:"",
  linkedStatus:AsyncStates.INITIAL,
  reports: []
};

const compareFormulationsReducer = handleActions<CompareFormulationState, any>(
  {
    [CompareFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [CompareFormulationsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        formulationDetailsData: action.payload.formulationDetailsData,
      };
    },
    [CompareFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [LinkedCompareFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      linkedStatus: AsyncStates.LOADING,
      linkedError: "",
    }),
    [LinkedCompareFormulationsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        linkedStatus: AsyncStates.SUCCESS,
        linkedError: "",
        linkedFormulationDetailsData: action.payload.formulationDetailsData,
      };
    },
    [LinkedCompareFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      linkedStatus: AsyncStates.ERROR,
      linkedError: action.payload.error,
    }),
    [ReportUploadActionTypes.REQUEST]: (state) => ({
      ...state,
      uploadStatus: AsyncStates.LOADING,
      uploadError: "",
    }),
    [ReportUploadActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        uploadStatus: AsyncStates.SUCCESS,
        uploadError: "",
      };
    },
    [ReportUploadActionTypes.FAILURE]: (state, action) => ({
      ...state,
      uploadStatus: AsyncStates.ERROR,
      uploadError: action.payload,
    }),
    [ReportShareActionTypes.REQUEST]: (state) => ({
      ...state,
      shareStatus: AsyncStates.LOADING,
      shareError: "",
    }),
    [ReportUploadActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        shareStatus: AsyncStates.SUCCESS,
        shareError: "",
      };
    },
    [ReportShareActionTypes.FAILURE]: (state, action) => ({
      ...state,
      shareStatus: AsyncStates.ERROR,
      shareError: action.payload,
    }),
    [ReportDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteStatus: AsyncStates.LOADING,
      deleteError: "",
    }),
    [ReportDeleteActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        deleteStatus: AsyncStates.SUCCESS,
        deleteError: "",
      };
    },
    [ReportDeleteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteStatus: AsyncStates.ERROR,
      deleteError: action.payload,
    }),
    [ReportsRetrieveActionTypes.REQUEST]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.LOADING,
      retrieveError: "",
    }),
    [ReportsRetrieveActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        retrieveStatus: AsyncStates.SUCCESS,
        retrieveError: "",
        reports: action.payload.reports.reverse(),
      };
    },
    [ReportsRetrieveActionTypes.FAILURE]: (state, action) => ({
      ...state,
      retrieveStatus: AsyncStates.ERROR,
      retrieveError: action.payload.error,
    }),
    [CompareFormulationsActionTypes.CLEANUP]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.INITIAL,
      retrieveError: "",
      formulationDetailsData: [],
    }),
  },
  defaultState
);

export default compareFormulationsReducer;
