import { createActions } from "redux-actions"
import { SuggestedExpResponse } from "../../services/suggestedExp/interface"

export enum SuggestedExpActionTypes {
	SUGGESTED_EXPERIMENTS_REQUEST = "SUGGESTED_EXPERIMENTS_REQUEST",
	SUGGESTED_EXPERIMENTS_SUCCESS = "SUGGESTED_EXPERIMENTS_SUCCESS",
	SUGGESTED_EXPERIMENTS_FAILURE = "SUGGESTED_EXPERIMENTS_FAILURE",
	SUGGESTED_EXPERIMENTS_CLEAR = "SUGGESTED_EXPERIMENTS_CLEAR",
}

export enum SuggestedExperimentsFilterActionTypes {
	SUCCESS = "SUGGESTED_EXPERIMENTS_Filter_SUCCESS",
	FAILURE = "SUGGESTED_EXPERIMENTS_Filter_FAILURE",
}

export enum ExportPredictionActionTypes {
	REQUEST = "EXPORT_PREDICTION_REQUEST",
	SUCCESS = "EXPORT_PREDICTION_SUCCESS",
	FAILURE = "EXPORT_PREDICTION_FAILURE",
	CLEAR = "EXPORT_PREDICTION_CLEAR",
}

export const {
	suggestedExperimentsRequest,
	suggestedExperimentsSuccess,
	suggestedExperimentsFailure,
	suggestedExperimentsClear,
	exportPredictionRequest,
	exportPredictionSuccess,
	exportPredictionFailure,
	exportPredictionClear,
	suggestedExperimentsFilterSuccess,
	suggestedExperimentsFilterFailure
} = createActions({
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_REQUEST]: (payload) => payload,
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_SUCCESS]: (
		response: SuggestedExpResponse
	) => response,
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_FAILURE]: (error: string) => ({
		error,
	}),
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_CLEAR]: () => { },
	[ExportPredictionActionTypes.REQUEST]: (payload) => payload,
	[ExportPredictionActionTypes.SUCCESS]: (response) => response,
	[ExportPredictionActionTypes.FAILURE]: error => ({ error }),
	[ExportPredictionActionTypes.CLEAR]: () => ({}),
	[SuggestedExperimentsFilterActionTypes.SUCCESS]: (response: SuggestedExpResponse) => response,
	[SuggestedExperimentsFilterActionTypes.FAILURE]: (error: string) => ({ error }),
})
