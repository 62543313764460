import { createActions } from "redux-actions";


export enum UnitListActionTypes {
  REQUEST = "UNIT_LIST_REQUEST",
  SUCCESS = "UNIT_LIST_SUCCESS",
  FAILURE = "UNIT_LIST_FAILURE",
}

export enum ConversionActionTypes {
  REQUEST = "CONVERSION_REQUEST",
  SUCCESS = "CONVERSION_SUCCESS",
  FAILURE = "CONVERSION_FAILURE",
}

export const {
  unitListRequest,
  unitListSuccess,
  unitListFailure,
  conversionRequest,
  conversionSuccess,
  conversionFailure,
} = createActions({
  [UnitListActionTypes.REQUEST]: payload => payload,
  [UnitListActionTypes.SUCCESS]: response => response,
  [UnitListActionTypes.FAILURE]: () => { },
  [ConversionActionTypes.REQUEST]: payload => payload,
  [ConversionActionTypes.SUCCESS]: response => response,
  [ConversionActionTypes.FAILURE]: () => { },
})
