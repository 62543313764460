import { createActions } from "redux-actions";


export enum ConfigsActionTypes {
    REQUEST = "CONFIGS_REQUEST",
    SUCCESS = "CONFIGS_SUCCESS",
    FAILURE = "CONFIGS_FAILURE",
    CLEAR = "CONFIGS_CLEAR"
}


export const {
    configsRequest,
    configsSuccess,
    configsFailure,
    configsClear
} = createActions({
    [ConfigsActionTypes.REQUEST]: payload => payload,
    [ConfigsActionTypes.SUCCESS]: response => response,
    [ConfigsActionTypes.FAILURE]: () => { },
    [ConfigsActionTypes.CLEAR]: () => { },
})
