import { handleActions } from "redux-actions";
import { celsureReportPreviewActionTypes } from "../actions/celsureReportPreview";
import { AsyncStates } from "../../constants";


export type CelsureReportPreviewReducerState = {
  celsureReportUploadStatus: AsyncStates,
  celsureReportRetrieveStatus: AsyncStates,
  celsureReportReviewStatus: AsyncStates,
  celsureReportDeleteStatus: AsyncStates,
  data: any
  message: string
}

const defaultState: CelsureReportPreviewReducerState = {
  celsureReportUploadStatus: AsyncStates.INITIAL,
  celsureReportRetrieveStatus: AsyncStates.INITIAL,
  celsureReportReviewStatus: AsyncStates.INITIAL,
  celsureReportDeleteStatus: AsyncStates.INITIAL,
  data: { wo_reports: [], total: "" },
  message: ''
}

const celsureReportPreviewReducer = handleActions({
  [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_REQUEST]: (state) => ({
    ...state,
    celsureReportUploadStatus: AsyncStates.LOADING,
  }),
  [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_FAILURE]: (state) => ({
    ...state,
    celsureReportUploadStatus: AsyncStates.ERROR,
  }), [celsureReportPreviewActionTypes.CELSURE_WO_UPLOAD_REPORT_SUCCESS]: (state) => ({
    ...state,
    celsureReportUploadStatus: AsyncStates.SUCCESS,
  }),
  [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_REQUEST]: (state) => ({
    ...state,
    celsureReportRetrieveStatus: AsyncStates.LOADING,
    data: { wo_reports: [], total: "" },
  }),
  [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_FAILURE]: (state, action) => ({
    ...state,
    celsureReportRetrieveStatus: AsyncStates.ERROR,
    data: { wo_reports: [], total: "" },
  }), [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    celsureReportRetrieveStatus: AsyncStates.SUCCESS,
    data: action.payload
  }),
  [celsureReportPreviewActionTypes.CELSURE_WO_DELETE_REPORT_REQUEST]: (state) => ({
    ...state,
    celsureReportDeleteStatus: AsyncStates.LOADING,
  }),
  [celsureReportPreviewActionTypes.CELSURE_WO_RETRIEVE_REPORT_FAILURE]: (state) => ({
    ...state,
    celsureReportDeleteStatus: AsyncStates.ERROR,
  }), [celsureReportPreviewActionTypes.CELSURE_WO_DELETE_REPORT_SUCCESS]: (state) => ({
    ...state,
    celsureReportDeleteStatus: AsyncStates.SUCCESS,
  }),
}, defaultState)

export default celsureReportPreviewReducer