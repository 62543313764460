import { createActions } from "redux-actions";


export enum ProcedureActionTypes {
    CREATE_PROCEDURE_REQUEST = 'CREATE_PROCEDURE_REQUEST',
    CREATE_PROCEDURE_SUCCESS = 'CREATE_PROCEDURE_SUCCESS',
    CREATE_PROCEDURE_FAILURE = 'CREATE_PROCEDURE_FAILURE',
    RETRIEVE_PROCEDURE_REQUEST = 'RETRIEVE_PROCEDURE_REQUEST',
    RETRIEVE_PROCEDURE_SUCCESS = 'RETRIEVE_PROCEDURE_SUCCESS',
    RETRIEVE_PROCEDURE_FAILURE = 'RETRIEVE_PROCEDURE_FAILURE',
    CLEAR_PROCEDURE = 'CLEAR_PROCEDURE'
}

export const {
    createProcedureRequest,
    createProcedureSuccess,
    createProcedureFailure,
    retrieveProcedureRequest,
    retrieveProcedureSuccess,
    retrieveProcedureFailure,
    clearProcedure
} = createActions({
    [ProcedureActionTypes.CREATE_PROCEDURE_REQUEST]: (payload) => payload,
    [ProcedureActionTypes.CREATE_PROCEDURE_SUCCESS]: (response) => response,
    [ProcedureActionTypes.CREATE_PROCEDURE_FAILURE]: (error) => ({ error }),
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_REQUEST]: (payload) => payload,
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_SUCCESS]: (response) => response,
    [ProcedureActionTypes.RETRIEVE_PROCEDURE_FAILURE]: (error) => ({ error }),
    [ProcedureActionTypes.CLEAR_PROCEDURE]: () => { }
})
