import { createActions } from "redux-actions";

export enum GetPerformanceActionTypes {
  REQUEST = "GET_PERFORMANCE_REQUEST",
  SUCCESS = "GET_PERFORMANCE_SUCCESS",
  FAILURE = "GET_PERFORMANCE_FAILURE",
  CLEAR = "GET_PERFORMANCE_CLEAR",
}

export const {
  getPerformanceRequest,
  getPerformanceSuccess,
  getPerformanceFailure,
  getPerformanceClear,
} = createActions({
  [GetPerformanceActionTypes.REQUEST]: (payload) => payload,
  [GetPerformanceActionTypes.SUCCESS]: (response) => response,
  [GetPerformanceActionTypes.FAILURE]: (payload) => payload,
  [GetPerformanceActionTypes.CLEAR]: () => {},
});
