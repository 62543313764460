import { handleActions } from "redux-actions"
import { PivotTableActionTypes } from "src/store/actions/pivotTable"
import { AsyncStates } from "src/constants"
import { PivotTableData } from "src/components/AIEngine/DataSummary/tabs/summaries/pivot-table-analysis/types"

export type PivotTableReducerState = {
	status: AsyncStates
	error: string
	message: string
	data: PivotTableData | null
}

const defaultState: PivotTableReducerState = {
	status: AsyncStates.INITIAL,
	error: "",
	message: "",
	data: null,
}

const pivotTableReducer = handleActions(
	{
		[PivotTableActionTypes.REQUEST]: (state) => ({
			...state,
			status: AsyncStates.LOADING,
			error: "",
		}),
		[PivotTableActionTypes.SUCCESS]: (state, action) => ({
			...state,
			status: AsyncStates.SUCCESS,
			error: "",
			message: action.payload.message,
			data: action.payload.data,
		}),
		[PivotTableActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[PivotTableActionTypes.CLEAR]: (state) => ({
			...state,
			status: AsyncStates.INITIAL,
			error: "",
			message: "",
		}),
	},
	defaultState
)

export default pivotTableReducer
