import { Typography, Space } from 'antd';
import Text from 'antd/lib/typography/Text';

const { Title } = Typography;

const Header = () => {
    return (
        <Space direction="vertical" style={{ width: '100%' }} align="center">
            <a target="_blank" href="https://polymerize.io" rel="noopener noreferrer">
                <Title level={2} style={{ margin: 0 }}>
                    <img src="/logo192.png" alt="Polymerize Logo" height="36" width={"36"} />{" "}
                    <span>
                        Polymerize Labs
                    </span>
                    <sup style={{ fontSize: '15px' }}>&#169;</sup>
                </Title>
            </a>
            <Text type="secondary" style={{ width: '100%' }}>AI Catalyst for Material Innovation</Text>
        </Space>
    );

}
export default Header;
