import { useEffect, useState } from 'react'
import dayjs from "dayjs"
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';

const getTrialEndsIn = (subscription_status: string | undefined, date: string | null | undefined) => {
    if (subscription_status === "trialing") {
        const today = dayjs().startOf('day');
        const trialEnd = dayjs(date).startOf('day');
        const daysRemaining = trialEnd.diff(today, 'day');
        return (daysRemaining >= 0 && daysRemaining <= 7 ? daysRemaining : null)
    } else {
        return null
    }
}

export const useTrialReminderAlert = () => {
    const [showTrialAlert, setshowTrialAlert] = useState(false)
    const userInfo = useSelector((state: StoreState) => state.login.loginResponse)

    const trialEndsIn = userInfo.user_role === "admin" ? getTrialEndsIn(userInfo.subscription_status, userInfo.trial_end_date) : null

    useEffect(() => {
        if (trialEndsIn === null) return
        const remindMeOn = localStorage.getItem("connectRemindMeOn")
        if (!remindMeOn) {
            setshowTrialAlert(true)
            return
        }
        const remindMeDate = dayjs(remindMeOn)
        if (!remindMeDate.isValid()) {
            setshowTrialAlert(true)
            return
        }
        if (dayjs().isAfter(remindMeDate)) {
            setshowTrialAlert(true)
        }

    }, [userInfo, trialEndsIn])

    const remindMeLater = () => {
        setshowTrialAlert(false)
        localStorage.setItem("connectRemindMeOn", dayjs().add(1, "day").toISOString())
    }
    return { showTrialAlert, remindMeLater, trialEndsIn }
}
