import { notification } from "antd"
import { put, takeLatest, call, select } from "redux-saga/effects"
import { messages } from "src/utils/hooks"
import {
	fetchPendingInvitations,
	invite,
	resendEmailInvitation,
	withdrawInvitationApi,
} from "src/services/invite"
import {
	getPendingInvitationsListFailure,
	getPendingInvitationsListRequest,
	getPendingInvitationsListSuccess,
	inviteFailure,
	inviteRequest,
	inviteSuccess,
	resendEmailFailure,
	resendEmailRequest,
	resendEmailSuccess,
	withdrawInvitationRequest,
	withdrawInvitationSuccess,
} from "../actions/invite"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { trackEvent } from "src/analytics"

type inviteAction = {
	type: string
	payload: any
}

function* inviteSaga(action: inviteAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const userId = yield select((state) => state.login.loginResponse.user_id)
		const params = action.payload
		const {
			data: {
				result: { message, status },
			},
		} = yield call(invite, params, headers)

		if (status === "Success") {
			trackEvent(userId, 'User sent an invite successfully')
			yield put(inviteSuccess())
			notification["success"]({
				message,
				placement: "topRight",
			})
		} else {
			trackEvent(userId, "User's invite request failed", {message})
			yield put(inviteFailure(message))
			notification.error({
				message,
				placement: "topRight",
			})
		}
	} catch (error) {
		yield put(inviteFailure(error))
		notification["error"]({
			message:
				(error as any).response.data.detail ??
				messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* getPendingInvitationSaga(): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: {
					data: { invited_users },
					message,
					status,
				},
			},
		} = yield call(fetchPendingInvitations, null, headers)

		if (status === "Success") {
			yield put(getPendingInvitationsListSuccess(invited_users))
		} else {
			yield put(getPendingInvitationsListFailure(message))
		}
	} catch (error) {
		yield put(getPendingInvitationsListFailure(error))
	}
}

function* resendInvitationSaga(action: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const payload = action.payload

		const {
			data: {
				result: { status, message },
			},
		} = yield call(resendEmailInvitation, payload, headers)

		if (status === "Success") {
			yield put(resendEmailSuccess())
			notification["success"]({
				message: message,
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(resendEmailFailure(error))
		notification["error"]({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		})
	}
}

function* withdrawInvitationSaga(action: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const payload = action.payload

		const {
			data: {
				result: { status, message },
			},
		} = yield call(withdrawInvitationApi, payload, headers)
		if (status === "Success") {
			yield put(withdrawInvitationSuccess())
			yield put(getPendingInvitationsListRequest())

			notification["success"]({
				message: message,
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(resendEmailFailure(error))
		notification["error"]({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		})
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(inviteRequest, inviteSaga)
	yield takeLatest(getPendingInvitationsListRequest, getPendingInvitationSaga)
	yield takeLatest(resendEmailRequest, resendInvitationSaga)
	yield takeLatest(withdrawInvitationRequest, withdrawInvitationSaga)
}
