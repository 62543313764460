import { FormInstance } from "antd"
import confirm from "antd/lib/modal/confirm"
import { Dispatch, SetStateAction } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setIsEditing } from "src/store/actions/isEditing"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"

type P<Form> = {
	form: FormInstance<Form extends void ? any : Form>
	setModal: Dispatch<SetStateAction<boolean>>
	setNewMethod: Dispatch<SetStateAction<any>>
}

export const useUnsavedChangesPrompt = <Form extends void>({
	form,
	setModal,
	setNewMethod
}: P<Form>) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const isEditing = useSelector((state: StoreState) => state.isEditing)

	function checkUnsavedChanges() {
		if (isEditing) {
			confirm({
				width: 500,
				title: t("common.modal.unsavedwarning"),
				onOk: () => {
					form.resetFields()
					setModal(false)
					setNewMethod({
						name: '',
						category: '',
						description: '',
						variations: [
							{ key: 0, name: '', description: '', data: [], properties: [] }
						]
					})
					dispatch(setIsEditing(false))
				},
				okText: t("common.yes"),
				okType: "danger",
				cancelText: t("common.no"),
				onCancel: () => { },
			})
		} else {
			form.resetFields()
			setModal(false)
			setNewMethod({
				name: '',
				category: '',
				description: '',
				variations: [
					{ key: 0, name: '', description: '', data: [], properties: [] }
				]
			})
			dispatch(setIsEditing(false))
		}
	}

	return { checkUnsavedChanges }
}
