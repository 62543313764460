/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppNotificationSubscriptionVariables = {
    user_id: string;
};
export type AppNotificationSubscriptionResponse = {
    readonly notifications: {
        readonly data: {
            readonly message: {
                readonly en: {
                    readonly message: {
                        readonly receivers_message: string | null;
                        readonly description: string | null;
                        readonly senders_message: string | null;
                    } | null;
                } | null;
                readonly fr: {
                    readonly message: {
                        readonly receivers_message: string | null;
                        readonly description: string | null;
                        readonly senders_message: string | null;
                    } | null;
                } | null;
                readonly ja: {
                    readonly message: {
                        readonly receivers_message: string | null;
                        readonly description: string | null;
                        readonly senders_message: string | null;
                    } | null;
                } | null;
            } | null;
            readonly notification_type: string | null;
            readonly created: string | null;
            readonly notification_id: string | null;
            readonly is_read: boolean | null;
            readonly download_link: string | null;
            readonly work_order_id: string | null;
            readonly sender: string | null;
            readonly receiver: ReadonlyArray<string | null> | null;
        } | null;
    } | null;
};
export type AppNotificationSubscription = {
    readonly response: AppNotificationSubscriptionResponse;
    readonly variables: AppNotificationSubscriptionVariables;
};



/*
subscription AppNotificationSubscription(
  $user_id: String!
) {
  notifications(user_id: $user_id) {
    data {
      message {
        en {
          message {
            receivers_message
            description
            senders_message
          }
        }
        fr {
          message {
            receivers_message
            description
            senders_message
          }
        }
        ja {
          message {
            receivers_message
            description
            senders_message
          }
        }
      }
      notification_type
      created
      notification_id
      is_read
      download_link
      work_order_id
      sender
      receiver
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Message",
    "kind": "LinkedField",
    "name": "message",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receivers_message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "senders_message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user_id",
        "variableName": "user_id"
      }
    ],
    "concreteType": "NotificationData",
    "kind": "LinkedField",
    "name": "notifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocalizationMessage",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationMessage",
                "kind": "LinkedField",
                "name": "en",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationMessage",
                "kind": "LinkedField",
                "name": "fr",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationMessage",
                "kind": "LinkedField",
                "name": "ja",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notification_type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notification_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_read",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "download_link",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "work_order_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receiver",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppNotificationSubscription",
    "selections": (v2/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppNotificationSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bba513870e4265bb45bdc220e1b889a8",
    "id": null,
    "metadata": {},
    "name": "AppNotificationSubscription",
    "operationKind": "subscription",
    "text": "subscription AppNotificationSubscription(\n  $user_id: String!\n) {\n  notifications(user_id: $user_id) {\n    data {\n      message {\n        en {\n          message {\n            receivers_message\n            description\n            senders_message\n          }\n        }\n        fr {\n          message {\n            receivers_message\n            description\n            senders_message\n          }\n        }\n        ja {\n          message {\n            receivers_message\n            description\n            senders_message\n          }\n        }\n      }\n      notification_type\n      created\n      notification_id\n      is_read\n      download_link\n      work_order_id\n      sender\n      receiver\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd3d35f1ceffb5ec673acd0ffabe94e77';
export default node;
