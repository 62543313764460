import { CSSProperties } from "react"
import { camelCaseToTrainCase } from "src/utils/general"

export const stylesheetToString = (styles: CSSProperties) => {
	return Object.keys(styles).reduce((acc, property) => {
		const cssProperty = property as keyof CSSProperties
		return acc.concat(
			`${camelCaseToTrainCase(cssProperty)}:${styles[cssProperty]};`
		)
	}, "")
}
