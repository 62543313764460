import { createActions } from "redux-actions";

export enum FetchDashboardDataActionTypes {
  REQUEST = "FETCH_DASHBOARD_DATA_REQUEST",
  SUCCESS = "FETCH_DASHBOARD_DATA_SUCCESS",
  FAILURE = "FETCH_DASHBOARD_DATA_FAILURE",
}

export enum FetchNewsActionTypes {
  REQUEST = "FETCH_NEWS_REQUEST",
  SUCCESS = "FETCH_NEWS_SUCCESS",
  FAILURE = "FETCH_NEWS_FAILURE",
}

export const {
  fetchDashboardDataRequest,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
  fetchNewsRequest,
  fetchNewsSuccess,
  fetchNewsFailure,
} = createActions({
  [FetchDashboardDataActionTypes.REQUEST]: (payload) => payload,
  [FetchDashboardDataActionTypes.SUCCESS]: (payload) => payload,
  [FetchDashboardDataActionTypes.FAILURE]: (error: any) => error,
  [FetchNewsActionTypes.REQUEST]: (payload) => payload,
  [FetchNewsActionTypes.SUCCESS]: (payload) => payload,
  [FetchNewsActionTypes.FAILURE]: (error: any) => error,
});