import dayjs from "dayjs"
import en_US from "antd/lib/locale/en_US"
import zh_CN from "antd/lib/locale/zh_CN"
import ja_JP from "antd/lib/locale/ja_JP"
import es_ES from "antd/lib/locale/es_ES"
import pt_PT from "antd/lib/locale/pt_PT"
import fr_FR from "antd/lib/locale/fr_FR"
// @ts-ignore
import * as plotly_zh from "plotly.js-locales/zh-cn"
// @ts-ignore
import * as plotly_ja from "plotly.js-locales/ja"
// @ts-ignore
import * as plotly_es from "plotly.js-locales/es"
// @ts-ignore
import * as plotly_pt from "plotly.js-locales/pt-pt"
// @ts-ignore
import * as plotly_fr from "plotly.js-locales/fr"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { useEffect, useState } from "react"
import { Language } from "src/store/reducers/language"

export function switchMomentLocale(current: Language) {
	switch (current) {
		case "en":
			dayjs.locale("en")
			break
		case "zh":
			import("dayjs/locale/zh-cn" as any).then(() => dayjs.locale("zh-cn"))
			break
		case "ja":
			import("dayjs/locale/ja" as any).then(() => dayjs.locale("ja"))
			break
		case "es":
			import("dayjs/locale/es" as any).then(() => dayjs.locale("es"))
			break
		case "pt":
			import("dayjs/locale/pt" as any).then(() => dayjs.locale("pt"))
			break
		case "fr":
			import("dayjs/locale/fr" as any).then(() => dayjs.locale("fr"))
			break
		default:
			dayjs.locale("en")
	}
}

export function switchAntdLocale(current: Language) {
	switch (current) {
		case "en":
			return en_US
		case "zh":
			return zh_CN
		case "ja":
			return ja_JP
		case "es":
			return es_ES
		case "pt":
			return pt_PT
		case "fr":
			return fr_FR
		default:
			return en_US
	}
}

function switchPlotlyLocale(current: Language) {
	switch (current) {
		case "en":
			return {
				displaylogo: false,
			}
		case "zh":
			return {
				locales: { "zh-cn": plotly_zh },
				locale: "zh-cn",
				displaylogo: false,
			}

		case "ja":
			return {
				locales: { ja: plotly_ja },
				locale: "ja",
				displaylogo: false,
			}
		case "es":
			return {
				locales: { es: plotly_es },
				locale: "es",
				displaylogo: false,
			}
		case "pt":
			return {
				locales: { pt: plotly_pt },
				locale: "pt",
				displaylogo: false,
			}
		case "fr":
			return {
				locales: { fr: plotly_fr },
				locale: "fr",
				displaylogo: false,
			}
		default:
			return {
				displaylogo: false,
			}
	}
}

export function usePlotlyLocale() {
	const { current } = useSelector((state: StoreState) => state.language)
	const [plotlyLocale, setPlotlyLocale] = useState(switchPlotlyLocale(current))

	useEffect(() => {
		setPlotlyLocale(switchPlotlyLocale(current))
	}, [current])

	return plotlyLocale
}
