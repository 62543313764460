import { handleActions } from "redux-actions";
import { ResetPasswordActionTypes } from "../actions/resetPassword";
import { AsyncStates } from "../../constants";


export type ResetPasswordReducerState = {
    status: AsyncStates;
    error: string;
    message: string
}

const defaultState: ResetPasswordReducerState = {
    status: AsyncStates.INITIAL,
    message: "",
    error: "",
}

const resetPasswordReducer = handleActions({
    [ResetPasswordActionTypes.REQUEST]: (state) => ({
        ...state,
        status: AsyncStates.LOADING,
        error: "",
    }),
    [ResetPasswordActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            status: AsyncStates.SUCCESS,
            error: "",
            message: action.payload.message,
        };
    },
    [ResetPasswordActionTypes.FAILURE]: (state, action) => ({
        ...state,
        status: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [ResetPasswordActionTypes.CLEAR]: (state) => ({
        ...state,
        status: AsyncStates.INITIAL,
        error: "",
        message: ""
    }),
}, defaultState)


export default resetPasswordReducer;