import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	boxPlotSummaryFailure,
	boxPlotSummaryRequest,
	boxPlotSummarySuccess,
	dataSummaryFailure,
	dataSummaryRequest,
	dataSummarySuccess,
	recipeDistributionFailure,
	recipeDistributionRequest,
	getZeonCharacterizationDsmFailure,
	getZeonCharacterizationDsmRequest,
	getZeonCharacterizationDsmSuccess,
} from "src/store/actions/dataSummary"
import {
	boxPlotSummaryApi,
	getIndividualSummaryApi,
	getOverallSummaryApi,
} from "src/services/dataSummary"
import { messages } from "src/utils/hooks"
import { APIError } from "src/typings"
import { LanguageUnion } from 'src/utils/useTranslate'
import { StoreState } from '../configureStore'
import { message, notification } from "antd"
import { getCharacterizationMethodsZeonDsmApi } from "src/services/forwardModel"
import jwtManager from "src/utils/jwtManager"
import { fetchExperiment } from "src/services/experiment"


type NumericalSummaryPayload = {
	payload: {
		work_order_ids?: string[]
		type?: "ingredients" | "processing" | "properties"
		characterization_set_id?: "string"
		formulation_id?: "string"
		normalise?: boolean,
		stage_identifier?: string | undefined,
		experiment_ids?: string[],
		work_order_id?: string[],
		with_processing_profile?: boolean
	}
}

function* recipeDistributionSaga({
	payload,
}: NumericalSummaryPayload): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const apiResponse = yield call(fetchExperiment,
			{ ...payload, background: true },
			headers
		)
		const { data: { result: { status } } } = apiResponse

		if (status !== "Success") {
			yield put(recipeDistributionFailure(messages[ln].internal_server_error))
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(recipeDistributionFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(recipeDistributionFailure(messages[ln].internal_server_error))
			} else {
				yield put(
					recipeDistributionFailure(
						error.response.data.detail ?? messages[ln].internal_server_error
					)
				)
			}
		}
	}
}


function* dataSummarySaga({
	payload,
}: NumericalSummaryPayload): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status, data } = yield call(
			payload.type ? getIndividualSummaryApi : getOverallSummaryApi,
			payload.type
				? {
					work_order_ids: payload.work_order_ids, type: payload.type, characterization_set_id: payload?.characterization_set_id, formulation_id: payload?.formulation_id,
					normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload?.experiment_ids, with_processing_profile: payload?.with_processing_profile
				}
				: {
					work_order_ids: payload.work_order_ids, characterization_set_id: payload?.characterization_set_id,
					formulation_id: payload?.formulation_id, normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload?.experiment_ids, with_processing_profile: payload?.with_processing_profile
				},
			headers
		)

		if (status === 200) {
			yield put(dataSummarySuccess(data))
		} else {
			yield put(dataSummaryFailure(messages[ln].internal_server_error))
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(dataSummaryFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(dataSummaryFailure(messages[ln].internal_server_error))
			} else {
				yield put(
					dataSummaryFailure(
						error.response.data.detail ?? messages[ln].internal_server_error
					)
				)
			}
		}
	}
}

function* getCharacterizationMethodsZeonDsmSaga({
	payload,
}: {
	payload: {
		characterization_method_id: string
		ings_procs: string[]
		properties: string[]
	}
}): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data, message },
			status,
		} = yield call(getCharacterizationMethodsZeonDsmApi, payload, headers)

		if (status === 200) {
			if (!Object.keys(data ?? {})[0]) {
				notification.error({ message: "No variations found for this characterization", duration: 3 })
			}
			yield put(getZeonCharacterizationDsmSuccess(data))
		} else {
			yield put(getZeonCharacterizationDsmFailure(message))
		}
	} catch (error) {
		yield put(getZeonCharacterizationDsmFailure(error))
	}
}

function* boxPlotSummarySaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: errorMessage } } } = yield call(boxPlotSummaryApi, payload, headers)

		if (status === "Success") {
			yield put(boxPlotSummarySuccess(data))
		} else {
			yield put(boxPlotSummaryFailure(errorMessage))
			message.error(errorMessage)
		}

	} catch (err) {
		yield put(boxPlotSummaryFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(recipeDistributionRequest, recipeDistributionSaga)
	yield takeLatest(dataSummaryRequest, dataSummarySaga)
	yield takeLatest(boxPlotSummaryRequest, boxPlotSummarySaga)
	yield takeLatest(getZeonCharacterizationDsmRequest, getCharacterizationMethodsZeonDsmSaga)
}
