import { useEffect, useState } from "react";
import Sha1 from "js-sha1";
import { Button, Divider, Form, Input, Space, Typography, Layout, Card, Alert } from 'antd';
import Icon from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../store/actions";
import { AsyncStates } from "../../constants";
import Spinner from "../Spinner/Spinner";
import { StoreState } from "../../store/configureStore";
import { setOtp, setVerification, verifyOtpRequest, verifyOtpTrueRequest } from "../../store/actions/login";
import { loginFirebaseRequest } from "../../store/actions/firebase";
import Header from "../OldSignUp/Header";
import Footer from "../OldSignUp/Footer";
import '../OldSignUp/SignUp.scss'
import useTranslate from "src/utils/useTranslate";
import QRCode from "react-qr-code";
import { Services, signInViaFirebase } from "src/services/firebase/firebase-auth";
import { loginFailure, } from "../../store/actions/login";


export function MicrosoftIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={21}
            viewBox="0 0 21 21"
            {...props}
        >
            <title>{"MS-SymbolLockup"}</title>
            <path fill="#f25022" d="M1 1h9v9H1z" />
            <path fill="#00a4ef" d="M1 11h9v9H1z" />
            <path fill="#7fba00" d="M11 1h9v9h-9z" />
            <path fill="#ffb900" d="M11 11h9v9h-9z" />
        </svg>
    )
}

export function GsuiteIcon(props: any) {
    return (
        <svg
            width={118}
            height={120}
            viewBox="0 0 118 120"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>{"google_buttn"}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M117.6 61.364c0-4.255-.382-8.346-1.09-12.273H60V72.3h32.29c-1.39 7.5-5.617 13.855-11.972 18.11v15.054H99.71c11.346-10.446 17.891-25.828 17.891-44.1z"
                    fill="#4285F4"
                />
                <path
                    d="M60 120c16.2 0 29.782-5.373 39.71-14.536L80.317 90.409c-5.373 3.6-12.245 5.727-20.318 5.727-15.627 0-28.855-10.554-33.573-24.736H6.382v15.545C16.255 106.555 36.545 120 60 120z"
                    fill="#34A853"
                />
                <path
                    d="M26.427 71.4c-1.2-3.6-1.882-7.445-1.882-11.4s.682-7.8 1.882-11.4V33.055H6.382A59.976 59.976 0 000 60a59.976 59.976 0 006.382 26.945L26.427 71.4z"
                    fill="#FBBC05"
                />
                <path
                    d="M60 23.864c8.81 0 16.718 3.027 22.936 8.972l17.21-17.209C89.754 5.945 76.172 0 60 0 36.545 0 16.255 13.445 6.382 33.055L26.427 48.6C31.145 34.418 44.373 23.864 60 23.864z"
                    fill="#EA4335"
                />
                <path d="M0 0h120v120H0V0z" />
            </g>
        </svg>
    )
}

const { Content: LayoutContent, Footer: LayoutFooter } = Layout
const { Text, Title } = Typography;

const Login = () => {
    const [t] = useTranslate()
    const dispatch = useDispatch();
    const { otpRequired, error: errorMsg, status: apiStatus, otpVerificationRequired, requestOtpData, verifyOtpTrueStatus } = useSelector((state: StoreState) => state.login)
    const [otpValue, setOtpValue] = useState<string>("")
    const [loginForm] = Form.useForm()
    const [otpForm] = Form.useForm()


    useEffect(() => {
        if (AsyncStates.LOADING === "LOADING") {
            dispatch(loginFailure(""))
        }

    }, [dispatch, loginForm])
    useEffect(() => {

    }, [loginForm])


    const firebaseStatus = useSelector<StoreState, AsyncStates>(
        (state: StoreState) => state.loginFirebase.status
    );

    const submitForm = (values: any) => {
        const encryptedPassword = Sha1(values.password.trim())
        dispatch(loginRequest({ ...values, password: encryptedPassword }));
    }

    const submitOtp = (values: any) => {
        dispatch(verifyOtpRequest({ otp: values.otp }))
    }

    const signInViaGsuite = () => {
        setshowSpinner(true)
        signInViaFirebase(Services.gsuite)
    }
    const signInViaMicrosoft = async () => {
        setshowSpinner(true)
        signInViaFirebase(Services.microsoft)
    }
    const [showSpinner, setshowSpinner] = useState(false)
    useEffect(() => {
        dispatch(loginFirebaseRequest())
    }, [dispatch])

    const codeChange = (e: any) => {
        if (e.target.value?.length < 7) {
            setOtpValue(e.target.value)
        }
    }

    return (
        <Layout style={{ textAlign: 'center', minHeight: '100%' }}>
            <LayoutContent style={{ overflow: 'auto', marginTop: "64px" }}>
                <Space style={{ width: "100%" }} direction="vertical" size="large">
                    <Space direction="vertical">
                        <Header />
                    </Space>
                    <Space style={{ flex: 1 }}>
                        <Card style={{ marginTop: "45px", padding: 0 }} className={"login-card-container"}>
                            <Space direction="vertical">
                                {otpRequired ?
                                    <Space style={{ margin: "25px" }}>
                                        <Form onFinish={submitOtp} form={otpForm}>
                                            <Form.Item>
                                                <Text strong>{t("login.authenticatorApp")}</Text>
                                            </Form.Item>

                                            <Form.Item name='otp' rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>Please Enter Otp</Text> }]}>
                                                <Input type="number" value={otpValue} placeholder="Enter Code" onChange={(e) => {
                                                    if (e.target.value?.length < 7) setOtpValue(e.target.value)
                                                }} />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button className="primary-btn" style={{ background: '#FF9300', width: "100%", borderRadius: 5 }} size="large" type="primary" htmlType="submit" disabled={!otpValue}>{t("common.Submit")}</Button>
                                            </Form.Item>

                                            <Text type="secondary" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>Go back to<Button style={{ padding: 0, marginLeft: "0.2rem" }} onClick={() => dispatch(setOtp(false))} size="small" type="link">{t("common.backToLogin")}</Button></Text>
                                        </Form>

                                    </Space>
                                    : otpVerificationRequired ?
                                        <Space direction="vertical">
                                            <Space direction="vertical">
                                                <Text strong>{t("login.adminEnabled2fa")} </Text>
                                                <Title level={5}>{t("login.setting2FA")}</Title>
                                                <Text type="secondary" strong>{t("common.authenticatorApp")}</Text>
                                                <Text type="secondary" strong>{t("common.QRCodeverification")}</Text>
                                            </Space>
                                            <Space direction="vertical">
                                                <QRCode value={requestOtpData} />
                                                <Input addonBefore={<Text strong>{t("common.code")}</Text>} value={otpValue} onChange={codeChange} placeholder={t("common.enterCode")} size="large" type="number" maxLength={6} />
                                                <Button loading={verifyOtpTrueStatus === AsyncStates.LOADING} style={{ width: "100%" }} disabled={otpValue?.length !== 6} type="primary"
                                                    onClick={() => { setOtpValue(""); dispatch(verifyOtpTrueRequest({ otp: otpValue, from: "login" })) }}>
                                                    {"Verify"}
                                                </Button>
                                                <Button onClick={() => dispatch(setVerification({ otpVerificationRequired: false, requestOtpData: false }))} size="small" type="link">{t("common.backToLogin")}</Button>
                                            </Space>
                                        </Space>
                                        : <Space direction="vertical" style={{ margin: 48 }}>
                                            <Title level={3} style={{ marginBottom: "40px" }}>Log in to your account</Title>

                                            <Form name="normal_login" className="login-form" layout='vertical' onFinish={submitForm} form={loginForm}>

                                                <Form.Item>
                                                    <Button size="large" className="round-text social-btn" onClick={signInViaGsuite} style={{ whiteSpace: 'normal', display: 'block', height: 'max-content', textAlign: 'center', padding: "8px, 16px, 8px, 16px", width: '100%' }}>
                                                        <Space><Icon component={GsuiteIcon} style={{ fontSize: 24 }} />&nbsp; Log in with Google</Space>
                                                    </Button>
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button size="large" className="round-text social-btn" onClick={signInViaMicrosoft} style={{ whiteSpace: 'normal', height: 'max-content', textAlign: 'center', padding: "8px, 16px, 8px, 16px", width: '100%' }}>
                                                        <Space><Icon component={MicrosoftIcon} style={{ fontSize: 24 }} />&nbsp; Log in with Microsoft</Space>
                                                    </Button>
                                                </Form.Item>

                                                <Form.Item>
                                                    <Divider plain><Text type="secondary">or log in with email</Text></Divider>
                                                </Form.Item>

                                                {!!errorMsg && <Alert message={errorMsg} type="error" style={{ backgroundColor: '#CF1322', color: "white", textAlign: "left", display: "block", marginBottom: "1rem" }} className="error-alert-msg" />}

                                                <Form.Item label={t("common.email")} name='user_email' validateTrigger="onChange"
                                                    rules={[{ required: true, pattern: new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/), message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>{t("common.notAvalidMail")}</Text> }]}>
                                                    <Input size='large' placeholder={t("common.email")} className="round-text" minLength={4} autoFocus />
                                                </Form.Item>

                                                <Form.Item label={t("login.password")} name='password' validateTrigger="onChange"
                                                    rules={[{ required: true, message: <Text style={{ color: "red", display: "block", textAlign: "left", margin: "0.2rem" }}>{t("login.warn.password")}</Text>, pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/) }]}>
                                                    <Input.Password size='large' placeholder={t("login.password")} className="round-text" type="password" />
                                                </Form.Item>

                                                <Text strong type="secondary" style={{ textAlign: "left", display: "block" }}>
                                                    <Link to="/forgot_password">{t("login.forgotPassword")}?</Link>
                                                </Text>

                                                <Form.Item>
                                                    <Button style={{ background: '#FF9300', width: "100%", marginTop: "1.5rem" }} size="large" type="primary" htmlType="submit" className="primary-btn">{t("common.login")}</Button>
                                                </Form.Item>
                                                <Text strong type="secondary" style={{ textAlign: "left", display: "block" }}>{t("login.noAccount")}? <Link to="/signup">{t("login.signUp")}</Link></Text>
                                            </Form>
                                        </Space>
                                }
                            </Space>
                        </Card>
                    </Space>
                </Space>
            </LayoutContent>
            <LayoutFooter style={{ minHeight: 160 }}>
                <Footer />
            </LayoutFooter>
            {(showSpinner || apiStatus === AsyncStates.LOADING || firebaseStatus === AsyncStates.LOADING) && <Spinner />}
        </Layout>
    );
};
export default Login;
