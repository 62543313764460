import { useEffect, useState } from "react";
import { Avatar, List, Tooltip, Popconfirm, Skeleton } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { Comment } from "@ant-design/compatible";
import relativeTime from "dayjs/plugin/relativeTime";
import { FB_COLLECTION_NAME_COMMENTS, firestoreDb } from "src/utils/firebase";
import {
  DocumentData,
  Unsubscribe,
  collection,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
dayjs.extend(relativeTime);

export const CommentList = (props: any) => {
  const [t] = useTranslate();
  const key = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );

  const [commentFeed, setCommentFeed] = useState<any[]>([]);

  useEffect(() => {
    let unsub: Unsubscribe;
    const listenToComments = async () => {
      const woCommentsQuery = query(
        collection(firestoreDb, FB_COLLECTION_NAME_COMMENTS),
        where("work_order_id", "==", work_order_id),
        where("status", "==", "active")
      );

      unsub = onSnapshot(woCommentsQuery, (querySnapshot) => {
        let updatedComments: DocumentData[] = [];
        querySnapshot.docs.forEach((docSnapshot) => {
          updatedComments.push(docSnapshot.data());
        });

        updatedComments.sort((a: any, b: any) => {
          const dateA = new Date(a.created);
          const dateB = new Date(b.created);
          return dateB - dateA;
        })
        setCommentFeed(updatedComments ?? []);
      });
    };
    if (work_order_id) {
      listenToComments();
    }

    return () => {
      unsub && unsub();
    };
  }, [work_order_id]);

  const deleteComment = async (comment_id: string) => {
    const col = collection(firestoreDb, FB_COLLECTION_NAME_COMMENTS);

    const commentQuery = query(col, where("comment_id", "==", comment_id));
    const querySnapshot = await getDocs(commentQuery);
    querySnapshot.forEach((docSnap) => {
      updateDoc(docSnap.ref, {
        status: "inactive",
      });
    });
  };

  return (
    <>
      {Boolean(commentFeed.length) && (
        <List
          dataSource={commentFeed}
          header={
            <Text strong>
              {commentFeed.length} {commentFeed.length > 1
                ? t("common.comments")
                : t("common.comment")}
            </Text>
          }
          itemLayout="horizontal"
          renderItem={(comment: any) => (
            <Comment
              key={comment.comment_id}
              author={<Text>{comment.user_name}</Text>}
              content={comment.comment}
              avatar={
                <Avatar src={comment.image_url} alt={comment.user_name} />
              }
              style={{
                padding: 10,
              }}
              actions={[
                comment.user_id === key && (
                  <Tooltip key="comment-delete" title={t("common.delete")}>
                    <Popconfirm
                      okText={t("common.ok")}
                      cancelText={t("common.cancel")}
                      title={`${t("common.commentDeleteConfirmation")}?`}
                      onConfirm={() => {
                        deleteComment(comment.comment_id);
                      }}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Tooltip>
                ),
                // target === comment.comment_id && (
                //   <Spin indicator={<LoadingOutlined />} />
                // ),
              ]}
              datetime={dayjs(comment.created).fromNow()}
            />
          )}
        />
      )}
      {!Boolean(commentFeed.length) &&
        [...Array(3)].map((item, index) => (
          <Skeleton key={index} loading={false} active avatar />
        ))}
    </>
  );
};
