import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	customTrainFailure,
	customTrainRequest,
	customTrainSuccess,
	filterDataFailure,
	filterDataRequest,
	filterDataSuccess,
	deleteModelFailure,
	deleteModelRequest,
	deleteModelSuccess,
	getCustomTraingsRequest,
	getCustomTraingsSuccess,
	getCustomTraingsFailure,
} from "../actions/customML"
import {
	customDataTrainApi,
	filterDataApi,
	deleteCustomModelsApi,
	getCustomTraingsApi,
} from "../../services/customML"
import { message, notification } from "antd"
import { modelsConfigListRequest } from "src/store/actions/formulate"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { history } from "src"

function* filterDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(filterDataApi, payload, headers)
		if (status === "Success") {
			if (!!data?.dataframe?.length) {
				yield put(filterDataSuccess(data))
			} else {
				yield put(filterDataFailure())
				notification.error({ message: messages[ln].trial_not_found_given_inputs })
			}
		} else {
			yield put(filterDataFailure())
			notification.error({ message })
		}
	} catch (error) {
		yield put(filterDataFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			duration: 5,
		})
	}
}

function* getCustomTrainingsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message, total_count },
			},
		} = yield call(getCustomTraingsApi, payload, headers)
		if (status === "Success") {
			yield put(getCustomTraingsSuccess({ data, total_count }))
		} else {
			yield put(getCustomTraingsFailure())
			notification.error({ message })
		}
	} catch (error) {
		yield put(filterDataFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			duration: 5,
		})
	}
}

function* customTrainSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message, description },
			},
		} = yield call(customDataTrainApi, payload, headers)
		if (status === "Success") {
			yield put(customTrainSuccess(data))
			notification.success({
				message,
				description,
				duration: 10,
			})
		} else {
			yield put(customTrainFailure())
			notification.error({
				message,
				duration: 10,
			})
		}
	} catch (error) {
		yield put(customTrainFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			duration: 4,
		})
	}
}

function* deleteCustomModelSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { message, status } } = yield call(deleteCustomModelsApi, payload, headers)

		if (status === "Success") {
			yield put(deleteModelSuccess())
			history.push("/ai-engine/new_model/model_analysis")
			notification.success({ message, duration: 4, })
			yield put(modelsConfigListRequest())
			yield put(getCustomTraingsRequest({
				"filter_name": "",
				"page_num": 1,
				"page_size": 10
			}))
		} else {
			yield put(deleteModelFailure())
			notification.error({
				message: message,
				duration: 4,
			})
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
		yield put(deleteModelFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(filterDataRequest, filterDataSaga)
	yield takeLatest(getCustomTraingsRequest, getCustomTrainingsSaga)
	yield takeLatest(customTrainRequest, customTrainSaga)
	yield takeLatest(deleteModelRequest, deleteCustomModelSaga)
}
