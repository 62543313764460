import HighchartsReact from "highcharts-react-official"
import { RefObject, useCallback, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

/**
 * Hook to redraw Highcharts chart when sidebar state changes.
 * Also returns the redraw function to redraw the chart whenever you need.
 *
 */
export const useHighChartRedraw = (): {
	ref: RefObject<HighchartsReact.RefObject>
	redraw: () => void
} => {
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
	const isSidebarCollapsed = useSelector(
		(state: StoreState) => state.sidebar.collapsed
	)

	const redraw = useCallback(() => {
		chartComponentRef.current?.chart.reflow()
	}, [])

	useEffect(() => {
		redraw()
	}, [isSidebarCollapsed, redraw])

	return { ref: chartComponentRef, redraw }
}
