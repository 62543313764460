import { createActions } from "redux-actions";

export enum FetchExperimentActionTypes {
  REQUEST = "FETCH_EXPERIMENT_REQUEST",
  SUCCESS = "FETCH_EXPERIMENT_SUCCESS",
  FAILURE = "FETCH_EXPERIMENT_FAILURE",
  CLEANUP = "FETCH_EXPERIMENT_CLEANUP",
}

export enum FetchLinkedExperimentActionTypes {
  REQUEST = "FETCH_LINKED_EXPERIMENT_REQUEST",
  SUCCESS = "FETCH_LINKED_EXPERIMENT_SUCCESS",
  FAILURE = "FETCH_LINKED_EXPERIMENT_FAILURE",
  CLEANUP = "FETCH_LINKED_EXPERIMENT_CLEANUP",
}

export enum FetchLinkedExperimentDetailsActionTypes {
  REQUEST = "FETCH_LINKED_EXPERIMENT_DETAILS_REQUEST",
  SUCCESS = "FETCH_LINKED_EXPERIMENT_DETAILS_SUCCESS",
  FAILURE = "FETCH_LINKED_EXPERIMENT_DETAILS_FAILURE",
  CLEANUP = "FETCH_LINKED_EXPERIMENT_DETAILS_CLEANUP",
}


export const {
  fetchExperimentRequest,
  fetchExperimentSuccess,
  fetchExperimentFailure,
  fetchExperimentCleanup,
  fetchLinkedExperimentRequest,
  fetchLinkedExperimentSuccess,
  fetchLinkedExperimentFailure,
  fetchLinkedExperimentCleanup,
  fetchLinkedExperimentDetailsRequest,
  fetchLinkedExperimentDetailsSuccess,
  fetchLinkedExperimentDetailsFailure,
  fetchLinkedExperimentDetailsCleanup,
} = createActions({
  [FetchExperimentActionTypes.REQUEST]: payload => payload,
  [FetchExperimentActionTypes.SUCCESS]: payload => payload,
  [FetchExperimentActionTypes.FAILURE]: payload => payload,
  [FetchExperimentActionTypes.CLEANUP]: () => { },
  [FetchLinkedExperimentActionTypes.REQUEST]: payload => payload,
  [FetchLinkedExperimentActionTypes.SUCCESS]: payload => payload,
  [FetchLinkedExperimentActionTypes.FAILURE]: payload => payload,
  [FetchLinkedExperimentActionTypes.CLEANUP]: () => { },
  [FetchLinkedExperimentDetailsActionTypes.REQUEST]: payload => payload,
  [FetchLinkedExperimentDetailsActionTypes.SUCCESS]: payload => payload,
  [FetchLinkedExperimentDetailsActionTypes.FAILURE]: payload => payload,
  [FetchLinkedExperimentDetailsActionTypes.CLEANUP]: () => { },
});
