import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { GetPerformanceActionTypes } from "../actions/performance";

export type PerformanceReducerState = {
  performanceData: any;
  getPerformanceStatus: AsyncStates;
  getPerformanceError: string;
};

const defaultState: PerformanceReducerState = {
  performanceData: {},
  getPerformanceStatus: AsyncStates.INITIAL,
  getPerformanceError: "",
};

const performanceReducer = handleActions<PerformanceReducerState, any>(
  {
    [GetPerformanceActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getPerformanceStatus: AsyncStates.LOADING,
        getPerformanceError: "",
      };
    },
    [GetPerformanceActionTypes.SUCCESS]: (state, action: any) => {
      const data = action.payload;
      return {
        ...state,
        getPerformanceStatus: AsyncStates.SUCCESS,
        getPerformanceError: "",
        performanceData: data,
      };
    },
    [GetPerformanceActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getPerformanceStatus: AsyncStates.ERROR,
      getPerformanceError: action.payload.error,
    }),
    [GetPerformanceActionTypes.CLEAR]: (state, action) => ({
      ...state,
      getPerformanceStatus: AsyncStates.INITIAL,
      performanceData: {},
      getPerformanceError: "",
    }),
  },
  defaultState,
);

export default performanceReducer;
