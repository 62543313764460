import { createActions } from "redux-actions";

export enum InviteActionTypes {
    REQUEST = "INVITE_REQUEST",
    SUCCESS = "INVITE_SUCCESS",
    FAILURE = "INVITE_FAILURE",
}

export enum GetPendingInvitationsListActionTypes {
    REQUEST = "GET_PENDING_INVITATIONS_LIST_REQUEST",
    SUCCESS = "GET_PENDING_INVITATIONS_LIST_SUCCESS",
    FAILURE = "GET_PENDING_INVITATIONS_LIST_FAILURE",
}

export enum ResendEmailActionTypes {
    REQUEST = "RESEND_EMAIL_REQUEST",
    SUCCESS = "RESEND_EMAIL_SUCCESS",
    FAILURE = "RESEND_EMAIL_FAILURE",
}

export enum WithDrawInvitationActionTypes {
    REQUEST = "WITHDRAW_INVITATION_REQUEST",
    SUCCESS = "WITHDRAW_INVITATION_SUCCESS",
    FAILURE = "WITHDRAW_INVITATION_FAILURE",
}

export const {
    inviteRequest,
    inviteSuccess,
    inviteFailure,
    getPendingInvitationsListRequest,
    getPendingInvitationsListSuccess,
    getPendingInvitationsListFailure,
    resendEmailRequest,
    resendEmailSuccess,
    resendEmailFailure,
    withdrawInvitationRequest,
    withdrawInvitationSuccess,
    withdrawInvitationFailure

} = createActions({
    [InviteActionTypes.REQUEST]: (payload) => payload,
    [InviteActionTypes.SUCCESS]: () => { },
    [InviteActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [GetPendingInvitationsListActionTypes.REQUEST]: (payload) => payload,
    [GetPendingInvitationsListActionTypes.SUCCESS]: (data: any[]) => data,
    [GetPendingInvitationsListActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ResendEmailActionTypes.REQUEST]: (payload) => payload,
    [ResendEmailActionTypes.SUCCESS]: (data: any[]) => data,
    [ResendEmailActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [WithDrawInvitationActionTypes.REQUEST]: (payload) => payload,
    [WithDrawInvitationActionTypes.SUCCESS]: (data: any[]) => data,
    [WithDrawInvitationActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
});
