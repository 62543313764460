import { handleActions } from "redux-actions";
import { ReportPreviewActionTypes } from "../actions/reportPreview";
import { AsyncStates } from "../../constants";


export type ReportPreviewReducerState = {
  reportUploadStatus: AsyncStates,
  reportRetrieveStatus: AsyncStates,
  reportDeleteStatus: AsyncStates,
  data: any
  message: string
}

const defaultState: ReportPreviewReducerState = {
  reportUploadStatus: AsyncStates.INITIAL,
  reportRetrieveStatus: AsyncStates.INITIAL,
  reportDeleteStatus: AsyncStates.INITIAL,
  data: { wo_reports: [], total: "" },
  message: ''
}

const reportPreviewReducer = handleActions({
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_REQUEST]: (state) => ({
    ...state,
    reportUploadStatus: AsyncStates.LOADING,
  }),
  [ReportPreviewActionTypes.WO_UPLOAD_REPORT_FAILURE]: (state) => ({
    ...state,
    reportUploadStatus: AsyncStates.ERROR,
  }), [ReportPreviewActionTypes.WO_UPLOAD_REPORT_SUCCESS]: (state) => ({
    ...state,
    reportUploadStatus: AsyncStates.SUCCESS,
  }),
  [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_REQUEST]: (state) => ({
    ...state,
    reportRetrieveStatus: AsyncStates.LOADING,
    data: { wo_reports: [], total: "" },
  }),
  [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_FAILURE]: (state, action) => ({
    ...state,
    reportRetrieveStatus: AsyncStates.ERROR,
    data: { wo_reports: [], total: "" },
  }), [ReportPreviewActionTypes.WO_RETRIEVE_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    reportRetrieveStatus: AsyncStates.SUCCESS,
    data: action.payload
  }),
  [ReportPreviewActionTypes.WO_DELETE_REPORT_REQUEST]: (state) => ({
    ...state,
    reportDeleteStatus: AsyncStates.LOADING,
  }),
  [ReportPreviewActionTypes.WO_DELETE_REPORT_FAILURE]: (state) => ({
    ...state,
    reportDeleteStatus: AsyncStates.ERROR,
  }), [ReportPreviewActionTypes.WO_DELETE_REPORT_SUCCESS]: (state) => ({
    ...state,
    reportDeleteStatus: AsyncStates.SUCCESS,
  }),
}, defaultState)


export default reportPreviewReducer