import { Space } from "antd";
import { CommentList } from "../CommentList/CommentList";
import { CommentEditor } from "../CommentEditor";
import { FormOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useEffect, useMemo, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, useSubscription } from "react-relay";
import dayjs from "dayjs";
import { StyledCard } from "src/styled_components/StyledCard";
import { Team } from "src/typings";
import { Dropdown, OnlineUser } from "./dropdown";
import { Comment } from "@ant-design/compatible";

const commentsSectionSubscription = graphql`
  subscription CommentsSectionSubscription(
    $work_order_id: String!
    $user_id: String!
  ) {
    woOnlineUsers(work_order_id: $work_order_id, user_id: $user_id) {
      type
      data {
        user_id
      }
    }
  }
`;

const commentsSectionMutation = graphql`
  mutation CommentsSectionMutation($user_id: String!, $work_order_id: String!) {
    setWoOnlineUserStatus(user_id: $user_id, work_order_id: $work_order_id) {
      user_id
    }
  }
`;

export const CommentsSection = () => {
  const [t] = useTranslate();
  const users = useSelector((state: StoreState) => state.teams.data) as Team[];
  const user_id = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );
  const [onlineUsers, setOnlineUsers] = useState<OnlineUser[]>([]);
  const [commit] = useMutation(commentsSectionMutation);

  const config = useMemo(
    () => ({
      variables: { work_order_id, user_id },
      subscription: commentsSectionSubscription,
      onNext: ({
        woOnlineUsers: {
          data: { user_id },
        },
      }: any) => {
        setOnlineUsers((state) => {
          const user = users.find((user) => user_id === user.user_id);
          if (user) return [{ ...user, lastSeen: dayjs() }, ...state];
          return state;
        });
      },
    }),
    [work_order_id, user_id, users]
  );

  useSubscription(config);

  useEffect(() => {
    const clearStatus = setInterval(function () {
      setOnlineUsers((state) =>
        state.filter((user) =>
          user.lastSeen.isAfter(dayjs().subtract(15, "seconds"))
        )
      );
    }, 15000);
    const publishStatus = setInterval(function () {
      commit({ variables: { user_id, work_order_id } });
    }, 10000);
    return () => {
      clearInterval(publishStatus);
      clearInterval(clearStatus);
    };
  }, [user_id, work_order_id, commit]);

  if (!Boolean(work_order_id)) return null;

  return (
    <StyledCard
      title={
        <Space size={16}>
          <FormOutlined style={{fontSize:12}}/>
          {t("common.comments")}
        </Space>
      }
      extra={
        <Dropdown onlineUsers={onlineUsers} user_id={user_id} users={users} />
      }
      headStyle={{padding:"8px 16px"}}
      bodyStyle={{padding:"0 16px"}}
    >
      <Comment
        style={{
          padding: 10,
          borderRadius: 5,
        }}
        content={<CommentEditor />}
      />
      <CommentList />
    </StyledCard>
  );
};
