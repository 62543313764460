import { createActions } from "redux-actions"

export enum DataSummaryActionTypes {
	REQUEST = "DATA_SUMMARY_REQUEST",
	SUCCESS = "DATA_SUMMARY_SUCCESS",
	FAILURE = "DATA_SUMMARY_FAILURE",
	CLEAR = "DATA_SUMMARY_CLEAR",
}

export enum BoxPlotSummaryActionTypes {
	REQUEST = "BOX_PLOT_SUMMARY_REQUEST",
	SUCCESS = "BOX_PLOT_SUMMARY_SUCCESS",
	FAILURE = "BOX_PLOT_SUMMARY_FAILURE",
	CLEAR = "BOX_PLOT_SUMMARY_CLEAR",
}

export enum RecipeDistributionActionTypes {
	REQUEST = "RECIPE_DISTRIBUTION_REQUEST",
	SUCCESS = "RECIPE_DISTRIBUTION_SUCCESS",
	FAILURE = "RECIPE_DISTRIBUTION_FAILURE",
	CLEAR = "RECIPE_DISTRIBUTION_CLEAR"
}

export enum GetZeonCharacterizationDsmActionTypes {
	REQUEST = "GET_ZEON_CHARACTERIZATION_DSM_REQUEST",
	SUCCESS = "GET_ZEON_CHARACTERIZATION_DSM_SUCCESS",
	FAILURE = "GET_ZEON_CHARACTERIZATION_DSM_FAILURE",
	CLEAR = "GET_ZEON_CHARACTERIZATION_DSM_CLEAR",
}

export const {
	dataSummaryRequest,
	dataSummarySuccess,
	dataSummaryFailure,
	dataSummaryClear,
	recipeDistributionRequest,
	recipeDistributionSuccess,
	recipeDistributionFailure,
	recipeDistributionClear,
	getZeonCharacterizationDsmRequest,
	getZeonCharacterizationDsmSuccess,
	getZeonCharacterizationDsmFailure,
	getZeonCharacterizationDsmClear,
	boxPlotSummaryRequest,
	boxPlotSummarySuccess,
	boxPlotSummaryFailure,
	boxPlotSummaryClear,
} = createActions({
	[DataSummaryActionTypes.REQUEST]: (payload) => payload,
	[DataSummaryActionTypes.SUCCESS]: (data) => ({ data }),
	[DataSummaryActionTypes.FAILURE]: (error) => ({ error }),
	[DataSummaryActionTypes.CLEAR]: () => { },
	[RecipeDistributionActionTypes.REQUEST]: (payload) => payload,
	[RecipeDistributionActionTypes.SUCCESS]: (data) => ({ data }),
	[RecipeDistributionActionTypes.FAILURE]: (error) => ({ error }),
	[RecipeDistributionActionTypes.CLEAR]: () => { },
	[GetZeonCharacterizationDsmActionTypes.REQUEST]: (payload) => payload,
	[GetZeonCharacterizationDsmActionTypes.SUCCESS]: (response) => (response),
	[GetZeonCharacterizationDsmActionTypes.FAILURE]: (error) => ({ error }),
	[GetZeonCharacterizationDsmActionTypes.CLEAR]: () => { },
	[BoxPlotSummaryActionTypes.REQUEST]: (payload) => payload,
	[BoxPlotSummaryActionTypes.SUCCESS]: (data) => ({ data }),
	[BoxPlotSummaryActionTypes.FAILURE]: (error) => ({ error }),
	[BoxPlotSummaryActionTypes.CLEAR]: () => { },
})
