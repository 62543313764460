import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	woUploadReportApi,
	woRetrieveReportApi,
	woDeleteReportApi,
} from "../../services/reportPreview"
import { messages } from "src/utils/hooks"
import {
	woUploadReportFailure,
	woUploadReportRequest,
	woUploadReportSuccess,
	woRetrieveReportFailure,
	woRetrieveReportRequest,
	woRetrieveReportSuccess,
	woDeleteReportFailure,
	woDeleteReportRequest,
	woDeleteReportSuccess,
} from "../actions/reportPreview"
import { notification } from "antd"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"

function* woUploadReportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = new FormData()
		payload?.report && params.append("report", payload.report)
		params.append("payload", JSON.stringify(payload.payload))
		const {
			data: { result },
		} = yield call(woUploadReportApi, params, headers)

		if (result.status === "Success") {
			yield put(woUploadReportSuccess(result))
			if (!!payload.report) {
				notification.success({ message: result.message })
				window.open(result.data.download_link, "_blank")
			}
			yield put(
				woRetrieveReportRequest({ workOrderId: payload.payload.workOrderId })
			)
		} else {
			yield put(woUploadReportFailure(result.message))
		}
	} catch (err) {
		yield put(woUploadReportFailure(messages[ln].internal_server_error))
	}
}

function* woRetrieveReportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(woRetrieveReportApi, payload, headers)
		if (result.status === "Success") {
			yield put(woRetrieveReportSuccess(result.data))
		} else {
			yield put(woRetrieveReportFailure(result.message))
		}
	} catch (err) {
		yield put(woRetrieveReportFailure(messages[ln].internal_server_error))
	}
}

function* woDeleteReportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(woDeleteReportApi, payload, headers)

		if (result.status === "Success") {
			yield put(woDeleteReportSuccess(result.data))
			yield put(woRetrieveReportRequest({ workOrderId: payload.workOrderId }))
			notification.success({ message: result.message })
		} else {
			yield put(woDeleteReportFailure(result.message))
		}
	} catch (err) {
		yield put(woDeleteReportFailure(messages[ln].internal_server_error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(woUploadReportRequest, woUploadReportSaga)
	yield takeLatest(woRetrieveReportRequest, woRetrieveReportSaga)
	yield takeLatest(woDeleteReportRequest, woDeleteReportSaga)
}
