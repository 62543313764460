import { useEffect, useMemo } from "react";
import "./styles/Global.scss";
import AppRouter from "./router/AppRouter";
import {
  displayNamesRequest,
  displayNamesUpdate,
} from "./store/actions/displayNames";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "./store/configureStore";
import { ConfigProvider, message, notification } from "antd";
import * as Sentry from "@sentry/react";
import { switchAntdLocale } from "./utils/switchLocale";
import {
  notificationAdd,
  notificationsRequest,
} from "./store/actions/notifications";
import { defaultHeaders, platformInfo, projectType } from "./constants";
import { refreshTokenRequest, setTwoFaStatus } from "./store/actions/login";
import { teamsRequest } from "./store/actions/teams";
import { fetchProjectListRequest } from "./store/actions/projects";
import mixpanel from "mixpanel-browser";
import { celsureDisplayNamesRequest } from "./store/actions/celsure";
import graphql from "babel-plugin-relay/macro";
import { useSubscription } from "react-relay";
import {
  formulateSuccess,
  formulateFailure,
  forwardPredListRequest,
  forwardPredResultRequest,
  modelsConfigListRequest,
} from "./store/actions/formulate";
import { enabledDomains } from "src";
import "react-datasheet-grid/dist/style.css";
import { unitListRequest } from "./store/actions/conversion";
import jwtManager from "./utils/jwtManager";
import { LoginProgress } from "./store/reducers/login";
import { setDefaultHeaders } from "./store/actions/defaultHeaders";
import { BroadcastChannel } from "broadcast-channel";
import { useDVCClient, withDVCProvider } from "@devcycle/devcycle-react-sdk";
import { configsRequest } from "./store/actions/configs";
import {
  COLLECTION_ENVIRONTMENT_SUFFIX,
  FB_COLLECTION_NAME_PRESENCE,
  FB_COLLECTION_NAME_RELEASES,
  firestoreDb,
} from "./utils/firebase";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { Userpilot } from "userpilot";
import { useLocation } from "react-router-dom";

const appSubscription = graphql`
  subscription AppSubscription($company_id: String!) {
    displayNames(company_id: $company_id) {
      type
      data {
        name
        unit
        category
        identifier
        property_type
      }
    }
  }
`;

const appForwardPredSubscription = graphql`
  subscription AppForwardPredSubscription($user_id: String!) {
    forwardPred(user_id: $user_id) {
      prediction_id
      message
      status
    }
  }
`;

const notificationSubscription = graphql`
  subscription AppNotificationSubscription($user_id: String!) {
    notifications(user_id: $user_id) {
      data {
        message {
          en {
            message {
              receivers_message
              description
              senders_message
            }
          }
          fr {
            message {
              receivers_message
              description
              senders_message
            }
          }
          ja {
            message {
              receivers_message
              description
              senders_message
            }
          }
        }
        notification_type
        created
        notification_id
        is_read
        download_link
        work_order_id
        sender
        receiver
      }
    }
  }
`;

export const tokenBroadcast = new BroadcastChannel("token_channel");
export const currentPlatform = process.env.REACT_APP_CURRENT_APP;

function App() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: StoreState) => state.language.current
  );
  const locale = useSelector((state: StoreState) => state.language.current);

  const antdLocale = useMemo(() => {
    return switchAntdLocale(locale);
  }, [locale]);

  tokenBroadcast.onmessage = (message: any) => {
    if (!!user_id) {
      jwtManager.setToken(message);
      window.indexedDB.deleteDatabase(
        "pubkey.broadcast-channel-0-token_channel"
      );
    }
  };

  const {
    user_loggedin_IP,
    user_id,
    user_name,
    user_email,
    company,
    company_id,
    created,
    image_url,
    project_type,
  } = useSelector<StoreState, any>(
    (state: StoreState) => state.login.loginResponse
  );
  const { tokenStatus, isLoginComplete } = useSelector(
    (state: StoreState) => state.login
  );
  const headers = useSelector((state: StoreState) => state.defaultHeaders);
  const tokenValid = useMemo(
    () => !!jwtManager.getToken() && !!user_id,
    [user_id]
  );
  const token = jwtManager.getToken();

  const location = useLocation();

  useEffect(() => {
    if (enabledDomains.includes(window.location.host)) {
      (window as any).clarity("set", "email", user_email);
      (window as any).clarity("set", "company", company);
      mixpanel.identify(user_id);
      mixpanel.people.set({
        $avatar: image_url,
        $name: user_name,
        $email: user_email,
        company: company,
      });
      Sentry.setUser({
        id: user_id,
        username: user_name,
        email: user_email,
        ip_address: user_loggedin_IP,
      });
      Sentry.setTag("user_name", user_name);
      Sentry.setTag("company", company);
    }
  }, [user_id, user_name, user_email, user_loggedin_IP, company, image_url]);

  useEffect(() => {
    if (currentPlatform) {
      document.title = platformInfo[currentPlatform].name;
    }
  }, []);

  useEffect(() => {
    const userDetails = {
      email: user_email,
      user_id: user_id,
      name: user_name,
      created_at: created,
      company: {
        company_name: company,
        company_id: company,
      },
    };
    const setDetails: Boolean = !!(
      user_email &&
      user_id &&
      user_name &&
      created &&
      company
    );
    (window as any).Intercom("boot", {
      app_id: "xumvd7ya",
      language_override: currentLanguage === "zh" ? "zh-CN" : currentLanguage,
      ...(setDetails ? userDetails : {}),
    });
  }, [company, created, user_email, user_id, user_name, currentLanguage]);

  useEffect(() => {
    dispatch(
      setDefaultHeaders({
        ...defaultHeaders,
        lang: currentLanguage,
        platform: currentPlatform,
      })
    );
  }, [currentLanguage, dispatch]);

  useEffect(() => {
    if (
      isLoginComplete === LoginProgress.COMPLETE &&
      !jwtManager.getToken() &&
      !!user_id
    ) {
      dispatch(refreshTokenRequest());
    }
  }, [dispatch, tokenValid, isLoginComplete, user_id]);

  useEffect(() => {
    tokenStatus &&
      !!jwtManager.getToken() &&
      dispatch(displayNamesRequest({ inputs: [] }));
  }, [locale, dispatch, tokenStatus, headers]);

  useEffect(() => {
    if (tokenStatus && !!jwtManager.getToken()) {
      dispatch(configsRequest());
      dispatch(notificationsRequest());
      dispatch(teamsRequest());
      dispatch(unitListRequest({}));
    }
  }, [dispatch, tokenStatus]);

  useEffect(() => {
    const isTokenAvailable = tokenStatus && !!jwtManager.getToken();
    if (isTokenAvailable) {
      dispatch(fetchProjectListRequest({ set: true }));
    }
    if (project_type === projectType.celsure && isTokenAvailable) {
      dispatch(celsureDisplayNamesRequest());
    }
  }, [dispatch, project_type, tokenStatus]);

  useEffect(() => {
    return () => {
      notification.destroy();
      message.destroy();
    };
  }, [token]);

  const config = useMemo(
    () => ({
      variables: { company_id },
      subscription: appSubscription,
      onNext: (msg: any) => {
        dispatch(displayNamesUpdate(msg));
      },
    }),
    [company_id, dispatch]
  );
  useSubscription(config);

  const forwardPredConfig = useMemo(
    () => ({
      variables: { user_id },
      subscription: appForwardPredSubscription,
      onNext: (response: any) => {
        const prediction_id = response?.forwardPred?.prediction_id;
        const failed = response?.forwardPred?.status === "failed";
        const responseMessage = response?.forwardPred?.message;
        if (!!prediction_id && !failed) {
          dispatch(
            forwardPredResultRequest({
              prediction_id: prediction_id,
              type: "predictions",
            })
          );
          dispatch(forwardPredListRequest({ pageNum: 1, pageSize: 10 }));
          dispatch(formulateSuccess());
        } else {
          dispatch(formulateFailure());
          message.error(responseMessage);
        }
      },
    }),
    [user_id, dispatch]
  );
  useSubscription(forwardPredConfig);

  //Notifications Subscription
  const notificationsConfig = useMemo(
    () => ({
      variables: { user_id },
      subscription: notificationSubscription,
      onNext: (msg: any) => {
        if (Object.keys(msg?.notifications?.data || {}).length) {
          const response = msg?.notifications?.data;
          switch (response?.notification_type) {
            case "2fa_status":
              dispatch(setTwoFaStatus(true));
              break;
            case "customML":
              dispatch(modelsConfigListRequest());
              break;
          }
          if (response?.receiver?.includes(user_id)) {
            notification.success({
              message:
                response?.message?.[currentLanguage]?.message
                  ?.receivers_message,
              description:
                response?.message?.[currentLanguage]?.message?.description ||
                null,
              duration: 8,
            });
          }
          dispatch(
            notificationAdd({
              ...response,
              message: response?.message?.[currentLanguage]?.message,
            })
          );
        }
      },
    }),
    [user_id, currentLanguage, dispatch]
  );
  useSubscription(notificationsConfig);

  const dvcClient = useDVCClient();

  useEffect(() => {
    if (user_id) {
      const newUser = {
        user_id,
        email: user_email,
      };

      dvcClient.identifyUser(newUser);
    }
  }, [user_id, user_email, dvcClient]);

  useEffect(() => {
    const setUserPresenceOnFirebase = async (online: boolean) => {
      const userDocRef = doc(firestoreDb, FB_COLLECTION_NAME_PRESENCE, user_id);

      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        await updateDoc(userDocRef, {
          online,
        });
      } else {
        await setDoc(doc(firestoreDb, FB_COLLECTION_NAME_PRESENCE, user_id), {
          online,
        });
      }
    };
    if (user_id) {
      setUserPresenceOnFirebase(true);
    }

    return () => {
      setUserPresenceOnFirebase(false);
    };
  }, [user_id]);

  // const dvcReady = useIsDVCInitialized()

  // const variables = dvcClient.allVariables()
  // console.log("🚀 ~ file: App.tsx:298 ~ App ~ variables:", variables)

  // useEffect(() => {
  // 	if (dvcReady) {
  // 		if (localStorage.getItem("version")) {
  // 			if (version !== localStorage.getItem("version")) {
  // 				// if (window.confirm(`🎉 Update to version ${version}!\n\nCaution: All unsaved changes will be lost if you choose proceed.`)) {
  // 				localStorage.setItem("version", version)
  // 				// window.location.reload();
  // 				// }
  // 			}
  // 		} else {
  // 			localStorage.setItem("version", version)
  // 		}
  // 	}
  // }, [version, dvcReady])

  const isSIT = process.env.REACT_APP_ENV === "sit";
  useEffect(() => {
    if (user_id && isSIT) {
      Userpilot.identify(user_id, {
        name: user_name,
        email: user_email,
      });
    }
  }, [isSIT, user_email, user_id, user_name]);

  useEffect(() => {
    if (user_id && isSIT) {
      Userpilot.reload();
    }
  }, [user_id, location, isSIT]);

  useEffect(() => {
    let unsub: Unsubscribe;
    const listenToAppUpdates = async () => {
      const versionDocRef = doc(
        firestoreDb,
        `${FB_COLLECTION_NAME_RELEASES}/${COLLECTION_ENVIRONTMENT_SUFFIX}`
      );
      unsub = onSnapshot(versionDocRef, (doc) => {
        const versionDoc = doc.data();
        if (versionDoc) {
          console.log("GGG", versionDoc);
          // @todo - Abhi add your logic here
        }
      });
    };

    listenToAppUpdates();

    return () => {
      unsub && unsub();
    };
  }, []);

  return (
    <ConfigProvider locale={antdLocale}>
      <AppRouter />
    </ConfigProvider>
  );
}

export default withDVCProvider({
  sdkKey: process.env.REACT_APP_DVC_CLIENT_SDK_KEY ?? "",
})(App);
