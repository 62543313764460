import { createActions } from "redux-actions";
import { CompleteManualSignUpPayload, SignupResponse } from "../../services/login/interface";

export enum SignupActionTypes {
    REQUEST = "SIGNUP_REQUEST",
    SUCCESS = "SIGNUP_SUCCESS",
    FAILURE = "SIGNUP_FAILURE",
    CLEAR = "SIGNUP_CLEAR_ERROR",
}

export const {
    signupRequest,
    signupSuccess,
    signupFailure,
    signupClearError,
} = createActions({
    [SignupActionTypes.REQUEST]: (payload: CompleteManualSignUpPayload) => payload,
    [SignupActionTypes.SUCCESS]: (signupResponse: SignupResponse) => ({
        signupResponse,
    }),
    [SignupActionTypes.FAILURE]: (error: string) => ({ error }),
    [SignupActionTypes.CLEAR]: () => {
    }
});
