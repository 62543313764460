import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	formulateRequest,
	formulateFailure,
	modelsConfigRequest,
	modelsConfigSuccess,
	modelsConfigFailure,
	forwardPredDeleteFailure,
	forwardPredDeleteRequest,
	forwardPredDeleteSuccess,
	forwardPredListFailure,
	forwardPredListRequest,
	forwardPredListSuccess,
	forwardPredResultFailure,
	forwardPredResultRequest,
	forwardPredResultSuccess,
	getForwardConfigsRequest,
	getForwardConfigsSuccess,
	getForwardConfigsFailure,
	getForwardConfigsZeonRequest,
	getForwardConfigsZeonSuccess,
	getForwardConfigsZeonFailure,
	setMetric,
	getCharacterizationMethodsZeonRequest,
	getCharacterizationMethodsZeonFailure,
	getCharacterizationMethodsZeonSuccess,
	addFavoritesRequest,
	addFavoritesSuccess,
	addFavoritesFailure,
	getFavoritesRequest,
	getFavoritesSuccess,
	getFavoritesFailure,
	deleteFavoritesRequest,
	deleteFavoritesSuccess,
	deleteFavoritesFailure,
	favouriteModelRequest,
	favouriteModelSuccess,
	favouriteModelFailure,
	modelsConfigListSuccess,
	modelsConfigListFailure,
	modelsConfigListRequest
} from "../actions/formulate"
import {
	fetchModelsConfig,
	forwardPredDeleteApi,
	forwardPredListApi,
	forwardPredRetrieveApi,
	getForwardConfigsApi,
	predictNewApi,
	predictApi,
	predictPlussApi,
	predictCeslureApi,
	getForwardConfigsZeonApi,
	getCharacterizationMethodsZeonApi,
	predictNewZeonAPI,
	forwardPredRetrieveZeonApi,
	addFavoriteApi,
	getFavoritesApi,
	deleteFavoritesApi,
	forwardPredFavApi,
	maxellForwardApi,
	fetchModelsConfigList
} from "src/services/forwardModel"
import {
	PLUSS_HS05_MODEL_CONFIG,
	maxellForwardVersion,
} from "src/constants"
import { message, notification } from "antd"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"


type formulateAction = {
	type: string
	payload: {
		company?: string
		model_names: string[]
		input_data: any[]
		objective: string
		version: any
		project_type?: string
		prediction_type?: string
	}
}

function* modelsConfigListSaga(): Generator<any, any, any> {
	try {
		const configs = yield select((state: StoreState) => state.configs.features)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(fetchModelsConfigList, null, headers)

		if (status === 200) {
			if (Boolean(configs?.hs05_model_config)) {
				data.push(PLUSS_HS05_MODEL_CONFIG)
			}
			yield put(modelsConfigListSuccess(data))
		}
	} catch (error) {
		yield put(modelsConfigListFailure(error))
	}
}

function* modelsConfigSaga({ payload }: any): Generator<any, any, any> {
	try {
		const configs = yield select((state: StoreState) => state.configs.features)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(fetchModelsConfig, payload, headers)

		if (status === 200) {
			if (Boolean(configs?.hs05_model_config)) {
				data.push(PLUSS_HS05_MODEL_CONFIG)
			}
			yield put(modelsConfigSuccess(data))
		}
	} catch (error) {
		yield put(modelsConfigFailure(error))
	}
}

function* formulateSaga({
	payload,
}: formulateAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const configs = yield select((state: StoreState) => state.configs.features)

		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		if (Boolean(configs?.zeon_configs)) {
			const {
				data: {
					data,
					status,
					message: errorMessage
				},
			} = yield call(predictNewZeonAPI, payload, headers)

			if (status === "Success") {
				const { statements } = data
				yield put(setMetric(statements ?? {}))
				const entries = Object.entries(statements ?? {})
				const values: object[] = Object.values(statements ?? {})

				if (
					entries.length > 0 &&
					values.every((value) =>
						Object.keys(value ?? {}).includes("matching_subset")
					)
				) {
					notification.error({
						message: messages[ln].ai_engine_no_matching_models,
						duration: 5,
					})
					yield put(formulateFailure())
				}
			} else if (status === "Failure") {
				yield put(formulateFailure())
				message.error(errorMessage)
			} else {
				yield put(formulateFailure())
				message.error(messages[ln].internal_server_error)
			}
			return
		}

		// 1) project_type = celsure, call predict celsure api
		// 2) version = pluss_custom, call predict pluss api
		// 3) version exists or version > 0, call Api for custom models
		// 4) Call predict new API with project_id
		const {
			data: {
				data: { status, statements, message: errorMessage },
			},
		} = yield call(
			(maxellForwardVersion.includes((Number(payload?.version))) && Boolean(configs?.maxell_configs)) ? maxellForwardApi : payload?.prediction_type === "celsure"
				? predictCeslureApi
				: payload?.version === -1
					? predictPlussApi
					: Number(payload?.version) >= 0
						? predictApi
						: predictNewApi,
			payload,
			headers
		)

		if (status === "Success") {
			yield put(setMetric(statements || {}))
			const entries = Object.entries(statements || {})

			if (
				entries.length > 0 &&
				entries.every(([_, value]: any) =>
					Object.keys(value || {}).includes("matching_subset")
				)
			) {
				notification.error({
					message: messages[ln].ai_engine_no_matching_models,
					duration: 5,
				})
				yield put(formulateFailure())
			}
		} else if (status === "Failure") {
			yield put(formulateFailure())
			message.error(errorMessage)
		} else {
			yield put(formulateFailure())
			message.error(messages[ln].internal_server_error)
		}
	} catch (error) {
		yield put(formulateFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* forwardPredListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, total },
			},
			status,
		} = yield call(forwardPredListApi, payload, headers)
		if (status === 200) {
			yield put(
				forwardPredListSuccess({ forwardData: data, forwardTotal: total })
			)
		} else {
			yield put(forwardPredListFailure())
		}
	} catch (error) {
		yield put(forwardPredListFailure(error))
	}
}

function* forwardPredResultSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)


	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const configs = yield select((state: StoreState) => state.configs.features)

		const {
			data: {
				result: { data },
			},
			status,
		} = yield call(
			Boolean(configs?.zeon_configs)
				? forwardPredRetrieveZeonApi
				: forwardPredRetrieveApi,
			payload,
			headers
		)
		if (status === 200) {
			if (data?.predictions?.some((res: any) => !!Object.keys(res?.predicted_properties || {})?.length)) {
				yield put(forwardPredResultSuccess(data))
				// if (payload?.type === "predictions") {
				// 	notification.success({
				// 		message: responseMessage,
				// 		description: "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
				// 		duration: 0
				// 	})
				// }
			} else {
				message.error(messages[ln].ai_engine_no_matching_models)
				yield put(
					forwardPredResultFailure(messages[ln].ai_engine_no_matching_models)
				)
			}
		} else {
			yield put(forwardPredResultFailure())
		}
	} catch (error) {
		yield put(forwardPredResultFailure(error))
	}
}

function* forwardPredDeleteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status, data } = yield call(forwardPredDeleteApi, payload, headers)

		if (status === 200) {
			yield put(forwardPredDeleteSuccess())
			notification.success({
				message: data.result.message,
			})
		} else {
			yield put(forwardPredDeleteFailure())
		}
	} catch (error) {
		yield put(forwardPredDeleteFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* getMlProjectSpecificDataSaga({
	payload,
}: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(getForwardConfigsApi, payload, headers)

		if (status === 200) {
			yield put(getForwardConfigsSuccess(data))
		} else {
			yield put(getForwardConfigsFailure())
		}
	} catch (error) {
		yield put(getForwardConfigsFailure(error))
	}
}

function* getMlProjectSpecificDataZeonSaga({
	payload,
}: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(getForwardConfigsZeonApi, payload, headers)

		if (status === 200) {
			yield put(getForwardConfigsZeonSuccess(data))
		} else {
			yield put(getForwardConfigsZeonFailure())
		}
	} catch (error) {
		yield put(getForwardConfigsZeonFailure(error))
	}
}

function* getCharacterizationMethodsZeonSaga({ payload: apiPayload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)

		const { payload, from } = apiPayload
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data, message },
			status,
		} = yield call(getCharacterizationMethodsZeonApi, payload, headers)

		if (status === 200) {
			if (!Object.keys(data ?? {})[0]) {
				notification.error({ message: "No variations found for this characterization", duration: 3 })
			}
			yield put(getCharacterizationMethodsZeonSuccess(data, from))
		} else {
			yield put(getCharacterizationMethodsZeonFailure(message))
		}
	} catch (error) {
		yield put(getCharacterizationMethodsZeonFailure(error))
	}
}

function* addFavoriteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { message, status },
		} = yield call(addFavoriteApi, payload, headers)
		if (status === "Success") {
			yield put(addFavoritesSuccess())
			notification.success({
				message: message,
			})

		} else {
			yield put(addFavoritesFailure(message))
			notification.error({
				message: message,
			})
		}

	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}

}

function* getFavoritesListSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, message, status, total_count, display_names },
			},
		} = yield call(getFavoritesApi, payload, headers)

		if (status === "Success") {
			yield put(getFavoritesSuccess({
				favoritesList: data,
				favoritesDisplayNames: display_names,
				total_count
			}))
		} else {
			yield put(getFavoritesFailure(message))
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}
}

function* deleteFavoritesSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { message, status },
		} = yield call(deleteFavoritesApi, payload, headers)
		if (status === "Success") {
			yield put(deleteFavoritesSuccess())
			notification.success({
				message: message,
			})
		} else {
			yield put(deleteFavoritesFailure(message))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}
}


function* favoriteModelSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result: { status, data, message } },
		} = yield call(forwardPredFavApi, payload, headers)
		if (status === "Success") {
			yield put(favouriteModelSuccess(data))
			notification.success({
				message: message,
			})
		} else {
			yield put(favouriteModelFailure(message))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		yield put(favouriteModelFailure())
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(modelsConfigListRequest, modelsConfigListSaga)
	yield takeLatest(modelsConfigRequest, modelsConfigSaga)
	yield takeLatest(formulateRequest, formulateSaga)
	yield takeLatest(forwardPredListRequest, forwardPredListSaga)
	yield takeLatest(forwardPredResultRequest, forwardPredResultSaga)
	yield takeLatest(forwardPredDeleteRequest, forwardPredDeleteSaga)
	yield takeLatest(getForwardConfigsRequest, getMlProjectSpecificDataSaga)
	yield takeLatest(
		getForwardConfigsZeonRequest,
		getMlProjectSpecificDataZeonSaga
	)
	yield takeLatest(
		getCharacterizationMethodsZeonRequest,
		getCharacterizationMethodsZeonSaga
	)
	yield takeLatest(addFavoritesRequest, addFavoriteSaga)
	yield takeLatest(getFavoritesRequest, getFavoritesListSaga)
	yield takeLatest(deleteFavoritesRequest, deleteFavoritesSaga)
	yield takeLatest(favouriteModelRequest, favoriteModelSaga)
}
