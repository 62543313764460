import axios from 'axios'
import { Headers } from "../interface"


export const generateNewExperimentAPi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_doe_experiments',
    params,
    {
      headers
    }
  )
}


export const listAlgorithmsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_doe_algorithms',
    params,
    {
      headers
    }
  )
}


export const uploadDoeExperimentApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'upload_latest_doe_experiments',
    params,
    {
      headers
    }
  )
}


export const getDoeHistoryListApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'get_doe_history_list',
    params,
    {
      headers
    }
  )
}


export const retrieveDoeExperimentsApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'retrieve_doe_experiment',
    params,
    {
      headers
    }
  )
}


export const deleteDoeApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'delete_doe',
    params,
    {
      headers
    }
  )
}


export const editDoeApi = (params: any, headers: Headers) => {
  return axios.post<any>(
    process.env.REACT_APP_ML_URL + 'edit_doe',
    params,
    {
      headers
    }
  )
}
